<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let team;

	const dispatch = createEventDispatcher();

	function handleWinConditionActiveChange({ detail }) {
		dispatch("event", { value: { ...team, winConditionActive: detail.value } });
	}

	function handleWinConditionPassiveChange({ detail }) {
		dispatch("event", {
			value: { ...team, winConditionPassive: detail.value },
		});
	}
</script>

<div class="grid grid-cols-2 gap-2">
	<SetupValueSelect
		dataType="True or False"
		scopeTypes={["Game", "Team"]}
		excludedValueTypes={["Constant"]}
		label="Active Win Condition"
		placeholder="Active Win Condition"
		value={team.winConditionActive}
		on:event={handleWinConditionActiveChange}
	/>
	<SetupValueSelect
		dataType="True or False"
		scopeTypes={["Game", "Team"]}
		excludedValueTypes={["Constant"]}
		label="Passive Win Condition"
		placeholder="Passive Win Condition"
		value={team.winConditionPassive}
		on:event={handleWinConditionPassiveChange}
	/>
</div>

<script>
	import { register, activeRoute } from "./Router.svelte";

	import urlStore from "../../../../stores/urlStore.js";
	import pageNameStore from "../../../../stores/pageNameStore.js";

	import interpolateUrl from "../../../../util/interpolateUrl.js";

	import AccessChecker from "../AccessChecker.svelte";

	export let route;

	// define new middleware property
	export let middleware = [];

	let params = {};
	let show = false;

	// pass in middlewares to Router.
	register({ path: route.path, component: route.component, middleware });

	$: handleActivePathChange($activeRoute.path);

	function handleActivePathChange(activePath) {
		if (activePath === route.path) handleRouteActivated();
	}

	function handleRouteActivated() {
		show = false;
		show = true;
		params = $activeRoute.params;
		pageNameStore.set(route.name);
		const url = interpolateUrl(route.path, params);
		urlStore.set(url);
	}
</script>

{#if $activeRoute.path === route.path}
	{#if $activeRoute.component}
		<AccessChecker activeRoute={$activeRoute}>
			<svelte:component this={$activeRoute.component} {...$$restProps} />
		</AccessChecker>
	{:else}
		<slot {params} />
	{/if}
{/if}

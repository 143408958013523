<script>
	import Badge from "../../../../tailwind/Badge.svelte";

	export let flavor;
	export let title;

	$: style = `background-color: ${flavor.secondaryColor}; border-color: ${flavor.primaryColor};`;
</script>

<div class="w-full border-4 p-4 rounded-lg mt-4" {style}>
	<div class="flex w-full">
		{#if title}
			<Badge
				backgroundColor={flavor.primaryColor}
				textColor={flavor.secondaryColor}
				size="lg"
				removeZIndex
			>
				{title}
			</Badge>
		{/if}
		<div class="grow"></div>
		<slot name="rightOfTitle" />
	</div>
	<slot />
</div>

<script>
	import { setContext, onDestroy } from "svelte";
	import { writable } from "svelte/store";

	import currentUserStore from "../../../../stores/data/currentUserStore";
	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";
	import gamesStore from "../../../../stores/data/gameCreator/gamesStore";
	import itemsCountStore from "../../../../stores/itemsCountStore";
	import scrollStore from "../../../../stores/scrollStore";
	import documentVisibleStore from "../../../../stores/documentVisibleStore";

	import topicChannel from "../../../../channels/topicChannel.js";

	import BbCodeManager from "../../../../bbCode/BbCodeManager";

	import PostsWrapper from "../../../shared/PostsWrapper.svelte";
	import Post from "../post/Post.svelte";
	import Fetcher from "../../../../api/Fetcher";
	import TopicAction from "../../gameCreator/game/action/TopicAction.svelte";

	const userStylesStore = writable({});
	setContext("userStylesStore", userStylesStore);

	$: domainSlug = $paramsStore.domainSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: game = $gamesStore[gameSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];
	$: pageNumber =
		parseInt($paramsStore.pageNumber) ||
		$paramsStore.pageCount ||
		$paramsStore.pageNumber;
	$: posts = getPosts($currentUserStore, topic, pageNumber);

	$: userStylesStore.set({
		...((game && game.userStyles) || {}),
		...((topic && topic.userStyles) || {}),
	});

	let lastViewedPostNumber = 0;
	let updateLastViewedPostNumber = false;
	let debounceTimer;
	let channel;

	$: fetchPostsPage(pageNumber, $documentVisibleStore);

	$: itemsCountStore.set(topic?.postCount || 0);

	$: handleSlugsChange({
		domainSlug,
		sectionSlug,
		gameSlug,
		topicSlug,
	});

	function getPosts(currentUser, topic, pageNumber) {
		const pageSize = currentUser?.settings?.pageSize || 50;
		const pageCount = Math.floor((topic.postCount - 1) / pageSize) + 1;
		const effectivePageNumber = currentUser?.settings?.newestPostFirst
			? pageCount - pageNumber + 1
			: pageNumber;
		const offset = pageSize * (effectivePageNumber - 1);
		const result = topic?.posts?.slice(offset, offset + pageSize) || [];
		return currentUser?.settings?.newestPostFirst
			? result.toReversed()
			: result;
	}

	function handleSlugsChange(slugs) {
		topicsStore.fetchTopic(slugs);
		if (slugs.gameSlug) gamesStore.fetchGame(slugs.gameSlug);
		channel?.unsubscribe();
		channel = topicChannel(
			slugs.gameSlug ? `${slugs.gameSlug}/${slugs.topicSlug}` : slugs.topicSlug
		);
	}

	function fetchPostsPage(pageNumber, visible) {
		if (visible && pageNumber != null) {
			topicsStore.fetchPostsPage({
				domainSlug,
				sectionSlug,
				gameSlug,
				topicSlug,
				pageNumber,
			});
		}
	}

	onDestroy(handleDestroy);

	function handleDestroy() {
		channel?.unsubscribe();
	}

	const bbCodeManager = new BbCodeManager(
		"b",
		"i",
		"u",
		"s",
		"datetime",
		"timediff",
		"quote",
		"link",
		"font",
		"hr",
		"pre",
		"code",
		"ul",
		"ol",
		"li",
		"spoiler",
		"list",
		"left",
		"center",
		"right",
		"email",
		"sup",
		"sub",
		"move",
		"badge",
		"player",
		"grid",
		"cell"
	);
	setContext("bbCodeManager", bbCodeManager);

	$: checkScrollStore($scrollStore, posts);
	$: handleTopicChange(topic);

	function checkScrollStore(scroll, posts, depth = 0) {
		if (depth > 100) return;
		if (!scroll) return;
		if (scroll.location === "top") {
			scrollStore.clear();
			return window.scrollTo(0, 0);
		}
		const element = document.getElementById(scroll.id);
		if (!element) {
			setTimeout(() => checkScrollStore(scroll, posts, depth + 1), 200);
			return;
		}
		scrollStore.clear();
		element.scrollIntoView({ behavior: "smooth" });
	}

	function handleTopicChange(topic) {
		const lvpn = topic?.userData?.lastViewedPostNumber;
		updateLastViewedPostNumber = lvpn != null;
		if (lvpn > lastViewedPostNumber) {
			lastViewedPostNumber = lvpn;
		}
	}

	function handlePostInView(e) {
		if (!updateLastViewedPostNumber) return;
		const number = e.detail.number;
		if (number > lastViewedPostNumber) {
			lastViewedPostNumber = number;
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(
				() => Fetcher.updateUserTopic({ topic, lastViewedPostNumber }),
				3000
			);
		}
	}
</script>

<PostsWrapper>
	{#each topic?.actions || [] as action}
		<TopicAction {action} />
	{/each}
	{#each posts.filter(Boolean) as post (post.id)}
		{#if !post.spam || topic.title === "__spam__"}
			<Post {post} on:inView={handlePostInView} />
		{/if}
	{/each}
</PostsWrapper>

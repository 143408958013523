<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let permissions;

	const dispatch = createEventDispatcher();

	function handleChange(key, detail) {
		dispatch("event", { value: { ...permissions, [key]: detail.value } });
	}
</script>

<div class="grid grid-cols-2 gap-2">
	<SetupValueSelect
		value={permissions.playerView}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Team", "Player", "Chat"]}
		allowRaw
		label="Players can view"
		on:event={({ detail }) => handleChange("playerView", detail)}
	/>
	<SetupValueSelect
		value={permissions.playerChat}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Team", "Player", "Chat"]}
		allowRaw
		label="Players can chat"
		on:event={({ detail }) => handleChange("playerChat", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorView}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators can view"
		on:event={({ detail }) => handleChange("spectatorView", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorChat}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators can chat"
		on:event={({ detail }) => handleChange("spectatorChat", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorViewUrl}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators with the URL can view"
		on:event={({ detail }) => handleChange("spectatorViewUrl", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorChatPassword}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators with the password can chat"
		on:event={({ detail }) => handleChange("spectatorChatPassword", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorViewAdd}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators can be added to view"
		on:event={({ detail }) => handleChange("spectatorViewAdd", detail)}
	/>
	<SetupValueSelect
		value={permissions.spectatorChatAdd}
		dataType="True or False"
		scopeTypes={["Setup", "Game", "Chat"]}
		allowRaw
		label="Spectators can be added to chat"
		on:event={({ detail }) => handleChange("spectatorChatAdd", detail)}
	/>
</div>

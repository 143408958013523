<script>
	import currentUserStore from "../../stores/data/currentUserStore.js";
	import layoutStore from "../../stores/layoutStore.js";
	import themeStore from "../../stores/themeStore.js";

	import Routes from "./routes/Routes.svelte";

	$: currentUser = $currentUserStore;
	$: if (currentUser?.settings) {
		themeStore.setSettings(currentUser.settings.theme);
	}

	const bottomPanelPositionStore = layoutStore.bottomPanelPositionStore;

	$: leftMargin = $layoutStore.sideWidth;
	$: rightMargin = $layoutStore.sideWidth + $layoutStore.scrollbarWidth;
	$: margins = 2 * $layoutStore.sideWidth;
	$: paddingBottom =
		$layoutStore.bottomBarHeight +
		$layoutStore.bottomPanelHeight +
		$bottomPanelPositionStore;

	$: topAndBottomMargins = $layoutStore.topAndBottomMargins;

	$: mainStyle = `margin-left: ${leftMargin}px; margin-right: ${rightMargin}px; width: calc(100% - ${margins}px); padding-bottom: ${paddingBottom + topAndBottomMargins}px;`;
</script>

<main class="flex w-screen h-full z-0">
	<div
		class="w-full min-h-full h-max bg-base-300 z-0"
		style="{mainStyle} margin-top: {$layoutStore.topBarHeight +
			topAndBottomMargins}px;"
	>
		<div class="w-full min-h-full h-max p-8">
			<Routes />
		</div>
	</div>
</main>

<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let field;
	export let error = null;

	const emailRegex =
		/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

	const validateEmail = () => {
		if (emailRegex.test(field.value)) {
			error = null;
		} else {
			error = "Please enter a valid email address.";
		}
	};
</script>

<input
	type="text"
	maxLength={field.maxLength}
	id={field.id}
	placeholder={field.placeholder}
	class="
  input input-bordered
  {field.inputClass ? ` input-${field.inputClass}` : ''}
  "
	class:input-error={error != null}
	bind:value={field.value}
	on:change={() => dispatch("event", { type: "change" })}
	on:keyup={() => dispatch("event", { type: "keyup" })}
	on:focus={() => dispatch("event", { type: "focus" })}
	on:blur={() => dispatch("event", { type: "blur" })}
	on:input={() => dispatch("event", { type: "input" })}
	on:blur={validateEmail}
/>

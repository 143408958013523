<script>
	import ConcatenationPiece from "./ConcatenationPiece.svelte";

	export let concatenation;
	export let scopeType;
	export let valueId;

	$: concatenationPiecesToShow = getConcatenationPiecesToShow(concatenation);

	function getConcatenationPiecesToShow(concatenation) {
		if (concatenation.includes("")) return concatenation;
		return [...concatenation, ""];
	}

	function handleConcatenationPieceChange({ detail }) {
		const { value, rank } = detail;
		if (concatenation[rank] === value) return;
		concatenation[rank] = value;
		if (concatenation[concatenation.length - 1] === "") {
			concatenation = concatenation.slice(0, concatenation.length - 1);
		}
	}
</script>

{#each concatenationPiecesToShow as concatenationPiece, rank}
	<ConcatenationPiece
		{concatenationPiece}
		{scopeType}
		{rank}
		{valueId}
		on:change={handleConcatenationPieceChange}
	/>
{/each}

<script>
	import { createEventDispatcher } from "svelte";

	import Matrix from "./Matrix.svelte";

	export let matrices;

	const dispatch = createEventDispatcher();

	function handleMatrixChange(i, detail) {
		matrices[i] = detail.value;
		dispatch("event", { value: matrices });
	}

	function deleteMatrix(i) {
		dispatch("event", {
			value: [...matrices.slice(0, i), ...matrices.slice(i + 1)],
		});
	}
</script>

{#each matrices as matrix, i}
	<Matrix
		{matrix}
		on:event={({ detail }) => handleMatrixChange(i, detail)}
		on:delete={() => deleteMatrix(i)}
	/>
{/each}

<script>
	import { flip } from "svelte/animate";

	import draftsStore from "../../../../stores/data/draftsStore";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import Draft from "./Draft.svelte";

	$: drafts =
		$draftsStore
			.filter((draft) => draft.text.length > 0)
			.sort((a, b) => b.rank - a.rank) || [];
</script>

<Display title="Drafts">
	{#each drafts as draft (draft.id)}
		<div animate:flip={{ duration: 200 }}>
			<Transition>
				<Draft {draft} />
			</Transition>
		</div>
	{/each}
</Display>

<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Fetcher from "../../../../../api/gameCreator/Fetcher.js";

	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import AddRoleButton from "./AddRoleButton.svelte";
	import Roles from "./Roles.svelte";

	export let activeTab;
	export let setup;
	export let unsaved;
	export let roleConstants;
	export let playerVariables;

	let roles = [];
	let show = true;

	$: handleSetupRolesChange(setup.roles);
	$: unsaved = !equal(roles, setup.roles);

	function handleSetupRolesChange(setupRoles) {
		if (roles.length === 0) roles = clone(setupRoles);
	}

	function addRole({ detail }) {
		roles = [
			...roles,
			{
				team: detail.team,
				action: null,
				winCondition: null,
				roleFlavor: {
					fullName: detail.name,
					...getDefaultColors(detail.team),
				},
				constants: {},
				playerVariables: {},
			},
		];
	}

	function getDefaultColors(team) {
		if (team !== "None") return {};
		const activeFlavor = setup.flavors.find((flavor) => flavor.active);
		return {
			primaryColor: activeFlavor.primaryColor,
			secondaryColor: activeFlavor.secondaryColor,
		};
	}

	function handleSave() {
		if (activeTab !== "Roles") return;
		Fetcher.updateRoles({ setupSlug: setup.slug, roles }).then(
			handleSuccessfulSave
		);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		roles = clone(savedSetup.roles);
	}

	function handleRevert() {
		roles = clone(setup.roles);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
>
	<div>
		<AddRoleButton on:click={addRole} />
	</div>
	{#each setup.teams as team}
		<div>
			<AddRoleButton {team} on:click={addRole} />
		</div>
	{/each}
</SaveAndRevertButtons>

{#if show}
	<Roles bind:roles {roleConstants} {playerVariables} />
{/if}

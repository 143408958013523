import formModalStore from "../stores/formModalStore.js";

class Form {
	static title = "Form";
	static code = "form";
	static focusOnOpen = null;
	static submitValue = "Save";

	static async submit() {}
	static initialFields() {}
	static initialPages() {}
	static handleValueChange() {}
	static submit() {}
	static handleSuccess() {}

	static open(args) {
		formModalStore.open({
			open: true,
			title: this.title,
			code: this.code,
			focusOnOpen: this.focusOnOpen,
			submitValue: this.submitValue,
			pages: this.initialPages(args),
			fields: this.initialFields(args),
			errors: {},
			handleValueChange: this.handleValueChange,
			submit: this.submit,
			handleSuccess: this.handleSuccess,
		});
	}
}

export default Form;

import { getJwt, setJwt } from "./jwt.js";
import { getCurrentUserSlug, setCurrentUserSlug } from "./currentUserSlug.js";

import currentUserStore from "../stores/data/currentUserStore.js";
import toastsStore from "../stores/toastsStore.js";
import apiVersionStore from "../stores/apiVersionStore.js";

class API {
	static BASE_HEADERS = {
		"Content-Type": "application/json",
		Accept: "application/json",
		"Access-Control-Allow-Origin": "*",
	};

	static get(url, { previousRequest } = {}) {
		return this.fetchFromAPI({
			url,
			method: "GET",
			previousRequest,
		});
	}

	static post(url, body) {
		return this.fetchFromAPI({ url, method: "POST", body });
	}

	static postForm(url, body) {
		const formData = new FormData();
		for (const key in body) {
			formData.append(key, body[key]);
		}
		return fetch(BASE_URL + url, {
			method: "POST",
			headers: { Authorization: `Bearer ${getJwt()}` },
			body: formData,
		});
	}

	static put(url, body) {
		return this.fetchFromAPI({ url, method: "PUT", body });
	}

	static patch(url, body) {
		return this.fetchFromAPI({ url, method: "PATCH", body });
	}

	static delete(url, body) {
		return this.fetchFromAPI({ url, method: "DELETE", body });
	}

	static headers(previousRequest) {
		const headers = { ...this.BASE_HEADERS };
		const jwt = getJwt();
		if (jwt) headers["Authorization"] = `Bearer ${jwt}`;
		if (previousRequest) headers["Previous-Request"] = previousRequest;
		return headers;
	}

	static configObject(method, body, previousRequest) {
		const configObject = {
			headers: this.headers(previousRequest),
			method: method,
		};
		if (body) configObject["body"] = JSON.stringify(body);
		return configObject;
	}

	static async fetchFromAPI({ url, method, body, previousRequest }) {
		let jwt = getJwt();
		if (!jwt) {
			jwt = "guest";
			setJwt(jwt);
			setCurrentUserSlug(jwt);
		}
		const response = await fetch(
			BASE_URL + url,
			this.configObject(method, body, previousRequest)
		);
		const json = await response.json();
		if (["Unlock failed.", "Not found."].includes(json?.errors?.slug)) {
			return this.handleErrors(json.errors);
		}
		if (json.jwt) {
			setJwt(json.jwt);
		}
		if (json.userSlug && json.userSlug !== getCurrentUserSlug()) {
			setCurrentUserSlug(json.userSlug);
			setTimeout(() => {
				API.get("current_user").then(currentUserStore.updateCurrentUser);
			}, 250);
		}
		if (json.toast) {
			toastsStore.addToast(json.toast);
		}
		if (json.apiVersion) {
			apiVersionStore.set(json.apiVersion);
		}
		return json.data || json;
	}

	static handleErrors(errors) {
		// if (errors.slug === "Unlock failed.") return redirect("/");
		// if (errors.slug === "Not found.") return redirect("/");
	}
}

export default API;

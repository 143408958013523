import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/forumPermissionsTab.js";

import forumsStore from "../stores/data/forumsStore.js";

class EditForumForm extends Form {
	static title = "Edit Forum";
	static code = "edit-forum";
	static submitValue = "Save";

	static initialPages({
		forumSlug,
		domain,
		domainPermissions,
		forumPermissions,
	}) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: domain.description,
						label: "Description",
						id: "edit-forum-description",
						placeholder: domain.description,
						maxLength: 128,
					},
				],
			},
			permissionsTab({
				domainPermissions,
				forumPermissions,
			}),
			{ forumSlug },
		];
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const description = basicFields[0].value;

		const permissionsField = pages[1].fields[0];
		const domainPermissions = permissionsField.levels[0].value;
		const sectionGroupsPermissions = permissionsField.levels[1].value;
		const sectionsPermissions = permissionsField.levels[2].value;
		const topicsPermissions = permissionsField.levels[3].value;

		const forumPermissions = {
			sectionGroups: sectionGroupsPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};

		const { forumSlug } = pages[2];

		return Fetcher.updateForum({
			forumSlug,
			description,
			domainPermissions,
			forumPermissions,
		});
	}

	static handleSuccess(json) {
		forumsStore.updateForum(json);
	}
}

export default EditForumForm;

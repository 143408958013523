import { writable } from "svelte/store";
import API from "../../api/API";

const { subscribe, set, update } = writable([]);

function reset() {
	set([]);
}

function fetchBookmarks() {
	API.get("bookmarks").then(updateBookmarks);
}

function updateBookmarks(newBookmarks) {
	update(() => newBookmarks);
}

function addBookmark(newBookmark) {
	update((bookmarks) => {
		bookmarks = [
			newBookmark,
			...bookmarks.filter((bookmark) => bookmark.id !== newBookmark.id),
		];
		return bookmarks;
	});
}

function removeBookmark(id) {
	update((bookmarks) => bookmarks.filter((bookmark) => bookmark.id !== id));
}

export default {
	subscribe,
	reset,
	fetchBookmarks,
	addBookmark,
	removeBookmark,
};

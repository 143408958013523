<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Chats from "./Chats.svelte";
	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Fetcher from "../../../../../api/gameCreator/Fetcher";

	export let activeTab;
	export let setup;
	export let unsaved;

	let chatConstants, chatVariables;
	let show = true;

	$: handleSetupChatsChange(setup.chats);
	$: handleSetupChange(setup);
	$: unsaved = !equal(chats, setup.chats);

	function handleSetupChatsChange(setupChats) {
		if (chats.length === 0) chats = clone(setupChats);
	}
	let chats = [];

	function handleSetupChange(setup) {
		if (!setup?.values) return;
		const chatValues = setup.values.Chat;
		chatConstants = chatValues.filter(
			(value) => value.valueType === "Constant"
		);
		chatVariables = chatValues.filter(
			(value) => value.valueType === "Variable"
		);
	}

	function handleSave() {
		if (activeTab !== "Chats") return;
		Fetcher.updateChats({
			setupSlug: setup.slug,
			chats: chats,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		chats = clone(savedSetup.chats);
	}

	function handleRevert() {
		chats = clone(setup.chats);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
/>

{#if show}
	<Chats {chatConstants} {chatVariables} bind:chats />
{/if}

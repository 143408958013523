<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Field from "../../../../forms/Field.svelte";

	export let action;
	export let placeholder = "Action";
	export let label = null;

	const dispatch = createEventDispatcher();

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: field = getField(action, setup);

	function getField(action, setup) {
		return {
			type: "input",
			inputType: "select",
			items: [
				{ value: "None", label: "None" },
				...setup.actions.map((action) => ({
					value: action.id,
					label: action.name,
				})),
			],
			value: action?.id || action,
			placeholder,
			label,
		};
	}

	function handleChange({ detail }) {
		dispatch("event", { value: detail.value });
	}
</script>

{#if field.type}
	<Field {field} on:event />
{/if}

<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Effects from "./Effects.svelte";
	import Fetcher from "../../../../../api/gameCreator/Fetcher";

	export let activeTab;
	export let setup;
	export let unsaved;

	let effects = {};
	let show = true;

	$: unsaved = !equal(effects, setup.effects);

	$: handleEffectsChange(setup.effects);

	function handleEffectsChange(setupEffects) {
		if (Object.keys(effects).length === 0) effects = clone(setupEffects);
	}

	function handleSave() {
		if (activeTab !== "Effects") return;
		Fetcher.updateEffects({
			setupSlug: setup.slug,
			effects: effects,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		effects = clone(savedSetup.effects);
	}

	function handleRevert() {
		effects = clone(setup.effects);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
/>

{#if show}
	<div class="flex flex-col gap-2 mt-2">
		<Effects
			bind:effects={effects.Game}
			title="Game Effects"
			scopeType="Game"
		/>
		<Effects
			bind:effects={effects.Team}
			title="Team Effects"
			scopeType="Team"
		/>
		<Effects
			bind:effects={effects.Player}
			title="Player Effects"
			scopeType="Player"
		/>
		<Effects
			bind:effects={effects.Action}
			title="Action Effects"
			scopeType="Action"
		/>
		<Effects
			bind:effects={effects.Chat}
			title="Chat Effects"
			scopeType="Chat"
		/>
	</div>
{/if}

<script>
	import randomColor from "randomcolor";

	import Avatar from "../../shared/avatar/Avatar.svelte";

	export let name;
	export let avatarData;

	const credit = avatarData.credit;
	const splitCredit = credit.split(" ");
	const formatCredit =
		splitCredit.length > 5 &&
		splitCredit[0] == "by" &&
		splitCredit[splitCredit.length - 4] === "under";
</script>

<p class="font-bold">
	<a class="link max-w-full" href={avatarData.url}>
		<div class="max-w-full truncate">{name}</div>
	</a>
</p>
<p class="text-sm">
	{#if formatCredit}
		by <span class="underline">
			{splitCredit.slice(1, splitCredit.length - 4).join(" ")}
		</span>
		under
		<span class="underline">
			{splitCredit.slice(splitCredit.length - 3).join(" ")}
		</span>
	{:else}
		{credit}
	{/if}
</p>
<div class="flex">
	<div class="flex-grow"></div>
	<Avatar avatar="default {name} {randomColor()}" />
	<div class="flex-grow"></div>
</div>

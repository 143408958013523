<script>
	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import EditForum from "./EditForum.svelte";
	import EditSectionGroups from "./EditSectionGroups.svelte";

	import paramsStore from "../../../../stores/paramsStore";
	import forumsStore from "../../../../stores/data/forumsStore";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionGroupIds = forum?.sectionGroupIds;
</script>

<Display title="Forum Settings">
	<Transition><EditForum /></Transition>
	{#if sectionGroupIds?.length > 0}
		<Transition><EditSectionGroups /></Transition>
	{/if}
</Display>

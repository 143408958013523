<script>
	import currentUserStore from "../../../../stores/data/currentUserStore.js";

	import TextInput from "./TextInput.svelte";
	import Avatar from "../../../shared/avatar/Avatar.svelte";
	import SvelteSelect from "./SvelteSelect.svelte";
	import ColorInput from "./ColorInput.svelte";

	import gameIcons from "../../../shared/gameIcons/gameIcons.js";

	export let field;
	export let error;

	let preview;
	let textError;

	$: splitIcon = field.value.avatarDefault.split(" ");

	$: icon = splitIcon.slice(1, splitIcon.length - 1).join(" ");
	$: iconColor = splitIcon[splitIcon.length - 1];

	$: setInitialAvatar($currentUserStore);

	const defaultAvatarItems = Object.keys(gameIcons).sort((a, b) => a - b);

	function setInitialAvatar(currentUser) {
		if (preview) return;
		if (!currentUser) return;
		preview = currentUser.profile?.avatar;
	}

	function handleIconChange({ detail }) {
		const newIcon = detail.value;
		field.value.avatarDefault = `${splitIcon[0]} ${newIcon} ${splitIcon[splitIcon.length - 1]}`;
	}

	function handleColorChange({ detail }) {
		const newColor = detail.value;
		field.value.avatarDefault = `${splitIcon.slice(0, splitIcon.length - 1).join(" ")} ${newColor}`;
	}

	function handleTextInputEvent({ detail }) {
		if (detail.type !== "blur") return;
		field.value.avatarUrl = detail.value;
		if (detail.value.length > 256) {
			textError = "URL too long";
			error = "URL too long";
		} else {
			textError = null;
			error = null;
		}
	}
</script>

<div class="flex flex-col gap-2">
	<TextInput
		field={{ value: field.value.avatarUrl, maxLength: 257, placeholder: "URL" }}
		error={textError}
		on:event={handleTextInputEvent}
	/>
	<div class="flex gap-2">
		<div class="flex-grow">
			<SvelteSelect
				items={defaultAvatarItems}
				value={icon}
				on:change={handleIconChange}
			/>
		</div>
		<div class="pt-1">
			<ColorInput field={{ value: iconColor }} on:input={handleColorChange} />
		</div>
	</div>
	<Avatar
		value={field.value.avatarUrl || field.value.avatarDefault}
		defaultAvatar={field.value.avatarDefault}
	/>
</div>

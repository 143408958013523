<script>
	import { tick } from "svelte";

	import API from "../../../api/API";
	import redirect from "../../../util/redirect";

	import paramsStore from "../../../stores/paramsStore.js";
	import itemsCountStore from "../../../stores/itemsCountStore.js";
	import domainsStore from "../../../stores/data/domainsStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import sectionsStore from "../../../stores/data/sectionsStore.js";
	import topicsStore from "../../../stores/data/topicsStore.js";
	import toastsStore from "../../../stores/toastsStore";
	import setupsStore from "../../../stores/data/gameCreator/setupsStore";
	import gamesStore from "../../../stores/data/gameCreator/gamesStore.js";

	export let activeRoute;

	let success = false;
	let body = {};

	$: params = activeRoute.params;

	$: domainSlug = params.domainSlug || params.slug || params.forumSlug;
	$: sectionSlug = params.sectionSlug;
	$: topicSlug = params.topicSlug;
	$: setupSlug = params.setupSlug;
	$: gameSlug = params.gameSlug;

	$: checkAccess(params);

	async function checkAccess(params) {
		body = {};
		success = false;
		await tick();
		if (
			domainSlug &&
			!Object.keys($domainsStore).includes(domainSlug) &&
			!Object.keys($forumsStore).includes(domainSlug) &&
			domainSlug !== "test-game"
		) {
			body.domain_slug = domainSlug;
		}
		if (
			sectionSlug &&
			sectionSlug !== "all" &&
			!Object.keys($sectionsStore).includes(sectionSlug)
		) {
			body.section_slug = sectionSlug;
		}
		if (topicSlug && !Object.keys($topicsStore).includes(topicSlug)) {
			body.topic_slug = topicSlug;
		}
		if (setupSlug) {
			body.setup_slug = setupSlug;
		}
		if (gameSlug) {
			body.game_slug = gameSlug;
		}
		if (Object.keys(body).length > 0) {
			API.post("unlock", body).then((json) => handleResponse(params, json));
		} else {
			accessCheckPassed(params);
		}
	}

	function handleResponse(params, json) {
		const result = checkResponse(json);
		if (result === "success") {
			accessCheckPassed(params);
		} else {
			redirect(result);
		}
	}

	function checkResponse({ domain, section, topic, setup, game }) {
		if (body.domain_slug && !body.game_slug) {
			if (!domain) {
				toastsStore.addToast({ text: "Domain not found.", className: "error" });
				return "/";
			}
			domainsStore.updateDomain({ slug: domainSlug, ...domain });
		}
		if (body.section_slug) {
			if (!section) {
				toastsStore.addToast({
					text: "Section not found.",
					className: "error",
				});
				return `/${domainSlug}/forum`;
			}
			sectionsStore.updateSection({ slug: sectionSlug, ...section });
		}
		if (body.topic_slug && !body.game_slug) {
			if (!topic) {
				toastsStore.addToast({
					text: "Topic not found.",
					className: "error",
				});
				return `/${domainSlug}/forum/${sectionSlug}/p/1`;
			}
			topicsStore.updateTopic({ slug: topicSlug, ...topic });
		}
		if (body.setup_slug) {
			if (!setup) return "/game-creator";
			setupsStore.updateSetup(setup);
		}
		if (body.game_slug) {
			if (!game) return "/";
			gamesStore.setGame(game);
			if (body.topic_slug) {
				if (!topic) return `/${domainSlug}/game/${gameSlug}`;
				topicsStore.updateTopic({ slug: `${gameSlug}/${topicSlug}`, ...topic });
			}
		}
		return "success";
	}

	function accessCheckPassed(params) {
		if (params.pageNumber) {
			params.pageNumber = parseInt(params.pageNumber) || params.pageNumber;
		}
		paramsStore.setParams(params);
		if (!params.pageNumber) {
			itemsCountStore.set(0);
		}
		success = true;
	}
</script>

{#if success}
	<slot />
{/if}

import channel from "../channel.js";
import setupsStore from "../../stores/data/gameCreator/setupsStore.js";

const messageFunctions = { setupSaved };

function setupsChannel() {
	return channel({ channel: "GameCreator::SetupsChannel" }, messageFunctions);
}

function setupSaved() {
	setupsStore.fetchSetups();
}

export default setupsChannel;

<script>
	import sectionsStore from "../../../../stores/data/sectionsStore";

	import Fetcher from "../../../../api/Fetcher";
	import Input from "../../../forms/fields/Input.svelte";
	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let section;

	let input;
	let showInput = false;

	let field = {
		type: "input",
		inputType: "text",
		value: "",
		inputClass: "input-sm",
		placeholder: "Password",
	};

	let error = null;

	$: handleInputChange(input);
	$: handleValueChange(field.value);

	function handleInputChange(input) {
		if (input?.focus) input.focus();
	}

	function handleValueChange() {
		if (error) error = null;
	}

	function handleClick() {
		setTimeout(() => (showInput = true), 50);
	}

	function handleSubmit() {
		if (field.value.length === 0) {
			error = true;
		} else {
			error = null;
			Fetcher.createTopicUnlock({ section, password: field.value }).then(
				handleResponse
			);
		}
	}

	function handleResponse(json) {
		if (json.error) {
			error = json.error;
		} else {
			sectionsStore.updateSection({ slug: section.slug, ...json });
		}
	}

	function handleCancel() {
		showInput = false;
	}
</script>

{#if showInput}
	<div class="flex gap-2">
		<div class="grow">
			<Input bind:this={input} bind:field {error} />
		</div>
		<div>
			<Button square size="small" on:click={handleSubmit}>
				<Icon name="tick"></Icon>
			</Button>
		</div>
		<div>
			<Button className="default" square size="small" on:click={handleCancel}>
				<Icon name="x"></Icon>
			</Button>
		</div>
	</div>
{:else}
	<Button className="warning" extraClasses="w-full" on:click={handleClick}>
		Create Topic <Icon name="lock" />
	</Button>
{/if}

<script>
	import Card from "../../tailwind/card/Card.svelte";
	import CardBody from "../../tailwind/card/CardBody.svelte";
	import gameIcons from "../../shared/gameIcons/gameIcons.js";

	import GameIconAcknowledgement from "./GameIconAcknowledgement.svelte";

	const names = Object.keys(gameIcons).sort();
</script>

<h1 class="font-bold text-xl">Game Icons</h1>

<div class="mt-4 grid lg:grid-cols-2 xl:grid-cols-4 gap-4">
	{#each names || [] as name}
		<Card>
			<CardBody>
				<GameIconAcknowledgement {name} avatarData={gameIcons[name]} />
			</CardBody>
		</Card>
	{/each}
</div>

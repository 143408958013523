<script>
	import { onMount, createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let effectLine;
	export let scopeType;

	const dispatch = createEventDispatcher();

	let dataType, variableSelect, baseValue;

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: changeTypeField = getChangeTypeField(effectLine, dataType);

	onMount(handleMount);

	$: baseValue = findBaseValue(effectLine.variable);

	function handleMount() {
		dataType ||= variableSelect.valueDataType();
	}

	function getChangeTypeField(effectLine, dataType) {
		if (!dataType || !effectLine) return;
		return {
			type: "input",
			inputType: "select",
			items: getChangeTypeItems(dataType),
			value: effectLine.changeType,
			padding: true,
		};
	}

	function getChangeTypeItems(dataType) {
		if (!dataType) return;
		if (dataType === "Number") {
			return ["Set to", "Add", "Subtract", "Multiply", "Divide"];
		}
		if (dataType === "True or False") {
			return ["Set to", "Negate"];
		}
		return ["Set to"];
	}

	function handleVariableChange({ detail }) {
		effectLine.variable = detail.value;
		dataType = detail.dataType;
		dispatch("event", { value: effectLine });
	}

	function findBaseValue(id) {
		if (!id) return;
		for (const scopeType of Object.keys(setup.values)) {
			for (const v of setup.values[scopeType]) {
				if (v.id === id) {
					return v;
				}
			}
		}
	}

	function handleChangeTypeChange({ detail }) {
		effectLine.changeType = detail.value;
		dispatch("event", { value: effectLine });
	}

	function handleChangeValueChange({ detail }) {
		effectLine.changeValue = detail.value;
		dispatch("event", { value: effectLine });
	}
</script>

<SetupValueSelect
	bind:this={variableSelect}
	value={effectLine.variable}
	{scopeType}
	valueTypes={["Variable"]}
	placeholder="Variable"
	on:event={handleVariableChange}
/>
{#if changeTypeField}
	<Field field={changeTypeField} on:event={handleChangeTypeChange} />
{:else}
	<div></div>
{/if}
{#if dataType}
	<SetupValueSelect
		value={effectLine.changeValue}
		{baseValue}
		{dataType}
		{scopeType}
		allowRaw
		excluded={effectLine.variable}
		on:event={handleChangeValueChange}
	/>
{:else}
	<div></div>
{/if}

import cardData from "./cardData.json";

export default function selectCards(n, banned, priority) {
	const cards = [];
	const selected = new Set();
	banned = new Set(banned);
	for (let i = 0; i < n; i++) {
		const card = selectCard(selected, banned, priority);
		if (!card) return [];
		selected.add(card.name);
		selected.add(card.icon || "Blank");
		(card.bans || []).forEach((ban) => banned.add(ban));
		cards.push(card);
	}
	return shuffle(cards);
}

function selectCard(selected, banned, priority) {
	let available = [];
	if (priority.length > 0) {
		available = cardData.filter((card) =>
			cardFilter(card, selected, banned, priority)
		);
	}
	if (available.length === 0) {
		available = cardData.filter((card) => cardFilter(card, selected, banned));
	}
	if (available.length === 0) return;
	return available[Math.floor(Math.random() * available.length)];
}

function cardFilter(card, selected, banned, priority = null) {
	if (priority && !priority.includes(card.name)) return false;
	if (banned.has(card.icon || "Blank")) return false;
	if (banned.has(card.set)) return false;
	if (banned.has(card.name)) return false;
	for (const ban of card.bans || []) {
		if (selected.has(ban)) return false;
	}
	if (selected.has(card.name)) return false;
	return true;
}

function shuffle(array) {
	let currentIndex = array.length;
	while (currentIndex != 0) {
		let randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}
	return array;
}

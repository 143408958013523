import channel from "./channel.js";
import forumsStore from "../stores/data/forumsStore.js";
import sectionsStore from "../stores/data/sectionsStore.js";
import topicsStore from "../stores/data/topicsStore.js";
import Fetcher from "../api/Fetcher.js";

const messageFunctions = {
	forumUpdate,
	topicUpdate,
	refetchForum,
	refetchSectionGroups,
};

function forumChannel(forumSlug, authority) {
	return channel(
		{ channel: channelName(authority), forum_slug: forumSlug },
		messageFunctions
	);
}

function channelName(authority) {
	if (authority == null || authority < 0) return "ForumGuestChannel";
	if (authority < 10) return "ForumMemberChannel";
	return "ForumAdminChannel";
}

function forumUpdate(forum, { forum_slug }) {
	forumsStore.setForumValues({
		slug: forum_slug,
		...forum,
	});
}

function topicUpdate({ topic }) {
	let args;
	if (topic.slug) {
		topicsStore.updateTopic(topic);
		sectionsStore.updateSection({ slug: topic.sectionSlug, lastTopic: topic });
		args = { topicSlug: topic.slug };
	} else {
		args = { topicId: topic.id };
	}
	Fetcher.requestTopicSectionData(args).then(({ section }) => {
		sectionsStore.updateSection({ slug: topic.sectionSlug, ...section });
	});
}

function refetchForum({ forumSlug }) {
	forumsStore.fetchForum(forumSlug);
}

function refetchSectionGroups({ forumSlug }) {
	forumsStore.fetchSectionGroups(forumSlug);
}

export default forumChannel;

<script>
	import { createEventDispatcher } from "svelte";

	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let team = null;

	const dispatch = createEventDispatcher();

	function handleClick() {
		dispatch("click", {
			team: team?.id || "None",
			name: team ? `${team.teamFlavor.name} Role` : "Role",
		});
	}
</script>

<div>
	<Button
		square
		size="small"
		tooltip={team ? `Add ${team.teamFlavor.name} Role` : "Add Role"}
		backgroundColor={team?.teamFlavor.primaryColor}
		textColor={team?.teamFlavor.secondaryColor}
		on:click={handleClick}
	>
		<Icon name="plus" />
	</Button>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let action;
	export let actionVariable;

	const dispatch = createEventDispatcher();

	function handleMinimumChange({ detail }) {
		if (detail.value === action.variableInputs[actionVariable.id].minimum) {
			return;
		}
		action.variableInputs[actionVariable.id].minimum = detail.value;
		dispatch("event", { value: action });
	}

	function handleMaximumChange({ detail }) {
		if (detail.value === action.variableInputs[actionVariable.id].maximum) {
			return;
		}
		action.variableInputs[actionVariable.id].maximum = detail.value;
		dispatch("event", { value: action });
	}

	function handleMustBeIntegerChange({ detail }) {
		if (
			detail.value === action.variableInputs[actionVariable.id].mustBeInteger
		) {
			return;
		}
		action.variableInputs[actionVariable.id].mustBeInteger = detail.value;
		dispatch("event", { value: action });
	}
</script>

<SetupValueSelect
	value={action.variableInputs[actionVariable.id].minimum}
	dataType="Number"
	scopeType="Action"
	label="Minimum"
	placeholder="Minimum"
	allowRaw
	on:event={handleMinimumChange}
/>
<SetupValueSelect
	value={action.variableInputs[actionVariable.id].maximum}
	dataType="Number"
	scopeType="Action"
	label="Maximum"
	placeholder="Maximum"
	allowRaw
	on:event={handleMaximumChange}
/>
<SetupValueSelect
	value={action.variableInputs[actionVariable.id].mustBeInteger}
	dataType="True or False"
	scopeType="Action"
	label="Must be Integer"
	placeholder="Must be Integer"
	allowRaw
	on:event={handleMustBeIntegerChange}
/>
<div></div>

<script>
	import BbCodeParsedText from "./BbCodeParsedText.svelte";

	export let text;
</script>

<div class="post-text whitespace-pre-line">
	<BbCodeParsedText {text} />
</div>

<style>
	.post-text {
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
</style>

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../stores/paramsStore";
	import topicsStore from "../../../stores/data/topicsStore";
	import layoutStore from "../../../stores/layoutStore";

	import Align from "../../tailwind/align/Align.svelte";
	import ChevronDown from "../../icons/large/ChevronDown.svelte";
	import ChevronUp from "../../icons/large/ChevronUp.svelte";
	import PostingButtons from "./PostingButtons.svelte";
	import Link from "../../tailwind/Link.svelte";

	export let style;
	export let draftId;
	export let text;
	export let textMatches;
	export let showTextarea;
	export let showPreview;
	export let postEdit;

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];

	const bottomPanelPositionStore = layoutStore.bottomPanelPositionStore;
	const dispatch = createEventDispatcher();

	$: fullyOpen = $bottomPanelPositionStore === 0;

	$: Chevron = fullyOpen ? ChevronDown : ChevronUp;

	function handleShowTextareaClick() {
		showTextarea = !showTextarea;
		if (showTextarea) {
			dispatch("focusTextarea");
		} else if (!showPreview) {
			showPreview = true;
		}
	}

	function handleShowPreviewClick() {
		showPreview = !showPreview;
		if (!showTextarea && !showPreview) {
			showTextarea = true;
			dispatch("focusTextArea");
		}
	}
</script>

<div
	class="bg-neutral text-neutral-content w-full rounded-t-lg text-xl h-full py-3 px-4 flex gap-2"
	{style}
>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<Align vertical="center">
		<div class="flex gap-4">
			<div class="cursor-pointer" on:click={layoutStore.toggleBottomPanel}>
				<svelte:component this={Chevron}></svelte:component>
			</div>
			{#if fullyOpen}
				<div
					class="cursor-pointer"
					class:line-through={!showTextarea}
					on:click={handleShowTextareaClick}
				>
					[ ]
				</div>
				<div
					class="cursor-pointer"
					class:line-through={!showPreview}
					on:click={handleShowPreviewClick}
				>
					Aa
				</div>
				{#if postEdit}
					<div class="text-sm" style="padding-top: 6px;">
						Editing
						<Link topicSlug={postEdit.topic.slug} postNumber={postEdit.number}>
							{postEdit.topic.title} #{postEdit.number}
						</Link>
					</div>
				{/if}
			{/if}
		</div>
	</Align>
	<div class="grow"></div>
	{#if fullyOpen}
		<PostingButtons
			{topic}
			{textMatches}
			{postEdit}
			bind:draftId
			bind:text
			bind:showTextarea
		/>
	{/if}
</div>

<script>
	import { getContext } from "svelte";
	import { fly } from "svelte/transition";
	import { linear } from "svelte/easing";

	export let side = null;

	side ||= getContext("side");

	const x = side === "right" ? 250 : -250;
</script>

<div
	class="w-full"
	transition:fly={{
		delay: 125,
		duration: 125,
		x,
		y: 0,
		opacity: 0.5,
		easing: linear,
	}}
>
	<slot />
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import Align from "../../../../tailwind/align/Align.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let roles;

	const dispatch = createEventDispatcher();

	$: style = [
		"",
		"grid-template-columns: 20px repeat(1, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(2, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(3, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(4, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(5, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(6, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(7, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(8, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(9, minmax(0, 1fr));",
		"grid-template-columns: 20px repeat(10, minmax(0, 1fr));",
	][roles[0].length];

	const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

	function handleRoleChange(rowIndex, columnIndex, roleIndex, detail) {
		roles[rowIndex][columnIndex][roleIndex] = detail.value;
		dispatch("event", { value: roles });
	}
</script>

<div class="grid gap-4 mt-4" {style}>
	<div></div>
	{#each roles[0] as _, columnIndex}
		<Align horizontal="center">{letters[columnIndex]}</Align>
	{/each}
	{#each roles as row, rowIndex}
		<Align both="center">{rowIndex + 1}</Align>
		{#each row as cell, columnIndex}
			<div>
				{#each cell as role, roleIndex}
					<SetupValueSelect
						dataType="Role"
						scopeType="Game"
						allowRaw
						value={role}
						on:event={({ detail }) => {
							handleRoleChange(rowIndex, columnIndex, roleIndex, detail);
						}}
					/>
				{/each}
			</div>
		{/each}
	{/each}
</div>

import { writable } from "svelte/store";
import API from "../../api/API";
import DateTime from "../../util/DateTime";

import sectionGroupsStore from "./sectionGroupsStore.js";
import sectionsStore from "./sectionsStore.js";

import { mergeObjects } from "./merge.js";

const { subscribe, set, update } = writable({});

let previousRequests = {
	forum: {},
	sectionGroups: {},
	sectionsData: {},
};

function reset() {
	set({});
	previousRequests = {
		forum: {},
		sectionGroups: {},
		sectionsData: {},
	};
}

function fetchForumsData() {
	const currentRequest = DateTime.now();
	const previousRequest = previousRequests.forumsData;
	API.get("forums?data=true", { previousRequest }).then(updateForums);
	previousRequests.forumsData = currentRequest;
}

function fetchForum(forumSlug) {
	const currentRequest = DateTime.now();
	const previousRequest = previousRequests.forum[forumSlug];
	API.get(`forums/${forumSlug}`, { previousRequest }).then(updateForum);
	previousRequests.forum[forumSlug] = currentRequest;
}

function fetchSectionGroups(forumSlug) {
	const currentRequest = DateTime.now();
	const previousRequest = previousRequests.sectionGroups[forumSlug];
	API.get(`forums/${forumSlug}/section_groups`, { previousRequest }).then(
		(json) => updateSectionGroups(forumSlug, json, ["sectionSlugs"])
	);
	previousRequests.sectionGroups[forumSlug] = currentRequest;
}

function fetchSectionsData(forumSlug) {
	const currentRequest = DateTime.now();
	API.get(`forums/${forumSlug}/section_groups?data=true`, {
		previousRequest: previousRequests.sectionsData[forumSlug],
	}).then((json) => {
		for (const sectionGroup of Object.values(json)) {
			sectionsStore.updateSections(sectionGroup);
		}
	});
	previousRequests.sectionsData[forumSlug] = currentRequest;
}

function updateSectionGroups(forumSlug, sectionGroups) {
	updateForum({
		slug: forumSlug,
		sectionGroups: sectionGroups,
	});
}

function updateForums(newForums) {
	update((forums) => {
		for (const slug of Object.keys(newForums)) {
			forums[slug] = mergeNewForum({ forums, newForums, slug });
		}
		return forums;
	});
}

function updateForum(newForum) {
	update((forums) => {
		forums[newForum.slug] = mergeNewForum({ forums, newForum });
		return forums;
	});
}

function setForumValues(newForum) {
	update((forums) => {
		forums[newForum.slug] = {
			...forums[newForum.slug],
			...newForum,
		};
		return forums;
	});
}

function mergeNewForum({ forums, newForums, slug, newForum }) {
	newForum ||= newForums[slug];
	slug ||= newForum.slug;
	const existingForum = forums[slug] || {};
	let sectionGroupIds = [];
	if (newForum.sectionGroups) {
		sectionGroupIds = newForum.sectionGroups.map((sg) => sg.id);
		sectionGroupsStore.updateSectionGroups(newForum.sectionGroups, [
			"sectionSlugs",
		]);
	} else if (existingForum.sectionGroupIds) {
		sectionGroupIds = forums.sectionGroupIds;
	}
	const keys = [
		"admins",
		"domain",
		"lastTopic",
		"permissions",
		"postCount",
		"sectionGroupIds",
		"slug",
		"topicCount",
		"userAuthority",
		"userPermissions",
	];
	return mergeObjects(existingForum, { ...newForum, sectionGroupIds }, keys);
}

function updateForumsData(newForumsData) {
	update(() => newForumsData);
}

export default {
	subscribe,
	reset,
	fetchForumsData,
	fetchForum,
	fetchSectionGroups,
	fetchSectionsData,
	updateForums,
	updateForum,
	setForumValues,
};

<script>
	import { createEventDispatcher } from "svelte";

	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";

	import StandardAssignmentItem from "./StandardAssignmentItem.svelte";

	export let standardAssignment;

	const dispatch = createEventDispatcher();

	$: standardAssignmentItems = getStandardAssignmentItems(standardAssignment);

	function getStandardAssignmentItems(standardAssignment) {
		if (standardAssignment.find((sa) => [null, ""].includes(sa.role))) {
			return [...standardAssignment];
		}
		return [
			...standardAssignment,
			{
				role: "",
				playerCountMinimum: null,
				playerCountMaximum: null,
				condition: null,
			},
		];
	}

	function handleStandardAssignmentItemChange(i, detail) {
		standardAssignment[i] = detail.value;
		dispatch("event", { value: standardAssignment });
	}

	function removeItem(i) {
		standardAssignmentItems = [];
		const newStandardAssignment = [
			...standardAssignment.slice(0, i),
			...standardAssignment.slice(i + 1),
		];
		setTimeout(() => dispatch("event", { value: newStandardAssignment }), 10);
	}
</script>

<Card>
	<CardBody>
		<CardTitle>Standard Assignment</CardTitle>
		<div class="grid grid-cols-4 gap-4">
			{#each standardAssignmentItems as standardAssignmentItem, i (i)}
				<StandardAssignmentItem
					{standardAssignmentItem}
					on:event={({ detail }) =>
						handleStandardAssignmentItemChange(i, detail)}
					on:remove={() => removeItem(i)}
				/>
			{/each}
		</div>
	</CardBody>
</Card>

import { writable } from "svelte/store";

const { subscribe, update, set } = writable({});

function setPageNumber(pageNumber) {
	update((page) => ({
		...page,
		pageNumber,
	}));
}

export default { subscribe, set, setPageNumber };

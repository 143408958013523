<script>
	import Avatar from "../../../shared/avatar/Avatar.svelte";
	import SvelteSelect from "./SvelteSelect.svelte";
	import ColorInput from "./ColorInput.svelte";

	import gameIcons from "../../../shared/gameIcons/gameIcons.js";

	export let field;
	export let error;

	$: splitIcon = field.value.split(" ");

	$: icon = splitIcon.slice(0, splitIcon.length - 1).join(" ");
	$: iconColor = splitIcon[splitIcon.length - 1];

	const defaultAvatarItems = Object.keys(gameIcons).sort((a, b) => a - b);

	function handleIconChange({ detail }) {
		const newIcon = detail.value;
		field.value = `${newIcon} ${splitIcon[splitIcon.length - 1]}`;
	}

	function handleColorChange({ detail }) {
		const newColor = detail.value;
		field.value = `${splitIcon.slice(0, splitIcon.length - 1).join(" ")} ${newColor}`;
	}
</script>

<div class="flex flex-col gap-2">
	<div class="flex gap-2">
		<div class="flex-grow">
			<SvelteSelect
				items={defaultAvatarItems}
				value={icon}
				on:change={handleIconChange}
			/>
		</div>
		<div class="pt-1">
			<ColorInput field={{ value: iconColor }} on:input={handleColorChange} />
		</div>
	</div>
	<Avatar value="default {field.value}" />
</div>

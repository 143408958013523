<script>
	import { onMount } from "svelte";

	import currentUserStore from "../../../../../stores/data/currentUserStore.js";

	import fetchAccount from "../../../../../api/fetchAccount.js";

	import FormModal from "../../../../shared/modal/FormModal.svelte";

	import EmailForm from "../../../../../forms/EmailForm.js";

	export let formModal;
	export let hideButton = false;
	export let openOnMount = false;

	$: account = $currentUserStore.account;
	$: indicator = account?.email?.confirmed === false ? { value: 1 } : null;

	onMount(handleMount);

	function handleMount() {
		fetchAccount();
	}
</script>

<FormModal
	bind:this={formModal}
	{indicator}
	disabled={!account}
	form={EmailForm}
	{hideButton}
	{openOnMount}
/>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/sectionPermissionsTab.js";

class EditSectionForm extends Form {
	static title = "Edit Section";
	static code = "edit-section";
	static submitValue = "Save";

	static initialPages({ forumSlug, sectionGroup, section }) {
		return [
			permissionsTab({
				sectionGroupPermissions: sectionGroup.permissions,
				sectionPermissions: section.permissions,
			}),
			{ forumSlug, sectionSlug: section.slug },
		];
	}

	static async submit({ pages }) {
		const permissionsField = pages[0].fields[0];
		const sectionPermissions = permissionsField.levels[0].value;
		const topicsPermissions = permissionsField.levels[1].value;

		const permissions = {
			...sectionPermissions,
			topics: topicsPermissions,
		};

		const { forumSlug, sectionSlug } = pages[1];

		return Fetcher.updateSection({
			forumSlug,
			sectionSlug,
			permissions,
		});
	}
}

export default EditSectionForm;

<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import TopicViewer from "./TopicViewer.svelte";
	import NewTopicViewer from "./NewTopicViewer.svelte";

	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];
</script>

<div class="flex flex-col gap-2">
	{#each topic?.viewers || [] as user (user.slug)}
		<TopicViewer {user} />
	{/each}
</div>
{#if topic?.userPermissions?.addViewers}
	<div class="mt-2">
		<NewTopicViewer />
	</div>
{/if}

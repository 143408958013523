<script>
	import { onMount } from "svelte";

	import Fetcher from "../../../../api/Fetcher.js";

	import paramsStore from "../../../../stores/paramsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import Transition from "../../shared/Transition.svelte";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import EditTopicForm from "../../../../forms/EditTopicForm.js";

	export let topic;

	$: forumSlug = $paramsStore.domainSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];
	$: topicPermissions = topic?.permissions;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: sectionPermissions = section?.permissions;

	$: args = topic &&
		sectionPermissions &&
		topicPermissions && { topic, sectionPermissions, topicPermissions };

	onMount(handleMount);

	function handleMount() {
		if (!topicPermissions || !sectionPermissions) {
			Fetcher.sectionAndTopicPermissions({
				forumSlug,
				topicSlug,
				sectionSlug,
			}).then((json) => {
				sectionsStore.updateSection({ slug: sectionSlug, ...json.section });
				topicsStore.updateTopic({ slug: topicSlug, ...json.topic });
			});
		}
	}
</script>

{#if args}
	<Transition>
		<FormModal
			form={EditTopicForm}
			{args}
			disabled={!(topicPermissions && sectionPermissions)}
		/>
	</Transition>
{/if}

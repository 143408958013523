<script>
	import VariableInput from "./VariableInput.svelte";

	export let action;
	export let actionVariables;
</script>

<div class="grid grid-cols-4 gap-4">
	{#each actionVariables as actionVariable}
		<VariableInput {action} {actionVariable} on:event />
	{/each}
</div>

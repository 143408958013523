<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let field;
	export let error = null;
</script>

<input
	type="password"
	maxLength={field.maxLength || 64}
	id={field.id}
	placeholder={field.placeholder}
	class="
    input input-bordered
    {field.inputClass ? ` input-${field.inputClass}` : ''}
  "
	class:input-error={error != null}
	bind:value={field.value}
	on:change={() => dispatch("event", { type: "change" })}
	on:keyup={() => dispatch("event", { type: "keyup" })}
	on:focus={() => dispatch("event", { type: "focus" })}
	on:blur={() => dispatch("event", { type: "blur" })}
	on:input={() => dispatch("event", { type: "input" })}
/>

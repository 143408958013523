import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";

class ForgotPasswordForm extends Form {
	static title = "Forgot Password";
	static code = "forgot-password";
	static focusOnOpen = "forgot-password-email-address";
	static submitValue = "Submit";

	static initialFields({ emailSent = false }) {
		if (emailSent) {
			return [
				{
					type: "input",
					inputType: "text",
					value: "",
					label: "Username",
					id: "forgot-password-username",
					placeholder: "Username",
					required: true,
					maxLength: 32,
				},
				{
					type: "input",
					inputType: "text",
					value: "",
					label: "Password reset code",
					id: "forgot-password-code",
					placeholder: "Password reset code",
					required: true,
					maxLength: 6,
				},
				{
					type: "input",
					inputType: "password",
					value: "",
					label: "New password",
					id: "forgot-password-password",
					placeholder: "Password",
					required: true,
				},
				{
					type: "input",
					inputType: "password",
					value: "",
					label: "New password confirmation",
					id: "forgot-password-password-confirmation",
					placeholder: "Password Confirmation",
					required: true,
				},
			];
		}
		return [
			{
				type: "input",
				inputType: "text",
				value: "",
				label: "Username or email address",
				id: "forgot-password-username-or-email-address",
			},
		];
	}

	static async submit({ fields }) {
		if (fields[0].id === "forgot-password-username-or-email-address") {
			const input = fields[0].value;
			return Fetcher.forgotPassword({ input });
		}
		const [username, passwordResetCode, password, passwordConfirmation] =
			fields;
		if (password.value != passwordConfirmation.value) {
			return {
				errors: {
					[password.id]: "",
					[passwordConfirmation.id]: "Passwords do not match.",
				},
			};
		}
		return Fetcher.resetPassword({
			username: username.value,
			code: passwordResetCode.value,
			password: password.value,
		});
	}

	static handleSuccess = (json) => {
		if (json.id) return;
		this.open({
			emailSent: true,
		});
	};
}

export default ForgotPasswordForm;

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import FlavorCard from "../flavors/FlavorCard.svelte";
	import Tabs from "../../../../shared/Tabs.svelte";
	import TabPage from "../../../../shared/TabPage.svelte";
	import ObjectValueInputs from "../ObjectValueInputs.svelte";
	import StandardRoleConstants from "./StandardRoleConstants.svelte";
	import RoleFlavor from "./RoleFlavor.svelte";

	export let role;
	export let roleConstants;
	export let playerVariables;

	const dispatch = createEventDispatcher();

	const tabs = ["Flavor", "Constants", "Initial Player Variables"];

	let activeTab = "Flavor";

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];
	$: activeFlavor = setup.flavors.find(
		(flavor) => flavor.id == setup.activeFlavor
	);
	$: team = setup.teams.find((team) => team.id === role.team) || role.team;
	$: flavor =
		team === "None" ? role?.roleFlavor : team?.teamFlavor || activeFlavor;
	$: primaryColor =
		team === "None"
			? role?.roleFlavor.primaryColor
			: team?.teamFlavor.primaryColor || activeFlavor.primaryColor;
	$: secondaryColor =
		team === "None"
			? role?.roleFlavor.secondaryColor
			: team?.teamFlavor.secondaryColor || activeFlavor.secondaryColor;

	function handleRoleFlavorChange({ detail }) {
		primaryColor = detail.value.primaryColor;
		secondaryColor = detail.value.secondaryColor;
		dispatch("event", { value: { ...role, roleFlavor: detail.value } });
	}

	function handleStandardRoleConstantsChange({ detail }) {
		if (detail.key === "team") {
			if (detail.value === "None") {
				role.roleFlavor.primaryColor ||= activeFlavor.primaryColor;
				role.roleFlavor.secondaryColor ||= activeFlavor.secondaryColor;
			} else {
				delete role.roleFlavor.primaryColor;
				delete role.roleFlavor.secondaryColor;
			}
		}
		dispatch("event", { value: { ...role, [detail.key]: detail.value } });
	}

	function handleRoleConstantsChange({ detail }) {
		dispatch("event", { value: { ...role, constants: detail.value } });
	}

	function handlePlayerVariablesChange({ detail }) {
		dispatch("event", { value: { ...role, playerVariables: detail.value } });
	}
</script>

<FlavorCard {flavor} title={role.roleFlavor.fullName}>
	<Tabs {tabs} bind:activeTab bordered></Tabs>
	<TabPage tab="Flavor" {activeTab}>
		<RoleFlavor {role} on:event={handleRoleFlavorChange} />
	</TabPage>
	<TabPage tab="Constants" {activeTab}>
		<StandardRoleConstants
			{role}
			on:event={handleStandardRoleConstantsChange}
		/>
		<ObjectValueInputs
			objectValues={role.constants}
			setupValues={roleConstants}
			defaultValues={team?.roleConstants || []}
			scopeType="Role"
			on:event={handleRoleConstantsChange}
		/>
	</TabPage>
	<TabPage tab="Initial Player Variables" {activeTab}>
		<ObjectValueInputs
			objectValues={role.playerVariables}
			setupValues={playerVariables}
			defaultValues={team?.playerVariables || []}
			scopeType="Role"
			on:event={handlePlayerVariablesChange}
		/>
	</TabPage>
</FlavorCard>

<script>
	import Field from "../../../../forms/Field.svelte";

	export let allowedValues;

	$: fields = getFields(allowedValues);

	function getFields(allowedValues) {
		if (!allowedValues) return;
		return [
			...allowedValues.map((value) => ({
				type: "input",
				inputType: "text",
				value,
				maxLength: 32,
			})),
			{ type: "input", inputType: "text", value: "", maxLength: 32 },
		];
	}

	function handleFieldEvent(i, { value, type }) {
		if (value == null) return;
		if (value === "" && type === "blur") {
			allowedValues = [
				...allowedValues.slice(0, i),
				...allowedValues.slice(i + 1),
			];
			return;
		}
		if (value === "" && i >= allowedValues.length) return;
		allowedValues[i] = value;
	}
</script>

<div>Allowed values</div>
<div></div>
<div></div>
<div></div>

{#if fields}
	{#each fields as field, i}
		<Field {field} on:event={({ detail }) => handleFieldEvent(i, detail)} />
	{/each}
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";
	import Field from "../../../../forms/Field.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";
	import MatrixRoles from "./MatrixRoles.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let matrix;

	const dispatch = createEventDispatcher();

	const rowsMax = 10;
	const columnsMax = 10;
	const rolesPerCellMax = 5;

	$: rowsField = getRowsField(matrix);
	$: columnsField = getColumnsField(matrix);
	$: rolesPerCellField = getRolesPerCellField(matrix);
	$: numberToSelectField = getNumberToSelectField(matrix);
	$: selectionTypeField = getSelectionTypeField(matrix);
	$: distinctSelectionField = getDistinctSelectionField(matrix);

	function getRowsField(matrix) {
		return {
			type: "input",
			inputType: "number",
			value: matrix.rows,
			min: 1,
			max: rowsMax,
			label: "Rows",
			placeholder: "Rows",
		};
	}

	function getColumnsField(matrix) {
		return {
			type: "input",
			inputType: "number",
			value: matrix.columns,
			min: 1,
			max: columnsMax,
			label: "Columns",
			placeholder: "Columns",
		};
	}

	function getRolesPerCellField(matrix) {
		return {
			type: "input",
			inputType: "number",
			value: matrix.rolesPerCell,
			min: 1,
			max: rolesPerCellMax,
			label: "Roles Per Cell",
			placeholder: "Roles Per Cell",
		};
	}

	function getNumberToSelectField(matrix) {
		return {
			type: "input",
			inputType: "number",
			value: matrix.numberToSelect,
			min: 1,
			max: 10,
			label: "Number to Select",
			placeholder: "Number to Select",
		};
	}

	function getSelectionTypeField(matrix) {
		return {
			type: "input",
			inputType: "select",
			items: [
				"Row",
				"Column",
				"Cell",
				"Row or Column",
				"Row or Column or Diagonal",
				"Row and Column",
				"Row and Column (Duplicate Intersection)",
			],
			value: matrix.selectionType,
			label: "Selection Type",
			placeholder: "Selection Type",
			padding: true,
		};
	}
	function getDistinctSelectionField(matrix) {
		if (!(matrix?.numberToSelect > 1)) return null;
		return {
			type: "checkbox",
			value: matrix.distinctSelection,
			label: "Distinct Selection",
		};
	}

	function handleRowsChange({ detail }) {
		if (detail.type !== "input" || detail.value > rowsMax) return;
		let newRoles = matrix.roles.slice(0, detail.value);
		while (newRoles.length < detail.value) {
			newRoles = [...newRoles, getNewRow(matrix)];
		}
		dispatch("event", {
			value: { ...matrix, rows: detail.value, roles: newRoles },
		});
	}

	function handleColumnsChange({ detail }) {
		if (detail.type !== "input" || detail.value > columnsMax) return;
		let newRoles = matrix.roles.map((row) => {
			let newRow = row.slice(0, detail.value);
			while (newRow.length < detail.value) {
				newRow = [...newRow, getNewCell(matrix)];
			}
			return newRow;
		});
		dispatch("event", {
			value: { ...matrix, columns: detail.value, roles: newRoles },
		});
	}

	function handleRolesPerCellChange({ detail }) {
		if (detail.type !== "input" || detail.value > rolesPerCellMax) return;
		let newRoles = matrix.roles.map((row) =>
			row.map((cell) => {
				let newCell = cell.slice(0, detail.value);
				while (newCell.length < detail.value) {
					newCell = [...newCell, ""];
				}
				return newCell;
			})
		);
		dispatch("event", {
			value: { ...matrix, rolesPerCell: detail.value, roles: newRoles },
		});
	}

	function handleNumberToSelectChange({ detail }) {
		if (detail.type !== "input") return;
		dispatch("event", { value: { ...matrix, numberToSelect: detail.value } });
	}

	function handleSelectionTypeChange({ detail }) {
		dispatch("event", { value: { ...matrix, selectionType: detail.value } });
	}

	function handleDistinctSelectionChange({ detail }) {
		dispatch("event", {
			value: { ...matrix, distinctSelection: detail.value },
		});
	}

	function handleRolesChange({ detail }) {
		dispatch("event", {
			value: { ...matrix, roles: detail.value },
		});
	}

	function getNewRow(matrix) {
		let result = [];
		for (let i = 0; i < matrix.columns; i++) {
			result = [...result, getNewCell(matrix)];
		}
		return result;
	}

	function getNewCell(matrix) {
		let result = [];
		for (let i = 0; i < matrix.rolesPerCell; i++) {
			result = [...result, ""];
		}
		return result;
	}

	function deleteMatrix() {
		dispatch("delete");
	}
</script>

<Card>
	<CardBody>
		<CardTitle>
			<div class="flex w-full">
				<div>Matrix</div>
				<div class="grow"></div>
				<div>
					<Button
						square
						size="small"
						className="error"
						tooltip="Delete Matrix"
						on:click={deleteMatrix}
					>
						<Icon name="bin" />
					</Button>
				</div>
			</div>
		</CardTitle>
		<div class="grid grid-cols-3 gap-2">
			{#if rowsField}
				<Field field={rowsField} on:event={handleRowsChange} />
			{/if}
			{#if columnsField}
				<Field field={columnsField} on:event={handleColumnsChange} />
			{/if}
			{#if rolesPerCellField}
				<Field field={rolesPerCellField} on:event={handleRolesPerCellChange} />
			{/if}
			{#if numberToSelectField}
				<Field
					field={numberToSelectField}
					on:event={handleNumberToSelectChange}
				/>
			{/if}
			{#if selectionTypeField}
				<Field
					field={selectionTypeField}
					on:event={handleSelectionTypeChange}
				/>
			{/if}
			{#if distinctSelectionField}
				<Align vertical="bottom">
					<Field
						field={distinctSelectionField}
						on:event={handleDistinctSelectionChange}
					/>
				</Align>
			{/if}
		</div>
		{#if matrix.roles.length > 0 && matrix.roles[0].length > 0}
			<MatrixRoles roles={matrix.roles} on:event={handleRolesChange} />
		{/if}
	</CardBody>
</Card>

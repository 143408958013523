<script>
	export let color = null;
	export let size = null;
	export let face = null;

	$: style = getStyle(color, size, face);

	function getStyle(color, size, face) {
		let result = [];
		if (color) result.push(`color: ${getColor(color)};`);
		if (size) result.push(`font-size: ${size}px;`);
		if (face) result.push(`font-family: ${face};`);
		return result.join(" ");
	}

	function getColor(color) {
		const regex = /^([0-9a-fA-F]{3}){1,2}$/;
		return color.match(regex) ? `#${color}` : color;
	}
</script>

<span {style}><slot /></span>

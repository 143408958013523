<script>
	import SvelteSelect from "../../forms/fields/inputs/SvelteSelect.svelte";

	import paramsStore from "../../../stores/paramsStore.js";
	import editSectionsStore from "../../../stores/editSectionsStore";

	export let sectionGroupId;
	export let sectionGroupIndex;
	export let sectionIndex;
	export let disabled;

	$: forumSlug = $paramsStore.domainSlug;

	let value = sectionGroupId;

	$: sectionGroups = $editSectionsStore[forumSlug] || [];

	$: items = sectionGroups
		.filter((sectionGroup) => sectionGroup.title.length > 0)
		.map((sectionGroup) => ({
			value: sectionGroup.id,
			label: sectionGroup.title,
		}));

	function handleChange(e) {
		const newValue = e.detail.value;
		if (newValue === value) return;
		editSectionsStore.moveSection(
			forumSlug,
			sectionGroupIndex,
			sectionIndex,
			newValue
		);
	}
</script>

<SvelteSelect
	{value}
	{items}
	{disabled}
	on:change={handleChange}
	on:change
	on:event
/>

import Setup from "../gameCreator/setup/Setup.svelte";
import Game from "../gameCreator/game/Game.svelte";
import Topic from "../forum/topic/Topic.svelte";

const gameCreatorRoutes = [
	{
		name: "gameCreatorSetup",
		path: "/game-creator/setup/:setupSlug",
		component: Setup,
	},
	{
		name: "gameCreatorGame",
		path: "/:domainSlug/game/:gameSlug",
		component: Game,
	},
	{
		name: "gameCreatorTopic",
		path: "/:domainSlug/game/:gameSlug/:topicSlug/p/:pageNumber",
		component: Topic,
	},
];

export default gameCreatorRoutes;

<script>
	import usersDataStore from "../../../../stores/data/usersDataStore.js";

	import SvelteSelect from "./SvelteSelect.svelte";

	export let field;
	export let error = null;

	$: users = $usersDataStore.members;

	$: items = (users || []).map((user) => ({
		label: user.username,
		value: user,
	}));
</script>

<SvelteSelect bind:value={field.value} {items} {error} on:change on:event />

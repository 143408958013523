<script>
	import { getContext } from "svelte";

	import paramsStore from "../../stores/paramsStore.js";
	import topicsStore from "../../stores/data/topicsStore.js";

	import redirect from "../../util/redirect.js";
	import { redirectToPost, redirectTo } from "../../util/redirectTo.js";

	export let post = null;
	export let domain = null;
	export let forum = null;
	export let section = null;
	export let game = null;
	export let topic = null;
	export let url = null;

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: currentTopicSlug = $paramsStore.topicSlug;
	$: currentTopic = $topicsStore[currentTopicSlug];

	function handleClick() {
		closeMenusUnlessFullSideMargins();
		if (currentTopic?.slug === topic) {
			if (topic && !(forum && section)) {
				forum = currentTopic.forumSlug;
				section = currentTopic.sectionSlug;
			}
			if (!forum && !section && !topic) return redirect("");
		}
		if (post) {
			return redirectToPost({
				forumSlug: forum,
				sectionSlug: section,
				gameSlug: game,
				topicSlug: topic,
				postNumber: post,
				currentTopicSlug,
			});
		}
		if (topic) {
			return redirectTo({
				forumSlug: forum,
				sectionSlug: section,
				topicSlug: topic,
			});
		}
		if (forum || section) {
			return redirectTo({
				forumSlug: forum,
				sectionSlug: section,
			});
		}
		redirect("");
	}
</script>

{#if url}
	<a class="link tooltip z-50" data-tip={url} href={url} target="_blank">
		<slot />
	</a>
{:else}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<span class="link" on:click={handleClick}>
		<slot />
	</span>
{/if}

<style>
	.link {
		text-decoration-line: underline;
	}
</style>

<script>
	import Ban from "./Ban.svelte";
	import Bin from "./Bin.svelte";
	import X from "./X.svelte";

	export let name;

	const Icon = {
		ban: Ban,
		bin: Bin,
		x: X,
	}[name];
</script>

<Icon />

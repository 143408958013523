<script>
	import Badge from "./Badge.svelte";

	export let colorName = "neutral";
	export let path = null;
	export let clickable = !!path;
	export let tooltip = null;
	export let extraClasses = "";
	export let size = null;
</script>

<div
	class="inline-flex"
	style="margin-top: 6px; margin-left: 1px; margin-right: 1px;"
>
	<Badge {colorName} {path} {clickable} {size} {tooltip} {extraClasses}>
		<slot />
	</Badge>
</div>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/sectionGroupPermissionsTab.js";

class NewSectionGroupForm extends Form {
	static title = "New Section Group";
	static code = "new-section-group";
	static submitValue = "Save";
	title = "New Section Group";
	code = "new-section-group";

	static initialPages({ forumSlug, domain, forum }) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Title",
						id: "new-section-group-title",
						placeholder: "Title",
						required: true,
					},
				],
			},
			permissionsTab({
				forumPermissions: {
					...(domain?.permissions || {}),
					...(forum?.permissions || {}),
				},
			}),
			{ forumSlug },
		];
	}

	static async submit({ pages }) {
		const title = pages[0].fields[0].value;
		const permissionsLevels = pages[1].fields[0].levels;
		const sectionGroupPermissions = permissionsLevels[0].value;
		const sectionsPermissions = permissionsLevels[1].value;
		const topicsPermissions = permissionsLevels[2].value;
		const permissions = {
			...sectionGroupPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};
		const { forumSlug } = pages[2];
		return Fetcher.createSectionGroup({
			forumSlug,
			title,
			permissions,
		});
	}
}

export default NewSectionGroupForm;

<script>
	import { getContext, setContext } from "svelte";

	import currentUserStore from "../../stores/data/currentUserStore.js";

	import ClearFormatting from "./ClearFormatting.svelte";
	import QuoteHeader from "./QuoteHeader.svelte";

	export let from = null;
	export let author = null;
	export let post = null;
	export let datetime = null;
	export let forum = null;
	export let section = null;
	export let topic = null;

	let show = false;

	$: authorToShow = author || from;

	$: currentUser = $currentUserStore;
	$: showQuoteDepth = currentUser?.settings?.quoteDepth;

	const quoteDepth = getContext("quoteDepth") || 1;
	setContext("quoteDepth", quoteDepth + 1);

	$: handleShowQuoteDepthChange(showQuoteDepth);

	const parity = quoteDepth % 2 === 0;

	function handleShowQuoteDepthChange(showQuoteDepth) {
		if (showQuoteDepth == null) showQuoteDepth = 2;
		show = showQuoteDepth >= quoteDepth;
	}
</script>

<ClearFormatting>
	<QuoteHeader
		author={authorToShow}
		tag={post}
		{datetime}
		forumSlug={forum}
		sectionSlug={section}
		topicSlug={topic}
		bind:show
	/>
	<div class="{parity ? 'bg-base-300' : 'bg-base-200'} py-2 px-3 rounded-xl">
		{#if show}
			<slot />
		{/if}
	</div>
</ClearFormatting>

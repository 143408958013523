<script>
	import SingleAlign from "./SingleAlign.svelte";

	export let both = null;
	export let vertical = both;
	export let horizontal = both;
</script>

<SingleAlign orientation="vertical" location={vertical}>
	<SingleAlign orientation="horizontal" location={horizontal}>
		<slot />
	</SingleAlign>
</SingleAlign>

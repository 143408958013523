<script>
	import Router from "./pager/Router.svelte";
	import Route from "./pager/Route.svelte";

	import routes from "./routes.js";
</script>

<Router>
	{#each routes as route}
		<Route {route} />
	{/each}
	<!-- <Route path="/snake" component={FourDSnake} /> -->
</Router>

<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";
	import EffectDetails from "./EffectDetails.svelte";

	export let effect;
	export let scopeType;

	const dispatch = createEventDispatcher();

	let showDetails = !effect.id;

	$: effectTypeField = getEffectTypeField(effect);
	$: nameField = getNameField(effect);

	function getEffectTypeField(effect) {
		return {
			type: "input",
			inputType: "select",
			items: ["Normal", "Switch"],
			value: effect.effectType,
			padding: true,
		};
	}

	function getNameField(effect) {
		return {
			type: "input",
			inputType: "text",
			value: effect.name,
			placeholder: "Name",
		};
	}

	function handleEffectTypeChange({ detail }) {
		dispatch("event", { value: { ...effect, effectType: detail.value } });
	}

	function handleNameChange({ detail }) {
		if (detail.type !== "input") return;
		dispatch("event", { value: { ...effect, name: detail.value } });
	}

	function handleChange({ detail }) {
		dispatch("event", detail);
	}

	function toggleShowDetails() {
		showDetails = !showDetails;
	}
</script>

<div class="bg-base-200 py-2 px-3 rounded-xl flex gap-2">
	<div class="grow grid grid-cols-4 gap-2">
		{#if effectTypeField}
			<Field field={effectTypeField} on:event={handleEffectTypeChange} />
		{/if}
		{#if nameField}
			<Field field={nameField} on:event={handleNameChange} />
		{/if}
		<div></div>
		<div></div>
		{#if showDetails && effect.name}
			<EffectDetails
				{effect}
				{scopeType}
				effectId={effect.id}
				on:event={handleChange}
			/>
		{/if}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="mt-3" on:click={toggleShowDetails}>
		<div class="cursor-pointer">
			<Icon name={showDetails ? "chevronUp" : "chevronDown"} />
		</div>
	</div>
</div>

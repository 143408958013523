<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "./values/SetupValueSelect.svelte";

	export let objectValues;
	export let setupValues;
	export let defaultValues = {};
	export let scopeType;

	const dispatch = createEventDispatcher();

	function handleChange(setupValueId, detail) {
		dispatch("event", {
			value: { ...objectValues, [setupValueId]: detail.value },
		});
	}
</script>

<div class="grid grid-cols-2 gap-2">
	{#each setupValues as setupValue (setupValue.id)}
		<SetupValueSelect
			value={objectValues[setupValue.id]}
			{scopeType}
			dataType={setupValue.dataType}
			label={setupValue.name}
			baseValue={setupValue}
			defaultValue={defaultValues[setupValue.id]}
			allowRaw
			on:event={({ detail }) => handleChange(setupValue.id, detail)}
		/>
	{/each}
</div>

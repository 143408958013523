<script>
	import urlStore from "../../stores/urlStore.js";
	import layoutStore from "../../stores/layoutStore.js";
	import currentUserStore from "../../stores/data/currentUserStore.js";
	import paramsStore from "../../stores/paramsStore.js";
	import itemsCountStore from "../../stores/itemsCountStore.js";

	import redirect from "../../util/redirect.js";

	import PaginationButton from "./PaginationButton.svelte";
	import pageNameStore from "../../stores/pageNameStore.js";

	export let invisible = false;

	$: maxButtons = $layoutStore.maxPaginationButtons;

	$: pageSize =
		$pageNameStore === "forumSection"
			? 50
			: $currentUserStore.settings?.pageSize || 50;
	$: itemsCount = $itemsCountStore || 0;
	$: pageCount = Math.floor((itemsCount - 1) / pageSize) + 1;
	$: pageNumber = $paramsStore.pageNumber;

	$: if (pageCount > 0 && pageNumber > pageCount) handleClick(1);

	$: pagesToShow =
		pageCount > 0 ? getPagesToShow(pageCount, pageNumber, maxButtons) : [];

	let handlingClick = false;

	function handleClick(pageN) {
		if (handlingClick) return;
		handlingClick = true;
		const url = $urlStore;
		const splitUrl = url.split("/");
		splitUrl[splitUrl.length - 1] = pageN;
		const newUrl = splitUrl.join("/");
		setTimeout(() => (handlingClick = false), 200);
		redirect(newUrl);
	}

	function getPagesToShow(pageCount, pageNumber, maxButtons) {
		if (pageCount <= maxButtons) return aToB(1, pageCount);
		if (pageNumber <= Math.ceil(maxButtons / 2))
			return [...aToB(1, maxButtons - 1), pageCount];
		if (pageNumber >= pageCount - Math.floor(maxButtons / 2))
			return [1, ...aToB(pageCount - (maxButtons - 2), pageCount)];
		const eitherSideOfPageNumber = Math.floor((maxButtons - 2) / 2);
		return [
			1,
			...aToB(
				pageNumber - eitherSideOfPageNumber,
				pageNumber + eitherSideOfPageNumber
			),
			pageCount,
		];
	}

	function aToB(a, b) {
		const result = [];
		for (let i = a; i <= b; i++) result.push(i);
		return result;
	}

	async function onKeyDown(e) {
		if (!e.ctrlKey || pageCount === 0 || invisible) return;
		if (e.key === "ArrowRight" && pageNumber < pageCount) {
			return handleClick(pageNumber + 1);
		}
		if (e.key === "ArrowLeft" && pageNumber > 1) {
			return handleClick(pageNumber - 1);
		}
		if (e.key === "ArrowDown" && pageNumber < pageCount) {
			return handleClick(pageCount);
		}
		if (e.key === "ArrowUp" && pageNumber > 1) {
			return handleClick(1);
		}
	}
</script>

<svelte:window on:keydown={onKeyDown} />

{#if pageNumber && pageCount > 1}
	<div class="join">
		{#each pagesToShow as thisPageNumber (thisPageNumber)}
			<PaginationButton
				pageNumber={thisPageNumber}
				currentPageNumber={pageNumber}
				{invisible}
			/>
		{/each}
	</div>
{/if}

<script>
	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import EffectSelect from "../effects/EffectSelect.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let standardSetupConstants;

	$: gameTypeField = getGameTypeField(standardSetupConstants);
	$: playerCountMinField = getPlayerCountMinField(standardSetupConstants);
	$: playerCountMaxField = getPlayerCountMaxField(standardSetupConstants);

	function getGameTypeField(standardSetupConstants) {
		return {
			type: "input",
			inputType: "text",
			value: standardSetupConstants.gameType,
			maxLength: 32,
			placeholder: "Game Type",
			label: "Game Type",
		};
	}

	function getPlayerCountMinField(standardSetupConstants) {
		return {
			type: "input",
			inputType: "number",
			value: standardSetupConstants.playerCountMin,
			min: 0,
			placeholder: "Minimum Player Count",
			label: "Minimum Player Count",
		};
	}

	function getPlayerCountMaxField(standardSetupConstants) {
		return {
			type: "input",
			inputType: "number",
			value: standardSetupConstants.playerCountMax,
			min: 0,
			placeholder: "Maximum Player Count",
			label: "Maximum Player Count",
		};
	}

	function handleGameTypeChange({ detail }) {
		standardSetupConstants.gameType = detail.value;
	}

	function handlePlayerCountMinChange({ detail }) {
		standardSetupConstants.playerCountMin = detail.value;
	}

	function handlePlayerCountMaxChange({ detail }) {
		standardSetupConstants.playerCountMax = detail.value;
	}

	function handleDefaultRoleChange({ detail }) {
		standardSetupConstants.defaultRole = detail.value;
	}

	function handleGameStartEffectChange({ detail }) {
		standardSetupConstants.gameStartEffect = detail.value;
	}

	function handleGameEndConditionChange({ detail }) {
		standardSetupConstants.gameEndCondition = detail.value;
	}

	function handleGameEndEffectChange({ detail }) {
		standardSetupConstants.gameEndEffect = detail.value;
	}
</script>

<div class="bg-base-200 py-2 px-3 rounded-xl flex gap-2">
	<div class="grow grid grid-cols-4 gap-2">
		{#if gameTypeField}
			<Field field={gameTypeField} on:event={handleGameTypeChange} />
		{/if}
		{#if playerCountMinField}
			<Field
				field={playerCountMinField}
				on:event={handlePlayerCountMinChange}
			/>
		{/if}
		{#if playerCountMaxField}
			<Field
				field={playerCountMaxField}
				on:event={handlePlayerCountMaxChange}
			/>
		{/if}
		<div></div>
		<SetupValueSelect
			dataType="Role"
			scopeType="Setup"
			allowRaw
			allowNull
			value={standardSetupConstants.defaultRole}
			placeholder="Default Role"
			label="Default Role"
			on:event={handleDefaultRoleChange}
		/>
		<EffectSelect
			scopeTypes={["Game"]}
			effect={standardSetupConstants.gameStartEffect}
			placeholder="Game Start Effect"
			label="Game Start Effect"
			on:event={handleGameStartEffectChange}
		/>
		<SetupValueSelect
			dataType="True or False"
			scopeTypes={["Game"]}
			value={standardSetupConstants.gameEndCondition}
			placeholder="Game End Condition"
			label="Game End Condition"
			on:event={handleGameEndConditionChange}
		/>
		<EffectSelect
			scopeTypes={["Game"]}
			effect={standardSetupConstants.gameEndEffect}
			placeholder="Game End Effect"
			label="Game End Effect"
			on:event={handleGameEndEffectChange}
		/>
	</div>
	<div class="mt-3 invisible">
		<Icon name="chevronUp" />
	</div>
</div>

import B from "./B.svelte";
import Center from "./Center.svelte";
import Badge from "./Badge.svelte";
import Cell from "./Cell.svelte";
import Code from "./Code.svelte";
import Datetime from "./Datetime.svelte";
import Email from "./Email.svelte";
import Hr from "./Hr.svelte";
import I from "./I.svelte";
import Font from "./Font.svelte";
import Left from "./Left.svelte";
import Grid from "./Grid.svelte";
import Li from "./Li.svelte";
import Link from "./Link.svelte";
import List from "./List.svelte";
import Move from "./Move.svelte";
import Ol from "./Ol.svelte";
import Player from "./Player.svelte";
import Pre from "./Pre.svelte";
import Quote from "./Quote.svelte";
import Right from "./Right.svelte";
import S from "./S.svelte";
import Spoiler from "./Spoiler.svelte";
import Sub from "./Sub.svelte";
import Sup from "./Sup.svelte";
import Timediff from "./Timediff.svelte";
import U from "./U.svelte";
import Ul from "./Ul.svelte";

export default {
	b: B,
	badge: Badge,
	cell: Cell,
	center: Center,
	code: Code,
	datetime: Datetime,
	email: Email,
	grid: Grid,
	hr: Hr,
	i: I,
	font: Font,
	left: Left,
	li: Li,
	link: Link,
	list: List,
	move: Move,
	ol: Ol,
	player: Player,
	pre: Pre,
	quote: Quote,
	right: Right,
	s: S,
	sub: Sub,
	sup: Sup,
	spoiler: Spoiler,
	timediff: Timediff,
	u: U,
	ul: Ul,
};

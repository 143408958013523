export default function getDataTypeItems(scopeType) {
	return [
		"Text",
		"Flavor Text",
		"Number",
		"True or False",
		"Duration",
		"Team",
		"List of Teams",
		"Role",
		"List of Roles",
		...(scopeType === "Setup"
			? []
			: [
					"Player",
					"List of Players",
					"Action",
					"List of Actions",
					"Chat",
					"List of Chats",
			  ]),
	];
}

<script>
	import Align from "../../../../tailwind/align/Align.svelte";
	import Badge from "../../../../tailwind/Badge.svelte";

	export let majority;
	export let votesPerRow;
	export let rowHeight;

	$: rows = Math.ceil(majority / votesPerRow);
</script>

<div
	class="grid grid-cols-{votesPerRow} gap-2 w-fit"
	style="height: {rowHeight}px;"
>
	{#each Array(majority - 1) as _}
		<Align vertical="center" width="101">
			<Badge colorName="warning" extraClasses="opacity-20" full />
		</Align>
	{/each}
	<Align vertical="center" width="101">
		<Badge colorName="error" extraClasses="opacity-20" full />
	</Align>
</div>

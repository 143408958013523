<script>
	import { getContext } from "svelte";

	export let text = "";

	const bbCodeManager = getContext("bbCodeManager");

	$: parsedText = bbCodeManager.parse(text);
	$: props = getProps(parsedText);

	function getProps(parsedText) {
		if (!parsedText?.componentRequiresInnerText) return parsedText?.props || {};
		return {
			...parsedText.props,
			innerText: parsedText.innerText,
		};
	}
</script>

{#if typeof parsedText === "string"}
	{parsedText}
{:else}
	{parsedText.beforeText}<svelte:component
		this={parsedText.component}
		{...props}><svelte:self text={parsedText.innerText} /></svelte:component
	><svelte:self text={parsedText.afterText} />
{/if}

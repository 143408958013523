<script>
	import Align from "../../tailwind/align/Align.svelte";
	import Icon from "../../icons/large/Icon.svelte";
</script>

<Align vertical="center">
	<div class="text-error tooltip tooltip-left" data-tip="Cannot post">
		<Icon name="lock" />
	</div>
</Align>

<script>
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import Field from "../../../../forms/Field.svelte";

	export let duration;
	export let scopeType;

	$: roundTypeField = getRoundTypeField(duration);

	function getRoundTypeField(duration) {
		return {
			type: "input",
			inputType: "select",
			items: ["Up", "Closest", "Down"],
			value: duration.roundType,
			placeholder: "Round Type",
			label: "Round Type",
		};
	}

	function handleDurationChange({ detail }) {
		duration.duration = detail.value;
	}

	function handleRoundToChange({ detail }) {
		duration.roundTo = detail.value;
	}

	function handleOffsetChange({ detail }) {
		duration.offset = detail.value;
	}

	function handleRoundTypeChange({ detail }) {
		duration.roundType = detail.value;
	}
</script>

<SetupValueSelect
	dataType="Number"
	{scopeType}
	value={duration.duration}
	placeholder="Duration"
	label="Duration (seconds)"
	allowRaw
	on:event={handleDurationChange}
/>
<SetupValueSelect
	dataType="Number"
	{scopeType}
	value={duration.roundTo}
	placeholder="Round to"
	label="Round to (seconds)"
	allowRaw
	min={0}
	on:event={handleRoundToChange}
/>
<SetupValueSelect
	dataType="Number"
	{scopeType}
	value={duration.offset}
	placeholder="Offset"
	label="Offset (seconds)"
	allowRaw
	min={0}
	on:event={handleOffsetChange}
/>
{#if roundTypeField}
	<Field field={roundTypeField} on:event={handleRoundTypeChange} />
{/if}

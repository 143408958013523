<script>
	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import Subscribe from "../Subscribe.svelte";
	import EditTopic from "./EditTopic.svelte";
	import MoveTopic from "./MoveTopic.svelte";

	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];
</script>

<Display title="Topic Settings">
	<Transition><Subscribe /></Transition>
	{#if topic?.userPermissions?.editTopic}
		<Transition><EditTopic {topic} /></Transition>
	{/if}
	{#if topic?.userPermissions?.moveTopic}
		<Transition><MoveTopic /></Transition>
	{/if}
</Display>

<script>
	import TimeStamp from "../../shared/TimeStamp.svelte";
	import { redirectToPost } from "../../../util/redirectTo.js";

	export let topic;

	function handleClick() {
		redirectToPost({
			topic,
			postNumber: "last",
		});
	}
</script>

<TimeStamp
	clickable={true}
	time={topic?.lastPost?.createdAt}
	reversed={true}
	on:click={handleClick}
/>

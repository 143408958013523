<script>
	import pluralize from "pluralize";

	import Card from "../../tailwind/card/Card.svelte";
	import CardBody from "../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../tailwind/card/CardTitle.svelte";
	import Link from "../../tailwind/Link.svelte";
	import Badge from "../../tailwind/Badge.svelte";

	import LastPost from "../forum/sectionGroup/LastPost.svelte";

	export let domain;
	export let forumData = {};

	const path = `/${domain.slug}/forum`;
</script>

<Card>
	<CardBody>
		<CardTitle><Link {path}>{domain.title}</Link></CardTitle>
		<div>{domain.description}</div>
		{#if forumData?.lastTopic?.lastPost}
			<div>
				<LastPost topic={forumData.lastTopic} />
			</div>
		{/if}
		<div class="flex flex-wrap gap-2">
			<div>
				<Badge>{pluralize("topic", forumData?.topicCount || 0, true)}</Badge>
			</div>
			<div>
				<Badge>{pluralize("post", forumData?.postCount || 0, true)}</Badge>
			</div>
		</div>
	</CardBody>
</Card>

<script>
	import Breadcrumbs from "./Breadcrumbs.svelte";
	import Pagination from "../tailwind/Pagination.svelte";

	export let size = 0;

	let middleWidth;

	$: invisible = middleWidth < 10;

	function marginTop() {
		if (size === 0) return 0;
		if (size % 2 === 0) return -32;
		return -36;
	}

	const marginStyle = `margin-top: ${marginTop()}px;`;
</script>

<div class="flex" style={marginStyle} class:invisible>
	<div>
		<Breadcrumbs {size} />
	</div>
	<div class="grow" bind:clientWidth={middleWidth}></div>
	<div>
		<Pagination {invisible} />
	</div>
</div>
{#if invisible && size < 21}
	<svelte:self size={size + 1} />
{/if}

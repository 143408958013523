<script>
	import Value from "./Value.svelte";
	import ValueEmpty from "./ValueEmpty.svelte";
	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";

	export let values = [];
	export let title = "Values";
	export let scopeType;

	$: showNewValue =
		!!values &&
		!(
			values.map((vs) => vs.name).includes(undefined) ||
			values.map((vs) => vs.name).includes(null) ||
			values.map((vs) => vs.name).includes("")
		);

	function newValue({ detail }) {
		const { dataType, valueType, isFlavorText } = detail;
		if (!dataType) return;
		let value = { dataType, valueType };
		if (isFlavorText) value.isFlavorText = true;
		if (dataType === "Text") {
			const useAllowedValues =
				scopeType !== "Setup" && ["Constant", "Variable"].includes(valueType);
			value = {
				...value,
				...(useAllowedValues ? { allowedValues: [] } : {}),
				...(valueType === "Concatenation" ? { concatenation: [] } : {}),
				...(valueType === "List Concatenation"
					? {
							listConcatenation: {
								list: null,
								itemText: null,
								separator: "",
								lastSeparator: "",
							},
						}
					: {}),
				value: "",
			};
		} else if (dataType === "Number") {
			if (valueType === "Sum") {
				value = { ...value, sum: { query: "", value: "" } };
			} else if (valueType === "Calculation") {
				value = {
					...value,
					calculation: [[[1], []]],
				};
			} else {
				value = {
					...value,
					value: 0,
				};
			}
		} else if (dataType === "True or False") {
			if (valueType === "Condition") {
				value = {
					...value,
					condition: {
						ruleType: "All",
						conditionLines: [],
					},
				};
			} else {
				value = {
					...value,
					value: true,
				};
			}
		} else if (dataType === "Duration") {
			value = {
				...value,
				duration: {
					seconds: null,
					roundTo: 0,
					offset: 0,
					roundType: "Up",
				},
			};
		}
		if (valueType === "Switch") {
			value = {
				...value,
				switch: {
					comparisonDataType: "True or False",
					comparisonValue: "True",
					operator: "equals",
					switchLines: [],
					otherwise: null,
				},
			};
		} else if (valueType === "List") {
			value = {
				...value,
				list: [],
			};
		} else if (valueType === "Filter") {
			value = {
				...value,
				filterList: {
					filterBy: null,
					sortBy: null,
					sortType: "Ascending",
				},
			};
		}
		values = [...values, value];
	}
</script>

<Card>
	<CardBody>
		<CardTitle>{title}</CardTitle>
		<slot />
		{#each values as _, i}
			<Value bind:value={values[i]} {scopeType} />
		{/each}
		{#if showNewValue}
			<ValueEmpty on:change={newValue} {scopeType} />
		{/if}
	</CardBody>
</Card>

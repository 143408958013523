<script>
	import Card from "../../tailwind/card/Card.svelte";
	import CardTitle from "../../tailwind/card/CardTitle.svelte";
	import CardBody from "../../tailwind/card/CardBody.svelte";
	import Link from "../../../bbCode/components/Link.svelte";

	export let setup;
</script>

<div class="my-2">
	<Card>
		<CardBody>
			<CardTitle>
				<Link url="game-creator/setup/{setup.slug}">{setup.title}</Link>
			</CardTitle>
			{#if setup.playerCountMin === setup.playerCountMax}
				{setup.playerCountMin} players.
			{:else}
				{setup.playerCountMin} - {setup.playerCountMax} players.
			{/if}
		</CardBody>
	</Card>
</div>

<script>
	import { createEventDispatcher, tick } from "svelte";

	import formModalStore from "../../../stores/formModalStore";

	import Tabs from "../Tabs.svelte";

	export let id = "modal";
	export let heading = null;
	export let focusOnOpen = null;
	export let tabs = null;

	const dispatch = createEventDispatcher();

	let modalOpen = false;
	let activeTab = tabs && tabs[0];
	let focused = null;

	$: focus(focusOnOpen);

	export async function open() {
		modalOpen = true;
		focus(focusOnOpen);
		dispatch("open");
	}

	export function close() {
		modalOpen = false;
		formModalStore.close();
		dispatch("close");
	}

	export function isOpen() {
		return modalOpen;
	}

	async function focus(id) {
		if (!id) return;
		await tick();
		const focusElement = document.getElementById(focusOnOpen);
		focusElement && focusElement.focus();
		focused = id;
	}
</script>

<div class="modal w-full" {id} class:modal-open={modalOpen}>
	<div class="modal-box overflow-y-visible">
		{#if heading}
			<h3 class="font-bold text-lg">{heading}</h3>
		{/if}
		{#if tabs}
			<Tabs {tabs} bind:activeTab />
		{/if}
		<slot {activeTab} />
	</div>
</div>

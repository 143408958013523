<script>
	import { getContext } from "svelte";

	import Fetcher from "../../../../api/Fetcher";

	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let post;

	const draftSelected = getContext("draftSelected");

	function handleClick() {
		Fetcher.createPostDraft({ post }).then(handleResponse);
	}

	function handleResponse(json) {
		setTimeout(() => draftSelected(json.id), 250);
	}
</script>

<Button
	className="primary"
	size="small"
	outline
	square
	tooltip="Edit"
	on:click={handleClick}
>
	<Icon name="pencilSquare" />
</Button>

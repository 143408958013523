<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let field;
	export let error = null;

	function handleBlur() {
		if (field.min && field.value < field.min) {
			field.value = field.min;
		} else if (field.max && field.value > field.max) {
			field.value = field.max;
		}
		dispatch("event", { type: "blur" });
	}
</script>

<input
	type="number"
	min={field.min}
	max={field.max}
	step={field.step}
	id={field.id}
	placeholder={field.placeholder}
	class="
    input input-bordered
    {field.inputClass ? ` input-${field.inputClass}` : ''}
  "
	class:input-error={error != null}
	bind:value={field.value}
	on:change={() => dispatch("event", { type: "change" })}
	on:keyup={() => dispatch("event", { type: "keyup" })}
	on:focus={() => dispatch("event", { type: "focus" })}
	on:blur={handleBlur}
	on:input={() => dispatch("event", { type: "input" })}
/>

import channel from "./channel.js";

function domainsChannel(currentUser) {
	return channel({ channel: channelName(currentUser) });
}

function channelName(currentUser) {
	const authority = currentUser?.authority;
	if (authority == null || authority < 0) return "DomainsGuestChannel";
	if (authority < 10) return "DomainsMemberChannel";
	return "DomainsAdminChannel";
}

export default domainsChannel;

<script>
	import { tick } from "svelte";
	import Field from "../../../../forms/Field.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	import getDataTypeItems from "./getDataTypeItems";
	import getValueTypeItems from "./getValueTypeItems.js";
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import ValueDetails from "./ValueDetails.svelte";
	import CountField from "./CountField.svelte";

	export let value;
	export let scopeType;

	let showDetails = !value.id;
	let showValueTypeField = true;
	let changingDataType = false;
	$: nameField = {
		type: "input",
		inputType: "text",
		value: value.name,
		placeholder: "Name",
		maxLength: 32,
	};

	$: valueField = getValueField(value);

	$: excludeVariable = ["Setup", "Role"].includes(scopeType);
	$: excludeConstant = ["Player"].includes(scopeType);

	$: showChevron = getShowChevron(value);

	const dataTypeItems = getDataTypeItems(scopeType);

	function getShowChevron(value) {
		return !!(
			value.allowedValues ||
			value.switch ||
			value.concatenation ||
			value.listConcatenation ||
			value.calculation ||
			value.condition ||
			value.sum ||
			value.calculation ||
			value.duration ||
			value.list ||
			value.filterList
		);
	}

	function handleNameFieldEvent({ detail }) {
		if (
			(detail.type === "input" || detail.type === "blur") &&
			value.name !== detail.value
		) {
			value.name = detail.value;
		}
	}

	$: dataTypeField = getDataTypeField(value);
	$: valueTypeField = getValueTypeField(
		value,
		excludeVariable,
		excludeConstant
	);

	function getDataTypeField(value) {
		return {
			type: "input",
			inputType: "select",
			items: dataTypeItems,
			value: dataTypeValueToUse(value),
			padding: true,
			disabled: !!value.id,
		};
	}

	function dataTypeValueToUse(value) {
		if (value.isFlavorText) return "Flavor Text";
		return value?.dataType || dataTypeItems[0];
	}

	function getValueTypeField(value, excludeVariable, excludeConstant) {
		const valueTypeItems = getValueTypeItems(
			value.dataType,
			excludeVariable,
			excludeConstant,
			value.isFlavorText
		);
		if (value?.valueType && !valueTypeItems.includes(value.valueType)) {
			delete value.valueType;
			delete value.allowedValues;
			delete value.switch;
			delete value.concatenation;
			delete value.calculation;
			delete value.condition;
			delete value.sum;
			delete value.calculation;
			delete value.duration;
			delete value.list;
			delete value.filterList;
			value = value;
		}
		return {
			type: "input",
			inputType: "select",
			items: valueTypeItems,
			value: value?.valueType,
			padding: true,
			disabled: !!value.id,
		};
	}

	function getValueField(value) {
		if (value.condition) return null;
		if (value.allowedValues?.length > 0) {
			if (!value.allowedValues.includes(value.value)) {
				value.value = value.allowedValues[0];
			}
			return {
				type: "input",
				inputType: "select",
				items: value.allowedValues,
				value: value.value,
				padding: true,
			};
		}
		if (value.dataType === "Text") {
			if (["Constant", "Variable"].includes(value.valueType)) {
				const placeholder = scopeType === "Setup" ? "Value" : "Default value";
				return {
					type: "input",
					inputType: "text",
					value: value.value,
					placeholder,
					maxLength: 256,
				};
			}
		}
		if (value.dataType === "Number") {
			if (!["Constant", "Variable"].includes(value.valueType)) return null;
			return {
				type: "input",
				inputType: "number",
				value:
					typeof value.value === "number"
						? value.value
						: parseInt(value.value) || 0,
			};
		}
		if (value.dataType === "True or False") {
			return {
				type: "input",
				inputType: "select",
				items: ["True", "False"],
				value:
					value.value === "False" ? "False" : !!value.value ? "True" : "False",
				padding: true,
			};
		}
		return null;
	}

	function handleValueFieldEvent({ detail }) {
		if (
			["blur", "check", "select", "change"].includes(detail.type) &&
			detail.value != null
		) {
			value.value = detail.value;
		}
	}

	async function handleDataTypeChange({ detail }) {
		showValueTypeField = false;
		changingDataType = true;
		await tick();
		let dataType;
		if (detail.value === "Flavor Text") {
			dataType = "Text";
			value.isFlavorText = true;
		} else {
			dataType = detail.value;
			value.isFlavorText = false;
		}
		value.dataType = dataType;
		if (dataType === "Text") {
			if (scopeType === "Setup") {
				delete value.allowedValues;
			} else {
				value.allowedValues ||= [];
			}
			if (typeof value.value !== "string") value.value = "";
		} else if (dataType === "Number") {
			value.allowedValues = null;
			if (typeof value.value !== "number") value.value = 0;
		} else if (dataType === "True or False") {
			value.allowedValues = null;
			if (typeof value.value !== "boolean") {
				value.value = true;
			}
		}
		if (dataType !== "Text" && value.concatenation) {
			delete value.concatenation;
			value = value;
		}
		if (dataType !== "True or False" && value.condition) {
			delete value.condition;
			value = value;
		}
		if (dataType === "Duration") {
			value.duration ||= {
				duration: null,
				roundTo: 0,
				offset: 0,
				roundType: "Up",
			};
		} else {
			delete value.duration;
			value = value;
		}
		setTimeout(() => {
			showValueTypeField = true;
			changingDataType = false;
		}, 1);
	}

	function handleValueTypeChange({ detail }) {
		value.valueType = detail.value;
		if (useAllowedValues(value.valueType)) {
			value.allowedValues ||= [];
		} else {
			delete value.allowedValues;
			value = value;
		}
		if (value.valueType === "Condition") {
			value.condition ||= {
				ruleType: "All",
				conditionLines: [],
			};
		} else {
			delete value.condition;
			value = value;
		}
		if (value.valueType === "Concatenation") {
			value.concatenation ||= [];
		} else {
			delete value.concatenation;
			value = value;
		}
		if (value.valueType === "List Concatenation") {
			value.listConcatenation ||= {
				list: null,
				itemText: null,
				separator: "",
				lastSeparator: "",
			};
		} else {
			delete value.listConcatenation;
			value = value;
		}
		if (value.valueType === "Sum") {
			value.sum ||= { query: "", value: "" };
		} else {
			delete value.sum;
			value = value;
		}
		if (value.valueType === "Calculation") {
			value.calculation ||= [[[1], []]];
		} else {
			delete value.calculation;
			value = value;
		}
		if (value.valueType === "Switch") {
			value.switch ||= {
				comparisonDataType: "True or False",
				comparisonValue: "True",
				operator: "equals",
				switchLines: [],
				otherwise: null,
			};
		} else {
			delete value.switch;
			value = value;
		}
		if (value.valueType === "List") {
			value.list ||= [];
		} else {
			delete value.list;
			value = value;
		}
		if (value.valueType === "Filter") {
			value.filterList ||= {
				filter: null,
				sortBy: null,
				sortType: "Ascending",
			};
		} else {
			delete value.filterList;
			value = value;
		}
	}

	function useAllowedValues(valueType) {
		if (scopeType === "Setup") return false;
		return ["Constant", "Variable"].includes(valueType);
	}

	function toggleShowDetails() {
		showDetails = !showDetails;
	}
</script>

<div class="bg-base-200 py-2 px-3 rounded-xl flex gap-2">
	<div class="grow grid grid-cols-4 gap-2">
		{#if dataTypeField}
			<Field field={dataTypeField} on:event={handleDataTypeChange} />
		{/if}
		{#if !changingDataType}
			{#if valueTypeField && (showValueTypeField || true)}
				<Field field={valueTypeField} on:event={handleValueTypeChange} />
			{:else}
				<div></div>
			{/if}
			{#if nameField}
				<Field field={nameField} on:event={handleNameFieldEvent} />
			{/if}
			{#if valueField}
				<Field field={valueField} on:event={handleValueFieldEvent} />
			{:else if value.valueType === "Count"}
				<CountField
					value={value.value}
					{scopeType}
					on:event={handleValueFieldEvent}
				/>
			{:else if ["Team", "Role"].includes(value.dataType)}
				<SetupValueSelect
					dataType={value.dataType}
					{scopeType}
					value={value.value}
					allowRaw
					allowNull
					excluded={value.id}
					on:event={handleValueFieldEvent}
				/>
			{:else}
				<div></div>
			{/if}
			{#if showChevron && showDetails}
				<ValueDetails bind:value {scopeType} />
			{/if}
		{/if}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="mt-3" class:invisible={!showChevron} on:click={toggleShowDetails}>
		<div class="cursor-pointer">
			<Icon name={showDetails ? "chevronUp" : "chevronDown"} />
		</div>
	</div>
</div>

import { writable } from "svelte/store";
import { mergeObjects } from "./merge.js";
import API from "../../api/API.js";
import DateTime from "../../util/DateTime";
import Fetcher from "../../api/Fetcher.js";

const { subscribe, set, update } = writable({});

let previousRequests = {
	topic: {},
	posts: {},
};

function reset() {
	set({});
	previousRequests = {
		topic: {},
		posts: {},
	};
}

let forbiddenIds = [];

function fetchTopic({ forumSlug, sectionSlug, topicSlug }) {
	if (!forumSlug || !sectionSlug || !topicSlug) return;
	const currentRequest = DateTime.now();
	API.get(`forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}`, {
		previousRequest: previousRequests.topic[topicSlug],
	}).then((json) => {
		updateTopics([json]);
		previousRequests.topic[topicSlug] = currentRequest;
	});
}

function fetchPostsPage({ forumSlug, sectionSlug, topicSlug, pageNumber }) {
	if (!(forumSlug && sectionSlug && topicSlug && pageNumber)) return;
	const currentRequest = DateTime.now();
	const url = `forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}/pages/${pageNumber}`;
	API.get(url, { previousRequest: previousRequests.posts[topicSlug] }).then(
		({ offset = 0, posts } = {}) => {
			updateTopics([{ slug: topicSlug, posts: [...Array(offset), ...posts] }]);
			previousRequests.posts[topicSlug] = currentRequest;
		}
	);
}

function addPost({ topicSlug, post }) {
	updateTopics([
		{
			slug: topicSlug,
			posts: [...Array(post.number - 1), post],
			postCount: post.number,
		},
	]);
}

function updateTopics(newTopics) {
	update((topics) => {
		for (const newTopic of newTopics) {
			const slug = newTopic.slug;
			topics[slug] = mergeInTopic(topics[slug], newTopic);
		}
		return topics;
	});
}

function updateTopic(newTopic) {
	const slug = newTopic.slug;
	if (!slug) return requestTopicCover(newTopic.id);
	update((topics) => {
		topics[slug] = mergeInTopic(topics[slug], newTopic);
		return topics;
	});
}

function setTopicValues(newTopic) {
	update((topics) => {
		topics[newTopic.slug] = {
			...topics[newTopic.slug],
			...newTopic,
		};
		return topics;
	});
}

function requestTopicCover(topicId) {
	if (!topicId) return;
	Fetcher.requestTopicCover({ topicId }).then(({ topic }) => {
		if (topic) updateTopic(topic);
	});
}

function mergeInTopic(topic = {}, newTopic = {}) {
	const keys = [
		"id",
		"slug",
		"sectionSlug",
		"forumSlug",
		"title",
		"posts",
		"postCount",
		"startedBy",
		"userData",
		"userPermissions",
		"userSettings",
		"posts",
		"lastPost",
		"permissions",
		"viewers",
		"posters",
	];
	const result = mergeObjects(topic, newTopic, keys);
	return result;
}

function updateReceived(message) {
	if (message.topic) {
		updateTopic(message.topic);
	} else if (message.topicId) {
		if (forbiddenIds.includes(message.topicId)) return;
	}
}

function accessGranted(id) {
	forbiddenIds = forbiddenIds.filter((i) => i !== id);
}

export default {
	subscribe,
	reset,
	updateTopics,
	updateTopic,
	setTopicValues,
	fetchTopic,
	fetchPostsPage,
	addPost,
};

<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let effectLine;
	export let scopeType;

	const dispatch = createEventDispatcher();

	function handleChatChange({ detail }) {
		dispatch("event", { value: { ...effectLine, chat: detail.value } });
	}

	function handleTextChange({ detail }) {
		dispatch("event", { value: { ...effectLine, text: detail.value } });
	}
</script>

<SetupValueSelect
	value={effectLine.chat}
	{scopeType}
	dataType="Chat"
	on:event={handleChatChange}
/>
<SetupValueSelect
	value={effectLine.text}
	{scopeType}
	dataType="Text"
	allowRaw
	on:event={handleTextChange}
/>
<div></div>

<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Actions from "./Actions.svelte";
	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Fetcher from "../../../../../api/gameCreator/Fetcher";

	export let activeTab;
	export let setup;
	export let unsaved;

	let actions = [];
	let actionConstants, actionVariables;
	let show = true;

	$: handleSetupActionsChange(setup.actions);
	$: handleSetupChange(setup);
	$: unsaved = !equal(actions, setup.actions);

	function handleSetupActionsChange(setupActions) {
		if (actions.length === 0) actions = clone(setupActions);
	}

	function handleSetupChange(setup) {
		if (!setup?.values) return;
		const actionValues = setup.values.Action;
		actionConstants = actionValues.filter(
			(value) => value.valueType === "Constant"
		);
		actionVariables = actionValues.filter(
			(value) => value.valueType === "Variable"
		);
	}
	function handleSave() {
		if (activeTab !== "Actions") return;
		Fetcher.updateActions({
			setupSlug: setup.slug,
			actions: actions,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		actions = clone(savedSetup.actions);
	}

	function handleRevert() {
		actions = clone(setup.actions);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
/>

{#if show}
	<Actions bind:actions {actionConstants} {actionVariables} />
{/if}

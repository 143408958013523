<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let field = null;
	export let error = null;

	let input;

	export function focus() {
		input.focus();
	}
</script>

<input
	bind:this={input}
	type="text"
	maxLength={field.maxLength || 64}
	id={field.id}
	placeholder={field.placeholder}
	class="
    input input-bordered w-full
    {field.inputClass ? ` ${field.inputClass}` : ''}
  "
	class:input-error={error != null}
	disabled={field.disabled}
	bind:value={field.value}
	on:change={() => dispatch("event", { type: "change", value: field.value })}
	on:keyup={() => dispatch("event", { type: "keyup", value: field.value })}
	on:focus={() => dispatch("event", { type: "focus", value: field.value })}
	on:blur={() => dispatch("event", { type: "blur", value: field.value })}
	on:input={() => dispatch("event", { type: "input", value: field.value })}
/>

<script>
	import PostReact from "./PostReact.svelte";

	export let reacts;

	$: sortedReacts = getSortedReacts(reacts);

	function getSortedReacts(reacts) {
		const result = [];
		for (const react of reacts) {
			const index = result.findIndex((x) => x.text === react.text);
			if (index === -1) {
				result.push({
					text: react.text,
					count: 1,
					usernames: [react.user.username],
				});
			} else {
				result[index].count += 1;
				result[index].usernames.push(react.user.username);
			}
		}
		return result.sort((a, b) => b.count - a.count).slice(0, 6);
	}
</script>

{#each sortedReacts as react}
	<PostReact {react} />
{/each}

<script>
	import { createEventDispatcher } from "svelte";

	import SvelteSelect from "./SvelteSelect.svelte";

	export let field = null;
	export let error = null;

	const dispatch = createEventDispatcher();

	function handleEvent({ detail }) {
		field.value = detail.value;
		dispatch("event", detail);
	}
</script>

{#if field.padding}
	<div style="padding-top: 3px;">
		<SvelteSelect
			value={field.value}
			items={field.items}
			placeholder={field.placeholder}
			error={error || field.error}
			clearable={field.clearable}
			disabled={field.disabled}
			on:event={handleEvent}
			on:change
		/>
	</div>
{:else}
	<SvelteSelect
		value={field.value}
		items={field.items}
		placeholder={field.placeholder}
		error={error || field.error}
		clearable={field.clearable}
		disabled={field.disabled}
		on:event
		on:change
	/>
{/if}

<script>
	import { getContext, onDestroy } from "svelte";

	import themeStore from "../../../../stores/themeStore";

	import SvelteSelect from "./SvelteSelect.svelte";

	export let field;
	export let error = null;

	$: themeStore.setTemporary(field.value);

	const items = [
		"light",
		"dark",
		"cupcake",
		"bumblebee",
		"emerald",
		"corporate",
		"synthwave",
		"retro",
		"cyberpunk",
		"valentine",
		"halloween",
		"garden",
		"forest",
		"aqua",
		"lofi",
		"pastel",
		"fantasy",
		"wireframe",
		"black",
		"luxury",
		"dracula",
		"cmyk",
		"autumn",
		"business",
		"acid",
		"lemonade",
		"night",
		"coffee",
		"winter",
		"dim",
		"nord",
		"sunset",
	];

	onDestroy(handleDestroy);

	function handleDestroy() {
		themeStore.setTemporary(null);
	}
</script>

<SvelteSelect bind:value={field.value} {items} {error} on:change on:event />

<script>
	import { createEventDispatcher } from "svelte";

	import Select from "svelte-select";

	export let value;
	export let items;
	export let error = null;
	export let clearable = false;
	export let disabled = false;
	export let placeholder = "Please select";

	const dispatch = createEventDispatcher();

	let _items, _value;

	$: setItemsAndValue(items, value);

	function setItemsAndValue(items, value) {
		if (!items) return;
		_items = items.map((item) => {
			if (typeof item === "string") return { value: item, label: item };
			return item;
		});
		if (_items.length === 0) {
			_value = null;
			return;
		}
		if (value == null || value === "") return;
		const indexOfValue = _items.findIndex((item) => item.value === value);
		if (indexOfValue < 0) {
			// value = _items[0].value;
			_value = {
				value: _items[0].value,
				label: _items[0].label,
				index: 0,
			};
		} else {
			_value = {
				value: value,
				label: value,
				index: indexOfValue,
			};
		}
	}

	function handleSelect({ detail }) {
		value = detail.value || detail;
		value = value;
		setValue(value);
		dispatch("change", { ...detail, value });
		dispatch("event", { ...detail, type: "change", value });
	}

	function setValue(newValue) {
		value = newValue;
	}
</script>

<div class="bg-base-100" class:input-error={error != null}>
	<Select
		--background="bg-base-100"
		--list-background="white"
		--item-color="black"
		--item-hover-color="black"
		--border={error == null ? undefined : "1px solid red"}
		value={_value}
		on:select={handleSelect}
		items={_items}
		{clearable}
		{disabled}
		{placeholder}
	/>
</div>

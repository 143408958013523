<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";

	export let team;

	const dispatch = createEventDispatcher();

	$: teamFlavor = team.teamFlavor;
	$: nameField = getNameField(teamFlavor);
	$: primaryColorField = getPrimaryColorField(teamFlavor);
	$: secondaryColorField = getSecondaryColorField(teamFlavor);

	function getNameField(teamFlavor) {
		return {
			type: "input",
			inputType: "text",
			value: teamFlavor.name,
			maxLength: 16,
			label: "Name",
		};
	}

	function getPrimaryColorField(teamFlavor) {
		return {
			type: "input",
			inputType: "color",
			value: teamFlavor.primaryColor,
			label: "Primary Color",
		};
	}

	function getSecondaryColorField(teamFlavor) {
		return {
			type: "input",
			inputType: "color",
			value: teamFlavor.secondaryColor,
			label: "Secondary Color",
		};
	}

	function handleNameFieldChange({ detail }) {
		if (detail.type === "input") {
			dispatch("event", { value: { ...teamFlavor, name: detail.value } });
		}
	}

	function handlePrimaryColorChange({ detail }) {
		dispatch("event", { value: { ...teamFlavor, primaryColor: detail.value } });
	}

	function handleSecondaryColorChange({ detail }) {
		dispatch("event", {
			value: { ...teamFlavor, secondaryColor: detail.value },
		});
	}
</script>

<div class="mt-2">
	{#if nameField}
		<Field field={nameField} on:event={handleNameFieldChange} />
	{/if}
	<div class="grid grid-cols-2">
		{#if primaryColorField}
			<Field field={primaryColorField} on:event={handlePrimaryColorChange} />
		{/if}
		{#if secondaryColorField}
			<Field
				field={secondaryColorField}
				on:event={handleSecondaryColorChange}
			/>
		{/if}
	</div>
</div>

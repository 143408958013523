import { writable } from "svelte/store";
import API from "../../api/API";

const { subscribe, set, update } = writable([]);

function reset() {
	set([]);
}

function fetchDrafts() {
	API.get("drafts").then(updateDrafts);
}

function updateDrafts(newDrafts) {
	set(newDrafts);
}

function addDraft(newDraft) {
	update((drafts) => [
		newDraft,
		...drafts.filter((draft) => draft.id !== newDraft.id),
	]);
}

function removeDraft(id) {
	update((drafts) => drafts.filter((draft) => draft.id !== id));
}

export default {
	subscribe,
	reset,
	fetchDrafts,
	addDraft,
	removeDraft,
};

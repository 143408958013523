import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/sectionGroupPermissionsTab.js";

class EditSectionGroupForm extends Form {
	static title = "Edit Section Group";
	static code = "edit-section-group";
	static submitValue = "Save";

	static initialPages({
		forum,
		sectionGroup,
		domainPermissions,
		forumPermissions,
		sectionGroupPermissions,
	}) {
		return [
			permissionsTab({
				forumPermissions: { ...domainPermissions, ...forumPermissions },
				sectionGroupPermissions: sectionGroupPermissions,
			}),
			{ forum, sectionGroup },
		];
	}

	static async submit({ pages }) {
		const permissionsField = pages[0].fields[0];
		const sectionGroupPermissions = permissionsField.levels[0].value;
		const sectionsPermissions = permissionsField.levels[1].value;
		const topicsPermissions = permissionsField.levels[2].value;

		const permissions = {
			...sectionGroupPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};

		const { forum, sectionGroup } = pages[1];

		return Fetcher.updateSectionGroup({
			forum,
			sectionGroup,
			permissions,
		});
	}
}

export default EditSectionGroupForm;

<script>
	import Card from "../../tailwind/card/Card.svelte";
	import CardBody from "../../tailwind/card/CardBody.svelte";
	import defaultAvatars from "../../shared/avatar/defaults/defaultAvatars.js";

	import DefaultAvatarAcknowledgement from "./DefaultAvatarAcknowledgement.svelte";

	const avatars = Object.keys(defaultAvatars).sort();
</script>

<h1 class="font-bold text-xl">Default Avatars</h1>

<div class="mt-4 grid lg:grid-cols-2 xl:grid-cols-4 gap-4">
	{#each avatars as name}
		<Card>
			<CardBody>
				<DefaultAvatarAcknowledgement
					{name}
					avatarData={defaultAvatars[name]}
				/>
			</CardBody>
		</Card>
	{/each}
</div>

import Forum from "../forum/Forum.svelte";
import Section from "../forum/section/Section.svelte";
import Topic from "../forum/topic/Topic.svelte";

const forumRoutes = [
	{
		name: "forum",
		path: "/:slug/forum",
		component: Forum,
	},
	{
		name: "forumSection",
		path: "/:forumSlug/forum/:sectionSlug/p/:pageNumber",
		component: Section,
	},
	{
		name: "forumTopic",
		path: "/:forumSlug/forum/:sectionSlug/:topicSlug/p/:pageNumber",
		component: Topic,
	},
];

export default forumRoutes;

export function mergeArrays(a, b) {
	if (!a) return b;
	if (!b) return a;
	const result = [];
	for (let i = 0; i < Math.max(a.length, b.length); i++) {
		result.push(merge(a[i], b[i]));
	}
	return result;
}

export function mergeObjects(a, b, keys, replace = []) {
	a ||= {};
	b ||= {};
	keys ||= [...Object.keys(a), ...Object.keys(b)].filter(onlyUnique);
	const result = {};
	for (const key of keys) {
		let value;
		if (replace.includes(key)) {
			value = b[key] === undefined ? a[key] : b[key];
		} else {
			value = merge(a[key], b[key]);
		}
		if (value !== undefined) {
			result[key] = value;
		}
	}
	return result;
}

function onlyUnique(value, index, array) {
	return array.indexOf(value) === index;
}

function merge(a, b) {
	if (Array.isArray(a) && Array.isArray(b)) {
		return mergeArrays(a, b);
	}
	if (a && b && typeof a === "object" && typeof b === "object") {
		return mergeObjects(a, b);
	}
	return b === undefined ? a : b;
}

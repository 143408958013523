import { getColumns, values } from "./permissionsFunctions";

export default function sectionPermissionsTabs({
	sectionPermissions = {},
	topicPermissions,
} = {}) {
	const columns = getColumns(sectionPermissions);
	return JSON.parse(
		JSON.stringify({
			tab: "Permissions",
			fields: [
				{
					type: "permissions",
					levels: [
						{
							heading: "Topic",
							primary: true,
							columns,
							rows: ["view", "post"],
							tooltips: ["Who can view the topic", "Who can post in the topic"],
							...values({
								codes: ["view", "post"],
								parent: sectionPermissions?.topics,
								existing: topicPermissions,
							}),
						},
					],
				},
			],
		})
	);
}

<script>
	import { createEventDispatcher } from "svelte";
	import LotteryRole from "./LotteryRole.svelte";

	export let roles;
	export let items;

	const dispatch = createEventDispatcher();

	$: rolesToShow = getRolesToShow(roles);

	function getRolesToShow(roles) {
		if (roles.find((role) => !role.role)) {
			return [...roles];
		}
		return [
			...roles,
			{
				role: "",
				item: "",
				minimum: null,
				maximum: null,
			},
		];
	}

	function handleRoleChange(i, detail) {
		roles[i] = detail.value;
		dispatch("event", { value: roles });
	}

	function destroyRole(i) {
		dispatch("event", { value: [...roles.slice(0, i), ...roles.slice(i + 1)] });
	}
</script>

<div
	class="grid gap-2"
	style="grid-template-columns: repeat(4, minmax(0, 1fr)) 32px;"
>
	{#each rolesToShow as role, i}
		<LotteryRole
			{role}
			{items}
			on:event={({ detail }) => handleRoleChange(i, detail)}
			on:destroy={() => destroyRole(i)}
		/>
	{/each}
</div>

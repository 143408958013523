<script>
	export let tabs = null;
	export let activeTab = null;
	export let errors = {};
</script>

{#if tabs}
	<div class="tabs tabs-lifted">
		{#each tabs.filter(Boolean) as tab}
			<!-- svelte-ignore a11y-missing-attribute -->
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<a
				class="tab"
				class:tab-active={tab === activeTab}
				class:text-error={Object.keys(errors).includes(tab)}
				on:click={() => (activeTab = tab)}
			>
				{tab}
			</a>
		{/each}
	</div>
{/if}

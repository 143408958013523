<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";

	import pluralize from "pluralize";

	import Link from "../../../tailwind/Link.svelte";
	import Badge from "../../../tailwind/Badge.svelte";
	import LastPost from "./LastPost.svelte";

	export let sectionSlug;

	$: forumSlug = $paramsStore.domainSlug;
	$: section = $sectionsStore[sectionSlug] || {};
</script>

{#if section}
	<div class="divider my-1"></div>
	<div class="grid grid-cols-4 gap-4">
		<div class="col-span-4 lg:col-span-3">
			<div>
				<Link path="/{forumSlug}/forum/{section.slug}/p/1">
					{section.title}
				</Link>
			</div>
			<div>
				{section.description || ""}
			</div>
			{#if section.lastTopic}
				<div>
					<LastPost topic={section.lastTopic} />
				</div>
			{/if}
		</div>
		<div class="col-span-4 lg:col-span-1">
			<div>
				<Badge>{pluralize("topic", section.topicCount || 0, true)}</Badge>
			</div>
			<div>
				<Badge>{pluralize("post", section.postCount || 0, true)}</Badge>
			</div>
		</div>
	</div>
{/if}

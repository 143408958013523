import { writable } from "svelte/store";

const { subscribe, set } = writable({});

function setParams(params) {
	set({
		...params,
		domainSlug: params.slug || params.forumSlug || params.domainSlug,
	});
}

export default { subscribe, setParams };

<script>
	import LastPostBadge from "../LastPostBadge.svelte";
	import TopicBadge from "../TopicBadge.svelte";
	import UserBadge from "../../../shared/UserBadge.svelte";

	export let topic;
</script>

{#if topic?.lastPost}
	Last post:
	<LastPostBadge {topic} /> in <TopicBadge {topic} /> by <UserBadge
		user={topic?.lastPost?.user}
		inLine={true}
	/>
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	import getDataTypeItems from "./getDataTypeItems.js";
	import getValueTypeItems from "./getValueTypeItems.js";

	export let scopeType;

	$: excludeVariable = ["Setup", "Role"].includes(scopeType);
	$: excludeConstant = ["Player"].includes(scopeType);

	const dispatch = createEventDispatcher();

	let dataType = "";
	let valueType = "";
	let isFlavorText = false;

	$: dataTypeField = getDataTypeField(dataType, scopeType);

	$: valueTypeField = getValueTypeField(
		dataType,
		excludeVariable,
		excludeConstant,
		valueType,
		isFlavorText
	);

	function getDataTypeField(dataType, scopeType) {
		return {
			type: "input",
			inputType: "select",
			items: getDataTypeItems(scopeType),
			value: isFlavorText ? "Flavor Text" : dataType,
			placeholder: "Data type",
			padding: true,
		};
	}

	function getValueTypeField(
		dataType,
		excludeVariable,
		excludeConstant,
		valueType,
		isFlavorText
	) {
		if (!dataType) return null;
		return {
			type: "input",
			inputType: "select",
			items: getValueTypeItems(
				dataType,
				excludeVariable,
				excludeConstant,
				isFlavorText
			),
			value: valueType,
			placeholder: "Value type",
			padding: true,
		};
	}

	function handleDataTypeChange({ detail }) {
		if (detail.value === "Flavor Text") {
			dataType = "Text";
			isFlavorText = true;
		} else {
			dataType = detail.value;
			isFlavorText = false;
		}
	}

	function handleValueTypeChange({ detail }) {
		dispatch("change", { dataType, valueType: detail.value, isFlavorText });
		dataType = "";
		valueType = "";
		isFlavorText = false;
	}
</script>

<div class="bg-base-200 py-2 px-3 rounded-xl flex gap-2">
	<div class="grow grid grid-cols-4 gap-2">
		{#if dataTypeField}
			<Field field={dataTypeField} on:event={handleDataTypeChange} />
		{/if}
		{#if valueTypeField}
			<Field field={valueTypeField} on:event={handleValueTypeChange} />
		{:else}
			<div></div>
		{/if}
		<div></div>
		<div></div>
	</div>
	<div class="invisible">
		<Icon name="chevronDown" />
	</div>
</div>

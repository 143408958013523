<script>
	import Fetcher from "../../../api/Fetcher";

	import topicsStore from "../../../stores/data/topicsStore";

	import Button from "../../tailwind/Button.svelte";
	import Align from "../../tailwind/align/Align.svelte";
	import Icon from "../../icons/large/Icon.svelte";
	import Input from "../../forms/fields/Input.svelte";

	export let topic;

	let input;
	let showInput = false;

	let field = {
		type: "input",
		inputType: "text",
		value: "",
		inputClass: "input-sm",
		placeholder: "Password",
	};

	let error = null;

	$: handleInputChange(input);
	$: handleValueChange(field.value);

	function handleInputChange(input) {
		if (input?.focus) input.focus();
	}

	function handleValueChange() {
		if (error) error = null;
	}

	function handleClick() {
		setTimeout(() => (showInput = true), 50);
	}

	function handleSubmit() {
		if (field.value.length === 0) {
			error = true;
		} else {
			error = null;
			Fetcher.postUnlock({ topic, password: field.value }).then(handleResponse);
		}
	}

	function handleResponse(json) {
		if (json.error) {
			error = json.error;
		} else {
			topicsStore.updateTopic({ slug: topic.slug, ...json });
		}
	}

	function handleCancel() {
		showInput = false;
	}
</script>

<Align vertical="center">
	<div>
		{#if showInput}
			<div class="flex gap-2">
				<div class="grow text-base-content">
					<Input bind:this={input} bind:field {error} />
				</div>
				<div>
					<Button square size="small" on:click={handleSubmit}>
						<Icon name="tick"></Icon>
					</Button>
				</div>
				<div>
					<Button
						className="default"
						square
						size="small"
						on:click={handleCancel}
					>
						<Icon name="x"></Icon>
					</Button>
				</div>
			</div>
		{:else}
			<Button
				square
				tooltip="Unlock posting"
				tooltipDirection="left"
				className="warning"
				extraClasses="btn-sm"
				on:click={handleClick}
			>
				<Icon name="lock" />
			</Button>
		{/if}
	</div>
</Align>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import redirect from "../util/redirect.js";
import resetDataStores from "../stores/data/resetDataStores.js";
import fetchCurrentUser from "../api/fetchCurrentUser.js";

class LoginForm extends Form {
	static title = "Login";
	static code = "login-modal";
	static focusOnOpen = "login-username";
	static submitValue = "Login";

	static initialFields() {
		return [
			{
				type: "input",
				inputType: "text",
				value: "",
				label: "Username",
				id: "login-username",
				placeholder: "Username",
				required: true,
				maxLength: 32,
			},
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "Password",
				id: "login-password",
				placeholder: "Password",
				required: true,
			},
		];
	}

	static submit({ fields }) {
		const username = fields[0].value;
		const password = fields[1].value;
		return Fetcher.login({ username, password });
	}

	static handleSuccess() {
		resetDataStores();
		redirect("");
		setTimeout(() => {
			fetchCurrentUser();
		}, 250);
	}
}

export default LoginForm;

<script>
	import { createEventDispatcher } from "svelte";

	export let field;
	export let error;
	error;

	let value = field.value;

	const dispatch = createEventDispatcher();

	function handleChange(e) {
		dispatch("change", { value });
		dispatch("event", { value });
	}

	function handleInput(e) {
		dispatch("input", { value });
		dispatch("event", { value });
	}
</script>

<input
	type="color"
	bind:value
	on:change={handleChange}
	on:input={handleInput}
/>

import components from "./components/components.js";

const requireInnerText = ["datetime", "timediff"];

class BbCodeParser {
	constructor(codes) {
		this.codes = codes;
	}

	parse(text, staticBeforeText = "") {
		text = text
			.replaceAll("[color=", "[font color=")
			.replaceAll("[/color]", "[/font]")
			.replaceAll("[size=", "[font size=")
			.replaceAll("[/size]", "[/font]")
			.replaceAll("[font=", "[font face=")
			.replaceAll("[url=", "[link url=")
			.replaceAll("[url]", "[link]")
			.replaceAll("[/url]", "[/link]")
			.replaceAll("[time]", "[datetime]")
			.replaceAll("[/time]", "[/datetime]");
		const openTagRegex = /\[\w+( .*?)?\]/;
		const match = openTagRegex.exec(text);
		if (!match) return staticBeforeText + text;
		const openTag = match[0];
		const code = openTag.slice(1, openTag.length - 1).split(" ")[0];
		const beforeText = staticBeforeText + text.slice(0, match.index);
		const afterTagText = text.slice(match.index + openTag.length);
		const codeData = this.codes.find((c) => c.code === code);
		if (!codeData) {
			return this.parse(afterTagText, beforeText + openTag);
		}
		if (codeData.singleTag) {
			const props = this.props(openTag);
			let afterText = afterTagText;
			if (code === "hr" && afterText[0] === "\n") {
				afterText = afterText.slice(1);
			}
			const result = {
				component: components[code],
				props,
				beforeText,
				afterText,
			};
			return result;
		}
		const tagRegex = new RegExp(`\\[\\/?${code}( .*?)?\\]`);
		const closeTagNegativeIndexes = this.findCloseTag(afterTagText, tagRegex);
		if (!closeTagNegativeIndexes) {
			return this.parse(afterTagText, beforeText + openTag);
		}
		let innerText = afterTagText.slice(
			0,
			afterTagText.length - closeTagNegativeIndexes[0]
		);
		if (innerText[0] === "\n") {
			innerText = innerText.slice(1);
		}
		if (innerText[innerText.length - 1] === "\n") {
			innerText = innerText.slice(0, innerText.length - 1);
		}
		let afterText = afterTagText.slice(
			afterTagText.length - closeTagNegativeIndexes[1]
		);
		if (codeData.removeAfterTextNewLine && afterText[0] === "\n") {
			afterText = afterText.slice(1);
		}
		const props = this.props(openTag);
		const result = {
			component: components[code],
			props,
			beforeText,
			innerText,
			afterText,
			componentRequiresInnerText: requireInnerText.includes(code),
		};
		return result;
	}

	findCloseTag(text, tagRegex, depth = 1) {
		const match = tagRegex.exec(text);
		if (!match) return null;
		const tag = match[0];
		const afterText = text.slice(match.index + tag.length);
		if (tag[1] === "/") {
			if (depth > 1) return this.findCloseTag(afterText, tagRegex, depth - 1);
			return [tag.length + afterText.length, afterText.length];
		} else {
			return this.findCloseTag(afterText, tagRegex, depth + 1);
		}
	}

	props(tag) {
		const propsArray = tag
			.slice(1, tag.length - 1)
			.split(" ")
			.slice(1);
		const result = {};
		let i = 0;
		while (i < propsArray.length) {
			let splitProp = propsArray[i].split("=");
			if (splitProp.length > 2) {
				splitProp = [splitProp[0], splitProp.slice(1).join("=")];
			}
			if (splitProp.length === 2) {
				let [key, value] = splitProp;
				let j = i + 1;
				while (j < propsArray.length) {
					const nextValue = propsArray[j];
					if (nextValue.includes("=")) break;
					value += ` ${nextValue}`;
					j++;
				}
				result[key] = value;
				i = j;
			} else {
				i++;
			}
		}
		return result;
	}
}

export default BbCodeParser;

import Form from "../Form.js";
import Fetcher from "../../api/gameCreator/Fetcher.js";
import redirect from "../../util/redirect.js";

class NewSetupForm extends Form {
	static title = "New Test Game";
	static code = "new-test-game";
	static focusOnOpen = "new-test-game-title";
	static submitValue = "Create";

	static initialPages({ setup }) {
		return [this.basicTab(setup), ...this.setupPages(setup)];
	}

	static basicTab(setup) {
		return {
			tab: "Basic",
			fields: [
				{
					type: "input",
					inputType: "text",
					value: "",
					label: "Title",
					id: "new-test-game-title",
					placeholder: "Title",
					required: true,
				},
				{
					type: "input",
					inputType: "number",
					label: "Player count",
					id: "new-test-game-player-count",
					min: setup.playerCountMin,
					max: Math.min(setup.playerCountMax, 26),
					value: setup.playerCountMin,
					required: true,
				},
				{
					type: "input",
					inputType: "urlType",
					value: "fromTitle",
					label: "URL",
					id: "new-test-game-url-type",
					description:
						"A URL based off the title could cause another user to confirm the existence of a game with this title, even if they don't have access to it.",
				},
				{
					type: "input",
					inputType: "select",
					items: setup.flavors.map((flavor) => ({
						value: flavor.id,
						label: flavor.name,
					})),
					value: setup.activeFlavor,
					label: "Flavor",
					padding: true,
				},
				setup.id,
			],
		};
	}

	static setupPages(setup) {
		const gameConstants = setup.values.Game.filter(
			(setupValue) =>
				setupValue.valueType === "Constant" && !setupValue.isFlavorText
		);
		if (gameConstants.count === 0) {
			return [];
		}
		let pages = [{ tab: "Constants 1", fields: [] }];
		let pageNumber = 1;
		let fieldNumber = 0;
		for (const constant of gameConstants) {
			const fieldWeight = constant.dataType === "Duration" ? 4 : 1;
			if (fieldNumber + fieldWeight > 6) {
				pageNumber += 1;
				fieldNumber = 0;
				pages = [...pages, { tab: `Constants ${pageNumber}`, fields: [] }];
			}
			pages[pages.length - 1].fields.push(this.constantField(constant));
			fieldNumber += fieldWeight;
		}
		return pages;
	}

	static constantField(constant) {
		const field = {
			id: constant.id,
			value: constant.value,
			label: constant.name,
			placeholder: constant.name,
		};
		if (constant.dataType === "Text") {
			if (constant.allowedValues?.length > 0) {
				return {
					...field,
					type: "input",
					inputType: "select",
					items: constant.allowedValues,
				};
			}
			return {
				...field,
				type: "input",
				inputType: "text",
				maxLength: 64,
			};
		}
		if (constant.dataType === "Number") {
			return {
				...field,
				type: "input",
				inputType: "number",
			};
		}
		if (constant.dataType === "True or False") {
			return {
				...field,
				type: "input",
				inputType: "select",
				items: ["True", "False"],
			};
		}
		if (constant.dataType === "Duration") {
			let totalSeconds = parseInt(constant.duration.duration);
			const days = Math.floor(totalSeconds / 86400);
			totalSeconds -= days * 86400;
			const hours = Math.floor(totalSeconds / 3600);
			totalSeconds -= hours * 3600;
			const minutes = Math.floor(totalSeconds / 60);
			const seconds = totalSeconds - minutes * 60;
			let totalRoundSeconds = parseInt(constant.duration.roundTo);
			const roundDays = Math.floor(totalRoundSeconds / 86400);
			totalRoundSeconds -= roundDays * 86400;
			const roundHours = Math.floor(totalRoundSeconds / 3600);
			totalRoundSeconds -= roundHours * 3600;
			const roundMinutes = Math.floor(totalRoundSeconds / 60);
			const roundSeconds = totalRoundSeconds - minutes * 60;
			let totalOffsetSeconds = parseInt(constant.duration.offset);
			const offsetDays = Math.floor(totalOffsetSeconds / 86400);
			totalOffsetSeconds -= offsetDays * 86400;
			const offsetHours = Math.floor(totalOffsetSeconds / 3600);
			totalOffsetSeconds -= offsetDays * 3600;
			const offsetMinutes = Math.floor(totalOffsetSeconds / 60);
			const offsetSeconds = totalOffsetSeconds - minutes * 60;
			return {
				...field,
				value: {
					days,
					hours,
					minutes,
					seconds,
					roundDays,
					roundHours,
					roundMinutes,
					roundSeconds,
					offsetDays,
					offsetHours,
					offsetMinutes,
					offsetSeconds,
					roundType: constant.duration.roundType,
				},
				type: "duration",
			};
		}
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const title = basicFields[0].value;
		const playerCount = basicFields[1].value;
		const urlType = basicFields[2].value;
		const flavorId = basicFields[3].value;
		const setupId = basicFields[4];
		const constantPages = pages.slice(1);
		let constants = [];
		for (const page of constantPages) {
			constants = [
				...constants,
				...page.fields.map((field) => ({
					setupValueId: field.id,
					value: {
						duration:
							field.value.days * 86400 +
							field.value.hours * 3600 +
							field.value.minutes * 60 +
							field.value.seconds,
						roundTo:
							field.value.roundDays * 86400 +
							field.value.roundHours * 3600 +
							field.value.roundMinutes * 60 +
							field.value.roundSeconds,
						offset:
							field.value.offsetDays * 86400 +
							field.value.offsetHours * 3600 +
							field.value.offsetMinutes * 60 +
							field.value.offsetSeconds,
						roundType: field.value.roundType,
					},
				})),
			];
		}
		const args = { setupId, title, playerCount, urlType, flavorId, constants };
		return Fetcher.createTestGame(args);
	}

	static handleSuccess(json) {
		if (json.slug) {
			const url = `game-creator/game/${json.slug}`;
			redirect(url);
		}
	}
}
export default NewSetupForm;

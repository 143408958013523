<script>
	import CardIcon from "./CardIcon.svelte";

	export let ban;

	const icons = ["Sun", "Sandal", "Die", "Helmet", "Hidden", "Blank"];
</script>

{#if icons.includes(ban)}
	<div>
		<CardIcon icon={ban} small />
	</div>
{:else}
	<div class="pt-1">
		{ban}
	</div>
{/if}

<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import editSectionsStore from "../../../../stores/editSectionsStore.js";

	import Card from "../../../tailwind/card/Card.svelte";
	import CardBody from "../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../tailwind/card/CardTitle.svelte";
	import Reorder from "../../../shared/reorder/Reorder.svelte";
	import TextInput from "../../../forms/fields/inputs/TextInput.svelte";
	import SectionGroupSelector from "../SectionGroupSelector.svelte";
	import EditSection from "../EditSection.svelte";

	export let sectionGroup;
	export let sectionGroupIndex;

	$: forumSlug = $paramsStore.domainSlug;

	function handleSort(e) {
		editSectionsStore.changeSections(forumSlug, sectionGroupIndex, e.detail);
	}

	function handleTextEvent(e, index) {
		if (e.detail.type === "keyup") {
			editSectionsStore.renameSection(
				forumSlug,
				sectionGroupIndex,
				index,
				e.detail.value
			);
		}
	}

	function itemToField(item) {
		return {
			id: item.id,
			value: item.title,
			placeholder: item.placeholder,
			required: !item.new,
		};
	}
</script>

{#if sectionGroup.title}
	<Card>
		<CardBody>
			<CardTitle>
				{sectionGroup.title}
			</CardTitle>
			<Reorder
				items={sectionGroup.sections}
				let:item
				let:index
				on:sort={handleSort}
			>
				<div class="flex gap-2">
					<div class="grow grid grid-cols-2 gap-2">
						<TextInput
							field={itemToField(item)}
							on:event={(e) => handleTextEvent(e, index)}
						/>
						<div class="flex flex-col">
							<div class="flex-grow"></div>
							<SectionGroupSelector
								sectionGroupId={sectionGroup.id}
								{sectionGroupIndex}
								sectionIndex={index}
								disabled={item.title.length === 0}
							/>
							<div class="flex-grow"></div>
						</div>
					</div>
					<div class:invisible={item.new}>
						<EditSection
							sectionGroupId={sectionGroup.id}
							sectionId={item.id}
							buttonClass="btn-square"
							iconName="cog"
						/>
					</div>
				</div>
			</Reorder>
		</CardBody>
	</Card>
{/if}

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab1 from "../components/shared/permissionsTabs/forumPermissionsTab1.js";
import permissionsTab2 from "../components/shared/permissionsTabs/forumPermissionsTab2.js";

import domainsStore from "../stores/data/domainsStore.js";
import forumsStore from "../stores/data/forumsStore.js";

class EditForumForm extends Form {
	static title = "Edit Forum";
	static code = "edit-forum";
	static submitValue = "Save";

	static initialPages({
		forumSlug,
		domain,
		domainPermissions,
		forumPermissions,
	}) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: domain.description,
						label: "Description",
						id: "edit-forum-description",
						placeholder: domain.description,
						maxLength: 128,
					},
					{
						type: "input",
						inputType: "icon",
						value: domain.icon,
						label: "Icon",
						id: "domain-icon",
					},
				],
			},
			permissionsTab1({
				domainPermissions,
				forumPermissions,
			}),
			permissionsTab2({
				domainPermissions,
				forumPermissions,
			}),
			{ forumSlug },
		];
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const description = basicFields[0].value;
		const icon = basicFields[1].value;
		const permissionsField1 = pages[1].fields[0];
		const permissionsField2 = pages[2].fields[0];
		const domainPermissions = permissionsField1.levels[0].value;
		const sectionGroupsPermissions = permissionsField1.levels[1].value;
		const sectionsPermissions = permissionsField1.levels[2].value;
		const topicsPermissions = permissionsField2.levels[0].value;

		const forumPermissions = {
			sectionGroups: sectionGroupsPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};

		const { forumSlug } = pages[3];

		return Fetcher.updateForum({
			forumSlug,
			description,
			icon,
			domainPermissions,
			forumPermissions,
		});
	}

	static handleSuccess({ domain, forum }) {
		domainsStore.updateDomain(domain);
		forumsStore.updateForum(forum);
	}
}

export default EditForumForm;

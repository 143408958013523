import { writable } from "svelte/store";
import API from "../../api/API";

const { subscribe, set, update } = writable({});

function reset() {
	set({});
	API.removePreviousRequest("domains");
}

function fetchDomains() {
	API.get("domains").then(updateDomains);
}

function fetchDomain(slug) {
	API.get(`domains/${slug}`).then(updateDomain);
}

function updateDomains(newDomains) {
	update((domains) => {
		for (const newDomain of newDomains) {
			const slug = newDomain.slug;
			domains[slug] = {
				...domains[slug],
				...newDomain,
			};
		}
		return domains;
	});
}

function updateDomain(newDomain) {
	update((domains) => {
		const slug = newDomain.slug;
		domains[slug] = {
			...domains[slug],
			...newDomain,
		};
		return domains;
	});
}

export default {
	subscribe,
	reset,
	fetchDomains,
	fetchDomain,
	updateDomain,
};

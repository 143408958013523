let ls;

try {
	ls = _localStorage;
} catch {
	ls = localStorage;
}

export function getCurrentUserSlug() {
	const currentUserSlug = ls.getItem("currentUserSlug");
	if (currentUserSlug) return currentUserSlug;
	setCurrentUserSlug("guest");
	return "guest";
}

export function setCurrentUserSlug(currentUserSlug) {
	ls.setItem("currentUserSlug", currentUserSlug);
}

export function clearCurrentUserSlug() {
	ls.setItem("currentUserSlug", "guest");
}

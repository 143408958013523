<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import EffectSelect from "../effects/EffectSelect.svelte";
	import SwitchLine from "./SwitchLine.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";

	import getOperatorItems from "./getOperatorItems";

	export let switchValue;
	export let scopeType;
	export let valueId;
	export let valueDataType;

	const dispatch = createEventDispatcher();

	const dataTypeItems = [
		"Text",
		"Number",
		"True or False",
		"Team",
		"Role",
		...(scopeType === "Setup" ? [] : ["Player", "Action", "Chat"]),
	];

	let allowRaw = false;

	$: comparisonDataTypeField = getComparisonDataTypeField(switchValue);
	$: operatorField = getOperatorField(switchValue);
	$: switchLines = getSwitchLines(switchValue);

	function getComparisonDataTypeField(switchValue) {
		return {
			type: "input",
			inputType: "select",
			items: dataTypeItems,
			value: switchValue.comparisonDataType,
			padding: true,
		};
	}

	function handleComparisonDataTypeChange({ detail }) {
		const dataType = detail.value;
		switchValue.comparisonDataType = dataType;
		if (dataType === "Text") {
			switchValue.comparisonValue = "";
		} else if (dataType === "Number") {
			switchValue.comparisonValue = null;
		} else if (dataType === "True or False") {
			switchValue.comparisonValue = "True";
		} else {
			switchValue.comparisonValue = null;
		}
		dispatch("event", { value: switchValue });
	}

	function handleComparisonValueChange({ detail }) {
		switchValue.comparisonValue = detail.value;
		const newAllowRaw = !detail.raw;
		if (newAllowRaw !== allowRaw) {
			allowRaw = newAllowRaw;
		}
		dispatch("event", { value: switchValue });
	}

	function handleOperatorChange({ detail }) {
		switchValue.operator = detail.value;
		dispatch("event", { value: switchValue });
	}

	function getOperatorField(switchValue) {
		return {
			type: "input",
			inputType: "select",
			items: getOperatorItems(switchValue.comparisonDataType),
			value: switchValue.operator,
			padding: true,
		};
	}

	function getSwitchLines(switchValue) {
		if (
			switchValue.switchLines.find(
				(switchLine) => switchLine.comparisonValue == null
			)
		) {
			return [...switchValue.switchLines];
		}
		return [
			...switchValue.switchLines,
			{ comparisonValue: null, result: null },
		];
	}

	function handleSwitchLineChange(i, { value }) {
		switchValue.switchLines[i] = value;
		dispatch("event", { value: switchValue });
	}

	function handleOtherwiseChange({ detail }) {
		switchValue.otherwise = detail.value;
		dispatch("event", { value: switchValue });
	}
</script>

<Align both="center">Comparison:</Align>
{#if comparisonDataTypeField}
	<Field
		field={comparisonDataTypeField}
		on:event={handleComparisonDataTypeChange}
	/>
{/if}
<SetupValueSelect
	value={switchValue.comparisonValue}
	dataType={switchValue.comparisonDataType}
	{scopeType}
	allowRaw
	excluded={valueId}
	on:event={handleComparisonValueChange}
/>
{#if operatorField}
	<Field field={operatorField} on:event={handleOperatorChange} />
{/if}
{#each switchLines as switchLine, i}
	<SwitchLine
		{switchLine}
		{switchValue}
		{scopeType}
		{valueId}
		{valueDataType}
		{allowRaw}
		on:event={({ detail }) => handleSwitchLineChange(i, detail)}
	/>
{/each}
{#if valueDataType === "Effect"}
	<EffectSelect
		effect={switchValue.othewise}
		{scopeType}
		excluded={valueId}
		on:event={handleOtherwiseChange}
	/>{:else}
	<SetupValueSelect
		value={switchValue.otherwise}
		dataType={switchValue.comparisonDataType}
		{scopeType}
		allowRaw
		excluded={valueId}
		placeholder="Otherwise"
		on:event={handleOtherwiseChange}
	/>
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import ReorderBaseItem from "./ReorderBaseItem.svelte";
	import ReorderDraggableItem from "./ReorderDraggableItem.svelte";

	export let items;
	export let key = "id";

	const dispatch = createEventDispatcher();

	const heights = [];
	const tops = [];
	const gapSize = 8;
	const sizeStyles = [];

	let movingIndex = -1;
	let movingTop;

	function onMouseUp(e) {
		const { index, top } = e.detail;
		const currentKey = items[index][key];
		let newIndex;
		if (top > tops[index]) {
			for (let i = index; i < tops.length; i++) {
				if (i === tops.length - 1 || top < tops[i + 1]) {
					newIndex = i;
					break;
				}
			}
		} else if (top < tops[index]) {
			for (let i = index; i >= 0; i--) {
				if (i === 0 || top > tops[i - 1]) {
					newIndex = i;
					break;
				}
			}
		}
		if (newIndex === index) return;
		let newItems;
		if (newIndex < index) {
			newItems = [
				...items.slice(0, newIndex),
				items[index],
				...items.slice(newIndex, index),
				...items.slice(index + 1),
			];
		} else {
			newItems = [
				...items.slice(0, index),
				...items.slice(index + 1, newIndex + 1),
				items[index],
				...items.slice(newIndex + 1),
			];
		}
		const newKeys = newItems.map((item) => item[key]);
		if (newKeys.filter((k) => k === currentKey).length === 1) {
			dispatch("sort", newItems);
		}
	}
</script>

<div class="relative flex flex-col gap-2 mt-2">
	{#each items as item, index (item[key])}
		<ReorderBaseItem
			bind:sizeStyle={sizeStyles[index]}
			bind:height={heights[index]}
		>
			<slot {item} {index} />
		</ReorderBaseItem>
	{/each}
	{#each items as item, index (item[key])}
		<ReorderDraggableItem
			sizeStyle={sizeStyles[index]}
			bind:top={tops[index]}
			bind:movingIndex
			bind:movingTop
			{index}
			{heights}
			{gapSize}
			on:mouseup={onMouseUp}
		>
			<slot {item} {index} />
		</ReorderDraggableItem>
	{/each}
</div>

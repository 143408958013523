<script>
	import { onMount, onDestroy } from "svelte";

	import paramsStore from "../../../../stores/paramsStore.js";
	import gamesStore from "../../../../stores/data/gameCreator/gamesStore.js";
	import setupsStore from "../../../../stores/data/gameCreator/setupsStore";

	import Link from "../../../tailwind/Link.svelte";
	import ModeratorActions from "./ModeratorActions.svelte";

	$: domainSlug = $paramsStore.domainSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: game = $gamesStore[gameSlug];
	// $: setup = $setupsStore[game?.setup.slug];

	$: console.log("game", game);

	onMount(handleMount);
	onDestroy(handleDestroy);

	function handleMount() {
		gamesStore.fetchGame(gameSlug);
	}

	function handleDestroy() {}
</script>

<ModeratorActions {game} />

{#each game?.topics || [] as topic}
	<div>
		<Link path="/{topic.domainSlug}/game/{game.slug}/{topic.slug}/p/1">
			{topic.title}
		</Link>
	</div>
{/each}

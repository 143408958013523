<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let chat;

	const dispatch = createEventDispatcher();

	function handleTitleChange({ detail }) {
		if (detail.value === chat.title) return;
		dispatch("event", { value: { ...chat, title: detail.value } });
	}

	function handleRandomUrlChange({ detail }) {
		if (detail.value === chat.randomUrl) return;
		dispatch("event", { value: { ...chat, randomUrl: detail.value } });
	}

	function handleRevealRolesChange({ detail }) {
		if (detail.value === chat.revealRoles) return;
		dispatch("event", { value: { ...chat, revealRoles: detail.value } });
	}

	function handleRevealAllRolesChange({ detail }) {
		if (detail.value === chat.revealAllRoles) return;
		dispatch("event", { value: { ...chat, revealAllRoles: detail.value } });
	}
</script>

<div class="grid grid-cols-2 gap-2">
	<SetupValueSelect
		value={chat.title}
		dataType="Text"
		scopeTypes={["Setup", "Game", "Team", "Role", "Player", "Action", "Chat"]}
		placeholder="Title"
		label="Title"
		allowRaw
		on:event={handleTitleChange}
	/>
	<SetupValueSelect
		value={chat.randomUrl}
		dataType="True or False"
		scopeTypes={["Setup", "Game"]}
		placeholder="Random URL"
		label="Random URL"
		allowRaw
		on:event={handleRandomUrlChange}
	/>
	<SetupValueSelect
		value={chat.revealRoles}
		dataType="True or False"
		scopeTypes={["Setup", "Game"]}
		placeholder="Reveal Roles"
		label="Reveal Roles"
		allowRaw
		on:event={handleRevealRolesChange}
	/>
	<SetupValueSelect
		value={chat.revealAllRoles}
		dataType="True or False"
		scopeTypes={["Setup", "Game"]}
		placeholder="Reveal All Roles"
		label="Reveal All Roles"
		allowRaw
		on:event={handleRevealAllRolesChange}
	/>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import FlavorCard from "../flavors/FlavorCard.svelte";
	import Tabs from "../../../../shared/Tabs.svelte";
	import TabPage from "../../../../shared/TabPage.svelte";
	import TeamFlavor from "./TeamFlavor.svelte";
	import StandardTeamConstants from "./StandardTeamConstants.svelte";
	import ObjectValueInputs from "../ObjectValueInputs.svelte";

	export let team;
	export let teamConstants;
	export let teamVariables;
	export let roleConstants;
	export let playerVariables;

	const dispatch = createEventDispatcher();

	let activeTab = "Flavor";

	const tabs = [
		"Flavor",
		"Constants",
		"Initial Variables",
		"Role Constant Defaults",
		"Player Variable Defaults",
	];

	function handleTeamFlavorChange({ detail }) {
		dispatch("event", { value: { ...team, teamFlavor: detail.value } });
	}

	function handleTeamConstantsChange({ detail }) {
		dispatch("event", { value: { ...team, constants: detail.value } });
	}

	function handleTeamVariablesChange({ detail }) {
		dispatch("event", { value: { ...team, variables: detail.value } });
	}

	function handleRoleConstantsChange({ detail }) {
		dispatch("event", { value: { ...team, roleConstants: detail.value } });
	}

	function handlePlayerVariablesChange({ detail }) {
		dispatch("event", { value: { ...team, playerVariables: detail.value } });
	}
</script>

<FlavorCard flavor={team.teamFlavor} title={team.teamFlavor.name}>
	<Tabs {tabs} bind:activeTab bordered></Tabs>
	<TabPage tab="Flavor" {activeTab}>
		<TeamFlavor {team} on:event={handleTeamFlavorChange} />
	</TabPage>
	<TabPage tab="Constants" {activeTab}>
		<div class="flex flex-col gap-2">
			<StandardTeamConstants {team} on:event />
			<ObjectValueInputs
				objectValues={team.constants}
				setupValues={teamConstants}
				scopeType="Team"
				on:event={handleTeamConstantsChange}
			/>
		</div>
	</TabPage>
	<TabPage tab="Initial Variables" {activeTab}>
		<ObjectValueInputs
			objectValues={team.variables}
			setupValues={teamVariables}
			scopeType="Team"
			on:event={handleTeamVariablesChange}
		/>
	</TabPage>
	<TabPage tab="Role Constant Defaults" {activeTab}>
		<ObjectValueInputs
			objectValues={team.roleConstants}
			setupValues={roleConstants}
			scopeType="Team"
			on:event={handleRoleConstantsChange}
		/>
	</TabPage>
	<TabPage tab="Player Variable Defaults" {activeTab}>
		<ObjectValueInputs
			objectValues={team.playerVariables}
			setupValues={playerVariables}
			scopeType="Team"
			on:event={handlePlayerVariablesChange}
		/>
	</TabPage>
</FlavorCard>

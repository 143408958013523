<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import domainsStore from "../../../../stores/data/domainsStore.js";
	import sectionGroupsStore from "../../../../stores/data/sectionGroupsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";
	import editSectionsStore from "../../../../stores/editSectionsStore.js";

	import Fetcher from "../../../../api/Fetcher";
	import Button from "../../../tailwind/Button.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: domain = $domainsStore[forumSlug];
	$: forum = $forumsStore[forumSlug];
	$: sectionGroupIds = forum?.sectionGroupIds;
	$: sectionGroups = sectionGroupIds?.map(fullSectionGroup) || [];

	$: editSections = domain && $editSectionsStore[forumSlug];
	$: urlType = $editSectionsStore.urlType;

	function fullSectionGroup(id) {
		const sectionGroup = {};
		for (const [key, value] of Object.entries($sectionGroupsStore[id] || {})) {
			if (key === "sectionSlugs") {
				sectionGroup.sections = value.map(
					(sectionSlug) => $sectionsStore[sectionSlug] || []
				);
			} else {
				sectionGroup[key] = value;
			}
		}
		return sectionGroup;
	}

	function handleEdit() {
		Fetcher.allPermissions({ forumSlug }).then((json) => {
			domainsStore.updateDomain({
				slug: forumSlug,
				...json.domain,
			});
			forumsStore.updateForum({
				slug: forumSlug,
				...json.forum,
			});
			sectionGroupsStore.updateSectionGroups(json.sectionGroups);
			sectionsStore.updateSections(json.sections);
		});
		editSectionsStore.add(forumSlug, sectionGroups);
	}

	function handleSave() {
		Fetcher.saveEditedSections({ forumSlug, editSections, urlType }).then(
			(json) => {
				if (json.slug) handleCancel();
			}
		);
	}

	function handleCancel() {
		editSectionsStore.remove(forumSlug);
	}
</script>

{#if editSections}
	<div class="grid grid-cols-2 gap-2">
		<div><Button extraClasses="w-full" on:click={handleSave}>Save</Button></div>
		<div>
			<Button className="default" extraClasses="w-full" on:click={handleCancel}>
				Cancel
			</Button>
		</div>
	</div>
{:else}
	<Button extraClasses="w-full" on:click={handleEdit}>Edit Sections</Button>
{/if}

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import redirect from "../util/redirect.js";
import permissionsTab from "../components/shared/permissionsTabs/topicPermissionsTab.js";

import topicsStore from "../stores/data/topicsStore.js";
import layoutStore from "../stores/layoutStore.js";

class CreateTopicForm extends Form {
	static title = "New Topic";
	static code = "new-topic";
	static focusOnOpen = "new-topic-title";

	static initialPages({ section }) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Title",
						id: "new-topic-title",
						placeholder: "Title",
						required: true,
					},
					{
						type: "input",
						inputType: "urlType",
						value: "fromTitle",
						label: "URL",
						id: "new-topic-url-type",
						description:
							"A URL based off the title could cause another user to confirm the existence of a topic with this title, even if they don't have access to it.",
					},
					{
						type: "checkbox",
						value: true,
						label: "Enable post reacts",
						id: "new-topic-enable-post-reacts",
					},
				],
			},
			permissionsTab({
				sectionPermissions: section?.permissions,
			}),
			{ section },
		];
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const title = basicFields[0].value;
		const urlType = basicFields[1].value;
		const enablePostReacts = basicFields[2].value;
		const permissionsLevels = pages[1].fields[0].levels;
		const permissions = permissionsLevels[0].value;
		const { section } = pages[2];
		return Fetcher.createTopic({
			section,
			title,
			urlType,
			enablePostReacts,
			permissions,
		});
	}

	static handleSuccess(json) {
		if (json.slug) {
			topicsStore.updateTopic(json);
			layoutStore.openBottomPanel();
			redirect(`/${json.forumSlug}/forum/${json.sectionSlug}/${json.slug}/p/1`);
		}
	}
}

export default CreateTopicForm;

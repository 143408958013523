<script>
	import { getContext } from "svelte";

	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";

	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let post;

	$: gameSlug = $paramsStore.gameSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];

	const insertBbCode = getContext("insertBbCode");

	function handleClick() {
		const bbCode = `[quote author=${post.user.username} datetime=${post.createdAt} topic=${topic.slug} post=${post.tag}]\n${post.text}\n[/quote]\n\n`;
		insertBbCode(bbCode);
	}
</script>

<Button
	className="primary"
	size="small"
	outline
	square
	tooltip="Quote"
	on:click={handleClick}
>
	<Icon name="quote" />
</Button>

<script>
	import currentUserStore from "../../../stores/data/currentUserStore";

	import SideMenu from "../shared/SideMenu.svelte";

	import NotificationsDisplay from "./displays/Notifications.svelte";
	import FavoritesDisplay from "./displays/Favorites.svelte";
	import DraftsDisplay from "./displays/Drafts.svelte";
	import BookmarksDisplay from "./displays/Bookmarks.svelte";
	import UserDisplay from "./displays/user/UserDisplay.svelte";
	import MembersDisplay from "./displays/Members.svelte";
	import InfoDisplay from "./displays/Info.svelte";

	function notificationsItem(notifications) {
		return ["bell", NotificationsDisplay, { value: notifications.length }];
	}
	const favoritesItem = ["star", FavoritesDisplay];

	function bookmarksItem(bookmarks) {
		return [
			"bookmark",
			BookmarksDisplay,
			{
				value: bookmarks.length || 0,
			},
		];
	}

	function draftsItem(drafts) {
		return [
			"pencilSquare",
			DraftsDisplay,
			{
				value: drafts?.filter((d) => d.text.length > 0).length || 0,
			},
		];
	}

	function userItem(currentUser) {
		return [
			"user",
			UserDisplay,
			{
				value: currentUser?.account?.email?.confirmed === false ? 1 : 0,
			},
		];
	}

	const infoItem = ["info", InfoDisplay];

	function getItems({ currentUser, notifications, drafts, bookmarks }) {
		if (!currentUser?.username) return [];
		if (currentUser.authority < 0)
			return [userItem($currentUserStore), draftsItem(drafts), infoItem];
		return [
			userItem($currentUserStore),
			notificationsItem(notifications),
			// favoritesItem,
			draftsItem(drafts),
			bookmarksItem(bookmarks),
			// usersItem,
			infoItem,
		];
	}
</script>

<SideMenu side="left" {getItems} />

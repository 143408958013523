<script>
	import { createEventDispatcher } from "svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let effectLine;
	export let scopeType;

	const dispatch = createEventDispatcher();

	function handleChatChange({ detail }) {
		dispatch("event", { value: { ...effectLine, chat: detail.value } });
	}
</script>

<SetupValueSelect
	dataType="Chat"
	{scopeType}
	value={effectLine.chat}
	on:event={handleChatChange}
/>
<div></div>
<div></div>

<script>
	import currentUserStore from "../../../../stores/data/currentUserStore.js";
	import gamesStore from "../../../../stores/data/gameCreator/gamesStore.js";

	import Fetcher from "../../../../api/gameCreator/Fetcher.js";
	import Button from "../../../tailwind/Button.svelte";

	import Card from "../../../tailwind/card/Card.svelte";
	import CardBody from "../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../tailwind/card/CardTitle.svelte";
	import Field from "../../../forms/Field.svelte";

	export let game;

	$: currentUserSlug = $currentUserStore?.slug;
	$: moderatorSlug = game?.moderator?.slug;

	$: testPerspectiveField = getTestPerspectiveField(game);

	function getTestPerspectiveField(game) {
		if (!game.testPerspective) return;
		return {
			type: "input",
			inputType: "select",
			items: [
				{ value: "Moderator", label: "Moderator" },
				...game.players.map((player) => ({
					value: player.id,
					label: player.user.username,
				})),
				{ value: "Spectator", label: "Spectator" },
			],
			label: "Perspective",
			value: game.testPerspective || "None",
		};
	}

	function handleTestPerspectiveChange({ detail }) {
		Fetcher.updateGame({ slug: game.slug, testPerspective: detail.value }).then(
			(json) => {
				gamesStore.setGame(json);
			}
		);
	}

	function executeDeadlineEffect() {
		Fetcher.executeDeadlineEffect({ slug: game.slug }).then((json) => {
			gamesStore.setGame(json);
		});
	}
</script>

{#if currentUserSlug && currentUserSlug === moderatorSlug}
	<Card>
		<CardBody>
			<CardTitle>Moderator Actions</CardTitle>
			{#if testPerspectiveField}
				<Field
					field={testPerspectiveField}
					on:event={handleTestPerspectiveChange}
				/>
			{/if}
			{#if game.deadlineEffect}
				<Button on:click={executeDeadlineEffect}>
					{game.deadlineEffect.name}
				</Button>
			{/if}
		</CardBody>
	</Card>
{/if}

<script>
	import Ban from "./Ban.svelte";
	import Bin from "./Bin.svelte";
	import Bell from "./Bell.svelte";
	import Bookmark from "./Bookmark.svelte";
	import Calendar from "./Calendar.svelte";
	import ChevronDown from "./ChevronDown.svelte";
	import ChevronUp from "./ChevronUp.svelte";
	import ChevronUpDown from "./ChevronUpDown.svelte";
	import Clock from "./Clock.svelte";
	import Cog from "./Cog.svelte";
	import Info from "./Info.svelte";
	import Link from "./Link.svelte";
	import Lock from "./Lock.svelte";
	import Login from "./Login.svelte";
	import Logout from "./Logout.svelte";
	import No from "./No.svelte";
	import PencilSquare from "./PencilSquare.svelte";
	import Plus from "./Plus.svelte";
	import Quote from "./Quote.svelte";
	import Star from "./Star.svelte";
	import Tick from "./Tick.svelte";
	import Undo from "./Undo.svelte";
	import User from "./User.svelte";
	import UserCircle from "./UserCircle.svelte";
	import Users from "./Users.svelte";
	import X from "./X.svelte";

	export let name;

	$: Icon = {
		ban: Ban,
		bin: Bin,
		bell: Bell,
		bookmark: Bookmark,
		calendar: Calendar,
		chevronDown: ChevronDown,
		chevronUp: ChevronUp,
		chevronUpDown: ChevronUpDown,
		clock: Clock,
		cog: Cog,
		info: Info,
		link: Link,
		lock: Lock,
		login: Login,
		logout: Logout,
		no: No,
		pencilSquare: PencilSquare,
		plus: Plus,
		quote: Quote,
		star: Star,
		tick: Tick,
		undo: Undo,
		user: User,
		userCircle: UserCircle,
		users: Users,
		x: X,
	}[name];
</script>

<svelte:component this={Icon} />

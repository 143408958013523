<script>
	export let color = null;
	export let size = null;
	export let face = null;
	export let family = null;

	$: style = getStyle(color, size, face);

	function getStyle(color, size, face) {
		let result = [];
		let sizeInPx;
		if (color) result.push(`color: ${getColor(color)};`);
		if (size) {
			const ptRegex = /^[0-9]+(\.[0-9]+)?pt$/;
			const pxRegex = /^[0-9]+(\.[0-9]+)?px$/;
			if (ptRegex.test(size)) {
				sizeInPx = (parseFloat(size.slice(0, -2)) * 4) / 3;
			} else if (pxRegex.test(size)) {
				sizeInPx = parseFloat(size.slice(0, -2));
			} else {
				sizeInPx = size;
			}
			result.push(`font-size: ${sizeInPx}px;`);
		}
		if (face) result.push(`font-family: ${face};`);
		return result.join(" ");
	}

	function getColor(color) {
		const regex = /^([0-9a-fA-F]{3}){1,2}$/;
		return color.match(regex) ? `#${color}` : color;
	}
</script>

<span {style}><slot /></span>

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";
	import Field from "../../../../forms/Field.svelte";

	import getAllowedScopeTypes from "../getAllowedScopeTypes.js";

	export let effect;
	export let scopeType = null;
	export let scopeTypes = null;
	export let excluded = null;
	export let allowNull = false;
	export let label = null;
	export let placeholder = "Effect";

	const dispatch = createEventDispatcher();

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: field = getField({
		effect,
		setup,
		scopeType,
		scopeTypes,
		excluded,
		allowNull,
		placeholder,
	});

	function getItems({ setup, scopeType, scopeTypes, excluded, allowNull }) {
		const allowedScopeTypes = getAllowedScopeTypes(
			scopeType,
			scopeTypes,
			false
		);
		let result = allowNull
			? [{ value: "Do Nothing", label: "Do Nothing" }]
			: [];
		for (const allowedScopeType of allowedScopeTypes) {
			result = [
				...result,
				...setup.effects[allowedScopeType]
					.filter((e) => e.id && e.id !== excluded)
					.map((e) => ({
						value: e.id,
						label: `${allowedScopeType}: ${e.name}`,
					})),
			];
		}
		return result;
	}

	function getField({
		effect,
		setup,
		scopeType,
		scopeTypes,
		excluded,
		allowNull,
		placeholder,
	}) {
		return {
			type: "input",
			inputType: "select",
			items: getItems({ setup, scopeType, scopeTypes, excluded, allowNull }),
			value: effect?.value || effect,
			placeholder,
			padding: true,
			label,
		};
	}

	function handleChange({ detail }) {
		effect = detail.value;
		dispatch("event", detail);
	}
</script>

{#if field}
	<Field {field} on:event={handleChange} />
{/if}

<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import sectionGroupsStore from "../../../../stores/data/sectionGroupsStore.js";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";

	import NewSectionGroup from "./NewSectionGroup.svelte";
	import NewSection from "./NewSection.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionGroupIds = forum.sectionGroupIds;
	$: sectionGroups =
		sectionGroupIds?.map((id) => $sectionGroupsStore[id] || {}) || [];
</script>

<Display title="New">
	<Transition>
		<NewSectionGroup />
	</Transition>

	{#each sectionGroups as sectionGroup}
		<Transition>
			<NewSection {sectionGroup} />
		</Transition>
	{/each}
</Display>

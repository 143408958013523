import { writable } from "svelte/store";
import Fetcher from "../../../api/gameCreator/Fetcher.js";

import { mergeObjects } from "../merge.js";

const { subscribe, update } = writable({});

function fetchSetups() {
	Fetcher.getSetups().then(updateSetups);
}

function fetchSetup(setupSlug) {
	Fetcher.getSetup(setupSlug).then(updateSetup);
}

function setSetup(newSetup) {
	update((setups) => {
		const slug = newSetup.slug;
		setups[slug] = newSetup;
		return setups;
	});
}

function updateSetup(newSetup) {
	update((setups) => {
		const slug = newSetup.slug;
		const keys = [
			"createdBy",
			"playerCountMin",
			"playerCountMax",
			"slug",
			"title",
			"rank",
			"gameType",
			"variables",
			"constants",
			"conditions",
			"values",
			"effects",
			"flavors",
			"teams",
			"roles",
			"chats",
			"actions",
		];
		const existingSetup = setups[slug];
		return {
			...setups,
			[slug]: newSetup,
			// [slug]: mergeObjects(existingSetup, newSetup, keys),
		};
	});
}

function updateSetups(newSetups) {
	newSetups.forEach((newSetup) => updateSetup(newSetup));
}

export default {
	subscribe,
	fetchSetups,
	fetchSetup,
	updateSetup,
	updateSetups,
	setSetup,
};

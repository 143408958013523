import Form from "../Form.js";
import Fetcher from "../../api/gameCreator/Fetcher.js";

class NewSetupForm extends Form {
	static title = "Create New Setup";
	static code = "new-setup";
	static focusOnOpen = "new-setup-title";

	static initialFields() {
		return [
			{
				type: "input",
				inputType: "text",
				value: "",
				label: "Title",
				id: "new-setup-title",
				placeholder: "Title",
				required: true,
			},
			{
				type: "input",
				inputType: "text",
				value: "",
				label: "Game type",
				id: "new-setup-game-type",
				placeholder: "Game type",
			},
			{
				type: "input",
				inputType: "number",
				label: "Player count minimum",
				id: "new-setup-player-count-min",
				min: 0,
				required: true,
			},
			{
				type: "input",
				inputType: "number",
				label: "Player count maximum",
				id: "new-setup-player-count-max",
				min: 0,
				required: true,
			},
			{
				type: "input",
				inputType: "urlType",
				value: "fromTitle",
				label: "URL",
				id: "new-setup-url-type",
				description:
					"A URL based off the title could cause another user to confirm the existence of a setup with this title, even if they don't have access to it.",
			},
		];
	}

	static async submit({ fields }) {
		const title = fields[0].value;
		const gameType = fields[1].value;
		const playerCountMin = fields[2].value;
		const playerCountMax = fields[3].value;
		const urlType = fields[4].value;
		if (playerCountMin > playerCountMax) {
			return {
				errors: {
					"new-setup-player-count-min": "Cannot be greater than the maximum",
					"new-setup-player-count-max": "Cannot be less than the minimum",
				},
			};
		}
		const args = {
			title,
			gameType,
			playerCountMin,
			playerCountMax,
			urlType,
		};
		return Fetcher.createSetup(args);
	}

	static handleSuccess(json) {
		// 	currentUserStore.updateCurrentUser(json);
		// 	if (json.account?.email?.confirmed === false) {
		// 		this.open({
		// 			emailAddress: json.account.email.address,
		// 			emailConfirmed: false,
		// 		});
		// 	}
	}
}
export default NewSetupForm;

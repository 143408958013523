<script>
	import LeftMenu from "./sideMenus/left/LeftMenu.svelte";
	import RightMenu from "./sideMenus/right/RightMenu.svelte";
	import Main from "./main/Main.svelte";
	import PostBox from "./postBox/PostBox.svelte";
	import TopBar from "./topBar/TopBar.svelte";
	import Toasts from "./tailwind/Toasts.svelte";
	import FormModalMain from "./shared/modal/FormModalMain.svelte";

	export let postBox;
</script>

<TopBar />
<LeftMenu />
<RightMenu />
<Main />
<PostBox bind:this={postBox} />
<Toasts />
<FormModalMain />

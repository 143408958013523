<script>
	import gameIcons from "../shared/gameIcons/gameIcons.js";

	export let icon;
	export let small = false;

	$: name = {
		Blank: "Interdiction icon",
		Sun: "Sun icon",
		Sandal: "Wingfoot icon",
		Die: "Dice 12 icon",
		Helmet: "Crested helmet icon",
		Hidden: "Sight disabled icon",
	}[icon || "Blank"];

	$: foreground = {
		Blank: "#9c9c9e",
		Sun: "#d6815c",
		Sandal: "#997e85",
		Die: "#bf86bd",
		Helmet: "#709bea",
		Hidden: "#977bcf",
	}[icon || "Blank"];
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-8 w-8">
	<path d="M0 0h512v512H0z" fill-opacity="0"></path>
	<g class="" transform="translate(0,0)" style="">
		<path
			d={gameIcons[name] && gameIcons[name].path}
			fill={foreground}
			fill-opacity="1"
			transform="translate(512, 512) scale(-1, -1) rotate(-540, 256, 256) skewX(0) skewY(0)"
		></path>
	</g>
</svg>

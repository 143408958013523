<script>
	import Transition from "./Transition.svelte";

	export let title = undefined;
</script>

<div class="card absolute w-full">
	<div class="card-body">
		{#if title}
			<Transition>
				<h2 class="w-full card-title">
					<span class="mx-auto">{title}</span>
				</h2>
			</Transition>
		{/if}
		<slot />
	</div>
</div>

<script>
	import Align from "../../../tailwind/align/Align.svelte";
	import Badge from "../../../tailwind/Badge.svelte";

	export let react;

	let open = false;
</script>

<div class="flex">
	<div class="text-xl">{react.text}</div>
	<Align vertical="center">
		<div
			class="cursor-pointer dropdown dropdown-end"
			class:dropdown-open={open}
			on:mouseenter={() => (open = true)}
			on:mouseleave={() => (open = false)}
		>
			<Badge colorName="default">{react.count}</Badge>
			<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
			<div
				tabindex="0"
				class="dropdown-content z-[1] menu p-2 shadow bg-neutral text-neutral-content rounded-box"
			>
				{#each react.usernames as username}
					<div>{username}</div>
				{/each}
			</div>
		</div>
	</Align>
</div>

<script>
	import SingleAlign from "./SingleAlign.svelte";

	export let both = null;
	export let vertical = both;
	export let horizontal = both;
	export let width = null;
	export let height = null;

	$: style = `${width ? `width: ${width}px; ` : ""} ${height ? `height: ${height}px; ` : ""}`;
</script>

<div class:w-full={width == null} class:h-full={height == null} {style}>
	<SingleAlign orientation="vertical" location={vertical}>
		<SingleAlign orientation="horizontal" location={horizontal}>
			<slot />
		</SingleAlign>
	</SingleAlign>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "../effects/EffectSelect.svelte";

	export let action;

	const dispatch = createEventDispatcher();

	$: resolutionTimingField = getResolutionTimingField(action);

	function getResolutionTimingField(action) {
		return {
			type: "input",
			inputType: "select",
			items: ["On Action Resolution", "Instant"],
			value: action.resolutionTiming,
			label: "Resolution Timing",
			padding: true,
		};
	}

	function handleTitleChange({ detail }) {
		if (detail.value === action.title) return;
		dispatch("event", { value: { ...action, title: detail.value } });
	}

	function handleDescriptionChange({ detail }) {
		if (detail.value === action.description) return;
		dispatch("event", { value: { ...action, description: detail.value } });
	}

	function handleResolutionTimingChange({ detail }) {
		if (detail.value === action.resolutionTiming) return;
		dispatch("event", { value: { ...action, resolutionTiming: detail.value } });
	}

	function handleResolutionPriorityChange({ detail }) {
		if (detail.value === action.resolutionPriority) return;
		dispatch("event", {
			value: { ...action, resolutionPriority: detail.value },
		});
	}

	function handleConditionChange({ detail }) {
		if (detail.value === action.condition) return;
		dispatch("event", { value: { ...action, condition: detail.value } });
	}

	function handleEffectChange({ detail }) {
		if (detail.value === action.effect) return;
		dispatch("event", { value: { ...action, effect: detail.value } });
	}

	function handleChatChange({ detail }) {
		if (detail.value === action.chat) return;
		dispatch("event", { value: { ...action, chat: detail.value } });
	}
</script>

<div class="grid grid-cols-2 gap-2">
	<SetupValueSelect
		value={action.title}
		dataType="Text"
		scopeType="Action"
		allowRaw
		placeholder="Title"
		label="Title"
		on:event={handleTitleChange}
	/>
	<SetupValueSelect
		value={action.description}
		dataType="Text"
		scopeType="Action"
		allowRaw
		placeholder="Description"
		label="Description"
		on:event={handleDescriptionChange}
	/>
	{#if resolutionTimingField}
		<Field
			field={resolutionTimingField}
			on:event={handleResolutionTimingChange}
		/>
	{/if}
	<SetupValueSelect
		value={action.resolutionPriority}
		dataType="Number"
		scopeType="Action"
		allowRaw
		placeholder="Resolution Priority"
		label="Resolution Priority"
		on:event={handleResolutionPriorityChange}
	/>
	<SetupValueSelect
		value={action.condition}
		dataType="True or False"
		scopeType="Action"
		allowRaw
		placeholder="Condition"
		label="Condition"
		on:event={handleConditionChange}
	/>
	<EffectSelect
		effect={action.effect}
		scopeType="Action"
		label="Effect"
		on:event={handleEffectChange}
	/>
	<SetupValueSelect
		value={action.chat}
		dataType="Chat"
		scopeType="Action"
		placeholder="Chat"
		label="Chat"
		on:event={handleChatChange}
	/>
</div>

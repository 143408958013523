<script>
	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import NewForumForm from "../../../../forms/NewForumForm.js";
</script>

<Display title="New">
	<Transition>
		<FormModal form={NewForumForm} />
	</Transition>
</Display>

<script>
	import paramsStore from "../../../../stores/paramsStore.js";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import NewAdminForm from "../../../../forms/NewAdminForm.js";

	$: forumSlug = $paramsStore.domainSlug;
</script>

{#if forumSlug}
	<FormModal form={NewAdminForm} args={{ forumSlug }} />
{/if}

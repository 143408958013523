import consumer from "./consumer.js";
import globalMessageFunctions from "./messageFunctions.js";

function channel(params, messageFunctions = {}) {
	const subscription =
		existingSubscription(params) || newSubscription(params, messageFunctions);
	// console.log("SUBSCRIPTIONS (added)", consumer.subscriptions.subscriptions);
	return {
		unsubscribe() {
			consumer.subscriptions.remove(subscription);
			// console.log(
			// 	"SUBSCRIPTIONS (removed)",
			// 	consumer.subscriptions.subscriptions
			// );
		},
	};
}

function existingSubscription(params) {
	return consumer.subscriptions.subscriptions.find(
		(s) => s.identifier === JSON.stringify(params)
	);
}

function newSubscription(params, messageFunctions) {
	return consumer.subscriptions.create(params, {
		received(message) {
			// if (message.type !== "ping" && message.type !== "welcome")
			// console.log("MESSAGE RECEIVED", message);
			const messageFunction =
				messageFunctions[message.type] || globalMessageFunctions[message.type];
			messageFunction && messageFunction(message, params);
		},
	});
}

export default channel;

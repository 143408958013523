<script>
	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import SetupValueSelect from "./SetupValueSelect.svelte";
	import Field from "../../../../forms/Field.svelte";

	import getOperatorItems from "./getOperatorItems.js";

	export let conditionLine;
	export let scopeType;
	export let valueId;

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: baseValue = getBaseValue(conditionLine.value1);

	// $: setupValue1 = getSetupValue1(conditionLine.setupValueId1);

	$: operatorField = getOperatorField(conditionLine);

	const dataTypeItems = [
		"Text",
		"Number",
		"True or False",
		"Team",
		"Role",
		...(scopeType === "Setup" ? [] : ["Player", "Action", "Chat"]),
	];

	$: dataTypeField = getDataTypeField(conditionLine.dataType);

	function getDataTypeField(dataType) {
		return {
			type: "input",
			inputType: "select",
			items: dataTypeItems,
			value: dataType,
			padding: true,
		};
	}

	function handleDataTypeChange({ detail }) {
		conditionLine.dataType = detail.value;
	}

	function getSetupValue1(setupValueId1) {
		if (!setupValueId1) return null;
		for (const valueType of ["constants", "variables"]) {
			for (const scopeType of Object.keys(setup[valueType])) {
				for (const setupValue of setup[valueType][scopeType]) {
					if (setupValue.id === setupValueId1) return setupValue;
				}
			}
		}
	}

	function handleOperatorChange({ detail }) {
		conditionLine.operator = detail.value;
	}

	// function getValue2Items(setupValue1) {
	//   if (!setupValue1) return []
	//   let result = []
	//   if(setupValue1.allowedValues?.length > 0) {
	//     result = [...result, ...setupValue1.allowedValues]
	//   }
	//   const dataType =
	// }

	function getOperatorField(conditionLine) {
		return {
			type: "input",
			inputType: "select",
			items: getOperatorItems(conditionLine.dataType),
			value: conditionLine.operator,
			padding: true,
		};
	}

	function getBaseValue(value1) {
		if (!value1 || !setup?.values) return {};
		for (const valueGroup of Object.values(setup.values)) {
			for (const v of valueGroup) {
				if (v.id === value1) {
					return v;
				}
			}
		}
		return {};
	}

	function handleValue1Change({ detail }) {
		conditionLine.value1 = detail.value;
	}

	function handleValue2Change({ detail }) {
		conditionLine.value2 = detail.value;
	}
</script>

{#if dataTypeField}
	<Field field={dataTypeField} on:event={handleDataTypeChange} />
{/if}
<SetupValueSelect
	{scopeType}
	dataType={conditionLine.dataType}
	excluded={valueId}
	bind:value={conditionLine.value1}
	on:event={handleValue1Change}
/>
{#if operatorField}
	<Field field={operatorField} on:event={handleOperatorChange} />
{/if}
<SetupValueSelect
	{scopeType}
	dataType={conditionLine.dataType}
	excluded={valueId}
	{baseValue}
	allowRaw
	bind:value={conditionLine.value2}
	on:event={handleValue2Change}
/>

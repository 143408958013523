<script>
	import Icon from "../../icons/large/Icon.svelte";
	export let text = "";
	export let alertClass = "";
	export let iconName;
</script>

<div class="mt-4 alert{alertClass ? ` alert-${alertClass}` : ''}">
	<div class="flex-1">
		{#if iconName}
			<Icon name={iconName} />
		{/if}
		<!-- svelte-ignore a11y-label-has-associated-control -->
		<label class="ml-2">{text}</label>
	</div>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import Align from "../tailwind/align/Align.svelte";

	export let value = true;
	export let height = 68;
	export let disabled;
	export let options;

	const dispatch = createEventDispatcher();

	let hovered = false;
	let confirm = false;

	$: disabled =
		!options.removeUsedCards ||
		options.usedCards.length + options.priorityCards.length === 0;

	$: text = getText(options, confirm);

	$: borderWidth = hovered && !disabled ? 3 : 2;
	$: color = disabled ? "grey" : "green";

	function getText(options, confirm) {
		if (confirm) return "Sure?";
		if (!options.removeUsedCards) return "Reset Used Cards";
		return `Reset Used Cards (${options.usedCards.length})`;
	}

	function handleClick() {
		if (disabled) return;
		if (!confirm) {
			confirm = true;
			setTimeout(() => (confirm = false), 2500);
			return;
		}
		confirm = false;
		dispatch("click");
	}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="rounded-lg p-4 flex xs:text-lg sm:text-xl"
	class:cursor-pointer={!disabled}
	class:bg-base-200={value}
	class:bg-base-300={!value}
	style="border: {borderWidth}px solid {color}; height: {height}px;"
	on:click={handleClick}
	on:mouseenter={() => (hovered = true)}
	on:mouseleave={() => (hovered = false)}
>
	<Align vertical="center">
		{text}
	</Align>
</div>

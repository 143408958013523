<script>
	import { tick } from "svelte";

	import Card from "../tailwind/card/Card.svelte";
	import CardBody from "../tailwind/card/CardBody.svelte";
	import CardTitle from "../tailwind/card/CardTitle.svelte";

	import Field from "../forms/Field.svelte";
	import Button from "../tailwind/Button.svelte";

	import GodCard from "./GodCard.svelte";

	import selectCards from "./selectCards.js";

	import toastsStore from "../../stores/toastsStore";

	import ToggleButton from "../shared/ToggleButton.svelte";
	import CardIcon from "./CardIcon.svelte";

	import Align from "../tailwind/align/Align.svelte";

	import cardData from "./cardData.json";

	import Icon from "../icons/large/Icon.svelte";
	import ResetUsedCardsButton from "./ResetUsedCardsButton.svelte";

	const allSets = [
		"Pantheon Base",
		"Hiding in the Underworld",
		"Tides of Poseidon",
		"Seasons of Chaos",
		"Soaring Over Olympus",
	];
	const allIcons = ["Sun", "Sandal", "Die", "Helmet", "Hidden", "Blank"];

	let cards = [];
	let cardComponents = [];
	let noSetsIncluded = false;
	let noIconsIncluded = false;

	const defaultUsedCardCounts = {};
	allSets.forEach((set) => (defaultUsedCardCounts[set] = 0));
	allIcons.forEach((icon) => (defaultUsedCardCounts[icon] = 0));

	const cardCounts = {};
	for (const set of allSets) {
		cardCounts[set] = cardData.filter((card) => card.set === set).length;
	}
	for (const icon of allIcons) {
		cardCounts[icon] = cardData.filter(
			(card) => (card.icon || "Blank") === icon
		).length;
	}

	const options =
		JSON.parse(localStorage.getItem("santoriniShufflerOptions")) ||
		getDefaultOptions();

	$: handleOptionsChange(options);

	setAvailableCardCounts(options);

	$: cardCountField = getCardCountField(options.cardCount);

	function getDefaultOptions() {
		const includedSets = {};
		allSets.forEach((set) => (includedSets[set] = true));
		const includedIcons = {};
		allIcons.forEach((icon) => (includedIcons[icon] = true));
		return {
			cardCount: 2,
			removeUsedCards: true,
			includedSets,
			includedIcons,
			usedCards: [],
			priorityCards: [],
			showOptions: true,
			hideCardInfo: true,
			usedCardCounts: { ...defaultUsedCardCounts },
			availableCardCounts: {},
		};
	}

	function handleOptionsChange(options) {
		noSetsIncluded = !Object.values(options.includedSets).includes(true);
		noIconsIncluded = !Object.values(options.includedIcons).includes(true);
		localStorage.setItem("santoriniShufflerOptions", JSON.stringify(options));
	}

	function setAvailableCardCounts(options) {
		options.availableCardCounts ||= {};
		const allAvailableCards = availableCards(options);
		for (const set of allSets) {
			options.availableCardCounts[set] = allAvailableCards.filter(
				(card) => card.set === set
			).length;
		}
		for (const icon of allIcons) {
			options.availableCardCounts[icon] = allAvailableCards.filter(
				(card) => (card.icon || "Blank") === icon
			).length;
		}
		let totalAvailable = 0;
		for (const set of allSets) {
			if (noSetsIncluded || options.includedSets[set]) {
				totalAvailable += options.availableCardCounts[set];
			}
		}
		if (totalAvailable === 0 && options.usedCards.length > 0) {
			resetUsedCards();
		}
	}

	function availableCards(options) {
		noSetsIncluded = !Object.values(options.includedSets).includes(true);
		noIconsIncluded = !Object.values(options.includedIcons).includes(true);
		return cardData.filter((card) => {
			if (!noSetsIncluded && !options.includedSets[card.set]) return false;
			if (!noIconsIncluded && !options.includedIcons[card.icon || "Blank"]) {
				return false;
			}
			return !(
				options.removeUsedCards && options.usedCards.includes(card.name)
			);
		});
	}

	function getCardCountField(cardCount) {
		return {
			type: "input",
			inputType: "number",
			value: cardCount,
			label: "Cards",
			placeholder: "Cards",
			min: 1,
			max: 40,
		};
	}

	function handleCardCountFieldChange({ detail }) {
		options.cardCount = detail.value;
	}

	async function handleShuffleClick(usePriority = true) {
		for (let i = 0; i < 100; i++) {
			cards = selectCards(
				options.cardCount,
				[
					...allSets.filter(
						(set) => !noSetsIncluded && !options.includedSets[set]
					),
					...allIcons.filter(
						(icon) => !noIconsIncluded && !options.includedIcons[icon]
					),
					...(options.removeUsedCards ? options.usedCards : []),
				],
				usePriority && options.removeUsedCards ? options.priorityCards : []
			);
			if (cards.length > 0) break;
		}
		if (cards.length === 0) return handleNoCardsReturned(usePriority);
		if (options.removeUsedCards) {
			updateUsedCards(cards);
		} else {
			options.usedCards = [];
			options.priorityCards = [];
		}
		await tick();
		cardComponents.forEach((cardComponent) => {
			options.hideCardInfo ? cardComponent?.hide() : cardComponent?.unhide();
		});
	}

	function handleNoCardsReturned(usePriority) {
		if (usePriority && options.priorityCards.length > 0) {
			return handleShuffleClick(false);
		}
		if (options.removeUsedCards && options.usedCards.length > 0) {
			if (options.priorityCards.length === 0) {
				options.priorityCards = cardData
					.filter((card) => {
						return (
							(noSetsIncluded || options.includedSets[card.set]) &&
							(noIconsIncluded ||
								options.includedIcons[card.icon || "Blank"]) &&
							!options.usedCards.includes(card.name)
						);
					})
					.map((card) => card.name);
			}
			options.usedCards = [];
			return handleShuffleClick();
		}
		toastsStore.addToast({
			className: "error",
			text: "No combination found.",
		});
	}

	function updateUsedCards(cards) {
		options.usedCards = [
			...options.usedCards,
			...cards
				.map((card) => card.name)
				.filter((card) => !options.priorityCards.includes(card)),
		];
		options.usedCardCounts ||= {};
		for (const set of allSets) {
			options.usedCardCounts[set] = options.usedCards.filter((card) => {
				const data = cardData.find((cd) => cd.name === card);
				return data.set === set;
			}).length;
		}
		for (const icon of allIcons) {
			options.usedCardCounts[icon] = options.usedCards.filter((card) => {
				const data = cardData.find((cd) => cd.name === card);
				return (data.icon || "Blank") === icon;
			}).length;
		}
		if (options.priorityCards.length > 0) {
			for (const card of cards) removeFromPriority(card);
		}
		if (options.usedCards.length === 100) resetUsedCards();
		setAvailableCardCounts(options);
	}

	function removeFromPriority(card) {
		while (true) {
			const index = options.priorityCards.indexOf(card.name);
			if (index === -1) break;
			options.priorityCards = [
				...options.priorityCards.slice(0, index),
				...options.priorityCards.slice(index + 1),
			];
		}
	}

	function resetUsedCards() {
		options.usedCards = [];
		options.priorityCards = [];
		options.usedCardCounts = { ...defaultUsedCardCounts };
		setAvailableCardCounts(options);
	}

	function toggleIncludedSet(set) {
		options.includedSets[set] = !options.includedSets[set];
		setAvailableCardCounts(options);
	}

	function toggleIncludedIcon(icon) {
		options.includedIcons[icon] = !options.includedIcons[icon];
		setAvailableCardCounts(options);
	}
</script>

<div class="flex flex-col gap-2">
	<Card>
		<CardBody>
			<CardTitle>
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<div
					class="flex w-full cursor-pointer"
					on:click={() => (options.showOptions = !options.showOptions)}
				>
					<div>Options</div>
					<div class="grow"></div>
					<Icon name={options.showOptions ? "chevronUp" : "chevronDown"} />
				</div>
			</CardTitle>
			{#if options.showOptions}
				<div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
					<Field field={cardCountField} on:event={handleCardCountFieldChange} />
					<Align vertical="bottom">
						<ToggleButton bind:value={options.hideCardInfo} height={68}>
							<span class="text-sm xs:text-lg sm:text-xl">
								Hide Card Info
							</span>
						</ToggleButton>
					</Align>
					<ToggleButton bind:value={options.removeUsedCards} height={68}>
						<span class="text-sm xs:text-lg sm:text-xl">
							Remove Used Cards
						</span>
					</ToggleButton>
					<div style="height: 68px;">
						<ResetUsedCardsButton {options} on:click={resetUsedCards} />
					</div>
				</div>
				<div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
					<div class="flex flex-col gap-4">
						{#each allSets as set}
							<ToggleButton
								value={options.includedSets[set]}
								height={68}
								neutralState={noSetsIncluded}
								on:click={() => toggleIncludedSet(set)}
							>
								<span class="text-sm xs:text-lg sm:text-xl">
									{set}
									{#if options.includedSets[set] || noSetsIncluded}
										({options.removeUsedCards
											? options.availableCardCounts[set]
											: cardCounts[set]})
									{/if}
								</span>
							</ToggleButton>
						{/each}
					</div>
					<div class="flex flex-col gap-4">
						{#each allIcons as icon}
							<ToggleButton
								value={options.includedIcons[icon]}
								height={68}
								neutralState={noIconsIncluded}
								on:click={() => toggleIncludedIcon(icon)}
							>
								<div class="relative">
									<CardIcon {icon} />
									<div class="absolute" style="left:40px; top: 1px;">
										{#if options.includedIcons[icon] || noIconsIncluded}
											({options.removeUsedCards
												? options.availableCardCounts[icon]
												: cardCounts[icon]})
										{/if}
									</div>
								</div>
							</ToggleButton>
						{/each}
					</div>
				</div>
			{/if}
		</CardBody>
	</Card>
	<div class="my-2">
		<Button on:click={handleShuffleClick} extraClasses="w-full">Shuffle</Button>
	</div>
	{#each cards as card, i}
		<GodCard bind:this={cardComponents[i]} {card} />
	{/each}
</div>

<script>
	import paramsStore from "../../stores/paramsStore.js";
	import domainsStore from "../../stores/data/domainsStore.js";
	import sectionsStore from "../../stores/data/sectionsStore.js";
	import topicsStore from "../../stores/data/topicsStore.js";
	import setupsStore from "../../stores/data/gameCreator/setupsStore.js";
	import gamesStore from "../../stores/data/gameCreator/gamesStore.js";
	import pageNameStore from "../../stores/pageNameStore.js";

	import Link from "../tailwind/Link.svelte";

	export let size;

	$: forumSlug = $paramsStore.domainSlug;
	$: domain = $domainsStore[forumSlug];
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section =
		$sectionsStore[sectionSlug === "all" ? `${forumSlug}/all` : sectionSlug];
	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];
	$: gameSlug = $paramsStore.gameSlug;
	$: game = $gamesStore[gameSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];

	$: breadcrumbs = getBreadCrumbs(
		$pageNameStore,
		domain,
		section,
		topic,
		setup,
		game
	);

	const sizeClass = size % 2 === 0 ? "text-sm" : "text-xs";

	function getBreadCrumbs(pageName, domain, section, topic, setup, game) {
		const bcs = [indexCrumb(pageName)];
		if (pageName === "members") {
			bcs.push(membersCrumb());
		} else if (pageName === "acknowledgements") {
			bcs.push(acknowledgementsCrumb());
		} else if (["gameCreator", "gameCreatorSetup"].includes(pageName)) {
			bcs.push(gameCreatorCrumb(pageName));
			if (setup) {
				bcs.push(setupCrumb(setup));
			}
		}
		if (domain) {
			bcs.push(domainCrumb(pageName, domain));
		}
		if (section) {
			bcs.push(sectionCrumb(pageName, section));
		}
		if (game) {
			bcs.push(gameCrumb(pageName, domain, game));
		}
		if (topic) {
			bcs.push(topicCrumb(topic));
		}

		return bcs;
	}

	function indexCrumb(pageName) {
		const crumb = { value: "Quende" };
		if (pageName !== "index") crumb.path = "/";
		return crumb;
	}

	function domainCrumb(pageName, domain) {
		const crumb = {
			value: domain.title,
		};
		if (!["forum", "domainPage"].includes(pageName)) {
			crumb.path = `/${domain.slug}/forum`;
		}
		return crumb;
	}

	function sectionCrumb(pageName, section) {
		const crumb = { value: section.title || "All Topics" };
		if (pageName !== "forumSection") {
			crumb.path = `/${section.forumSlug}/forum/${section.slug}/p/1`;
		}
		return crumb;
	}

	function topicCrumb(topic) {
		const crumb = { value: topic.title };
		return crumb;
	}

	function membersCrumb() {
		return { value: "Members" };
	}

	function acknowledgementsCrumb() {
		return { value: "Acknowledgements" };
	}

	function gameCreatorCrumb(pageName) {
		const crumb = { value: "Game Creator" };
		if (pageName !== "gameCreator") {
			crumb.path = `/game-creator`;
		}
		return crumb;
	}

	function setupCrumb(setup) {
		const crumb = { value: setup.title };
		return crumb;
	}

	function gameCrumb(pageName, domain, game) {
		const crumb = { value: game.title };
		if (pageName !== "gameCreatorGame") {
			crumb.path = `/${domain?.slug}/game/${game?.slug}`;
		}
		return crumb;
	}

	function clipValue(value) {
		if (size < 2) return value;
		if (size < 4) {
			if (value.length < 33) return value;
			return `${value.slice(0, 33)}...`;
		}
		if (size < 6) {
			if (value.length < 25) return value;
			return `${value.slice(0, 25)}...`;
		}
		if (size < 8) {
			if (value.length < 17) return value;
			return `${value.slice(0, 17)}...`;
		}
		if (size < 10) {
			if (value.length < 13) return value;
			return `${value.slice(0, 13)}...`;
		}
		if (size < 12) {
			if (value.length < 11) return value;
			return `${value.slice(0, 11)}...`;
		}
		if (size < 14) {
			if (value.length < 9) return value;
			return `${value.slice(0, 9)}...`;
		}
		if (size < 16) {
			if (value.length < 7) return value;
			return `${value.slice(0, 7)}...`;
		}
		if (size < 18) {
			if (value.length < 5) return value;
			return `${value.slice(0, 5)}...`;
		}
		if (size < 20) {
			if (value.length < 3) return value;
			return `${value.slice(0, 3)}...`;
		}
		return value[0];
	}
</script>

<div class="{sizeClass} breadcrumbs">
	<ul>
		{#each breadcrumbs.filter(Boolean) as { value, path }}
			<!-- svelte-ignore a11y-missing-attribute -->
			<li><Link {path}>{clipValue(value) || ""}</Link></li>
		{/each}
	</ul>
</div>

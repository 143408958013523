<script>
	import { inview } from "svelte-inview";

	export let inView = false;

	const options = {};

	function handleInviewChange({ detail }) {
		const { inView: iv } = detail;
		if (iv) {
			inView = true;
		} else {
			inView = false;
		}
	}
</script>

<div use:inview={options} on:inview_change={handleInviewChange}></div>

<script>
	import { getContext, createEventDispatcher } from "svelte";

	const bbCodeManager = getContext("bbCodeManager");

	import BbCodeButton from "./BbCodeButton.svelte";
	import EmojiButton from "../tailwind/EmojiButton.svelte";

	const dispatch = createEventDispatcher();

	function handleEmojiClick({ detail }) {
		dispatch("insert", { code: detail.value });
	}
</script>

<div class="flex flex-row flex-wrap gap-1">
	{#each bbCodeManager.bbCodes.filter((x) => x.button) as bbCode (bbCode.code)}
		<BbCodeButton code={bbCode.code} button={bbCode.button} on:insert />
	{/each}
	<EmojiButton
		buttonClass="default"
		tooltip="Emoji"
		on:emojiClick={handleEmojiClick}
	/>
</div>

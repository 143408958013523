<script>
	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let sum;
	export let scopeType;
	export let valueId;
	scopeType;
	valueId;

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	const scopeTypes = ["Team", "Player", "Action", "Chat"];

	let currentQuery;
	let valueScope;

	$: handleQueryChange(sum.query);

	function handleQueryChange(query) {
		if (query === currentQuery) return;
		valueScope = null;
		if (currentQuery != null) sum.value = null;
		currentQuery = query;
		setTimeout(() => {
			valueScope = findValueScope(query);
		}, 10);
	}

	function findValueScope(query) {
		for (const st of scopeTypes) {
			for (const v of setup.values[st]) {
				if (v.id === query) return st;
			}
		}
	}
</script>

<SetupValueSelect
	bind:value={sum.query}
	dataType="True or False"
	{scopeTypes}
/>
{#if valueScope}
	<SetupValueSelect
		bind:value={sum.value}
		dataType="Number"
		scopeTypes={[valueScope]}
	/>
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import EffectSelect from "../effects/EffectSelect.svelte";
	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let switchLine;
	export let switchValue;
	export let scopeType;
	export let valueId;
	export let valueDataType;
	export let allowRaw;

	const dispatch = createEventDispatcher();

	function handleComparisonValueChange({ detail }) {
		switchLine.comparisonValue = detail.value;
		dispatch("event", { value: switchLine });
	}

	function handleResultChange({ detail }) {
		switchLine.result = detail.value;
		dispatch("event", { value: switchLine });
	}
</script>

<SetupValueSelect
	value={switchLine.comparisonValue}
	dataType={switchValue.comparisonDataType}
	{scopeType}
	excluded={valueId}
	{allowRaw}
	on:event={handleComparisonValueChange}
/>
{#if valueDataType === "Effect"}
	<EffectSelect
		effect={switchLine.result}
		{scopeType}
		excluded={valueId}
		on:event={handleResultChange}
	/>
{:else}
	<SetupValueSelect
		value={switchLine.result}
		dataType={valueDataType}
		{scopeType}
		excluded={valueId}
		allowRaw
		on:event={handleResultChange}
	/>
{/if}
<div></div>
<div></div>

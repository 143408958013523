<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import TopicPoster from "./TopicPoster.svelte";
	import NewTopicPoster from "./NewTopicPoster.svelte";

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
</script>

<div class="flex flex-col gap-2">
	{#each topic?.posters || [] as user (user.slug)}
		<TopicPoster {user} />
	{/each}
</div>
{#if topic?.userPermissions?.addPosters}
	<div class="mt-2">
		<NewTopicPoster />
	</div>
{/if}

<script>
	import { tick } from "svelte";

	import formModalStore from "../../../stores/formModalStore";

	import Modal from "./Modal.svelte";
	import Form from "../../forms/Form.svelte";

	let open = false;
	let title = "Form Modal";
	let code = "form-modal";
	let focusOnOpen = "";
	let submitValue = "Create";
	let pages = [];
	let fields = [];
	let errors = {};
	let handleValueChange = () => {};
	let submit = () => {};
	let handleSuccess = () => {};

	let modal;

	let shouldUpdate = false;

	$: handleChange($formModalStore);
	$: handlePagesChange(pages);
	$: handleFieldsChange(fields);
	$: handleErrorsChange(errors);

	async function handleChange(values) {
		if (!modal) return;
		if (!open && !values.open) return;
		shouldUpdate = false;
		open = values.open;
		title = values.title;
		code = values.code;
		focusOnOpen = values.focusOnOpen;
		submitValue = values.submitValue;
		pages = values.pages || [];
		fields = values.fields || [];
		errors = values.errors || [];
		handleValueChange = values.handleValueChange || (() => {});
		submit = values.submit || (() => {});
		handleSuccess = values.handleSuccess || (() => {});
		values.open ? modal.open() : modal.close();
		await tick();
		shouldUpdate = values.open;
	}

	function handlePagesChange(pages) {
		if (!shouldUpdate) return;
		formModalStore.update((previous) => ({
			...previous,
			pages,
		}));
	}

	function handleFieldsChange(fields) {
		if (!shouldUpdate) return;
		formModalStore.update((previous) => ({
			...previous,
			fields,
		}));
	}

	function handleErrorsChange(errors) {
		if (!shouldUpdate) return;
		formModalStore.update((previous) => ({
			...previous,
			errors,
		}));
	}

	export function close() {
		modal.close();
	}
</script>

<Modal
	bind:this={modal}
	id="{code}-modal"
	heading={title}
	{focusOnOpen}
	on:open
	on:close
>
	<Form
		bind:pages
		bind:fields
		bind:errors
		{submit}
		{handleSuccess}
		{modal}
		{submitValue}
		submitId="{code}-submit-button"
		cancelId="{code}-cancel-button"
	/>
</Modal>

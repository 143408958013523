import { writable } from "svelte/store";
import API from "../../api/API";

const { subscribe, set, update } = writable({});

function reset() {
	set({});
}

function fetchUsersData() {
	API.get("users/data").then(updateCurrentUser);
}

function updateCurrentUser(newUsersData) {
	update(() => newUsersData);
}

export default {
	subscribe,
	reset,
	fetchUsersData,
};

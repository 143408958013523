import DateTime from "../util/DateTime.js";

export default [
	{
		code: "b",
		insertion: "simple",
		keyCode: 66,
		button: {
			text: "B",
			textClass: "font-bold",
			tooltip: "Bold",
		},
	},
	{
		code: "i",
		insertion: "simple",
		keyCode: 73,
		button: {
			text: "I",
			textClass: "italic",
			tooltip: "Italicized",
		},
	},
	{
		code: "u",
		insertion: "simple",
		keyCode: 85,
		button: {
			text: "U",
			textClass: "underline",
			tooltip: "Underline",
		},
	},
	{
		code: "s",
		element: "del",
		button: {
			text: "S",
			textClass: "line-through",
			tooltip: "Strikethrough",
		},
	},
	{
		code: "datetime",
		innerFunc: DateTime.nowCode,
		singleSelection: true,
		button: {
			icon: "calendar",
			tooltip: "Datetime",
		},
	},
	{
		code: "timediff",
		innerFunc: DateTime.nowCode,
		singleSelection: true,
		button: {
			icon: "clock",
			tooltip: "Time difference",
		},
	},
	{
		code: "quote",
		params: "author=",
		newLines: true,
		removeAfterTextNewLine: true,
		button: {
			icon: "quote",
			tooltip: "Quote",
		},
	},
	{
		code: "link",
		params: "url= forum= section= topic= post=",
		removeAfterTextNewLine: true,
		button: {
			icon: "link",
			tooltip: "Link",
		},
	},
	{ code: "font" },
	{ code: "hr", singleTag: true, removeAfterTextNewLine: true },
	{ code: "pre" },
	{ code: "code", newLines: true },
	{ code: "ul", removeAfterTextNewLine: true },
	{ code: "ol", removeAfterTextNewLine: true },
	{ code: "li", removeAfterTextNewLine: true },
];

<script>
	import currentUserStore from "../../stores/data/currentUserStore.js";
	import nowStore from "../../stores/nowStore.js";

	import DateTime from "../../util/DateTime.js";

	export let innerText = "";

	$: timeZone = $currentUserStore.profile?.timeZone || "UTC";
	$: tooltip = innerText ? DateTime.timeDifference(innerText, $nowStore) : "";
	$: text = DateTime.dtStringFromIso(innerText, timeZone);
</script>

<span class="tooltip z-50" data-tip={tooltip}>
	{text}
</span>

<script>
	import TimeStamp from "../../../shared/TimeStamp.svelte";
	import PostOptions from "./PostOptions.svelte";
	import PostReacts from "./PostReacts.svelte";

	export let post;
	export let showOptions = true;
</script>

<div class="h-full flex flex-col gap-2">
	<div id={post.tag} class="flex flex-row flex-wrap gap-2 scroll-mt-20">
		{#if post.tag}
			<span class="badge badge-outline bg-base-100 font-bold">#{post.tag}</span>
		{/if}
		<TimeStamp time={post.createdAt} />
		<div class="flex-grow"></div>
		{#if showOptions}
			<div>
				<PostOptions {post} />
			</div>
		{/if}
	</div>
	<div class="flex-grow">
		<slot />
	</div>
	{#if post.note && typeof post.note === "string"}
		<div>Note: {post.note}</div>
	{/if}
	{#if post.editedAt || post.reacts?.length > 0}
		<div class="flex flex-wrap gap-2">
			{#if post.editedAt}
				<div class="text-xs italic">
					Edited on <TimeStamp time={post.editedAt} plain />
					{#if post.editedBy}
						by {post.editedBy.username}
					{/if}
				</div>
			{/if}
			<div class="grow"></div>
			{#if post.reacts.length > 0}
				<PostReacts reacts={post.reacts} />
			{/if}
		</div>
	{/if}
</div>

<script>
	import paramsStore from "../../../stores/paramsStore.js";
	import domainsStore from "../../../stores/data/domainsStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import sectionGroupsStore from "../../../stores/data/sectionGroupsStore.js";

	import FormModal from "../../shared/modal/FormModal.svelte";
	import EditSectionGroupForm from "../../../forms/EditSectionGroupForm.js";

	export let sectionGroupId;

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: forumPermissions = forum?.permissions;
	$: domain = $domainsStore[forumSlug];
	$: domainPermissions = domain?.permissions;
	$: sectionGroupIds = forum?.sectionGroupIds || [];
	$: sectionGroups = sectionGroupIds.map((id) => $sectionGroupsStore[id] || {});
	$: sectionGroup = sectionGroups.find((sg) => sg.id === sectionGroupId);
	$: sectionGroupPermissions = sectionGroup?.permissions;

	$: args = forum &&
		sectionGroup &&
		domainPermissions &&
		forumPermissions &&
		sectionGroupPermissions && {
			forum,
			sectionGroup,
			domainPermissions,
			forumPermissions,
			sectionGroupPermissions,
		};
</script>

{#if args}
	<FormModal
		form={EditSectionGroupForm}
		{args}
		iconName="cog"
		buttonClass="btn-square"
		disabled={!(
			forumPermissions &&
			domainPermissions &&
			sectionGroupPermissions
		)}
	/>
{/if}

<script>
	import { onMount } from "svelte";

	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";
	import currentUserStore from "../../../../stores/data/currentUserStore";

	import { redirectTo } from "../../../../util/redirectTo";

	$: forumSlug = $paramsStore.forumSlug || $paramsStore.domainSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: postNumberString = $paramsStore.postNumber;
	$: currentUser = $currentUserStore;

	onMount(handleMount);

	function handleMount() {
		let postNumber;
		if (postNumberString === "last") {
			postNumber = topic.postCount;
		} else {
			postNumber = parseInt(postNumberString);
		}
		if (typeof postNumber !== "number") return;
		const pageSize = currentUser.settings.pageSize;
		const pageCount = Math.floor((topic.postCount - 1) / pageSize) + 1;
		const effectivePageNumber = Math.floor((postNumber - 1) / pageSize + 1);
		const pageNumber = currentUser?.settings?.newestPostFirst
			? pageCount - effectivePageNumber + 1
			: effectivePageNumber;
		const tag = `#${postNumber}`;
		redirectTo({
			forumSlug: forumSlug || topic.forumSlug,
			sectionSlug: sectionSlug || topic.sectionSlug,
			topicSlug,
			pageNumber,
			tag,
		});
	}
</script>

PRED

<script>
	import Action from "./Action.svelte";

	export let actions;
	export let actionConstants;
	export let actionVariables;

	$: actionsToShow = getActionsToShow(actions);

	function getActionsToShow(actions) {
		if (actions.find((action) => [null, ""].includes(action.name))) {
			return [...actions];
		}
		return [
			...actions,
			{
				name: "",
				title: null,
				description: null,
				resolutionTiming: "On Action Resolution",
				resolutionPriority: null,
				condition: null,
				effect: null,
				chat: null,
				constants: {},
				variables: {},
				variableInputs: {},
			},
		];
	}

	function handleActionChange(i, detail) {
		actions[i] = detail.value;
	}
</script>

<div class="flex flex-col gap-2">
	{#each actionsToShow as action, i}
		<Action
			{action}
			{actionConstants}
			{actionVariables}
			on:event={({ detail }) => handleActionChange(i, detail)}
		/>
	{/each}
</div>

<script>
	import currentUserStore from "../../../../../stores/data/currentUserStore.js";

	import Display from "../../../shared/Display.svelte";
	import Transition from "../../../shared/Transition.svelte";
	import Login from "./Login.svelte";
	import Register from "./Register.svelte";
	import EditProfile from "./EditProfile.svelte";
	import EditSettings from "./EditSettings.svelte";
	import EmailAddress from "./EmailAddress.svelte";
	import ChangePassword from "./ChangePassword.svelte";
	import DeleteGuestData from "./DeleteGuestData.svelte";
	import Logout from "./Logout.svelte";
	import ForgotPassword from "./ForgotPassword.svelte";

	let show = true;
	let lastUserSlug = null;

	$: currentUser = $currentUserStore;
	$: guest = !currentUser || currentUser.authority < -5;
	$: upgradedGuest = currentUser?.authority === -10;

	$: handleCurrentUserChange(currentUser);

	function handleCurrentUserChange(currentUser) {
		if (currentUser.slug === lastUserSlug) return;
		if (lastUserSlug) show = false;
		lastUserSlug = currentUser.slug;
		if (!show) setTimeout(() => (show = true), 500);
	}
</script>

{#if show}
	<Display title={currentUser.username || "User"}>
		<Transition>
			<div class="flex flex-col gap-2">
				{#if guest}
					<Login />
					<Register />
					<ForgotPassword />
				{/if}
				<EditProfile />
				<EditSettings />
				{#if upgradedGuest}
					<DeleteGuestData />
				{:else if !guest}
					<EmailAddress />
					<ChangePassword />
					<Logout />
				{/if}
			</div>
		</Transition>
	</Display>
{/if}

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/topicPermissionsTab.js";

class EditTopicForm extends Form {
	static title = "Edit Topic";
	static code = "edit-topic";
	static submitValue = "Save";

	static initialPages({ topic, sectionPermissions, topicPermissions }) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "checkbox",
						value: topic.enablePostReacts,
						label: "Enable post reacts",
						id: "new-topic-enable-post-reacts",
					},
				],
			},
			permissionsTab({
				sectionPermissions: sectionPermissions,
				topicPermissions: topicPermissions,
			}),
			{ topic },
		];
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const enablePostReacts = basicFields[0].value;
		const permissionsField = pages[1].fields[0];
		const permissions = permissionsField.levels[0].value;
		const { topic } = pages[2];

		return Fetcher.updateTopic({
			topic,
			enablePostReacts,
			permissions,
		});
	}
}

export default EditTopicForm;

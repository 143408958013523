import { writable } from "svelte/store";

const { subscribe, set } = writable(null);

function setId(id) {
	set({ id });
}

function setLocation(location) {
	set({ location });
}

function clear() {
	set(null);
}

export default { subscribe, setId, setLocation, clear };

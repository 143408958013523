<script>
	import Input from "./fields/Input.svelte";
	import Checkbox from "./fields/Checkbox.svelte";
	import Permissions from "./fields/Permissions.svelte";
	import EmailConfirmation from "./fields/EmailConfirmation.svelte";

	export let field = {};
	export let errors = {};
	export let tab = null;
	export let error =
		tab && errors[tab] ? errors[tab][field.id] : errors[field.id];

	let _field;

	export function focus() {
		if (_field?.focus) field.focus();
	}

	function handleErrorsChange(errors) {
		if (!errors) return;
		error = tab && errors[tab] ? errors[tab][field.id] : errors[field.id];
	}

	function handleErrorChange(error) {
		if (tab && errors[tab]) {
			errors[tab][field.id] = error;
		} else {
			errors[field.id] = error;
		}
	}

	$: handleErrorsChange(errors);
	$: handleErrorChange(error);

	$: fieldComponent = {
		input: Input,
		checkbox: Checkbox,
		permissions: Permissions,
		emailConfirmation: EmailConfirmation,
	}[field.type];
</script>

{#if field.type}
	<svelte:component
		this={fieldComponent}
		bind:this={_field}
		bind:field
		bind:error
		on:event
	/>
{/if}

{#if field.description}
	<div class="text-sm mt-2">{field.description}</div>
{/if}

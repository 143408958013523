<script>
	import Button from "../../tailwind/Button.svelte";
	import Icon from "../../icons/large/Icon.svelte";

	export let disabled = false;
	export let moving = false;

	$: extraClasses = getExtraClasses(disabled, moving);

	function getExtraClasses(disabled, moving) {
		if (disabled) return "";
		return moving ? "cursor-grabbing" : "cursor-grab";
	}
</script>

<Button className="default" square {disabled} {extraClasses}>
	<Icon name="chevronUpDown" />
</Button>

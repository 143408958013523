<script>
	import Display from "../../../shared/Display.svelte";
	import Transition from "../../../shared/Transition.svelte";

	import FormModal from "../../../../shared/modal/FormModal.svelte";

	import NewSetupForm from "../../../../../forms/gameCreator/NewSetupForm.js";
</script>

<Display title="New">
	<Transition>
		<FormModal form={NewSetupForm} />
	</Transition>
</Display>

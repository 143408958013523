<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import Fetcher from "../../../../api/Fetcher.js";

	import bookmarksStore from "../../../../stores/data/bookmarksStore.js";

	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let post;

	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];
	$: bookmarks = $bookmarksStore;

	$: existingBookmark = checkForExistingBookmark(topic, post, bookmarks);

	function checkForExistingBookmark(topic, post, bookmarks) {
		if (!topic || !post || !bookmarks) return false;
		return !!bookmarks.find((bookmark) => {
			return (
				bookmark.tag == post.tag &&
				bookmark.topicSlug === topic.slug &&
				bookmark.sectionSlug === topic.sectionSlug &&
				bookmark.forumSlug === topic.forumSlug
			);
		});
	}

	function handleClick() {
		const func = existingBookmark ? "unbookmark" : "bookmark";
		Fetcher[func]({ post }).then(handleResponse);
	}

	function handleResponse({ bookmarked, bookmark, id }) {
		if (bookmarked) {
			bookmarksStore.addBookmark(bookmark);
		} else {
			bookmarksStore.removeBookmark(id);
		}
	}
</script>

<Button
	className="primary"
	size="small"
	outline={!existingBookmark}
	square
	tooltip="Bookmark"
	on:click={handleClick}
>
	<Icon name="bookmark" />
</Button>

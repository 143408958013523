import { writable } from "svelte/store";
import defaultTheme from "../util/defaultTheme";

const { subscribe, update } = writable({
	default: defaultTheme(),
	settings: null,
	temporary: null,
});

window
	.matchMedia("(prefers-color-scheme: dark)")
	.addEventListener("change", (event) => {
		setDefault(event.matches ? "dark" : "light");
	});

function setDefault(theme) {
	if (!theme) return;
	update((current) => ({
		...current,
		default: theme,
	}));
}

function setSettings(theme) {
	update((current) => ({
		...current,
		settings: theme,
	}));
}

function setTemporary(theme) {
	update((current) => ({
		...current,
		temporary: theme,
	}));
}

export default { subscribe, setSettings, setTemporary };

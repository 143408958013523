<script>
	import { createEventDispatcher } from "svelte";

	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";
	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import LotteryItems from "./LotteryItems.svelte";
	import LotteryRoles from "./LotteryRoles.svelte";

	export let lottery;

	const dispatch = createEventDispatcher();

	$: replacementTypeField = getReplacementTypeField(lottery);

	function getReplacementTypeField(lottery) {
		return {
			type: "input",
			inputType: "select",
			items: ["Replacement", "No Replacement", "Unique Items"],
			value: lottery.replacementType,
			padding: true,
		};
	}

	function handleReplacementTypeField({ detail }) {
		dispatch("event", { value: { ...lottery, replacementType: detail.value } });
	}

	function handleItemsToDrawChange({ detail }) {
		dispatch("event", { value: { ...lottery, itemsToDraw: detail.value } });
	}

	function handleItemsChange({ detail }) {
		dispatch("event", { value: { ...lottery, items: detail.value } });
	}

	function handleRolesChange({ detail }) {
		dispatch("event", { value: { ...lottery, roles: detail.value } });
	}

	function deleteLottery() {
		dispatch("delete");
	}
</script>

<Card>
	<CardBody>
		<CardTitle>
			<div class="flex w-full">
				<div>Lottery</div>
				<div class="grow"></div>
				<div>
					<Button
						square
						size="small"
						className="error"
						tooltip="Delete Lottery"
						on:click={deleteLottery}
					>
						<Icon name="bin" />
					</Button>
				</div>
			</div>
		</CardTitle>
		<div class="grid grid-cols-2 gap-2">
			{#if replacementTypeField}
				<Field
					field={replacementTypeField}
					on:event={handleReplacementTypeField}
				/>
			{/if}
			<SetupValueSelect
				dataType="Number"
				scopeType="Game"
				allowRaw
				value={lottery.itemsToDraw}
				placeholder="Items to Draw"
				on:event={handleItemsToDrawChange}
			/>
		</div>
		<div class="text-lg">Items</div>
		<LotteryItems items={lottery.items} on:event={handleItemsChange} />
		<div class="text-lg">Roles</div>
		<LotteryRoles
			roles={lottery.roles}
			items={lottery.items}
			on:event={handleRolesChange}
		/>
	</CardBody>
</Card>

<script>
	import { onMount } from "svelte";

	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import domainsStore from "../../../../stores/data/domainsStore.js";

	import Fetcher from "../../../../api/Fetcher.js";

	import Transition from "../../shared/Transition.svelte";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import EditForumForm from "../../../../forms/EditForumForm.js";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: forumPermissions = forum?.permissions;
	$: domain = $domainsStore[forumSlug];
	$: domainPermissions = domain?.permissions;

	$: args = forumSlug &&
		domain &&
		domainPermissions &&
		forumPermissions && {
			forumSlug,
			domain,
			domainPermissions,
			forumPermissions,
		};

	onMount(handleMount);

	function handleMount() {
		if (!domainPermissions || !forumPermissions) {
			Fetcher.domainAndForumPermissions({
				forumSlug,
			}).then((json) => {
				domainsStore.updateDomain({ slug: forumSlug, ...json.domain });
				forumsStore.updateForum({ slug: forumSlug, ...json.forum });
			});
		}
	}
</script>

<Transition>
	<FormModal
		form={EditForumForm}
		{args}
		disabled={!(forumPermissions && domainPermissions)}
	/>
</Transition>

<script>
	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";
	import currentUserStore from "../../../../stores/data/currentUserStore.js";

	import MarkAsSpamButton from "./MarkAsSpamButton.svelte";
	import UnmarkAsSpamButton from "./UnmarkAsSpamButton.svelte";
	import DeleteSpamButton from "./DeleteSpamButton.svelte";
	import EditPostButton from "./EditPostButton.svelte";
	import BookmarkButton from "./BookmarkButton.svelte";
	import LinkButton from "./LinkButton.svelte";
	import QuoteButton from "./QuoteButton.svelte";

	export let post;

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];

	const showSpam = false;

	$: currentUser = $currentUserStore;
</script>

<div class="flex gap-2">
	{#if showSpam}
		{#if post.user.authority <= -30}
			{#if post.spam}
				{#if currentUser.authority >= 50}
					<DeleteSpamButton {post} />
				{/if}
				<UnmarkAsSpamButton {post} />
			{:else}
				<MarkAsSpamButton {post} />
			{/if}
		{/if}
	{/if}
	{#if topic?.userPermissions?.editPosts}
		<EditPostButton {post} />
	{/if}
	{#if currentUser.authority >= 0}
		<BookmarkButton {post} />
	{/if}
	<LinkButton {post} />
	<QuoteButton {post} />
</div>

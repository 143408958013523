<script>
	import AllowedValues from "./AllowedValues.svelte";
	import Condition from "./Condition.svelte";
	import Concatenation from "./Concatenation.svelte";
	import ListConcatenation from "./ListConcatenation.svelte";
	import Sum from "./Sum.svelte";
	import Calculation from "./Calculation.svelte";
	import Switch from "./Switch.svelte";
	import Duration from "./Duration.svelte";
	import List from "./List.svelte";
	import FilterList from "./FilterList.svelte";

	export let value;
	export let scopeType;
</script>

{#if value.allowedValues}
	<AllowedValues bind:allowedValues={value.allowedValues} />
{:else if value.condition}
	<Condition bind:condition={value.condition} {scopeType} valueId={value.id} />
{:else if value.concatenation}
	<Concatenation
		bind:concatenation={value.concatenation}
		{scopeType}
		valueId={value.id}
	/>
{:else if value.listConcatenation}
	<ListConcatenation
		bind:listConcatenation={value.listConcatenation}
		{scopeType}
	/>
{:else if value.sum}
	<Sum bind:sum={value.sum} {scopeType} valueId={value.id} />
{:else if value.calculation}
	<Calculation
		bind:calculation={value.calculation}
		{scopeType}
		valueId={value.id}
	/>
{:else if value.switch}
	<Switch
		bind:switchValue={value.switch}
		{scopeType}
		valueId={value.id}
		valueDataType={value.dataType}
	/>
{:else if value.duration}
	<Duration bind:duration={value.duration} {scopeType} />
{:else if value.list}
	<List bind:list={value.list} {scopeType} dataType={value.dataType} />
{:else if value.filterList}
	<FilterList
		bind:filterList={value.filterList}
		{scopeType}
		dataType={value.dataType}
	/>
{/if}

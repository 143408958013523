import { writable } from "svelte/store";
import { tweened } from "svelte/motion";
import { cubicInOut } from "svelte/easing";

import getScrollbarWidth from "./getScrollBarWidth.js";

const sidebarWidth = 72;
const sideDisplayWidth = 250;

const topBarHeight = 56;

const constants = {
	sideDisplayWidth,
	topBarHeight,
	bottomPanelHeight: 256,
	bottomBarHeight: 56,
};

const initial = {
	bottomPanelOpen: false,
	scrollbarWidth: getScrollbarWidth(),
};

const xsLayout = {
	sidebarWidth: 56,
	menuOrientation: "horizontal",
	fullSideMargins: false,
	sideDisplayTopMargin: topBarHeight + 56,
	sideWidth: 0,
	topAndBottomMargins: 56,
	maxPaginationButtons: 5,
	paginationButtonSize: "xs",
};

const smLayout = {
	sidebarWidth,
	menuOrientation: "horizontal",
	fullSideMargins: false,
	sideDisplayTopMargin: topBarHeight + sidebarWidth,
	sideWidth: 0,
	topAndBottomMargins: sidebarWidth,
	maxPaginationButtons: 7,
	paginationButtonSize: "small",
};

const mdLayout = {
	sidebarWidth,
	menuOrientation: "vertical",
	fullSideMargins: false,
	sideDisplayTopMargin: topBarHeight,
	sideWidth: sidebarWidth,
	topAndBottomMargins: 0,
	maxPaginationButtons: 7,
	paginationButtonSize: "small",
};

const lgLayout = {
	sidebarWidth,
	menuOrientation: "vertical",
	fullSideMargins: false,
	sideDisplayTopMargin: topBarHeight,
	sideWidth: sidebarWidth,
	topAndBottomMargins: 0,
	maxPaginationButtons: 7,
	paginationButtonSize: "small",
};

const xlLayout = {
	sidebarWidth,
	menuOrientation: "vertical",
	fullSideMargins: true,
	sideDisplayTopMargin: 0,
	sideWidth: sidebarWidth + sideDisplayWidth,
	topAndBottomMargins: 0,
	maxPaginationButtons: 7,
	paginationButtonSize: "small",
};

const xxlLayout = {
	sidebarWidth,
	menuOrientation: "vertical",
	fullSideMargins: true,
	sideDisplayTopMargin: 0,
	sideWidth: sidebarWidth + sideDisplayWidth,
	topAndBottomMargins: 0,
	maxPaginationButtons: 7,
	paginationButtonSize: "small",
};

const { subscribe, update } = writable({
	...constants,
	...initial,
	...getNewLayout(window.innerWidth),
});

const bottomPanelPositionStore = tweened(-constants.bottomPanelHeight, {
	duration: 250,
	easing: cubicInOut,
});

function updateScreenWidth(width) {
	update((layout) => ({
		...layout,
		...getNewLayout(width),
		scrollbarWidth: getScrollbarWidth(),
	}));
}

function getNewLayout(width) {
	if (width < 640) return xsLayout;
	if (width < 768) return smLayout;
	if (width < 1024) return mdLayout;
	if (width < 1280) return lgLayout;
	if (width < 1536) return xlLayout;
	return xxlLayout;
}

function toggleBottomPanel() {
	update((layout) => {
		layout.bottomPanelOpen = !layout.bottomPanelOpen;
		bottomPanelPositionStore.set(
			layout.bottomPanelOpen ? 0 : -constants.bottomPanelHeight
		);
		return layout;
	});
}

function openBottomPanel() {
	update((layout) => {
		layout.bottomPanelOpen = true;
		bottomPanelPositionStore.set(0);
		return layout;
	});
}

function closeBottomPanel() {
	update((layout) => {
		layout.bottomPanelOpen = false;
		bottomPanelPositionStore.set(-constants.bottomPanelHeight);
		return layout;
	});
}

export default {
	subscribe,
	toggleBottomPanel,
	openBottomPanel,
	closeBottomPanel,
	bottomPanelPositionStore,
	updateScreenWidth,
};

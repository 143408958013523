<script>
	import PermissionsCell from "./PermissionsCell.svelte";
	import Input from "./Input.svelte";

	export let level;
	export let levelIndex;
	export let row;
	export let label;
	export let tooltip;
	export let columns;

	let passwordField;

	$: passwordSpan = ["col-span-1", "col-span-2", "col-span-3"][
		level.columns.length - 1
	];

	$: if (["createTopic", "post"].includes(row) && !passwordField) {
		passwordField = {
			type: "input",
			inputType: "text",
			value: level.value[`${row}Password`] || "",
			placeholder: `${label} password`,
			inputClass: "input-xs",
			disabled: true,
		};
	}

	$: handleLevelChange(level);
	$: if (passwordField) handlePasswordChange(passwordField?.value);

	function handlePasswordChange(password) {
		level.value[`${row}Password`] = password;
	}

	function handleLevelChange(level) {
		if (!passwordField) return;
		passwordField.disabled = level.value[row] === level.value[`${row}Unlock`];
	}
</script>

{#if tooltip}
	<div class="text-xs select-none tooltip" data-tip={tooltip}>
		{label}
	</div>
{:else}
	<div class="text-xs select-none">{label}</div>
{/if}
{#each columns as column}
	<PermissionsCell {level} {levelIndex} {row} {column} on:click />
{/each}
{#if passwordField}
	<div class="text-xs select-none tooltip">Password</div>
	<div class="{passwordSpan} w-full">
		<Input bind:field={passwordField} />
	</div>
{/if}

<script>
	import { getContext } from "svelte";

	import urlStore from "../../stores/urlStore";
	import layoutStore from "../../stores/layoutStore";

	import Button from "./Button.svelte";

	export let pageNumber;
	export let currentPageNumber;
	export let invisible;

	$: path = invisible ? null : getPath($urlStore);

	$: className = pageNumber == currentPageNumber ? "primary" : null;

	function getPath(currentUrl) {
		const splitUrl = currentUrl.split("/");
		splitUrl[splitUrl.length - 1] = pageNumber;
		return splitUrl.join("/");
	}
</script>

<Button
	{path}
	{className}
	size={$layoutStore.paginationButtonSize}
	extraClasses="join-item rounded-lg"
>
	{pageNumber}
</Button>

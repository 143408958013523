<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";

	export let role;

	const dispatch = createEventDispatcher();

	$: roleFlavor = role.roleFlavor;
	$: fullNameField = getFullNameField(roleFlavor);
	$: privateNameField = getPrivateNameField(roleFlavor);
	$: publicNameField = getPublicNameField(roleFlavor);
	$: primaryColorField = getPrimaryColorField(role, roleFlavor);
	$: secondaryColorField = getSecondaryColorField(role, roleFlavor);

	function getFullNameField(roleFlavor) {
		return {
			type: "input",
			inputType: "text",
			value: roleFlavor.fullName,
			maxLength: 32,
			label: "Full Name",
		};
	}

	function getPrivateNameField(roleFlavor) {
		return {
			type: "input",
			inputType: "text",
			value: roleFlavor.privateName,
			maxLength: 32,
			label: "Private Name",
			placeholder: "Leave blank to use Full Name",
		};
	}

	function getPublicNameField(roleFlavor) {
		return {
			type: "input",
			inputType: "text",
			value: roleFlavor.publicName,
			maxLength: 32,
			label: "Public Name",
			placeholder: "Leave blank to use Full Name",
		};
	}

	function getPrimaryColorField(role, roleFlavor) {
		if (role.team !== "None") return null;
		return {
			type: "input",
			inputType: "color",
			value: roleFlavor.primaryColor,
			label: "Primary Color",
		};
	}

	function getSecondaryColorField(role, roleFlavor) {
		if (role.team !== "None") return null;
		return {
			type: "input",
			inputType: "color",
			value: roleFlavor.secondaryColor,
			label: "Secondary Color",
		};
	}

	function handleFullNameChange({ detail }) {
		if (detail.type === "input") {
			dispatch("event", { value: { ...roleFlavor, fullName: detail.value } });
		}
	}

	function handlePrivateNameChange({ detail }) {
		if (detail.type === "input") {
			dispatch("event", {
				value: { ...roleFlavor, privateName: detail.value },
			});
		}
	}

	function handlePublicNameChange({ detail }) {
		if (detail.type === "input") {
			dispatch("event", { value: { ...roleFlavor, publicName: detail.value } });
		}
	}

	function handlePrimaryColorChange({ detail }) {
		dispatch("event", { value: { ...roleFlavor, primaryColor: detail.value } });
	}

	function handleSecondaryColorChange({ detail }) {
		dispatch("event", {
			value: { ...roleFlavor, secondaryColor: detail.value },
		});
	}
</script>

<div class="mt-2 grid grid-cols-2 gap-2">
	{#if fullNameField}
		<Field field={fullNameField} on:event={handleFullNameChange} />
	{/if}
	<div></div>
	{#if privateNameField}
		<Field field={privateNameField} on:event={handlePrivateNameChange} />
	{/if}
	{#if publicNameField}
		<Field field={publicNameField} on:event={handlePublicNameChange} />
	{/if}
	{#if primaryColorField}
		<Field field={primaryColorField} on:event={handlePrimaryColorChange} />
	{/if}
	{#if secondaryColorField}
		<Field field={secondaryColorField} on:event={handleSecondaryColorChange} />
	{/if}
</div>

<script>
	import editSectionsStore from "../../../../stores/editSectionsStore";

	import SectionGroupsEditor from "./SectionGroupsEditor.svelte";
	import SectionsEditor from "./SectionsEditor.svelte";
	import SaveAndCancelButtons from "./SaveAndCancelButtons.svelte";
	import Field from "../../../forms/Field.svelte";

	export let editSections;

	let urlTypeField = {
		type: "input",
		inputType: "urlType",
		value: "fromTitle",
		label: "Section URLs",
		id: "url-type",
	};

	$: editSectionsStore.update((value) => ({
		...value,
		urlType: urlTypeField.value,
	}));
</script>

<SaveAndCancelButtons />
<Field bind:field={urlTypeField} />
<SectionGroupsEditor />
{#each editSections as sectionGroup, sectionGroupIndex (sectionGroup.id)}
	<SectionsEditor {sectionGroup} {sectionGroupIndex} />
{/each}
<SaveAndCancelButtons />

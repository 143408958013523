<script>
	import StandardAssignment from "./StandardAssignment.svelte";
	import Matrices from "./Matrices.svelte";
	import Lotteries from "./Lotteries.svelte";

	export let roleAssignment;

	function handleStandardAssignmentChange({ detail }) {
		roleAssignment.standardAssignment = detail.value;
	}

	function handleMatricesChange({ detail }) {
		roleAssignment.matrices = detail.value;
	}

	function handleLotteriesChange({ detail }) {
		roleAssignment.lotteries = detail.value;
	}
</script>

<div class="flex flex-col gap-4">
	{#if roleAssignment?.standardAssignment}
		<StandardAssignment
			standardAssignment={roleAssignment.standardAssignment}
			on:event={handleStandardAssignmentChange}
		/>
	{/if}
	{#if roleAssignment?.matrices}
		<Matrices
			matrices={roleAssignment.matrices}
			on:event={handleMatricesChange}
		/>
	{/if}
	{#if roleAssignment?.lotteries}
		<Lotteries
			lotteries={roleAssignment.lotteries}
			on:event={handleLotteriesChange}
		/>
	{/if}
</div>

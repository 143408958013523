<script>
	export let toast;

	$: alertClass = {
		"": "",
		neutral: "alert-neutral",
		primary: "alert-primary",
		secondary: "alert-secondary",
		accent: "alert-accent",
		success: "alert-success",
		info: "alert-info",
		warning: "alert-warning",
		error: "alert-error",
	}[toast.className];
</script>

<div class="alert {alertClass} z-50">
	<span>{toast.text}</span>
</div>

import API from "../API.js";

class Fetcher {
	// POST START //

	static createSetup({
		urlType,
		title,
		gameType,
		playerCountMin,
		playerCountMax,
	}) {
		const body = {
			url_type: urlType,
			setup: {
				title,
				game_type: gameType,
				player_count_min: playerCountMin,
				player_count_max: playerCountMax,
			},
		};
		return API.post("game_creator/setups", body);
	}

	static createTestGame({
		setupId,
		title,
		playerCount,
		urlType,
		flavorId,
		constants,
	}) {
		const url = "game_creator/games";
		const body = {
			url_type: urlType,
			player_count: playerCount,
			constants,
			domain_slug: "game-creator",
			game: {
				setup_id: setupId,
				title,
				flavor_id: flavorId,
				test_game: true,
				test_perspective: "Moderator",
			},
		};
		return API.post(url, body);
	}

	// POST END //

	// GET START //

	static getSetups() {
		return API.get("game_creator/setups");
	}

	static getSetup(setupSlug) {
		return API.get(`game_creator/setups/${setupSlug}`);
	}

	static getGame(gameSlug) {
		return API.get(`game_creator/games/${gameSlug}`);
	}

	// GET END //

	// PUT START

	static updateSetup({ setupSlug, gameType, playerCountMin, playerCountMax }) {
		const body = {
			setup: {
				game_type: gameType,
				player_count_min: playerCountMin,
				player_count_max: playerCountMax,
			},
		};
		return API.put(`game_creator/setups/${setupSlug}`, body);
	}

	static updateSetupValues({ setupSlug, values, standardSetupConstants }) {
		const body = {
			game_type: standardSetupConstants.gameType,
			player_count_min: standardSetupConstants.playerCountMin,
			player_count_max: standardSetupConstants.playerCountMax,
			role_default: standardSetupConstants.defaultRole,
			game_start_effect: standardSetupConstants.gameStartEffect,
			game_end_condition: standardSetupConstants.gameEndCondition,
			game_end_effect: standardSetupConstants.gameEndEffect,
			values,
		};
		const url = `game_creator/setups/${setupSlug}/setup_values`;
		return API.put(url, body);
	}

	static updateEffects({ setupSlug, effects }) {
		const body = {
			effects,
		};
		const url = `game_creator/setups/${setupSlug}/effects`;
		return API.put(url, body);
	}

	static updateTeams({ setupSlug, teams }) {
		const body = {
			teams,
		};
		const url = `game_creator/setups/${setupSlug}/teams`;
		return API.put(url, body);
	}

	static updateRoles({ setupSlug, roles }) {
		const body = {
			roles,
		};
		const url = `game_creator/setups/${setupSlug}/roles`;
		return API.put(url, body);
	}

	static updateRoleAssignment({ setupSlug, roleAssignment }) {
		const body = {
			role_assignment: roleAssignment,
		};
		const url = `game_creator/setups/${setupSlug}/role_assignment`;
		return API.put(url, body);
	}

	static updateChats({ setupSlug, chats }) {
		const body = {
			chats,
		};
		const url = `game_creator/setups/${setupSlug}/chats`;
		return API.put(url, body);
	}

	static updateActions({ setupSlug, actions }) {
		const body = {
			actions,
		};
		const url = `game_creator/setups/${setupSlug}/actions`;
		return API.put(url, body);
	}

	static updateFlavors({
		setupSlug,
		flavors,
		activeFlavorId,
		defaultFlavorId,
	}) {
		const body = {
			flavors,
			active_flavor_id: activeFlavorId,
			default_flavor_id: defaultFlavorId,
		};
		const url = `game_creator/setups/${setupSlug}/flavors`;
		return API.put(url, body);
	}

	static updateGame({ slug, testPerspective }) {
		const body = {
			...(testPerspective ? { test_perspective: testPerspective } : {}),
		};
		const url = `game_creator/games/${slug}`;
		return API.put(url, body);
	}

	static executeDeadlineEffect({ slug }) {
		const body = {};
		const url = `game_creator/games/${slug}/execute_deadline_effect`;
		return API.put(url, body);
	}

	static changeActionVariableInputVote({ id, value }) {
		const body = {
			value,
		};
		const url = `game_creator/action_variable_inputs/${id}/change_vote`;
		return API.put(url, body);
	}

	// PUT END //
}

export default Fetcher;

<script>
	import pluralize from "pluralize";

	import Fetcher from "../../../../api/Fetcher";
	import fetchCurrentUser from "../../../../api/fetchCurrentUser.js";
	import TopicBadge from "../../../main/forum/TopicBadge.svelte";
	import Icon from "../../../icons/small/Icon.svelte";
	import DefaultAvatar from "../../../shared/avatar/defaults/DefaultAvatar.svelte";

	export let notification;

	function deleteNotification() {
		Fetcher.deleteNotification({ notification }).then(fetchCurrentUser);
	}
</script>

<div class="bg-base-300 py-2 px-4 rounded-lg flex gap-2">
	{#if notification.icon}
		<div class="my-auto">
			<DefaultAvatar avatar="default {notification.icon}" small />
		</div>
	{/if}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="grow max-w-full text-sm notification-text">
		{#if notification.category === "replies"}
			<div class="max-w-full">
				<TopicBadge
					topic={notification.object}
					postNumber={notification.tag}
					on:click={deleteNotification}
				/>
			</div>
			has {pluralize("new reply", notification.number, true)}.
		{:else if notification.category === "added"}
			You have been added to
			<div class="max-w-full">
				<TopicBadge
					topic={notification.object}
					postNumber={notification.tag}
					on:click={deleteNotification}
				/>
			</div>
		{/if}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="my-auto text-error cursor-pointer" on:click={deleteNotification}>
		<Icon name="bin" />
	</div>
</div>

<style>
	.notification-text {
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
</style>

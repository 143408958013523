import bbCodes from "./bbCodes.js";
import BbCodeInsertion from "./BbCodeInsertion.js";
import BbCodeParser from "./BbCodeParser.js";

class BbCodeManager {
	constructor(...codes) {
		this.bbCodes = codes.map(BbCodeManager.codeToBbCode);
		this.insertions = {};
		this.bbCodes.forEach(
			(bbCode) =>
				(this.insertions[bbCode.code] = BbCodeManager.bbCodeToInsertion(bbCode))
		);
		this.parser = new BbCodeParser(this.bbCodes);
	}

	insert(text, selectionStart, selectionEnd, code) {
		if (typeof code === "number") {
			const bbCode = this.bbCodes.find((bbCode) => bbCode.keyCode === code);
			if (bbCode) return text;
			code = bbCode.code;
		}
		const insertion = this.insertions[code];
		if (!insertion) return text;
		return insertion.insert(text, selectionStart, selectionEnd);
	}

	insertBbCode(text, selectionStart, bbCode) {
		const newText = text.slice(0, selectionStart) + text.slice(selectionStart);
		return { newText, newSelectionRange: [selectionStart, selectionStart] };
	}

	parse(text) {
		return this.parser.parse(text);
	}

	static codeToBbCode(code) {
		return bbCodes.find((bbCode) => bbCode.code === code);
	}

	static bbCodeToInsertion(bbCode) {
		return new BbCodeInsertion(bbCode);
	}

	static bbCodeToRaw(text) {
		let rawText = "";
		let iText = 0;
		let iRawText = 0;
		let k = 0;
		const tags = {};
		while (iText < text.length && k < 1000) {
			k++;
			if (text[iText] !== "[") {
				rawText += text[iText];
				iText++;
				iRawText++;
			} else {
				const iCloseBracketLocal = text.slice(iText).indexOf("]");
				if (iCloseBracketLocal < -1) {
					rawText += text[iText];
					iText++;
					iRawText++;
				} else {
					const iCloseBracket = iText + iCloseBracketLocal;
					const close = text[iText + 1] === "/";
					const tag = text.slice(iText + (close ? 2 : 1), iCloseBracket);
					if (close) {
						if (tags[tag]) {
							tags[tag][tags[tag].length - 1][1] ||= iRawText;
						}
					} else {
						tags[tag] ||= [];
						tags[tag].push([iRawText]);
					}
					iText = iCloseBracket + 1;
				}
			}
		}
		return { rawText, tags };
	}

	static rawToBbCode({ rawText, tags }) {
		let text = "";
		const tagIndexes = {};
		for (const tag in tags) {
			for (const instance of tags[tag]) {
				tagIndexes[instance[0]] ||= {};
				tagIndexes[instance[0]][tag] = instance[1];
			}
		}
		for (let i = 0; i < rawText.length; i++) {}
	}
}

export default BbCodeManager;

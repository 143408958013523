<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Fetcher from "../../../../../api/gameCreator/Fetcher.js";

	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";
	import Teams from "./Teams.svelte";

	export let activeTab;
	export let setup;
	export let unsaved;
	export let teamConstants;
	export let teamVariables;
	export let roleConstants;
	export let playerVariables;

	let teams = [];
	let show = true;
	$: activeFlavor = setup.flavors.find(
		(flavor) => flavor.id === setup.activeFlavor
	);

	$: handleSetupTeamsChange(setup.teams);
	$: unsaved = !equal(teams, setup.teams);

	export function handleActiveFlavorChanged() {
		for (let i = 0; i < teams.length; i++) {
			if (setup.teams[i]) {
				teams[i].teamFlavor = clone(setup.teams[i].teamFlavor);
			}
		}
	}

	function handleSetupTeamsChange(setupTeams) {
		if (teams.length === 0) teams = clone(setupTeams);
	}

	function addTeam() {
		teams = [
			...teams,
			{
				winCondition: null,
				teamFlavor: {
					name: "Team",
					primaryColor: activeFlavor.primaryColor,
					secondaryColor: activeFlavor.secondaryColor,
				},
				constants: {},
				variables: {},
				roleConstants: {},
				playerVariables: {},
			},
		];
	}

	function handleSave() {
		if (activeTab !== "Teams") return;
		Fetcher.updateTeams({ setupSlug: setup.slug, teams }).then(
			handleSuccessfulSave
		);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		teams = clone(savedSetup.teams);
	}

	function handleRevert() {
		teams = clone(setup.teams);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
>
	<div>
		<Button square size="small" on:click={addTeam} tooltip="Add Team">
			<Icon name="plus" />
		</Button>
	</div>
</SaveAndRevertButtons>

{#if show}
	<Teams
		{teamConstants}
		{teamVariables}
		{roleConstants}
		{playerVariables}
		bind:teams
	/>
{/if}

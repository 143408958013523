<script>
	import { createEventDispatcher } from "svelte";

	import Button from "./Button.svelte";
	import Icon from "../icons/large/Icon.svelte";
	import Align from "./align/Align.svelte";

	export let buttonClass = "primary";
	export let currentEmoji = null;
	export let extraClasses = "btn-sm";
	export let tooltip;
	export let closeOnEmojiClick = false;

	let open = false;

	const dispatch = createEventDispatcher();

	const emojis = [
		"👍",
		"👎",
		"🤞",
		"👋",
		"👌",
		"👏",
		"😀",
		"😊",
		"😂",
		"🤣",
		"😥",
		"😭",
		"❤️",
		"💔",
		"🔥",
		"⭐",
		"🎉",
		"✨",
		"❗️",
		"❓",
		"⁉️",
		"✅",
		"❌",
		"💯",
	];

	function handleEmojiClick(emoji) {
		if (closeOnEmojiClick) open = false;
		dispatch("emojiClick", { value: emoji });
	}

	function handleExternalClick() {
		if (open) open = false;
	}
</script>

<svelte:window on:click={handleExternalClick} />

<div class="relative">
	<Button
		className={buttonClass}
		{extraClasses}
		outline
		square
		{tooltip}
		on:click={() => (open = !open)}
	>
		<Icon name="thumbsUp" />
	</Button>
	{#if open}
		<div
			class="absolute menu bg-base-100 rounded-box z-[100] w-52 p-2 shadow-xl"
			style="right: 0;"
		>
			<div class="grid grid-cols-6 gap-1 text-xl">
				{#each emojis as emoji}
					<div class="w-full" class:bg-primary={currentEmoji === emoji}>
						<Align horizontal="center">
							<!-- svelte-ignore a11y-click-events-have-key-events -->
							<div
								class="cursor-pointer"
								on:click|stopPropagation={() => handleEmojiClick(emoji)}
							>
								{emoji}
							</div>
						</Align>
					</div>
				{/each}
			</div>
		</div>
	{/if}
</div>

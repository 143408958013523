<script>
	import Avatar from "./avatar/Avatar.svelte";

	export let userSlug;
	export let username;
	export let avatar;
</script>

<div class="flex flex-col gap-2">
	<div
		class="badge badge-neutral mx-auto flex tooltip cursor-pointer"
		data-tip="---link to {userSlug}---"
	>
		{username}
	</div>
	<div class="mx-auto flex">
		<Avatar value={avatar} />
	</div>
</div>

<script>
	import SvelteSelect from "./SvelteSelect.svelte";

	export let field;
	export let error = null;

	const items = [
		{ value: "fromTitle", label: "From Title" },
		{ value: "random", label: "Random" },
	];
</script>

<SvelteSelect bind:value={field.value} {items} {error} on:change on:event />

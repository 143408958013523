<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import VariableNumberInput from "./VariableNumberInput.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "../effects/EffectSelect.svelte";

	export let action;
	export let actionVariable;

	const dispatch = createEventDispatcher();
	const scopeTypes = ["Team", "Role", "Player", "Action", "Chat"];

	$: variableInput = action.variableInputs[actionVariable.id];
	$: included = Object.keys(action.variableInputs).includes(actionVariable.id);
	$: includedField = getIncludedField(included, actionVariable);
	$: tiebreakerField = getTiebreakerField(action, actionVariable);

	function getIncludedField(included, actionVariable) {
		return {
			type: "checkbox",
			value: included,
			label: actionVariable.name,
		};
	}

	function getTiebreakerField(action, actionVariable) {
		if (!action.variableInputs[actionVariable.id]) return;
		return {
			type: "input",
			inputType: "select",
			items: ["None", "Random", "Highest", "Lowest"],
			value: action.variableInputs[actionVariable.id].tiebreaker,
			label: "Tiebreaker",
			placeholder: "Tiebreaker",
			padding: true,
		};
	}

	function handleIncludedChange({ detail }) {
		if (detail.value) {
			action.variableInputs[actionVariable.id] ||= newVariableInput(
				actionVariable.dataType
			);
		} else {
			delete action.variableInputs[actionVariable.id];
		}
		dispatch("event", { value: action });
	}

	function newVariableInput(dataType) {
		const base = {
			mandatory: null,
			default: null,
			whoCanInput: null,
			plurality: null,
			tiebreaker: null,
			tiebreakerValue: null,
			votesPerPlayer: 1,
			majorityEffect: null,
		};
		if (dataType === "Number") {
			return {
				...base,
				minimum: null,
				maximum: null,
				mustBeInteger: null,
			};
		}
		if (scopeTypes.includes(dataType)) {
			return {
				...base,
				allowedObjects: null,
			};
		}
		return base;
	}

	function handleChange(key, detail) {
		if (detail.value === action.variableInputs[actionVariable.id][key]) {
			return;
		}
		action.variableInputs[actionVariable.id][key] = detail.value;
		dispatch("event", { value: action });
	}
</script>

{#if includedField}
	<Field field={includedField} on:event={handleIncludedChange} />
{/if}
<div></div>
<div></div>
<div></div>
{#if included}
	<SetupValueSelect
		value={action.variableInputs[actionVariable.id].mandatory}
		dataType="True or False"
		scopeType="Action"
		label="Mandatory"
		placeholder="Mandatory"
		allowRaw
		on:event={({ detail }) => handleChange("mandatory", detail)}
	/>
	<SetupValueSelect
		value={action.variableInputs[actionVariable.id].default}
		dataType={actionVariable.dataType}
		scopeType="Action"
		label="Default"
		placeholder="Default"
		allowRaw
		excluded={actionVariable.id}
		on:event={({ detail }) => handleChange("default", detail)}
	/>
	<SetupValueSelect
		value={action.variableInputs[actionVariable.id].whoCanInput}
		dataType="True or False"
		scopeTypes={["Player"]}
		label="Who Can Input"
		placeholder="Who Can Input"
		on:event={({ detail }) => handleChange("whoCanInput", detail)}
	/>
	<SetupValueSelect
		value={variableInput.plurality}
		dataType="True or False"
		scopeType="Action"
		label="Plurality"
		placeholder="Plurality"
		allowRaw
		allowNull
		on:event={({ detail }) => handleChange("plurality", detail)}
	/>
	{#if tiebreakerField}
		<Field
			field={tiebreakerField}
			on:event={({ detail }) => handleChange("tiebreaker", detail)}
		/>
	{/if}
	<SetupValueSelect
		value={action.variableInputs[actionVariable.id].tiebreakerValue}
		dataType="Number"
		scopeTypes={["Player"]}
		label="Tiebreaker Value"
		placeholder="Tiebreaker Value"
		on:event={({ detail }) => handleChange("tiebreakerValue", detail)}
	/>
	<SetupValueSelect
		value={action.variableInputs[actionVariable.id].votesPerPlayer}
		dataType="Number"
		scopeTypes={["Player"]}
		label="Votes per Player"
		placeholder="Votes per Player"
		allowRaw
		min={1}
		max={1}
		on:event={({ detail }) => handleChange("votesPerPlayer", detail)}
	/>
	<EffectSelect
		effect={action.variableInputs[actionVariable.id].majorityEffect}
		scopeType="Action"
		label="Majority Effect"
		placeholder="Majority Effect"
		allowNull
		on:event={({ detail }) => handleChange("majorityEffect", detail)}
	/>
	{#if actionVariable.dataType === "Number"}
		<VariableNumberInput {action} {actionVariable} on:event />
	{:else if scopeTypes.includes(actionVariable.dataType)}
		<SetupValueSelect
			value={action.variableInputs[actionVariable.id]["objectsToChooseFrom"]}
			dataType="True or False"
			scopeTypes={[actionVariable.dataType]}
			label="{actionVariable.dataType}s to Choose From"
			placeholder="{actionVariable.dataType}s to Choose From"
			on:event={({ detail }) => handleChange("objectsToChooseFrom", detail)}
		/>
		<div></div>
		<div></div>
		<div></div>
	{:else}
		<div></div>
	{/if}
{/if}

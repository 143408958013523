<script>
	import { getContext } from "svelte";

	import paramsStore from "../../../stores/paramsStore";
	import topicsStore from "../../../stores/data/topicsStore";

	import Fetcher from "../../../api/Fetcher";

	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];

	$: subscribed = topic?.userSettings?.status === "subscribed";

	let previousSubscribed;

	$: handleSubscribedChanged(subscribed);

	function handleSubscribedChanged(subscribed) {
		if (previousSubscribed === subscribed) return;
		previousSubscribed = subscribed;
	}

	function handleClick() {
		const action = subscribed ? "unsubscribe" : "subscribe";
		Fetcher[action]({ topic }).then(handleResponse);
	}

	function handleResponse(json) {
		topicsStore.updateTopic({
			slug: topic.slug,
			...json,
		});
	}
</script>

<div class="form-control">
	<label class="cursor-pointer label">
		<span class="label-text">Subscribe</span>
		<input
			type="checkbox"
			class="toggle toggle-primary"
			checked={subscribed}
			on:click|preventDefault={handleClick}
		/>
	</label>
</div>

<script>
	import Align from "../../../../tailwind/align/Align.svelte";
	import Badge from "../../../../tailwind/Badge.svelte";

	export let playersToChooseFrom;
	export let rowHeight;
	export let votes;
	export let notVoting;
</script>

<div class="flex flex-col gap-2">
	{#each playersToChooseFrom as player}
		<div style="height: {rowHeight}px;">
			<Align vertical="center">
				<Badge colorName="default" full>
					{votes
						.filter((vote) => vote.value == player.id)
						.reduce((acc, cur) => acc + cur.weight, 0)}
				</Badge>
			</Align>
		</div>
	{/each}
	<div style="height: 32px;">
		<Align vertical="center">
			<Badge colorName="default" full>
				{notVoting.reduce((acc, cur) => acc + cur.weight, 0)}
			</Badge>
		</Align>
	</div>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import Lottery from "./Lottery.svelte";

	export let lotteries;

	const dispatch = createEventDispatcher();

	function handleLotteryChange(i, detail) {
		lotteries[i] = detail.value;
		dispatch("event", { value: lotteries });
	}

	function deleteLottery(i) {
		dispatch("event", {
			value: [...lotteries.slice(0, i), ...lotteries.slice(i + 1)],
		});
	}
</script>

{#each lotteries as lottery, i}
	<Lottery
		{lottery}
		on:event={({ detail }) => handleLotteryChange(i, detail)}
		on:delete={() => deleteLottery(i)}
	/>
{/each}

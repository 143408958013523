<script>
	import { createEventDispatcher, tick } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let calculationTerm;
	export let scopeType;
	export let valueId;

	const dispatch = createEventDispatcher();

	let initialFactorField, multiplicationFactors, divisionFactors, extraCells;

	$: handleCalculationTermChange(calculationTerm);

	async function handleCalculationTermChange(calculationTerm) {
		if (
			calculationTerm.length === 0 ||
			typeof calculationTerm[0][0] !== "number"
		)
			initialFactorField = null;
		else {
			initialFactorField = {
				type: "input",
				inputType: "number",
				value: calculationTerm[0][0],
			};
		}
		multiplicationFactors = null;
		divisionFactors = null;
		await tick();
		setFactors();
		extraCells =
			5 - ((calculationTerm[0].length + calculationTerm[1].length) % 4);
	}

	function setFactors() {
		multiplicationFactors = calculationTerm[0].slice(1);
		if (!multiplicationFactors.includes("")) {
			multiplicationFactors = [...multiplicationFactors, ""];
		}
		divisionFactors = calculationTerm[1];
		if (!divisionFactors.includes("")) {
			divisionFactors = [...divisionFactors, ""];
		}
	}

	function handleInitialFactorChange({ detail }) {
		if (detail.value === calculationTerm[0][0]) return;
		calculationTerm[0][0] = detail.value;

		dispatch("event", { value: calculationTerm });
	}

	function handleFactorChange(i, j, value) {
		if (value === "") return;
		if (i === 0) j += 1;
		if (value.label && value.value === null) {
			calculationTerm[i] = [
				...calculationTerm[i].slice(0, j),
				...calculationTerm[i].slice(j + 1),
			];
		} else {
			calculationTerm[i][j] = value;
		}
		dispatch("event", { value: calculationTerm });
	}
</script>

{#if initialFactorField}
	<Field field={initialFactorField} on:event={handleInitialFactorChange} />
{/if}
{#if multiplicationFactors}
	{#each multiplicationFactors as factor, j}
		<SetupValueSelect
			value={factor}
			dataType="Number"
			{scopeType}
			excluded={valueId}
			allowNull
			on:event={({ detail }) => handleFactorChange(0, j, detail.value)}
		/>
	{/each}
{/if}
{#if divisionFactors}
	<Align both="center"><Icon name="divide" /></Align>
	{#each divisionFactors as factor, j}
		<SetupValueSelect
			value={factor}
			dataType="Number"
			{scopeType}
			excluded={valueId}
			allowNull
			on:event={({ detail }) => handleFactorChange(1, j, detail.value)}
		/>
	{/each}
{/if}
{#each Array(extraCells || 0) as _}
	<div></div>
{/each}

<script>
	import pageNameStore from "../../../stores/pageNameStore.js";
	import paramsStore from "../../../stores/paramsStore.js";

	import {
		getIndexItems,
		getForumItems,
		getSectionItems,
		getTopicItems,
		getGameCreatorItems,
		getGameCreatorSetupItems,
	} from "./getItems.js";

	import SideMenu from "../shared/SideMenu.svelte";

	let sideMenu;

	export function closeMenu() {
		sideMenu.closeMenu();
	}

	$: slug =
		$paramsStore.domainSlug || $paramsStore.slug || $paramsStore.forumSlug;
	$: getItems = getGetItems($pageNameStore, slug);

	function getGetItems(pageName, slug) {
		if (!pageName) return null;
		if (pageName === "index") return getIndexItems;
		if (pageName === "forum") return getForumItems;
		if (pageName === "forumSection") return getSectionItems;
		if (pageName === "forumTopic") return getTopicItems;
		if (pageName === "domainPage" && slug === "game-creator") {
			return getGameCreatorItems;
		}
		if (pageName === "gameCreatorSetup") return getGameCreatorSetupItems;
		return null;
	}
</script>

<SideMenu bind:this={sideMenu} side="right" {getItems} on:mouseenter />

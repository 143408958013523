export default function getOperatorItems(dataType) {
	if (dataType === "Number") {
		return [
			"Is equal to",
			"Is not equal to",
			"Is at least",
			"Is at most",
			"Is more than",
			"Is less than",
		];
	}
	return ["Is equal to", "Is not equal to"];
}

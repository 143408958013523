<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import sectionGroupsStore from "../../../../stores/data/sectionGroupsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";

	import SvelteSelect from "./SvelteSelect.svelte";

	export let field;
	export let error = null;

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionGroupIds = forum?.sectionGroupIds || [];
	$: sectionGroups = sectionGroupIds.map((id) => $sectionGroupsStore[id] || {});

	let items = [];

	$: setItems(sectionGroups);

	function setItems(sectionGroups) {
		if (!sectionGroups) return;
		items = [];
		sectionGroups.forEach((sectionGroup) => {
			sectionGroup.sectionSlugs.forEach((sectionSlug) => {
				const section = $sectionsStore[sectionSlug];
				items = [
					...items,
					{
						value: section.id,
						label: `${sectionGroup.title} / ${section.title}`,
					},
				];
			});
		});
	}
</script>

{#if items.length > 0}
	<SvelteSelect bind:value={field.value} {items} {error} on:change on:event />
{/if}

<script>
	import { getContext } from "svelte";

	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";

	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let post;

	$: topicSlug = $paramsStore.topicSlug;
	$: gameSlug = $paramsStore.gameSlug;
	$: topic = $topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];

	const insertBbCode = getContext("insertBbCode");

	function handleClick() {
		const bbCode = `[link topic=${topic.slug} post=${post.tag}]#${post.tag}[/link]`;
		insertBbCode(bbCode);
	}
</script>

<Button
	className="primary"
	size="small"
	outline
	square
	tooltip="Link"
	on:click={handleClick}
>
	<Icon name="link" />
</Button>

<script>
	import { getContext } from "svelte";

	import UserBadge from "../../../shared/UserBadge.svelte";
	import Avatar from "../../../shared/avatar/Avatar.svelte";
	import Badge from "../../../tailwind/Badge.svelte";

	export let post;

	const userStylesStore = getContext("userStylesStore");

	$: userStyle = $userStylesStore[post.user.username];
	$: backgroundColor = userStyle?.primaryColor;
	$: textColor = userStyle?.secondaryColor;
</script>

<div class="mx-auto max-w-full">
	<UserBadge user={post.user} {backgroundColor} {textColor} />
</div>
<div class="mx-auto flex">
	<Avatar
		value={post.user?.profile?.avatarUrl || post.user?.profile?.avatarDefault}
		defaultAvatar={post.user?.profile?.avatarDefault}
	/>
</div>
{#if userStyle?.label}
	<div class="mx-auto max-w-full">
		<Badge {backgroundColor} {textColor}>{userStyle.label}</Badge>
	</div>
{/if}

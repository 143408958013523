<script>
	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let list;
	export let scopeType;
	export let dataType;

	$: listItems = getListItems(list);
	$: listItemDataType = dataType.split(" ")[2].slice(0, -1);

	function getListItems(list) {
		if (list.find((listItem) => [null, ""].includes(listItem))) {
			return [...list];
		}
		return [...list, null];
	}

	function handleChange(i, detail) {
		list[i] = detail.value;
	}
</script>

{#each listItems as listItem, i}
	<SetupValueSelect
		value={listItem}
		{scopeType}
		dataType={listItemDataType}
		allowRaw
		on:event={({ detail }) => handleChange(i, detail)}
	/>
{/each}

import { values } from "./permissionsFunctions";

export default function forumPermissionsTabs({
	domainPermissions = null,
	forumPermissions = {},
} = {}) {
	return JSON.parse(
		JSON.stringify({
			tab: "Permissions",
			fields: [
				{
					type: "permissions",
					levels: [
						{
							heading: "Forum",
							primary: true,
							columns: ["admin", "member", "guest"],
							rows: ["view"],
							tooltips: ["Who can view the forum main page"],
							...values({
								codes: ["view"],
								existing: domainPermissions,
							}),
						},
						{
							heading: "Section Groups",
							columns: ["admin", "member", "guest"],
							rows: ["viewMin", "viewDefault", "viewMax"],
							tooltips: [
								"The least restrictive a view permission can be",
								"Default view permission for section groups",
								"The most restrictive a view permission can be",
							],
							...values({
								codes: ["view"],
								existing: forumPermissions.sectionGroups,
								meta: true,
							}),
						},
						{
							heading: "Sections",
							columns: ["admin", "member", "guest"],
							rows: [
								"viewMin",
								"viewDefault",
								"viewMax",
								"createTopicMin",
								"createTopicDefault",
								"createTopicMax",
							],
							tooltips: [
								"The least restrictive a view permission can be",
								"Default view permission for sections",
								"The most restrictive a view permission can be",
								"The least restrictive a create topic permission can be",
								"Default create topic permission",
								"The most restrictive a create topic permission can be",
							],
							...values({
								codes: ["view", "createTopic"],
								meta: true,
							}),
						},
						{
							type: "permissions",
							heading: "Topics",
							columns: ["admin", "member", "guest"],
							rows: [
								"viewMin",
								"viewDefault",
								"viewMax",
								"postMin",
								"postDefault",
								"postMax",
							],
							tooltips: [
								"The least restrictive a view permission can be",
								"Default view permission for topics",
								"The most restrictive a view permission can be",
								"The least restrictive a post permission can be",
								"Default post permission",
								"The most restrictive a post permission can be",
							],
							...values({
								codes: ["view", "post"],
								existing: forumPermissions.topics,
								meta: true,
							}),
						},
					],
				},
			],
		})
	);
}

<script>
	import PostText from "./PostText.svelte";

	export let text = "";
</script>

<div class="h-full overflow-auto bg-base-300">
	<div class="p-4 bg-base-100 h-full w-full rounded-lg overflow-auto">
		<div class=" h-full"><PostText {text} /></div>
	</div>
</div>

<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Fetcher from "../../../../../api/gameCreator/Fetcher.js";
	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";
	import RoleAssignment from "./RoleAssignment.svelte";

	export let activeTab;
	export let setup;
	export let unsaved;

	let roleAssignment = {};
	let show = true;

	$: handleSetupRoleAssignmentChange(setup.roleAssignment);
	$: unsaved = !equal(roleAssignment, setup.roleAssignment);

	function handleSetupRoleAssignmentChange(setupRoleAssignment) {
		if (Object.keys(roleAssignment).length === 0) {
			roleAssignment = clone(setupRoleAssignment);
		}
	}

	function handleSave() {
		if (activeTab !== "Role Assignment") return;
		Fetcher.updateRoleAssignment({
			setupSlug: setup.slug,
			roleAssignment,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		roleAssignment = clone(savedSetup.roleAssignment);
	}

	function addMatrix() {
		roleAssignment.matrices = [
			...roleAssignment.matrices,
			{
				rows: null,
				columns: null,
				rolesPerCell: 1,
				numberToSelect: 1,
				selectionType: "Row",
				distinctSelection: true,
				roles: [],
			},
		];
	}

	function addLottery() {
		roleAssignment.lotteries = [
			...roleAssignment.lotteries,
			{
				replacementType: "Replacement",
				itemsToDraw: null,
				items: [],
				roles: [],
			},
		];
	}

	function handleRevert() {
		roleAssignment = clone(setup.roleAssignment);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
>
	<div>
		<Button square size="small" tooltip="Add Matrix" on:click={addMatrix}>
			<Icon name="tableCell" />
		</Button>
	</div>
	<div>
		<Button square size="small" tooltip="Add Lottery" on:click={addLottery}>
			<span class="text-xl">L</span>
		</Button>
	</div>
</SaveAndRevertButtons>

{#if show}
	<RoleAssignment bind:roleAssignment />
{/if}

<script>
	import Field from "../../../../forms/Field.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";
	import ConditionLine from "./ConditionLine.svelte";
	import ConditionLineEmpty from "./ConditionLineEmpty.svelte";

	export let condition;
	export let scopeType;
	export let valueId;

	const ruleTypeItems = [
		"All",
		"None",
		"At least",
		"At most",
		"More than",
		"Less than",
		"Exactly",
	];

	$: ruleTypeField = {
		type: "input",
		inputType: "select",
		items: ruleTypeItems,
		value: condition.ruleType,
		padding: true,
	};

	$: ruleValueField = getRuleValueField(condition);
	$: showEmpty = getShowEmpty(condition);

	function getRuleValueField(condition) {
		if (condition.ruleValue == null) return null;
		return {
			type: "input",
			inputType: "number",
			value: condition.ruleValue,
			min: ["More than", "Exactly"].includes(condition.ruleType) ? 0 : 1,
		};
	}

	$: description = getDescription(condition);

	function handleNameFieldEvent({ detail }) {
		condition.name = detail.value;
	}

	function handleRuleValueFieldEvent({ detail }) {
		condition.ruleValue = detail.value;
	}

	function handleRuleTypeEvent({ detail }) {
		condition.ruleType = detail.value;
		if (["All", "None"].includes(condition.ruleType)) {
			delete condition.ruleValue;
			condition = condition;
		} else if (condition.ruleValue == null) {
			condition.ruleValue = 1;
		}
	}

	function handleRuleValueEvent({ detail }) {
		condition.ruleValue = detail.value;
	}

	function getDescription(condition) {
		if (["All", "None"].includes(condition.ruleType))
			return `${condition.ruleType} True`;
		return `${condition.ruleType} ${parseInt(condition.ruleValue)} True`;
	}

	function newConditionLine({ detail }) {
		const dataType = detail.value;

		let conditionLine = {
			dataType,
			operator: "Is equal to",
			value1: null,
			value2: null,
		};
		condition.conditionLines = [...condition.conditionLines, conditionLine];
	}

	function getShowEmpty(condition) {
		for (const conditionLine of condition.conditionLines) {
			if (
				conditionLine.value1 == null ||
				conditionLine.value == "" ||
				conditionLine.value2 == null ||
				conditionLine.value2 === ""
			) {
				return false;
			}
		}
		return true;
	}
</script>

<Field field={ruleTypeField} on:event={handleRuleTypeEvent} />
{#if ruleValueField}
	<Field field={ruleValueField} on:event={handleRuleValueEvent} />
{:else}
	<div></div>
{/if}

<Align both="center">
	{description}
</Align>

<div></div>

<div class="col-span-4 divider"></div>

{#each condition.conditionLines as conditionLine}
	<ConditionLine bind:conditionLine {scopeType} {valueId} />
{/each}
{#if showEmpty}
	<ConditionLineEmpty {condition} {scopeType} on:change={newConditionLine} />
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";
	import Field from "../../../../forms/Field.svelte";
	import getSetupValueItems from "./getSetupValueItems.js";

	export let value;
	export let baseValue = null;
	export let dataType = null;
	export let dataTypes = null;
	export let scopeType = null;
	export let scopeTypes = null;
	export let excludedScopeTypes = null;
	export let excluded = null;
	export let allowRaw = false;
	export let allowNull = false;
	export let valueTypes = null;
	export let excludedValueTypes = null;
	export let placeholder = null;
	export let label = null;
	export let defaultValue = null;
	export let min = null;
	export let max = null;

	const dispatch = createEventDispatcher();

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: placeholderToShow =
		placeholder || (dataType ? `${dataType} value` : "Value");

	export function valueDataType() {
		if (!field?.items) return;
		for (const item of field.items) {
			if (item.value === value || item.value.split(" ")[1] === value) {
				return item.dataType;
			}
		}
	}

	function handleChange({ detail }) {
		if (value == detail.value) return;
		value = detail.value;
		dispatch("event", detail);
	}

	let field = {};
	$: getField({
		setup,
		dataType,
		dataTypes,
		baseValue,
		value,
		allowRaw,
		defaultValue,
		allowNull,
	});

	function getField({
		setup,
		dataType,
		dataTypes,
		baseValue,
		value,
		allowRaw,
		allowNull,
		defaultValue,
	}) {
		field = {};
		if (
			allowRaw &&
			["Text", "Number"].includes(dataType) &&
			!(baseValue?.allowedValues?.length > 0)
		) {
			const items = getSetupValueItems({
				setup,
				dataType,
				dataTypes,
				baseValue,
				allowRaw,
				allowNull,
				scopeType,
				scopeTypes,
				excludedScopeTypes,
				valueTypes,
				excludedValueTypes,
				excluded,
			});
			const mode = items.map((item) => item.value).includes(value)
				? "select"
				: dataType.toLowerCase();
			field = {
				type: "selectOrInput",
				dataType: dataType.toLowerCase(),
				items,
				value: getValueToUse(baseValue, value, defaultValue),
				placeholder: placeholderToShow,
				mode,
				label,
				min,
				max,
			};
			return;
		}
		const items = getSetupValueItems({
			setup,
			dataType,
			dataTypes,
			allowRaw,
			baseValue,
			allowNull,
			scopeType,
			scopeTypes,
			excludedScopeTypes,
			valueTypes,
			excludedValueTypes,
			excluded,
		});
		field = {
			type: "input",
			inputType: "select",
			items,
			value: getValueToUse(baseValue, value, defaultValue),
			placeholder: placeholderToShow,
			padding: true,
			label,
		};
	}

	function getValueToUse(baseValue, value, defaultValue) {
		if (value != null) return value;
		if (defaultValue != null) return defaultValue;
		return baseValue?.value;
	}

	$: handleFieldChange(field);

	function handleFieldChange(field) {
		if (value === field.value) return;
		value = field.value;
	}
</script>

{#if field.type}
	<Field {field} on:event={handleChange} />
{/if}

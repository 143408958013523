<script>
	import Link from "../../tailwind/Link.svelte";

	export let topic;
	export let postNumber = null;

	function topicPath(topic) {
		if (!topic) return;
		return `/${topic.forumSlug}/forum/${topic.sectionSlug}/${topic.slug}/p/1`;
	}
</script>

<Link on:click {topic} {postNumber}>
	{topic?.title || ""}
</Link>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import EmailForm from "./EmailForm.js";
import redirect from "../util/redirect.js";
import resetDataStores from "../stores/data/resetDataStores.js";
import currentUserStore from "../stores/data/currentUserStore.js";

class RegisterForm extends Form {
	static title = "Register";
	static code = "register-modal";
	static focusOnOpen = "register-username";
	static submitValue = "Register";

	static initialFields({ currentUser }) {
		const fields = [
			{
				type: "input",
				inputType: "text",
				value: "",
				label: "Username",
				id: "register-username",
				placeholder: "Username",
				required: true,
				maxLength: 32,
			},
			{
				type: "input",
				inputType: "email",
				value: "",
				label: "Email address",
				id: "register-email-address",
				placeholder: "example@email.com",
				required: true,
			},
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "Password",
				id: "register-password",
				placeholder: "Password",
				required: true,
			},
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "Password confirmation",
				id: "register-password-confirmation",
				placeholder: "Password Confirmation",
				required: true,
			},
		];
		if (currentUser.authority > -20) {
			fields.push({
				type: "checkbox",
				value: true,
				label: "Transfer guest data to new account",
			});
		}
		return fields;
	}

	static async submit({ fields }) {
		const [
			username,
			emailAddress,
			password,
			passwordConfirmation,
			transferGuestData,
		] = fields;
		if (password.value != passwordConfirmation.value) {
			return {
				errors: {
					[password.id]: "",
					[passwordConfirmation.id]: "Passwords do not match.",
				},
			};
		}
		return Fetcher.register({
			username: username.value,
			password: password.value,
			emailAddress: emailAddress.value,
			transferGuestData: transferGuestData?.value || false,
		});
	}

	static handleSuccess(json) {
		resetDataStores();
		redirect("");
		currentUserStore.updateCurrentUser(json);
		if (json.account?.email?.confirmed === false) {
			EmailForm.open({
				emailAddress: json.account.email.address,
				emailConfirmed: false,
			});
		}
	}
}

export default RegisterForm;

<script>
	import currentUserStore from "../../../stores/data/currentUserStore";

	import TimeStamp from "../../shared/TimeStamp.svelte";
	import { redirectToTopic } from "../../../util/redirectTo.js";

	export let topic;

	function handleClick() {
		redirectToTopic({
			topic,
			postNumber: "last",
			pageSize: $currentUserStore.settings?.pageSize,
		});
	}
</script>

<TimeStamp
	clickable={true}
	time={topic?.lastPost?.createdAt}
	reversed={true}
	on:click={handleClick}
/>

<script>
	import { clone } from "../../../../../util/objectOperations";

	import Field from "../../../../forms/Field.svelte";
	import Flavor from "./Flavor.svelte";

	export let flavors;
	export let activeFlavorId;
	export let defaultFlavorId;

	$: activeFlavor = flavors.find((flavor) => flavor.id === activeFlavorId);
	$: style = `background-color: ${activeFlavor.secondaryColor}; border-color: ${activeFlavor.primaryColor};`;

	$: flavorItems = flavors.map((flavor) => ({
		value: flavor.id,
		label: flavor.name,
	}));
	$: activeFlavorField = getActiveFlavorField(flavorItems, activeFlavorId);
	$: defaultFlavorField = getDefaultFlavorField(flavorItems, defaultFlavorId);

	function getActiveFlavorField(flavorItems, activeFlavorId) {
		return {
			type: "input",
			inputType: "select",
			items: flavorItems,
			value: activeFlavorId,
			label: "Active Flavor",
			padding: true,
		};
	}

	function getDefaultFlavorField(flavorItems, defaultFlavorId) {
		return {
			type: "input",
			inputType: "select",
			items: flavorItems,
			value: defaultFlavorId,
			label: "Default Flavor",
			padding: true,
		};
	}

	function handleActiveFlavorChange({ detail }) {
		activeFlavorId = detail.value;
	}

	function handleDefaultFlavorChange({ detail }) {
		defaultFlavorId = detail.value;
	}

	function handleFlavorChange(i, detail) {
		flavors[i] = detail.value;
	}

	function duplicateFlavor(i) {
		flavors = [
			...flavors,
			{ ...clone(flavors[i]), duplicatedFrom: flavors[i].id },
		];
	}
</script>

<div class="w-full border-4 p-4 rounded-lg mt-4" {style}>
	<div class="grid grid-cols-2 gap-2">
		{#if activeFlavorField}
			<Field field={activeFlavorField} on:event={handleActiveFlavorChange} />
		{/if}
		{#if defaultFlavorField}
			<Field field={defaultFlavorField} on:event={handleDefaultFlavorChange} />
		{/if}
	</div>
</div>

{#each flavors as flavor, i}
	<Flavor
		{flavor}
		on:event={({ detail }) => handleFlavorChange(i, detail)}
		on:duplicateFlavor={() => duplicateFlavor(i)}
	/>
{/each}

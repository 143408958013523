<script>
	import { createEventDispatcher } from "svelte";

	import ReorderButton from "./ReorderButton.svelte";

	export let sizeStyle = "";
	export let top;
	export let movingIndex = -1;
	export let movingTop = null;
	export let index;
	export let heights;
	export let gapSize;

	const dispatch = createEventDispatcher();

	let left = 0;

	let moving = false;

	let baseTop = top;

	$: disabled = movingIndex > -1 && !moving;

	$: handleIndexChange(index, heights, gapSize);

	$: displayTop = getDisplayTop(top, movingIndex, movingTop);

	function getDisplayTop(top, movingIndex, movingTop) {
		if (movingIndex === -1) return top;
		if (movingIndex === index) return movingTop;
		if (movingIndex > index) {
			if (movingTop < top) return top + heights[movingIndex] + gapSize;
			return top;
		}
		if (movingIndex < index) {
			if (movingTop > top) return top - heights[movingIndex] - gapSize;
			return top;
		}
	}

	function add(a, b) {
		return a + b;
	}

	function handleIndexChange(index, heights, gapSize) {
		if (heights.reduce(add, 0) === 0) return;
		const sumOfHeights = heights.slice(0, index).reduce(add, 0);
		baseTop = sumOfHeights + gapSize * index;
		if (!moving) top = baseTop;
	}

	function onMouseDown() {
		moving = true;
		movingIndex = index;
		movingTop = top;
	}

	function onMouseMove(e) {
		if (moving) {
			left += e.movementX;
			movingTop += e.movementY;
		}
	}

	function onMouseUp() {
		if (!moving) return;
		dispatch("mouseup", { index, top: movingTop });
		moving = false;
		movingIndex = -1;
		movingTop = null;
		left = 0;
	}
</script>

<svelte:window on:mouseup={onMouseUp} on:mousemove={onMouseMove} />

<div
	class="absolute flex gap-2"
	class:z-50={moving}
	style="{sizeStyle} left: {left}px; top: {displayTop}px;"
>
	<div class="flex-grow">
		<slot />
	</div>
	<div on:mousedown={onMouseDown}>
		<ReorderButton {disabled} {moving} />
	</div>
</div>

<script>
	import { createEventDispatcher } from "svelte";
	import Icon from "../../icons/large/Icon.svelte";

	export let level;
	export let levelIndex;
	export let row;
	export let column;

	const dispatch = createEventDispatcher();

	$: value = level.value[row];
	$: valueMin = level.valueMin && level.valueMin[row];
	$: valueMax = level.valueMax && level.valueMax[row];
	$: valueUnlock = level.value[`${row}Unlock`];
	$: valueUnlockMin = level.valueMin && level.valueMin[`${row}Unlock`];
	$: valueUnlockMax = level.valueMax && level.valueMax[`${row}Unlock`];
	$: valueAdd = level.value[`${row}Add`];
	$: valueAddMin = level.valueMin && level.valueMin[`${row}Add`];
	$: valueAddMax = level.valueMax && level.valueMax[`${row}Add`];

	$: authority = {
		admin: 10,
		member: 0,
		guest: -20,
	}[column];

	$: nextUp = {
		admin: 20,
		member: 10,
		guest: 0,
	}[column];

	const iconNamessAndColorClasses = {
		all: ["tick", "text-success"],
		unlock: ["lock", "text-warning"],
		locked: ["lock", "text-error"],
		none: ["x", "text-error"],
	};

	$: status = getStatus(value, valueUnlock, valueAdd);

	$: [iconName, colorClass] = iconNamessAndColorClasses[status];

	$: dataTip = getDataTip(status);

	function getStatus(value, valueUnlock, valueAdd) {
		if (authority >= value) return "all";
		if (valueUnlock != undefined && authority >= valueUnlock) return "unlock";
		if (valueAdd != undefined && authority >= valueAdd) return "locked";
		return "none";
	}

	function getDataTip(status) {
		if (status === "all") return "All";
		if (status === "none") return "None";
		if (status === "locked") return "Only if added by an admin";
		if (row.includes("view")) return "Only with URL";
		return "Only with password";
	}

	function handleClick() {
		let values = {};
		if (status === "none") {
			values[row] = Math.max(authority, valueMin);
			values[`${row}Unlock`] = Math.max(authority, valueUnlockMin);
			values[`${row}Add`] = Math.max(authority, valueAddMin);
		} else {
			if (status === "all") {
				if (valueMax >= nextUp) {
					values[row] = nextUp;
				}
			} else if (status === "unlock") {
				if (valueUnlockMax >= nextUp) {
					values[`${row}Unlock`] = nextUp;
				} else {
					values[row] = Math.max(authority, valueMin);
					// values = bumpToBottom({ values, row });
				}
			} else if (status === "locked") {
				if (valueAddMax >= nextUp) {
					values[`${row}Add`] = nextUp;
				} else {
					values[row] = Math.max(authority, valueMin);
					values[`${row}Unlock`] = Math.max(authority, valueUnlockMin);
				}
			}
		}
		dispatch("click", {
			levelIndex,
			values,
		});
	}

	function bumpToBottom({ values, row }) {
		values[row] = Math.max(authority, valueMin);
		values[`${row}Unlock`] = Math.max(authority, valueUnlockMin);
		values[`${row}Add`] = Math.max(authority, valueAddMin);
		return values;
	}

	function handleRightClick() {
		let values = {};
		if (status === "all") {
			values[row] = Math.min(nextUp, valueMax);
			values[`${row}Unlock`] = Math.min(nextUp, valueUnlockMax);
			values[`${row}Add`] = Math.min(nextUp, valueAddMax);
		} else if (status === "none") {
			if (valueAddMin <= authority) {
				values[`${row}Add`] = authority;
			} else if (valueUnlockMin <= authority) {
				values[`${row}Unlock`] = authority;
			}
		} else if (status === "locked") {
			if (valueUnlockMin <= authority) {
				values[`${row}Unlock`] = authority;
			} else {
				values[`${row}Add`] = Math.min(nextUp, valueAddMax);
			}
		} else if (status === "unlock") {
			if (valueMin <= authority) {
				values[row] = authority;
			} else {
				values[`${row}Unlock`] = Math.min(nextUp, valueUnlockMax);
				values[`${row}Add`] = Math.min(nextUp, valueAddMax);
			}
		}
		dispatch("click", {
			levelIndex,
			values,
		});
	}

	function bumpToTop({ values, row }) {
		values[row] = Math.min(nextUp, valueMax);
		values[`${row}Unlock`] = Math.min(nextUp, valueUnlockMax);
		values[`${row}Add`] = Math.min(nextUp, valueAddMax);
		return values;
	}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
{#if column}
	<div
		class="{colorClass} cursor-pointer tooltip"
		data-tip={dataTip}
		on:click|preventDefault={handleClick}
		on:contextmenu|preventDefault={handleRightClick}
	>
		<Icon name={iconName} />
	</div>
{:else}
	<div class="invisible"><Icon name={iconName} /></div>
{/if}

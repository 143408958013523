<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let value;
	export let scopeType;

	const dispatch = createEventDispatcher();

	$: handleChange(value?.value || value);

	function handleChange({ detail }) {
		if (!detail) return;
		dispatch("event", { value: detail.value, type: "select" });
	}
</script>

<SetupValueSelect
	{value}
	dataType="True or False"
	scopeTypes={["Team", "Player", "Action", "Chat"]}
	on:event={handleChange}
/>

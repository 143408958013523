<script>
	import { getContext } from "svelte";

	import paramsStore from "../../stores/paramsStore";
	import gamesStore from "../../stores/data/gameCreator/gamesStore";
	import topicsStore from "../../stores/data/topicsStore";

	import Badge from "../../components/tailwind/Badge.svelte";

	export let full = false;

	let span;
	const userStylesStore = getContext("userStylesStore");

	$: gameSlug = $paramsStore.gameSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: game = gameSlug && $gamesStore[gameSlug];
	$: topic =
		topicSlug &&
		$topicsStore[gameSlug ? `${gameSlug}/${topicSlug}` : topicSlug];

	$: userStyles =
		$userStylesStore || getUserStylesFromGameAndTopic(game, topic);
	$: userStyle = span && userStyles[span.innerText];
	$: backgroundColor = userStyle?.primaryColor;
	$: textColor = userStyle?.secondaryColor;

	function getUserStylesFromGameAndTopic(game, topic) {
		return {
			...((game && game.userStyles) || {}),
			...((topic && topic.userStyles) || {}),
		};
	}
</script>

<Badge {backgroundColor} {textColor} {full}>
	<span bind:this={span}><slot /></span>
</Badge>

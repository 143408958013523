<script>
	import { onMount } from "svelte";

	import paramsStore from "../../../stores/paramsStore.js";
	import sectionGroupsStore from "../../../stores/data/sectionGroupsStore.js";
	import sectionsStore from "../../../stores/data/sectionsStore.js";

	import Fetcher from "../../../api/Fetcher.js";

	import FormModal from "../../shared/modal/FormModal.svelte";
	import EditSectionForm from "../../../forms/EditSectionForm.js";

	export let section = null;
	export let sectionGroupId = null;
	export let sectionId = null;
	export let buttonClass;
	export let iconName = null;

	$: forumSlug = $paramsStore.domainSlug;
	let sectionSlug;
	$: if ($paramsStore.sectionSlug) sectionSlug = $paramsStore.sectionSlug;

	let sectionGroup;
	let sectionGroupPermissions;
	let sectionPermissions;

	$: args = forumSlug &&
		sectionGroup &&
		section && { forumSlug, sectionGroup, section };

	onMount(handleMount);

	function handleMount() {
		if (!$paramsStore.sectionSlug) return;
		sectionSlug ||= $paramsStore.sectionSlug;
		section ||= $sectionsStore[sectionSlug];
		sectionGroupId ||= section.sectionGroupId;
		sectionGroup ||= $sectionGroupsStore[sectionGroupId];
		if (!sectionGroupPermissions || !sectionPermissions) {
			Fetcher.sectionGroupAndSectionPermissions({
				forumSlug,
				sectionSlug,
			}).then((json) => {
				sectionGroupsStore.updateSectionGroup({
					id: section.sectionGroupId,
					...json.sectionGroup,
				});
				sectionsStore.updateSection({ slug: sectionSlug, ...json.section });
				setSectionAndSectionGroup(sectionSlug, sectionGroupId, sectionId);
			});
		}
	}

	$: setSectionAndSectionGroup(sectionSlug, sectionGroupId, sectionId);

	function setSectionAndSectionGroup(sectionSlug, sectionGroupId, sectionId) {
		if (sectionSlug) {
			section = $sectionsStore[sectionSlug];
		} else if (sectionId) {
			section = Object.values($sectionsStore).find((s) => s.id === sectionId);
		}
		if (sectionGroupId) {
			sectionGroup = $sectionGroupsStore[sectionGroupId];
		} else if (section) {
			sectionGroup = $sectionGroupsStore[section.sectionGroupId];
		}
		sectionGroupPermissions = sectionGroup?.permissions;
		sectionPermissions = section?.permissions;
	}
</script>

{#if args}
	<FormModal
		form={EditSectionForm}
		{args}
		{iconName}
		disabled={!(sectionGroupPermissions && sectionPermissions)}
		{buttonClass}
	/>
{/if}

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let effectLine;
	export let scopeType;

	const dispatch = createEventDispatcher();

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];
	$: chatField = getChatField(effectLine, setup);

	function getChatField(effectLine, setup) {
		return {
			type: "input",
			inputType: "select",
			items: getChatItems(setup),
			value: effectLine.chat,
			padding: true,
			placeholder: "Chat",
		};
	}

	function getChatItems(setup) {
		return setup.chats.map((chat) => ({ value: chat.id, label: chat.name }));
	}

	function handleChatChange({ detail }) {
		dispatch("event", { value: { ...effectLine, chat: detail.value } });
	}

	function handleSetVariableChange({ detail }) {
		dispatch("event", { value: { ...effectLine, setVariable: detail.value } });
	}
</script>

{#if chatField}
	<Field field={chatField} on:event={handleChatChange} />
{:else}
	<div></div>
{/if}
<SetupValueSelect
	value={effectLine.setVariable}
	{scopeType}
	dataType="Chat"
	valueTypes={["Variable"]}
	placeholder="Set Variable"
	on:event={handleSetVariableChange}
/>
<div></div>

import B from "./B.svelte";
import Code from "./Code.svelte";
import Datetime from "./Datetime.svelte";
import Hr from "./Hr.svelte";
import I from "./I.svelte";
import Font from "./Font.svelte";
import Li from "./Li.svelte";
import Link from "./Link.svelte";
import Ol from "./Ol.svelte";
import Pre from "./Pre.svelte";
import Quote from "./Quote.svelte";
import S from "./S.svelte";
import Timediff from "./Timediff.svelte";
import U from "./U.svelte";
import Ul from "./Ul.svelte";

export default {
	b: B,
	code: Code,
	datetime: Datetime,
	hr: Hr,
	i: I,
	font: Font,
	li: Li,
	link: Link,
	ol: Ol,
	pre: Pre,
	quote: Quote,
	s: S,
	timediff: Timediff,
	u: U,
	ul: Ul,
};

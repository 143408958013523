<script>
	import paramsStore from "../stores/paramsStore.js";
	import domainsStore from "../stores/data/domainsStore.js";

	import redirect from "../util/redirect.js";

	import Link from "./tailwind/Link.svelte";
	import DefaultAvatar from "./shared/avatar/defaults/DefaultAvatar.svelte";

	import GameCreator from "./main/gameCreator/GameCreator.svelte";
	import SantoriniShuffler from "./santoriniShuffler/SantoriniShuffler.svelte";

	let component;

	$: slug =
		$paramsStore.domainSlug || $paramsStore.slug || $paramsStore.forumSlug;
	$: domain = $domainsStore[slug];

	const components = {
		"game-creator": GameCreator,
		"santorini-shuffler": SantoriniShuffler,
	};

	$: handleSlugChange(slug);

	function handleSlugChange(slug) {
		if (!slug) return;
		component = components[slug];
		if (!component) redirect("");
	}
</script>

<div class="flex gap-2 mb-2">
	<Link path="{domain.slug}/forum">Forum</Link>
	<div class="grow"></div>
	<div><DefaultAvatar avatar="default {domain?.icon}" small /></div>
</div>
<svelte:component this={component} />

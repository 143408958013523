<script>
	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";
	import ActionVariableInput from "./ActionVariableInput.svelte";

	export let action;
</script>

<Card>
	<CardBody>
		<CardTitle>
			{action.title}
		</CardTitle>
		{#each Object.values(action?.variableInputs || {}) as actionVariableInput}
			<ActionVariableInput {actionVariableInput} />
		{/each}
	</CardBody>
</Card>

<script>
	export let orientation;
	export let location = null;

	$: leftDiv = ["center", "right", "bottom"].includes(location);
	$: rightDiv = ["center", "left", "top"].includes(location);
</script>

{#if location}
	<div
		class="flex"
		class:flex-col={orientation === "vertical"}
		class:h-full={orientation === "vertical"}
		class:w-full={orientation === "horizontal"}
	>
		{#if leftDiv}<div class="grow"></div>{/if}
		<slot />
		{#if rightDiv}<div class="grow"></div>{/if}
	</div>
{:else}
	<slot />
{/if}

import { writable } from "svelte/store";

import DateTime from "../util/DateTime.js";

const { subscribe, set } = writable(DateTime.now());

function update() {
	set(DateTime.now());
}

setInterval(update, 5000);

export default { subscribe };

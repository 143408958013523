<script>
	import { createEventDispatcher } from "svelte";

	import { clone, equal } from "../../../../../util/objectOperations";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Fetcher from "../../../../../api/gameCreator/Fetcher.js";

	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Flavors from "./Flavors.svelte";

	export let activeTab;
	export let setup;
	export let unsaved;

	const dispatch = createEventDispatcher();

	let flavors = [];
	let activeFlavorId, defaultFlavorId;
	let reverting = false;
	let show = true;

	$: handleSetupFlavorsChange(setup.flavors);
	$: unsaved = !equal(
		[flavors, activeFlavorId, defaultFlavorId],
		[setup.flavors, setup.activeFlavor, setup.defaultFlavor]
	);

	function handleSetupFlavorsChange(setupFlavors) {
		if (flavors.length === 0) {
			flavors = clone(setupFlavors);
			activeFlavorId = setup.activeFlavor;
			defaultFlavorId = setup.defaultFlavor;
		}
	}

	function handleSave() {
		if (activeTab !== "Flavors") return;
		Fetcher.updateFlavors({
			setupSlug: setup.slug,
			flavors,
			activeFlavorId,
			defaultFlavorId,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		const activeFlavorChanged = setup.activeFlavor !== activeFlavorId;
		setupsStore.updateSetup(savedSetup);
		flavors = clone(savedSetup.flavors);
		if (activeFlavorChanged)
			setTimeout(() => dispatch("activeFlavorChanged"), 10);
	}

	function handleRevert() {
		flavors = clone(setup.flavors);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
/>

{#if show}
	<Flavors bind:flavors bind:activeFlavorId bind:defaultFlavorId />
{/if}

<script>
	import { createEventDispatcher } from "svelte";
	import Select from "svelte-select";

	export let value;
	export let items;
	export let error = null;
	export let clearable = false;
	export let disabled = false;

	const dispatch = createEventDispatcher();

	let selectValue = value;

	$: if (selectValue.value && value !== selectValue.value) {
		value = selectValue.value;
	}

	$: handleChange(value);

	function handleChange(value) {
		dispatch("change", { value });
		dispatch("event", { type: "change", value });
	}
</script>

<div class="bg-base-100" class:input-error={error != null}>
	<Select
		--background="bg-base-100"
		--list-background="white"
		--item-color="black"
		--item-hover-color="black"
		--border={error == null ? undefined : "1px solid red"}
		bind:value={selectValue}
		{items}
		{clearable}
		{disabled}
	/>
</div>

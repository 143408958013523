<script>
	import layoutStore from "../../stores/layoutStore";

	import Size from "./Size.svelte";

	$: marginTop = $layoutStore.topAndBottomMargins;
	$: marginLeft = $layoutStore.sideWidth;
	$: marginRight = $layoutStore.sideWidth + $layoutStore.scrollbarWidth;
	$: margins = 2 * $layoutStore.sideWidth;
	$: height = $layoutStore.topBarHeight;

	$: style = `margin-top: ${marginTop}px; margin-left: ${marginLeft}px; margin-right: ${marginRight}px; width: calc(100% - ${margins}px); height: ${height}px;`;
</script>

<div
	class="fixed bg-neutral text-neutral-content z-50 px-8 py-3 round-b-lg"
	{style}
>
	<Size />
</div>

import { writable } from "svelte/store";
import uuid from "../util/uuid";

const { subscribe, update } = writable({});

function add(forumSlug, sectionGroups) {
	if (!forumSlug || !sectionGroups) return;
	change(
		forumSlug,
		sectionGroups.map((sectionGroup) => ({
			id: sectionGroup.id,
			title: sectionGroup.title,
			placeholder: sectionGroup.title,
			new: false,
			sections: sectionGroup.sections.map((section) => ({
				id: section.id,
				title: section.title,
				placeholder: section.title,
				new: false,
			})),
		}))
	);
}

function change(forumSlug, value) {
	if (value.includes(undefined)) return;
	update((current) => ({
		...current,
		[forumSlug]: JSON.parse(JSON.stringify(manageNewItems(value))),
	}));
}

function changeSections(forumSlug, sectionGroupIndex, value) {
	if (value.includes(undefined)) return;
	update((current) => {
		current[forumSlug][sectionGroupIndex].sections = JSON.parse(
			JSON.stringify(manageNewItems(value, false))
		);
		return current;
	});
}

function renameSectionGroup(forumSlug, sectionGroupIndex, newTitle) {
	update((current) => {
		current[forumSlug][sectionGroupIndex].title = newTitle;
		current[forumSlug] = JSON.parse(
			JSON.stringify(manageNewItems(current[forumSlug]))
		);
		return current;
	});
}

function renameSection(forumSlug, sectionGroupIndex, sectionIndex, newTitle) {
	update((current) => {
		current[forumSlug][sectionGroupIndex].sections[sectionIndex].title =
			newTitle;
		current[forumSlug][sectionGroupIndex].sections = JSON.parse(
			JSON.stringify(
				manageNewItems(current[forumSlug][sectionGroupIndex].sections, false)
			)
		);
		return current;
	});
}

function moveSection(
	forumSlug,
	sectionGroupIndex,
	sectionIndex,
	newSectionGroupId
) {
	update((current) => {
		const sectionGroup = current[forumSlug][sectionGroupIndex];
		const section = sectionGroup.sections[sectionIndex];
		sectionGroup.sections = [
			...sectionGroup.sections.slice(0, sectionIndex),
			...sectionGroup.sections.slice(sectionIndex + 1),
		];
		const newSectionGroup = current[forumSlug].find(
			(sg) => sg.id === newSectionGroupId
		);
		const lastIndex = newSectionGroup.sections.findLastIndex(
			(s) => s.title.length > 0
		);
		newSectionGroup.sections = [
			...newSectionGroup.sections.slice(0, lastIndex + 1),
			section,
			...newSectionGroup.sections.slice(lastIndex + 1),
		];
		return current;
	});
}

function remove(forumSlug) {
	update((storeValue) => {
		delete storeValue[forumSlug];
		return storeValue;
	});
}

function manageNewItems(value, sections = true) {
	const newValue = addOrRemoveNewItems(value, sections);
	for (let i = 0; i < newValue.length; i++) {
		newValue[i].sections &&= manageNewItems(newValue[i].sections, false);
	}
	return newValue;
}

function addOrRemoveNewItems(value, sections) {
	if (value.includes(undefined)) return;
	const newItems = value.filter((item) => item.new);
	const emptyNewItems = newItems.filter((item) => item.title.length === 0);
	if (emptyNewItems.length === 0) return addNewItem(value, newItems, sections);
	if (emptyNewItems.length > 1) return removeExtraItems(value, emptyNewItems);
	return value;
}

function addNewItem(value, newItems, sections) {
	const currentIdIndexes = newItems.map((item) =>
		parseInt(item.id.split("-")[1])
	);
	return [
		...value,
		{
			id: `new-${uuid()}`,
			new: true,
			title: "",
			placeholder: "New",
			...(sections ? { sections: [] } : {}),
		},
	];
}

function removeExtraItems(value, emptyNewitems) {
	const idsToRemove = emptyNewitems.slice(1).map((x) => x.id);
	return value.filter((item) => !idsToRemove.includes(item.id));
}

export default {
	subscribe,
	update,
	add,
	change,
	changeSections,
	renameSectionGroup,
	renameSection,
	moveSection,
	remove,
};

<script>
	import { createEventDispatcher } from "svelte";

	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import Field from "../../../../forms/Field.svelte";
	import ActionSelect from "../actions/ActionSelect.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let role;

	const dispatch = createEventDispatcher();

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: teamField = getTeamField(role, setup);

	function getTeamField(role, setup) {
		return {
			type: "input",
			inputType: "select",
			label: "Team",
			items: [{ label: "None", value: "None" }, ...getTeamItems(setup)],
			value: role.team,
			padding: true,
		};
	}

	function getTeamItems(setup) {
		return setup.teams.map((team) => ({
			value: team.id,
			label: team.teamFlavor.name,
		}));
	}

	function handleTeamChange({ detail }) {
		dispatch("event", {
			key: "team",
			value: detail.label ? detail.value : value,
		});
	}

	function handleActionChange({ detail }) {
		dispatch("event", {
			key: "action",
			value: detail.label ? detail.value : value,
		});
	}

	function handleWinConditionActiveChange({ detail }) {
		dispatch("event", {
			key: "winConditionActive",
			value: detail.label ? detail.value : value,
		});
	}

	function handleWinConditionPassiveChange({ detail }) {
		dispatch("event", {
			key: "winConditionPassive",
			value: detail.label ? detail.value : value,
		});
	}
</script>

<div class="grid grid-cols-2 gap-2">
	{#if teamField}
		<Field field={teamField} on:event={handleTeamChange} />
	{:else}
		<div></div>
	{/if}
	<ActionSelect
		action={role.action}
		label="Action"
		on:event={handleActionChange}
	/>
	{#if role.team === "None"}
		<SetupValueSelect
			dataType="True or False"
			scopeTypes={["Game", "Team", "Player"]}
			excludedValueTypes={["Constant"]}
			label="Active Win Condition"
			placeholder="Active Win Condition"
			value={role.winConditionActive}
			on:event={handleWinConditionActiveChange}
		/>
		<SetupValueSelect
			dataType="True or False"
			scopeTypes={["Game", "Team", "Player"]}
			excludedValueTypes={["Constant"]}
			label="Passive Win Condition"
			placeholder="Passive Win Condition"
			value={role.winConditionPassive}
			on:event={handleWinConditionPassiveChange}
		/>
	{/if}
</div>

<script>
	import paramsStore from "../../../../stores/paramsStore";
	import forumsStore from "../../../../stores/data/forumsStore";
	import sectionGroupsStore from "../../../../stores/data/sectionGroupsStore";

	import Section from "./Section.svelte";
	import Card from "../../../tailwind/card/Card.svelte";
	import CardBody from "../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../tailwind/card/CardTitle.svelte";
	import SectionsEditor from "../sectionGroupsAndSectionsEditor/SectionsEditor.svelte";

	export let sectionGroupId;
	export let editSections;

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionGroup = $sectionGroupsStore[sectionGroupId] || {};
	$: sectionSlugs = sectionGroup.sectionSlugs || [];
	$: editSgSections = editSections?.find(
		(sg) => sg.id === sectionGroup.id
	).sections;
</script>

<Card>
	<CardBody>
		<CardTitle>
			{sectionGroup.title}
		</CardTitle>
		{#if editSgSections}
			<SectionsEditor {forum} {sectionGroup} editSections={editSgSections} />
		{:else}
			{#each sectionSlugs as sectionSlug (sectionSlug)}
				<Section {sectionSlug} />
			{/each}
		{/if}
	</CardBody>
</Card>

import channel from "../channel.js";
import setupsStore from "../../stores/data/gameCreator/setupsStore.js";

const messageFunctions = { setupSaved };

function setupsChannel(setupSlug) {
	return channel(
		{ channel: "GameCreator::SetupChannel", setup_slug: setupSlug },
		messageFunctions
	);
}

function setupSaved({ setup }) {
	setupsStore.setSetup(setup);
}

export default setupsChannel;

<script>
	import UserBadge from "../../../shared/UserBadge.svelte";
	import Avatar from "../../../shared/avatar/Avatar.svelte";

	export let post;
</script>

<div class="mx-auto max-w-full">
	<UserBadge user={post.user} />
</div>
<div class="mx-auto flex">
	<Avatar
		value={post.user?.profile?.avatarUrl || post.user?.profile?.avatarDefault}
		defaultAvatar={post.user?.profile?.avatarDefault}
	/>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";

	export let condition;
	export let scopeType;

	condition;

	const dispatch = createEventDispatcher();

	const dataTypeItems = [
		"Text",
		"Number",
		"True or False",
		"Team",
		"Role",
		...(scopeType === "Setup" ? [] : ["Player", "Action", "Chat"]),
	];

	const initialField = {
		type: "input",
		inputType: "select",
		items: dataTypeItems,
		placeholder: "Data type",
		padding: true,
	};

	let dataTypeField = { ...initialField };

	function handleEvent({ detail }) {
		dispatch("change", detail);
		dataTypeField = { ...initialField, value: null };
	}
</script>

<Field field={dataTypeField} on:event={handleEvent} />

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";

class NewTopicViewerForm extends Form {
	static title = "New Viewer";
	static code = "new-topic-viewer";
	static submitValue = "Confirm";

	static initialFields({ forumSlug, sectionSlug, topicSlug }) {
		return [
			{
				type: "input",
				inputType: "user",
				value: "",
				label: "Username (type to search)",
				id: "new-viewer-username",
				placeholder: "Username",
				required: true,
			},
			{ forumSlug, sectionSlug, topicSlug },
		];
	}

	static async submit({ fields }) {
		const user = fields[0].value;
		const { forumSlug, sectionSlug, topicSlug } = fields[1];
		return Fetcher.makeTopicViewer({ forumSlug, sectionSlug, topicSlug, user });
	}
}

export default NewTopicViewerForm;

<script>
	import toastsStore from "../../stores/toastsStore";
	import Toast from "./Toast.svelte";
</script>

<div class="toast toast-center">
	{#each $toastsStore as toast}
		<Toast {toast} />
	{/each}
</div>

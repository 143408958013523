<script>
	import { createEventDispatcher } from "svelte";

	import Button from "../../tailwind/Button.svelte";
	import Fetcher from "../../../api/Fetcher";

	export let draftId;
	export let text;
	export let disabled;

	export let confirm = false;

	const dispatch = createEventDispatcher();

	function handleClick() {
		if (!confirm) {
			confirm = true;
			setTimeout(() => (confirm = false), 2500);
			return;
		}
		Fetcher.deleteDraft({ draftId }).then(handleDraftDeleted);
	}

	function handleDraftDeleted() {
		draftId = null;
		text = "";
		dispatch("focusTextarea");
	}
</script>

<Button
	className="error"
	extraClasses="btn-xs"
	{disabled}
	on:click={handleClick}
>
	{confirm ? "Sure?" : "Delete"}
</Button>

import { writable } from "svelte/store";

const { subscribe, set, update } = writable({});

function reset() {
	set({});
}

function updateCurrentUser(newCurrentUser) {
	update((currentUser) => ({
		...currentUser,
		...newCurrentUser,
	}));
}

export default {
	subscribe,
	reset,
	updateCurrentUser,
};

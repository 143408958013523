<script>
	import toastsStore from "../../stores/toastsStore";
	import layoutStore from "../../stores/layoutStore";

	import Toast from "./Toast.svelte";

	$: menuOrientation = $layoutStore?.menuOrientation;
</script>

<div
	class="toast toast-center z-50"
	class:mb-16={menuOrientation === "horizontal"}
>
	{#each $toastsStore as toast}
		<Toast {toast} />
	{/each}
</div>

<script>
	import paramsStore from "../../../../stores/paramsStore";
	import sectionsStore from "../../../../stores/data/sectionsStore";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";

	import CreateTopic from "./CreateTopic.svelte";
	import CreateTopicUnlocker from "./CreateTopicUnlocker.svelte";

	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: userPermissions = section?.userPermissions || {};
</script>

<Display title="New">
	<Transition>
		{#if userPermissions.createTopic}
			<CreateTopic {section} />
		{:else if userPermissions.createTopicUnlock}
			<CreateTopicUnlocker {section} />
		{/if}
	</Transition>
</Display>

<script>
	import topicsStore from "../../../../stores/data/topicsStore.js";
	import layoutStore from "../../../../stores/layoutStore.js";

	import Badge from "../../../tailwind/Badge.svelte";
	import UserBadge from "../../../shared/UserBadge.svelte";
	import TopicBadge from "../TopicBadge.svelte";
	import LastPostBadge from "../LastPostBadge.svelte";
	import Align from "../../../tailwind/align/Align.svelte";

	export let topicSlug = null;

	$: topic = $topicsStore[topicSlug];
	$: lastPoster = topic?.lastPost?.user;
</script>

<div
	class="{$layoutStore.menuOrientation === 'vertical'
		? 'col-span-6 lg:col-span-8'
		: 'col-span-8'} bg-base-100 rounded-l-lg pl-4 pr-2 py-2"
>
	{#if topic}
		<div class="max-w-full">
			<TopicBadge {topic} />
		</div>
		{#if lastPoster}
			<div>
				Last post:
				<LastPostBadge {topic} /> by <UserBadge
					user={lastPoster}
					inLine={true}
				/>
			</div>
		{/if}
	{:else}
		<Align vertical="center">
			<Badge colorName="default">Topic</Badge>
		</Align>
	{/if}
</div>
{#if $layoutStore.menuOrientation === "vertical"}
	<div class="bg-base-100 pr-2 py-2 col-span-3 lg:col-span-2">
		<Align both="center">
			{#if topic}
				<UserBadge user={topic.startedBy} />
			{:else}
				<Badge colorName="default">Started by</Badge>
			{/if}
		</Align>
	</div>
	<div class="bg-base-100 rounded-r-lg pr-2 py-2 col-span-3 lg:col-span-2">
		<Align both="center">
			{#if topic}
				<Badge>{topic.postCount}</Badge>
			{:else}
				<Badge colorName="default">Posts</Badge>
			{/if}
		</Align>
	</div>
{:else}
	<div class="bg-base-100 pr-2 py-2 col-span-4">
		<Align both="center">
			<div class="flex flex-col gap-1">
				<Align both="center">
					{#if topic}
						<UserBadge user={topic.startedBy} />
					{:else}
						<Badge colorName="default">Started by</Badge>
					{/if}
				</Align>
				<Align both="center">
					{#if topic}
						<Badge>{topic.postCount}</Badge>
					{:else}
						<Badge colorName="default">Posts</Badge>
					{/if}
				</Align>
			</div>
		</Align>
	</div>
{/if}

class BbCodeInsertion {
	constructor(bbCode) {
		const code = bbCode.code;
		const params = bbCode.params ? ` ${bbCode.params}` : "";
		const n = bbCode.newLines ? "\n" : "";
		this.pattern = `[${code}${params}]${n}$1${n}[/${code}]`;
		[this.startTag, this.endTag] = this.pattern.split("$1");
		this.maxTagLength = Math.max(this.startTag.length, this.endTag.length);
		this.singleSelection = !!bbCode.singleSelection;
		this.innerFunc = bbCode.innerFunc || null;
	}

	insert(text, selectionStart, selectionEnd) {
		if (this.singleSelection) selectionEnd = selectionStart;
		let beginning = text.slice(0, selectionStart);
		let middle = text.slice(selectionStart, selectionEnd);
		let end = text.slice(selectionEnd);

		[beginning, middle, end] = this.correctForSelectionsInsideTags(
			beginning,
			middle,
			end
		);

		[beginning, middle, end] = this.doInsertion(beginning, middle, end);

		beginning = this.clearEmptyTagsFromEnd(
			beginning,
			this.startTag,
			this.endTag
		);
		// beginning = this.clearEmptyTagsFromEnd(
		// 	beginning,
		// 	this.endTag,
		// 	this.startTag
		// );
		// middle = this.clearEmptyTags(middle);
		end = this.clearEmptyTagsFromStart(end, this.startTag, this.endTag);
		// end = this.clearEmptyTagsFromStart(end, this.endTag, this.startTag);

		if (this.innerFunc) {
			middle = this.innerFunc();
		}

		const newText = beginning + middle + end;
		let newSelectionStart, newSelectionEnd;
		if (this.singleSelection) {
			newSelectionStart = newText.length;
			newSelectionEnd = newSelectionStart;
		} else {
			newSelectionStart = beginning.length;
			newSelectionEnd = newSelectionStart + middle.length;
		}

		return { newText, newSelectionRange: [newSelectionStart, newSelectionEnd] };
	}

	doInsertion(beginning, middle, end) {
		const lastStartTagInBeginning = beginning.lastIndexOf(this.startTag);
		const lastEndTagInBeginning = beginning.lastIndexOf(this.endTag);

		const middleStartsInTags = lastStartTagInBeginning > lastEndTagInBeginning;

		const lastStartTagInMiddle = middle.lastIndexOf(this.startTag);
		const lastEndTagInMiddle = middle.lastIndexOf(this.endTag);
		const middleEndsInTags =
			lastStartTagInMiddle > lastEndTagInMiddle ||
			(middleStartsInTags && lastEndTagInMiddle === -1);

		if (middleStartsInTags) {
			return this.doInsertionStartingInTags(
				beginning,
				middle,
				end,
				middleEndsInTags
			);
		}
		return this.doInsertionNotStartingInTags(
			beginning,
			middle,
			end,
			middleEndsInTags
		);
	}

	doInsertionStartingInTags(beginning, middle, end, middleEndsInTags) {
		const firstEndTagInMiddle = middle.indexOf(this.endTag);
		middle = this.clearTags(middle);
		if (middleEndsInTags) {
			if (firstEndTagInMiddle === -1) {
				beginning += this.endTag;
				end = this.startTag + end;
			}
		} else {
			end = this.endTag + end;
		}
		return [beginning, middle, end];
	}

	doInsertionNotStartingInTags(beginning, middle, end, middleEndsInTags) {
		if (middleEndsInTags) {
			middle = this.clearTags(middle);
			beginning += this.startTag;
		} else {
			middle = this.clearTags(middle);
			beginning += this.startTag;
			end = this.endTag + end;
		}
		return [beginning, middle, end];
	}

	clearTags(text) {
		return text.replace(this.startTag, "").replace(this.endTag, "");
	}

	clearEmptyTags(text) {
		// return text;
		// .replace(this.endTag + this.startTag, "")
		// .replace(this.startTag + this.endTag, "");
		// text = this.clearTagsContainingOnlyBbCode(text, this.startTag, this.endTag);
		// text = this.clearTagsContainingOnlyBbCode(text, this.endTag, this.startTag);
		return text;
	}

	clearEmptyTagsFromStart(text, startTag, endTag) {
		if (!text.startsWith(startTag)) return text;
		const indexOfEndTag = text.indexOf(endTag);
		if (indexOfEndTag < 0) return text;
		const innerText = text.slice(startTag.length, indexOfEndTag);
		if (!this.onlyBbCode(innerText)) return text;
		const afterText = text.slice(indexOfEndTag + endTag.length);
		return innerText + afterText;
	}

	clearEmptyTagsFromEnd(text, startTag, endTag) {
		if (!text.endsWith(endTag)) return text;
		const indexOfStartTag = text.lastIndexOf(startTag);
		if (indexOfStartTag < 0) return text;
		const innerText = text.slice(
			indexOfStartTag + startTag.length,
			text.length - endTag.length
		);
		if (!this.onlyBbCode(innerText)) return text;
		const beforeText = text.slice(0, indexOfStartTag);
		return beforeText + innerText;
	}

	onlyBbCode(text) {
		let inTag = false;
		for (let i = 0; i < text.length; i++) {
			if (text[i] === "[" && !inTag) {
				inTag = true;
			} else if (text[i] === "]" && inTag) {
				inTag = false;
			} else if (!inTag) {
				return false;
			}
		}
		return true;
	}

	clearTagsContainingOnlyBbCode(text, start, end) {
		let newText = "";
		let i = 0;
		let k = 0;
		while (i < text.length && k < 1000) {
			if (this.hasTagAt(text, i, start)) {
				for (let j = i + start.length; j < text.length; j++) {
					if (this.hasTagAt(text, j, end)) {
						newText +=
							text.slice(0, i) +
							text.slice(i + start.length, j) +
							text.slice(j + end.length);
						i = j + end.length;
						break;
					}
				}
			} else {
				newText += text[i];
				i++;
			}
			k++;
		}
	}

	hasTagAt(text, i, tag) {
		return text.slice(i, i + tag.length) === tag;
	}

	hasStartTagAt(text, i) {
		return this.hasTagAt(text, i, this.startTag);
	}

	hasEndTagAt(text, i) {
		return this.hasTagAt(text, i, this.endTag);
	}

	correctForSelectionsInsideTags(beginning, middle, end) {
		[beginning, middle] = this.correctForSelectionStartInsideTag(
			beginning,
			middle
		);
		[middle, end] = this.correctForSelectionEndInsideTag(middle, end);
		return [beginning, middle, end];
	}

	correctForSelectionStartInsideTag(beginning, middle) {
		for (let i = 0; i < this.maxTagLength; i++) {
			const newBeginning = beginning + middle.slice(0, i);
			const newMiddle = middle.slice(i);
			if (
				(newBeginning.endsWith(this.startTag) && i < this.startTag.length) ||
				(newBeginning.endsWith(this.endTag) && i < this.endTag.length)
			) {
				return [newBeginning, newMiddle];
			}
		}
		return [beginning, middle];
	}

	correctForSelectionEndInsideTag(middle, end) {
		for (let i = 1; i <= this.maxTagLength; i++) {
			const newMiddle = i === 0 ? "" : middle.slice(0, -i);
			const newEnd = middle.slice(-i) + end;
			if (
				(newEnd.startsWith(this.startTag) && i < this.startTag.length) ||
				(newEnd.startsWith(this.endTag) && i < this.endTag.length)
			) {
				return [newMiddle, newEnd];
			}
		}
		return [middle, end];
	}
}

export default BbCodeInsertion;

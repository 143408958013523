<script>
	import { onDestroy } from "svelte";

	import paramsStore from "../../../../stores/paramsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";
	import itemsCountStore from "../../../../stores/itemsCountStore.js";
	import documentVisibleStore from "../../../../stores/documentVisibleStore.js";

	import sectionChannel from "../../../../channels/sectionChannel.js";

	import { flip } from "svelte/animate";
	import { quintOut } from "svelte/easing";

	import Topic from "./Topic.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: pageNumber = $paramsStore.pageNumber;
	$: pageSize = 50;
	$: offset = pageSize * (pageNumber - 1) || 0;
	$: topicSlugs = section?.topicSlugs.slice(offset, offset + pageSize) || [];
	$: userAuthority = section?.userAuthority;

	let displayedTopicSlugs = [];
	let freezeTopics = false;

	const gridClass = "grid grid-cols-12 gap-y-4 mt-4";

	let channel;
	let pageParams = {};

	$: itemsCountStore.set(section?.topicCount || 0);

	$: handleParamsChange(
		forumSlug,
		sectionSlug,
		pageNumber,
		$documentVisibleStore
	);
	$: handlePage(forumSlug, sectionSlug, pageNumber, $documentVisibleStore);

	$: manageDisplayedTopicsSlugs(topicSlugs, freezeTopics);

	function handleParamsChange(
		forumSlug,
		sectionSlug,
		pageNumber,
		documentVisible
	) {
		if (!documentVisible) return;
		sectionsStore.fetchSection({ forumSlug, sectionSlug });
	}

	$: manageChannel(sectionSlug, userAuthority, pageNumber);

	function manageChannel(sectionSlug, userAuthority, pageNumber) {
		channel?.unsubscribe();
		if (!sectionSlug || userAuthority == undefined || pageNumber !== 1) return;
		channel = sectionChannel(sectionSlug, userAuthority);
	}

	function handlePage(forumSlug, sectionSlug, pageNumber, documentVisible) {
		if (
			[forumSlug, sectionSlug, pageNumber].includes(undefined) ||
			!documentVisible
		)
			return;
		pageParams = {
			forumSlug,
			sectionSlug,
			pageNumber,
		};
		sectionsStore.fetchPage(forumSlug, sectionSlug, pageNumber);
	}

	function manageDisplayedTopicsSlugs(topicSlugs, freezeTopics) {
		if (freezeTopics) return;
		displayedTopicSlugs = topicSlugs;
	}

	onDestroy(handleDestroy);

	function handleDestroy() {
		channel?.unsubscribe();
	}
</script>

<div class={gridClass}>
	<Topic />
</div>
{#each displayedTopicSlugs.filter(Boolean) as topicSlug (topicSlug)}
	<!-- svelte-ignore a11y-mouse-events-have-key-events -->
	<div
		class={gridClass}
		on:mouseenter={() => {
			freezeTopics = true;
		}}
		on:mouseleave={() => {
			freezeTopics = false;
		}}
		animate:flip={{ delay: 100, duration: 750, easing: quintOut }}
	>
		<Topic {topicSlug} />
	</div>
{/each}

<script>
	import { createEventDispatcher } from "svelte";

	import EffectLine from "./EffectLine.svelte";
	import Switch from "../values/Switch.svelte";

	const dispatch = createEventDispatcher();

	export let effect;
	export let scopeType;
	export let effectId;

	$: effectLines = getEffectLines(effect);

	function getEffectLines(effect) {
		if (!effect.effectLines) return [];
		if (
			effect.effectLines.find((effectLine) =>
				[null, ""].includes(effectLine?.effectLineType)
			)
		) {
			return [...effect.effectLines];
		}
		return [
			...effect.effectLines,
			{
				effectLineType: null,
			},
		];
	}

	function handleEffectLineChange(i, detail) {
		effect.effectLines[i] = detail.value;
		dispatch("event", { value: effect });
	}

	function handleSwitchChange({ detail }) {
		dispatch("event", { value: { ...effect, switch: detail.value } });
	}
</script>

{#if effect.effectType === "Normal" && effect.effectLines}
	{#each effectLines as effectLine, i}
		<EffectLine
			{effectLine}
			{scopeType}
			{effectId}
			on:event={({ detail }) => handleEffectLineChange(i, detail)}
		/>
	{/each}
{:else if effect.effectType === "Switch"}
	<Switch
		switchValue={effect.switch}
		{scopeType}
		valueId={effect.id}
		valueDataType="Effect"
		on:event={handleSwitchChange}
	/>
{/if}

<script>
	import { getContext, createEventDispatcher } from "svelte";

	import layoutStore from "../../../stores/layoutStore";

	const dispatch = createEventDispatcher();

	export let selected = false;
	export let indicator = null;

	const side = getContext("side");

	$: menuOrientation = $layoutStore.menuOrientation;
	$: indicatorStart = side === "right" && menuOrientation === "vertical";
</script>

<div class="mx-1 sm:mx-3 md:my-3 md:mx-0 indicator">
	{#if indicator && indicator.value}
		<div
			class="indicator-item badge badge-sm sm:badge-md {indicator.class ||
				'badge-secondary'}"
			class:indicator-start={indicatorStart}
		>
			{indicator.value}
		</div>
	{/if}
	<button
		class="btn btn-sm sm:btn-md {selected
			? 'btn-circle'
			: 'btn-square btn-outline'} btn-primary m-1"
		on:click={() => dispatch("click")}
		on:mouseenter={() => dispatch("mouseenter")}
		on:mouseleave={() => dispatch("mouseleave")}
	>
		<slot />
	</button>
</div>

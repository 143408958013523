<script>
	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore.js";

	import Display from "../../../shared/Display.svelte";
	import Transition from "../../../shared/Transition.svelte";

	import FormModal from "../../../../shared/modal/FormModal.svelte";

	import NewTestGameForm from "../../../../../forms/gameCreator/NewTestGameForm.js";

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];
</script>

<Display title="New">
	{#if setup}
		<Transition>
			<FormModal form={NewTestGameForm} args={{ setup }} />
		</Transition>
	{/if}
</Display>

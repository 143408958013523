<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import NewAdmin from "./NewAdmin.svelte";
	import Admin from "./Admin.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
</script>

<Display title="Admins">
	<div class="flex flex-col gap-2">
		{#each forum?.admins || [] as user (user.slug)}
			<Transition>
				<Admin {user} />
			</Transition>
		{/each}
	</div>
	{#if forum?.userPermissions?.makeAdmin}
		<Transition>
			<div class="mt-2">
				<NewAdmin />
			</div>
		</Transition>
	{/if}
</Display>

import { writable } from "svelte/store";

const closedState = {
	open: false,
	code: "",
	pages: [],
	fields: [],
	errors: [],
	submit: () => {},
};

const { subscribe, update } = writable(closedState);

function open(newValue) {
	update((currentValue) => {
		if (currentValue.open) {
			return {
				...currentValue,
				next: newValue,
			};
		}
		return newValue;
	});
}

function close() {
	setTimeout(
		() => update((currentValue) => currentValue.next || closedState),
		250
	);
}

export default { subscribe, open, close, update };

<script>
	import { createEventDispatcher } from "svelte";
	import Input from "./Input.svelte";

	export let field = null;
	export let error = null;

	const dispatch = createEventDispatcher();

	$: daysField = getDaysField(field);
	$: hoursField = getHoursField(field);
	$: minutesField = getMinutesField(field);
	$: secondsField = getSecondsField(field);
	$: roundDaysField = getRoundDaysField(field);
	$: roundHoursField = getRoundHoursField(field);
	$: roundMinutesField = getRoundMinutesField(field);
	$: roundSecondsField = getRoundSecondsField(field);
	$: offsetDaysField = getOffsetDaysField(field);
	$: offsetHoursField = getOffsetHoursField(field);
	$: offsetMinutesField = getOffsetMinutesField(field);
	$: offsetSecondsField = getOffsetSecondsField(field);
	$: roundTypeField = getRoundTypeField(field);

	function getDaysField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.days,
			min: 0,
			label: "Days",
			placeholder: "Days",
		};
	}

	function getHoursField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.hours,
			min: 0,
			label: "Hours",
			placeholder: "Hours",
		};
	}

	function getMinutesField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.minutes,
			min: 0,
			label: "Minutes",
			placeholder: "Minutes",
		};
	}

	function getSecondsField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.seconds,
			min: 0,
			label: "Seconds",
			placeholder: "Seconds",
		};
	}

	function getRoundDaysField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.roundDays,
			min: 0,
			label: "Round Days",
			placeholder: "Round Days",
		};
	}

	function getRoundHoursField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.roundHours,
			min: 0,
			label: "Round Hours",
			placeholder: "Round Hours",
		};
	}

	function getRoundMinutesField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.roundMinutes,
			min: 0,
			label: "Round Minutes",
			placeholder: "Round Minutes",
		};
	}

	function getRoundSecondsField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.roundSeconds,
			min: 0,
			label: "Round Seconds",
			placeholder: "Round Seconds",
		};
	}

	function getOffsetDaysField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.offsetDays,
			min: 0,
			label: "Offset Days",
			placeholder: "Offset Days",
		};
	}

	function getOffsetHoursField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.offsetHours,
			min: 0,
			label: "Offset Hours",
			placeholder: "Offset Hours",
		};
	}

	function getOffsetMinutesField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.offsetMinutes,
			min: 0,
			label: "Offset Minutes",
			placeholder: "Offset Minutes",
		};
	}

	function getOffsetSecondsField(field) {
		return {
			type: "input",
			inputType: "number",
			value: field.value.offsetSeconds,
			min: 0,
			label: "Offset Seconds",
			placeholder: "Offset Seconds",
		};
	}

	function getRoundTypeField(field) {
		return {
			type: "input",
			inputType: "select",
			items: ["Up", "Closest", "Down"],
			value: field.value.roundType,
			label: "Round Type",
			placeholder: "Round Type",
			padding: true,
		};
	}

	function dispatchValue() {
		dispatch("click", { value: field.value });
		dispatch("event", { type: "change", value: field.value });
	}

	function handleDaysChange({ detail }) {
		field.value.days = detail.value;
		dispatchValue();
	}

	function handleHoursChange({ detail }) {
		field.value.hours = detail.value;
		dispatchValue();
	}

	function handleMinutesChange({ detail }) {
		field.value.minutes = detail.value;
		dispatchValue();
	}

	function handleSecondsChange({ detail }) {
		field.value.seconds = detail.value;
		dispatchValue();
	}

	function handleRoundDaysChange({ detail }) {
		field.value.roundDays = detail.value;
		dispatchValue();
	}

	function handleRoundHoursChange({ detail }) {
		field.value.roundHours = detail.value;
		dispatchValue();
	}

	function handleRoundMinutesChange({ detail }) {
		field.value.roundMinutes = detail.value;
		dispatchValue();
	}

	function handleRoundSecondsChange({ detail }) {
		field.value.roundSeconds = detail.value;
		dispatchValue();
	}

	function handleOffsetDaysChange({ detail }) {
		field.value.offsetDays = detail.value;
		dispatchValue();
	}

	function handleOffsetHoursChange({ detail }) {
		field.value.offsetHours = detail.value;
		dispatchValue();
	}

	function handleOffsetMinutesChange({ detail }) {
		field.value.offsetMinutes = detail.value;
		dispatchValue();
	}

	function handleOffsetSecondsChange({ detail }) {
		field.value.offsetSeconds = detail.value;
		dispatchValue();
	}

	function handleRoundTypeChange({ detail }) {
		field.value.roundType = detail.value;
		dispatchValue();
	}
</script>

<div class="mt-2">
	{#if field.label}
		<div class="text-sm">{field.label}</div>
	{/if}
	<div class="w-full grid grid-cols-4 gap-2">
		<Input field={daysField} bind:error on:event={handleDaysChange} />
		<Input field={hoursField} bind:error on:event={handleHoursChange} />
		<Input field={minutesField} bind:error on:event={handleMinutesChange} />
		<Input field={secondsField} bind:error on:event={handleSecondsChange} />
	</div>
	<div class="w-full grid grid-cols-4 gap-2">
		<Input field={roundDaysField} bind:error on:event={handleRoundDaysChange} />
		<Input
			field={roundHoursField}
			bind:error
			on:event={handleRoundHoursChange}
		/>
		<Input
			field={roundMinutesField}
			bind:error
			on:event={handleRoundMinutesChange}
		/>
		<Input
			field={roundSecondsField}
			bind:error
			on:event={handleRoundSecondsChange}
		/>
	</div>
	<div class="w-full grid grid-cols-4 gap-2">
		<Input
			field={offsetDaysField}
			bind:error
			on:event={handleOffsetDaysChange}
		/>
		<Input
			field={offsetHoursField}
			bind:error
			on:event={handleOffsetHoursChange}
		/>
		<Input
			field={offsetMinutesField}
			bind:error
			on:event={handleOffsetMinutesChange}
		/>
		<Input
			field={offsetSecondsField}
			bind:error
			on:event={handleOffsetSecondsChange}
		/>
	</div>
	<div class="w-full grid grid-cols-3 gap-2 mt-2">
		<Input field={roundTypeField} bind:error on:event={handleRoundTypeChange} />
	</div>
</div>

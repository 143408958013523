export default function getValueTypeItems(
	dataType,
	excludeVariable,
	excludeConstant,
	isFlavorText
) {
	if (!dataType || dataType.length === 0) return [];
	if (dataType === "Text") {
		if (isFlavorText) {
			return [...(excludeConstant ? [] : ["Constant"])];
		}
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Concatenation",
			"List Concatenation",
			"Switch",
		];
	}
	if (dataType === "Number") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Count",
			"Sum",
			"Calculation",
			"Switch",
		];
	}
	if (dataType === "True or False") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Condition",
			"Switch",
		];
	}
	if (dataType === "Duration") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
		];
	}
	if (dataType === "Team") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Switch",
		];
	}
	if (dataType === "Role") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Switch",
		];
	}
	if (dataType === "Player") {
		return [...(excludeVariable ? [] : ["Variable"]), "Switch"];
	}
	if (dataType === "Action") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Switch",
		];
	}
	if (dataType === "Chat") {
		return [
			...(excludeConstant ? [] : ["Constant"]),
			...(excludeVariable ? [] : ["Variable"]),
			"Switch",
		];
	}
	if (dataType.slice(0, 8) === "List of ") {
		return ["List", "Filter", "Switch"];
	}
	// return [];
}

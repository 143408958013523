<script>
	export let field;
	export let error;
	field;
	error;
</script>

<input
	type="file"
	class="file-input file-input-bordered w-full max-w-xs mb-2"
	on:change
/>

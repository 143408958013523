import { writable } from "svelte/store";

import Fetcher from "../../../api/gameCreator/Fetcher.js";

const { subscribe, update } = writable({});

function fetchGame(gameSlug) {
	Fetcher.getGame(gameSlug).then(setGame);
}

function setGame(newGame) {
	update((games) => {
		games[newGame.slug] = newGame;
		return games;
	});
}

export default {
	subscribe,
	fetchGame,
	setGame,
};

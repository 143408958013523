<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let field;
	export let error = null;

	$: checkboxClass = `checkbox ${
		error ? "checkbox checkbox-error" : "checkbox-primary"
	}`;

	const handleClick = () => {
		field.value = !field.value;
		dispatch("click", { value: field.value });
		dispatch("event", { type: "check", value: field.value });
	};
</script>

{#if field.label}
	<div class="form-control">
		<label class="cursor-pointer label">
			<span class="label-text">{field.label}</span>
			<input
				type="checkbox"
				checked={field.value}
				class={checkboxClass}
				on:click={handleClick}
			/>
		</label>
	</div>
{:else}
	<input
		type="checkbox"
		checked={field.value}
		class={checkboxClass}
		on:click={handleClick}
	/>
{/if}

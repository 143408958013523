<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";

	import Button from "../../../tailwind/Button.svelte";
	import TopicViewers from "./TopicViewers.svelte";
	import TopicPosters from "./TopicPosters.svelte";

	let title = "Users";

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: userPermissions = topic?.userPermissions || {};
</script>

<Display {title}>
	<Transition>
		{#if title === "Users"}
			{#if userPermissions.addViewers}
				<div class="mb-2">
					<Button extraClasses="w-full" on:click={() => (title = "Viewers")}>
						Viewers
					</Button>
				</div>
			{/if}
			{#if userPermissions.addPosters}
				<Button extraClasses="w-full" on:click={() => (title = "Posters")}>
					Posters
				</Button>
			{/if}
		{:else}
			{#if title === "Viewers"}
				<TopicViewers />
			{:else if title === "Posters"}
				<TopicPosters />
			{/if}
			<div class="mt-2">
				<Button
					className="neutral"
					extraClasses="w-full"
					on:click={() => (title = "Users")}
				>
					Done
				</Button>
			</div>
		{/if}
	</Transition>
</Display>

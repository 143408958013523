<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import Tabs from "../../../../shared/Tabs.svelte";
	import TabPage from "../../../../shared/TabPage.svelte";
	import StandardConstants from "./StandardConstants.svelte";
	import Permissions from "./Permissions.svelte";
	import ObjectValueInputs from "../ObjectValueInputs.svelte";

	export let chat;
	export let chatConstants;
	export let chatVariables;

	const dispatch = createEventDispatcher();

	const tabs = ["Constants", "Initial Variables", "Permissions"];

	let activeTab = "Constants";

	$: nameField = getNameField(chat);
	$: chatTypeField = getChatTypeField(chat);

	function getNameField(chat) {
		return {
			type: "input",
			inputType: "text",
			maxLength: 32,
			value: chat.name,
			placeholder: "Name",
		};
	}

	function getChatTypeField(chat) {
		if (!chat?.name?.length > 0) return null;
		return {
			type: "input",
			inputType: "select",
			items: ["Default", "Topic"],
			value: chat.chatType,
			padding: true,
		};
	}

	function handleNameChange({ detail }) {
		if (detail.type !== "input") return;
		dispatch("event", { value: { ...chat, name: detail.value } });
	}

	function handleChatTypeChange({ detail }) {
		dispatch("event", { value: { ...chat, chatType: detail.value } });
	}

	function handleChatFlavorChange({ detail }) {
		dispatch("event", { value: { ...chat, chatFlavor: detail.value } });
	}

	function handlePermissionsChange({ detail }) {
		dispatch("event", { value: { ...chat, permissions: detail.value } });
	}

	function handleConstantsChange({ detail }) {
		dispatch("event", { value: { ...chat, constants: detail.value } });
	}

	function handleVariablesChange({ detail }) {
		dispatch("event", { value: { ...chat, variables: detail.value } });
	}
</script>

<Card>
	<CardBody>
		<div class="grid grid-cols-4 gap-2">
			{#if nameField}
				<Field field={nameField} on:event={handleNameChange} />
			{/if}
			{#if chatTypeField}
				<Field field={chatTypeField} on:event={handleChatTypeChange} />
			{/if}
		</div>
		{#if chat?.name?.length > 0}
			<Tabs {tabs} bind:activeTab bordered />
			<TabPage tab="Flavor" {activeTab}></TabPage>
			<TabPage tab="Permissions" {activeTab}>
				<Permissions
					permissions={chat.permissions}
					on:event={handlePermissionsChange}
				/>
			</TabPage>
			<TabPage tab="Constants" {activeTab}>
				<div class="flex flex-col gap-2">
					<StandardConstants {chat} on:event />
					<ObjectValueInputs
						objectValues={chat.constants}
						setupValues={chatConstants}
						scopeType="Chat"
						on:event={handleConstantsChange}
					/>
				</div>
			</TabPage>
			<TabPage tab="Initial Variables" {activeTab}>
				<ObjectValueInputs
					objectValues={chat.variables}
					setupValues={chatVariables}
					scopeType="Chat"
					on:event={handleVariablesChange}
				/>
			</TabPage>
		{/if}
	</CardBody>
</Card>

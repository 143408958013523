import Index from "../index/Index.svelte";
import Members from "../members/Members.svelte";
import Acknowledgements from "../acknowledgements/Acknowledgements.svelte";
import DomainPage from "../../DomainPage.svelte";

import forumRoutes from "./forumRoutes.js";
import gameCreatorRoutes from "./gameCreatorRoutes.js";

const basicRoutes = [
	{ name: "index", path: "/", component: Index },
	{ name: "users", path: "/members", component: Members },
	{
		name: "acknowledgements",
		path: "/acknowledgements",
		component: Acknowledgements,
	},
	{
		name: "domainPage",
		path: "/:slug",
		component: DomainPage,
	},
];

const routes = [...basicRoutes, ...forumRoutes, ...gameCreatorRoutes];

export default routes;

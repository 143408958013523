<script>
	import paramsStore from "../../../../stores/paramsStore.js";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import NewTopicViewerForm from "../../../../forms/NewTopicViewerForm.js";

	$: forumSlug = $paramsStore.forumSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: topicSlug = $paramsStore.topicSlug;

	$: args = forumSlug &&
		sectionSlug &&
		topicSlug && { forumSlug, sectionSlug, topicSlug };
</script>

{#if args}
	<FormModal form={NewTopicViewerForm} {args} />
{/if}

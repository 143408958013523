import channel from "./channel.js";
import topicsStore from "../stores/data/topicsStore.js";

const messageFunctions = {
	topicUpdate,
	refetchTopic,
	newPost,
};

function topicChannel(topicSlug) {
	return channel(
		{ channel: "TopicChannel", topic_slug: topicSlug },
		messageFunctions
	);
}

function topicUpdate(topic, { topic_slug }) {
	topicsStore.setTopicValues({
		slug: topic_slug,
		...topic,
	});
}

function refetchTopic(slugs) {
	topicsStore.fetchTopic(slugs);
}

function newPost({ post }, { topic_slug: topicSlug }) {
	topicsStore.addPost({ topicSlug, post });
}

export default topicChannel;

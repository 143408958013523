import { writable } from "svelte/store";
import sectionsStore from "./sectionsStore";
import { mergeObjects } from "./merge";

const { subscribe, set, update } = writable({});

function reset() {
	set({});
}

function updateSectionGroups(newSectionGroups, replace = []) {
	update((sectionGroups) => {
		for (const newSectionGroup of newSectionGroups) {
			sectionGroups[newSectionGroup.id] = mergeNewSectionGroup({
				sectionGroups,
				newSectionGroup,
				replace,
			});
		}
		return sectionGroups;
	});
}

function updateSectionGroup(newSectionGroup, replace = []) {
	update((sectionGroups) => {
		sectionGroups[newSectionGroup.id] = mergeNewSectionGroup({
			sectionGroups,
			newSectionGroup,
			replace,
		});
		return sectionGroups;
	});
}

function mergeNewSectionGroup({
	sectionGroups,
	newSectionGroups,
	id,
	newSectionGroup,
	replace,
}) {
	newSectionGroup ||= newSectionGroups[id];
	id ||= newSectionGroup.id;
	const existingSectionGroup = sectionGroups[id];
	let sectionSlugs = [];
	if (newSectionGroup.sections) {
		sectionSlugs = newSectionGroup.sections.map((s) => s.slug);
		sectionsStore.updateSections(newSectionGroup.sections);
	} else if (existingSectionGroup?.sections) {
		sectionSlugs = existingSectionGroup.sections;
	}
	const keys = ["id", "placeholder", "sectionSlugs", "title", "permissions"];
	return mergeObjects(
		existingSectionGroup,
		{
			...newSectionGroup,
			sectionSlugs,
		},
		keys,
		replace
	);
}

export default {
	subscribe,
	reset,
	updateSectionGroups,
	updateSectionGroup,
};

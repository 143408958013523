<script>
	import { onMount, onDestroy } from "svelte";

	import paramsStore from "../../../../stores/paramsStore";
	import setupsStore from "../../../../stores/data/gameCreator/setupsStore.js";
	import documentVisibleStore from "../../../../stores/documentVisibleStore.js";

	import setupChannel from "../../../../channels/gameCreator/setupChannel.js";

	import Tabs from "../../../shared/Tabs.svelte";
	import TabPage from "../../../shared/TabPage.svelte";
	import TeamsTab from "./teams/TeamsTab.svelte";
	import RolesTab from "./roles/RolesTab.svelte";
	import RoleAssignmentTab from "./roleAssignment/RoleAssignmentTab.svelte";
	import ValuesTab from "./values/ValuesTab.svelte";
	import EffectsTab from "./effects/EffectsTab.svelte";
	import ActionsTab from "./actions/ActionsTab.svelte";
	import ChatsTab from "./chats/ChatsTab.svelte";
	import FlavorsTab from "./flavors/FlavorsTab.svelte";

	let unsavedTabs = {};

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: console.log("setup", setup);

	let channel;

	const tabs = [
		"Teams",
		"Roles",
		"Role Assignment",
		"Values",
		"Effects",
		"Actions",
		"Chats",
		"Flavors",
	];
	let activeTab = "Values";
	let teamConstants, teamVariables, roleConstants, playerVariables;
	let teamsTab, valuesTab;

	onMount(handleMount);
	onDestroy(handleDestroy);

	$: handleSetupSlugChange(setupSlug);
	$: handleSetupChange(setup);
	$: handleDocumentVisibleChange($documentVisibleStore);

	function handleDestroy() {
		channel?.unsubscribe();
	}

	function handleSetupSlugChange(setupSlug) {
		if (!setupSlug) return;
		channel?.unsubscribe();
		channel = setupChannel(setupSlug);
	}

	function handleMount() {
		setupsStore.fetchSetup(setupSlug);
	}

	function handleSetupChange(setup) {
		if (!setup?.values) return;
		const teamValues = setup.values.Team;
		const roleValues = setup.values.Role;
		const playerValues = setup.values.Player;
		teamConstants = teamValues.filter(
			(value) => value.valueType === "Constant"
		);
		teamVariables = teamValues.filter(
			(value) => value.valueType === "Variable"
		);
		roleConstants = roleValues.filter(
			(value) => value.valueType === "Constant"
		);
		playerVariables = playerValues.filter(
			(value) => value.valueType === "Variable"
		);
	}

	function handleDocumentVisibleChange(visible) {
		if (!visible) return;
		setupsStore.fetchSetup(setupSlug);
	}

	function handleActiveFlavorChanged() {
		teamsTab.handleActiveFlavorChanged();
		valuesTab.handleActiveFlavorChanged();
	}
</script>

{#if setup}
	<Tabs {tabs} {unsavedTabs} bind:activeTab bordered></Tabs>

	{#if setup.teams}
		<TabPage tab="Teams" {activeTab}>
			<TeamsTab
				bind:this={teamsTab}
				{activeTab}
				{setup}
				bind:unsaved={unsavedTabs.Teams}
				{teamConstants}
				{teamVariables}
				{roleConstants}
				{playerVariables}
			/>
		</TabPage>
	{/if}
	{#if setup.roles}
		<TabPage tab="Roles" {activeTab}>
			<RolesTab
				{activeTab}
				{setup}
				bind:unsaved={unsavedTabs.Roles}
				{roleConstants}
				{playerVariables}
			/>
		</TabPage>
	{/if}
	{#if setup.roleAssignment}
		<TabPage tab="Role Assignment" {activeTab}>
			<RoleAssignmentTab
				{activeTab}
				{setup}
				bind:unsaved={unsavedTabs["Role Assignment"]}
			/>
		</TabPage>
	{/if}
	{#if setup.values}
		<TabPage tab="Values" {activeTab}>
			<ValuesTab
				bind:this={valuesTab}
				{activeTab}
				{setup}
				bind:unsaved={unsavedTabs.Values}
			/>
		</TabPage>
	{/if}
	{#if setup.effects}
		<TabPage tab="Effects" {activeTab}>
			<EffectsTab {activeTab} {setup} bind:unsaved={unsavedTabs.Effects} />
		</TabPage>
	{/if}
	{#if setup.actions}
		<TabPage tab="Actions" {activeTab}>
			<ActionsTab {activeTab} {setup} bind:unsaved={unsavedTabs.Actions} />
		</TabPage>
	{/if}
	{#if setup.chats}
		<TabPage tab="Chats" {activeTab}>
			<ChatsTab {activeTab} {setup} bind:unsaved={unsavedTabs.Chats} />
		</TabPage>
	{/if}
	{#if setup.flavors}
		<TabPage tab="Flavors" {activeTab}>
			<FlavorsTab
				{activeTab}
				{setup}
				bind:unsaved={unsavedTabs.Flavors}
				on:activeFlavorChanged={handleActiveFlavorChanged}
			/>
		</TabPage>
	{/if}
{/if}

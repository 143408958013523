<script>
	import { onDestroy } from "svelte";

	import setupsStore from "../../../stores/data/gameCreator/setupsStore.js";
	import currentUserStore from "../../../stores/data/currentUserStore.js";

	import setupsChannel from "../../../channels/gameCreator/setupsChannel.js";
	import SetupCover from "./SetupCover.svelte";

	$: currentUser = $currentUserStore;
	$: setups = Object.values($setupsStore).sort((a, b) => a.rank - b.rank);

	let channel;

	onDestroy(handleDestroy);

	$: handleCurrentUserChange(currentUser);

	function handleDestroy() {
		channel?.unsubscribe();
	}

	function handleCurrentUserChange(currentUser) {
		if (!currentUser) return;
		setupsStore.fetchSetups();
		channel?.unsubscribe();
		channel = setupsChannel(currentUser);
	}
</script>

{#each setups as setup (setup.slug)}
	<SetupCover {setup}></SetupCover>
{/each}

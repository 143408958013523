<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "./EffectSelect.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";

	export let effectLine;
	export let scopeType;
	export let effectId;

	const dispatch = createEventDispatcher();

	function handleIfValueChange({ detail }) {
		effectLine.ifValue = detail.value;
		dispatch("event", { value: effectLine });
	}

	function handleThenEffectChange({ detail }) {
		effectLine.thenEffect = detail.value;
		dispatch("event", { value: effectLine });
	}

	function handleOtherwiseEffectChange({ detail }) {
		effectLine.otherwiseEffect = detail.value;
		dispatch("event", { value: effectLine });
	}
</script>

<SetupValueSelect
	value={effectLine.ifValue}
	dataType="True or False"
	{scopeType}
	on:event={handleIfValueChange}
/>
<Align both="center">Then:</Align>
<EffectSelect
	effect={effectLine.thenEffect}
	excluded={effectId}
	{scopeType}
	allowNull
	on:event={handleThenEffectChange}
/>
<div></div>
<div></div>
<Align both="center">Otherwise:</Align>
<EffectSelect
	effect={effectLine.otherwiseEffect}
	excluded={effectId}
	{scopeType}
	allowNull
	on:event={handleOtherwiseEffectChange}
/>

<script>
	import { onMount, createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	export let tab = null;
	export let activeTab = null;
	export let minHeight = 0;
	export let clientHeight = null;
	let mounted = false;

	onMount(async () => {
		mounted = true;
	});
</script>

{#if tab}
	<div
		class:hidden={mounted && tab !== activeTab}
		style="min-height: {minHeight}px"
		bind:clientHeight
	>
		<slot />
	</div>
{:else}
	<slot />
{/if}

<script>
	import DateTime from "../../../../../util/DateTime";
	import Vote from "./Vote.svelte";

	export let votes;
	export let options;
	export let votesPerRow;
	export let rows;
	export let rowHeight;

	$: votesToShow = getVotesToShow(
		votes,
		[...options, { id: null }],
		votesPerRow,
		rows,
		rowHeight
	);

	$: console.log("votesToShow", votesToShow);

	function getVotesToShow(votes, options, votesPerRow, rows, rowHeight) {
		let result = [];
		for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
			const option = options[optionIndex];
			const optionVotes = votes
				.filter((v) => v.value === option.id)
				.sort(
					(a, b) =>
						DateTime.dtFromIso(a.updatedAt) - DateTime.dtFromIso(b.updatedAt)
				);
			for (let i = 0; i < optionVotes.length; i++) {
				const rowNumber = Math.floor(i / votesPerRow);
				const columnNumber = i - rowNumber * votesPerRow;
				result.push({
					player: optionVotes[i].player,
					top: 40 * rowNumber + (rowHeight + 8) * optionIndex + 3,
					left: 109 * columnNumber,
				});
			}
		}
		return result;
	}
</script>

{#each votesToShow as vote (vote.player)}
	<Vote {vote} />
{/each}

<script>
	import { getContext } from "svelte";

	import apiVersionStore from "../../../../stores/apiVersionStore";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import Button from "../../../tailwind/Button.svelte";
	import Align from "../../../tailwind/align/Align.svelte";

	const version = getContext("version");
</script>

<Display title="Information">
	<Transition>
		<div>
			<Button extraClasses="my-1 w-full" path="acknowledgements">
				Acknowledgements
			</Button>
		</div>
	</Transition>
	<Transition>
		<Align horizontal="center">
			<div>
				Version: <strong>{version}</strong>
			</div>
		</Align>
	</Transition>
	{#if $apiVersionStore}
		<Transition>
			<Align horizontal="center">
				<div>
					API version: <strong>{$apiVersionStore}</strong>
				</div>
			</Align>
		</Transition>
	{/if}
</Display>

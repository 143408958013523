<script>
	import Role from "./Role.svelte";

	export let roles;
	export let roleConstants;
	export let playerVariables;

	function handleRoleChange(i, detail) {
		roles[i] = detail.value;
	}
</script>

{#each roles.filter(Boolean) as role, i}
	<Role
		{role}
		{roleConstants}
		{playerVariables}
		on:event={({ detail }) => handleRoleChange(i, detail)}
	/>
{/each}

export function clone(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function equal(a, b) {
	if (a === b) return true;
	if (Array.isArray(a)) {
		if (Array.isArray(b)) {
			const result = equalArrays(a, b);
			// if (result === false) {
			// 	console.log("a", a);
			// 	console.log("b", b);
			// }
			return result;
		}
	} else if (a && typeof a === "object") {
		if (b && typeof b === "object") {
			const result = equalObjects(a, b);
			// if (result === false) {
			// 	console.log("a", a);
			// 	console.log("b", b);
			// }
			return result;
		}
	}
	// console.log("a", a);
	// console.log("b", b);
	return false;
}

function equalArrays(a, b) {
	if (a.length !== b.length) return false;
	for (let i = 0; i < a.length; i++) {
		if (!equal(a[i], b[i])) return false;
	}
	return true;
}

function equalObjects(a, b) {
	const aKeys = Object.keys(a);
	if (aKeys.length !== Object.keys(b).length) return false;
	for (const key of aKeys) {
		if (!equal(a[key], b[key])) return false;
	}
	return true;
}

<script>
	import Badge from "../tailwind/Badge.svelte";
	import BadgeInLine from "../tailwind/BadgeInLine.svelte";

	export let user = null;
	export let username = null;
	export let inLine = false;
	export let size = null;

	$: Component = inLine ? BadgeInLine : Badge;

	$: name = username || user?.username;
</script>

<svelte:component this={Component} {size} tooltip={name || "User"} clickable>
	{name || "User"}
</svelte:component>

import Fetcher from "../api/Fetcher.js";
import channel from "./channel.js";
import sectionsStore from "../stores/data/sectionsStore.js";
import topicsStore from "../stores/data/topicsStore.js";

const messageFunctions = { topicUpdate, refetchSection };

function sectionChannel(sectionSlug, authority) {
	return channel(
		{ channel: channelName(authority), section_slug: sectionSlug },
		messageFunctions
	);
}

function channelName(authority) {
	if (authority == null || authority < 0) return "SectionGuestChannel";
	if (authority < 10) return "SectionMemberChannel";
	return "SectionAdminChannel";
}

function topicUpdate({ topic }, { section_slug }) {
	if (!topic.slug) return requestTopicCover(topic.id);
	updateTopicAndSection(topic, section_slug);
}

function requestTopicCover(topicId, section_slug) {
	if (!topicId) return;
	Fetcher.requestTopicCover({ topicId }).then(({ topic }) => {
		if (topic) updateTopicAndSection(topic, section_slug);
	});
}

function updateTopicAndSection(topic, sectionSlug) {
	topicsStore.updateTopic(topic);
	sectionsStore.updateSection({
		slug: sectionSlug || topic.sectionSlug,
		lastTopic: topic,
	});
}

function refetchSection(slugs) {
	sectionsStore.fetchSection(slugs);
}

export default sectionChannel;

import { DateTime as Luxon } from "luxon";
import tzData from "tzdata";
import pluralize from "pluralize";
// const iso = '2021-11-16T10:40 Australia/Adelaide';

function pad(number, places) {
	let text = number.toString();
	const difference = Math.max(0, places - text.length);
	return "0".repeat(difference) + text;
}

function timeDifferenceString(unit, value, pf) {
	return `${pluralize(unit, value, true)} ${["ago", "from now"][pf]}`;
}

export default class DateTime {
	static dtFromIso(iso) {
		const dt = Luxon.fromISO(iso);
		return dt.setZone("UTC");
	}

	static dtString(dt, timezone) {
		let tzDt = dt.setZone(timezone);
		const weekday = tzDt.weekdayShort;
		let day = tzDt.day;
		if (day < 10) {
			day = "0" + day;
		}
		if (tzDt.invalid) {
			return "-";
		}
		const month = tzDt.monthShort;
		const year = tzDt.year;
		let hour = tzDt.hour,
			minute = tzDt.minute,
			ap = "AM";
		if (hour > 11) {
			ap = "PM";
			if (hour > 12) {
				hour -= 12;
			}
		}
		if (hour < 10) {
			if (hour < 1) {
				hour = 12;
			} else {
				hour = `0${hour}`;
			}
		}
		if (minute < 10) {
			minute = `0${minute}`;
		}
		const time = `${hour}:${minute} ${ap}`;
		// const time = tzDt.toLocaleString(Luxon.TIME_SIMPLE)
		return `${weekday}, ${day} ${month} ${year}, ${time}`;
	}

	static dtStringFromIso(iso, timezone) {
		return this.dtString(this.dtFromIso(iso), timezone);
	}

	static isoFromDt(dt, timezone, showTimezone = false) {
		const tzDt = dt.setZone(timezone);
		let returnValue = tzDt.toISO({ includeOffset: false }).slice(0, -7);
		if (showTimezone) {
			returnValue += ` ${timezone}`;
		}
		return returnValue;
	}

	static now() {
		return Luxon.now().toUTC();
	}

	static nowCode(showS = false, showMs = false) {
		const dt = DateTime.now();
		let { year, month, day, hour, minute, second, millisecond } = dt.c;
		month = pad(month, 2);
		day = pad(day, 2);
		hour = pad(hour, 2);
		minute = pad(minute, 2);
		if (!showS) return `${year}-${month}-${day}T${hour}:${minute}Z`;
		second = pad(second, 2);
		if (!showMs) return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
		millisecond = pad(millisecond, 3);
		return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}Z`;
	}

	static timeDifference(iso, now) {
		const dt = this.dtFromIso(iso);
		now ||= this.now();
		let ms, pf;
		if (dt < now) {
			ms = now - dt;
			pf = 0;
		} else {
			ms = dt - now;
			pf = 1;
		}
		const sec = Math.round(ms / 1000);
		if (isNaN(sec)) return "";
		if (sec < 20) return ["Just now", "Just now"][pf];
		if (sec < 60)
			return ["Less than a minute ago", "Less than a minute from now"][pf];
		const min = Math.round(sec / 60);
		if (min < 60) return timeDifferenceString("minute", min, pf);
		const hours = Math.round(min / 60);
		if (hours < 24) return timeDifferenceString("hour", hours, pf);
		const days = Math.round(hours / 24);
		if (days < 14) return timeDifferenceString("day", days, pf);
		const weeks = Math.round(days / 7);
		if (weeks < 52) return timeDifferenceString("week", weeks, pf);
		const years = Math.round(days / 365.2425);
		return timeDifferenceString("year", years, pf);
	}

	static timeZones() {
		return Object.keys(tzData.zones);
	}
}

<script>
	import currentUserStore from "../../../../stores/data/currentUserStore";

	import EmojiButton from "../../../tailwind/EmojiButton.svelte";

	import Fetcher from "../../../../api/Fetcher.js";

	export let post;

	$: currentReact = getCurrentReact(post, $currentUserStore);

	function getCurrentReact(post, currentUser) {
		return post.reacts?.find(
			(react) => react.user.username === currentUser?.username
		);
	}

	function handleEmojiClick({ detail }) {
		Fetcher.postReact({ post, text: detail.value });
	}
</script>

<EmojiButton
	currentEmoji={currentReact?.text}
	tooltip="React"
	closeOnEmojiClick
	on:emojiClick={handleEmojiClick}
/>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";

class NewAdminForm extends Form {
	static title = "New Admin";
	static code = "new-admin";
	static submitValue = "Confirm";

	static initialFields({ forumSlug }) {
		return [
			{
				type: "input",
				inputType: "user",
				value: "",
				label: "Username (type to search)",
				id: "new-admin-username",
				placeholder: "Username",
				required: true,
			},
			{ forumSlug },
		];
	}

	static async submit({ fields }) {
		const user = fields[0].value;
		const { forumSlug } = fields[1];
		return Fetcher.makeForumAdmin({ user, forumSlug });
	}
}

export default NewAdminForm;

<script>
	import defaultAvatars from "./defaultAvatars.js";

	export let avatar;

	$: splitAvatar = avatar.split(" ").slice(1);
	$: name = splitAvatar.slice(0, splitAvatar.length - 1).join(" ");
	$: foreground = splitAvatar[splitAvatar.length - 1];
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 512 512"
	class="h-24 w-24 lg:h-28 lg:w-28"
>
	<path d="M0 0h512v512H0z" fill-opacity="0"></path>
	<g class="" transform="translate(0,0)" style="">
		<path
			d={defaultAvatars[name] && defaultAvatars[name].path}
			fill={foreground}
			fill-opacity="1"
			transform="translate(512, 512) scale(-1, -1) rotate(-540, 256, 256) skewX(0) skewY(0)"
		></path>
	</g>
</svg>

<script>
	import { createEventDispatcher } from "svelte";

	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import Field from "../../../../forms/Field.svelte";
	import Tabs from "../../../../shared/Tabs.svelte";
	import TabPage from "../../../../shared/TabPage.svelte";
	import StandardConstants from "./StandardConstants.svelte";
	import VariableInputs from "./VariableInputs.svelte";
	import ObjectValueInputs from "../ObjectValueInputs.svelte";

	export let action;
	export let actionConstants;
	export let actionVariables;

	const dispatch = createEventDispatcher();

	const tabs = ["Constants", "Initial Variables", "Variable Inputs"];

	let activeTab = "Constants";

	$: nameField = getNameField(action);

	function getNameField(action) {
		return {
			type: "input",
			inputType: "text",
			maxLength: 32,
			value: action.name,
			placeholder: "Name",
		};
	}

	function handleNameChange({ detail }) {
		if (detail.type !== "input") return;
		dispatch("event", { value: { ...action, name: detail.value } });
	}

	function handleConstantsChange({ detail }) {
		dispatch("event", { value: { ...action, constants: detail.value } });
	}

	function handleVariablesChange({ detail }) {
		dispatch("event", { value: { ...action, variables: detail.value } });
	}
</script>

<Card>
	<CardBody>
		<div class="grid grid-cols-4 gap-4">
			{#if nameField}
				<Field field={nameField} on:event={handleNameChange} />
			{/if}
		</div>
		{#if action?.name?.length > 0}
			<Tabs {tabs} bind:activeTab bordered />
			<TabPage tab="Constants" {activeTab}>
				<StandardConstants {action} on:event />
				<ObjectValueInputs
					objectValues={action.constants}
					setupValues={actionConstants}
					scopeType="Action"
					on:event={handleConstantsChange}
				/>
			</TabPage>
			<TabPage tab="Initial Variables" {activeTab}>
				<ObjectValueInputs
					objectValues={action.variables}
					setupValues={actionVariables}
					scopeType="Action"
					on:event={handleVariablesChange}
				/>
			</TabPage>
			<TabPage tab="Variable Inputs" {activeTab}>
				<VariableInputs {action} {actionVariables} on:event />
			</TabPage>
		{/if}
	</CardBody>
</Card>

<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "./EffectSelect.svelte";

	export let effectLine;
	export let scopeType;

	const dispatch = createEventDispatcher();

	function handleDurationChange({ detail }) {
		dispatch("event", { value: { ...effectLine, duration: detail.value } });
	}

	function handleEffectChange({ detail }) {
		dispatch("event", { value: { ...effectLine, effect: detail.value } });
	}
</script>

<SetupValueSelect
	dataType="Duration"
	{scopeType}
	value={effectLine.duration}
	on:event={handleDurationChange}
/>
<EffectSelect
	{scopeType}
	effect={effectLine.effect}
	on:event={handleEffectChange}
/>
<div></div>

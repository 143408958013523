import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab from "../components/shared/permissionsTabs/topicPermissionsTab.js";

class EditTopicForm extends Form {
	static title = "Edit Topic";
	static code = "edit-topic";
	static submitValue = "Save";

	static initialPages({ topic, sectionPermissions, topicPermissions }) {
		return [
			permissionsTab({
				sectionPermissions: sectionPermissions,
				topicPermissions: topicPermissions,
			}),
			{ topic },
		];
	}

	static async submit({ pages }) {
		const permissionsField = pages[0].fields[0];
		const permissions = permissionsField.levels[0].value;
		const { topic } = pages[1];

		return Fetcher.updateTopic({
			topic,
			permissions,
		});
	}
}

export default EditTopicForm;

<script>
	import PostInfo from "../../shared/PostInfo.svelte";

	export let member;
</script>

<div class="bg-base-100 flex gap-4 py-4 px-6 rounded-xl">
	<div class="w-24 flex flex-col">
		<PostInfo
			userSlug={member.slug}
			username={member.username}
			avatar={member.profile.avatar}
		/>
	</div>
	<div class="grow col-span-3">
		<slot />
	</div>
</div>

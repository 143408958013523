<script>
	import { createEventDispatcher } from "svelte";

	import Button from "./Button.svelte";

	import Icon from "../icons/large/Icon.svelte";

	export let disabled;

	const dispatch = createEventDispatcher();

	let confirmRevert = false;

	function handleSave() {
		dispatch("save");
	}

	function handleRevert() {
		if (!confirmRevert) {
			confirmRevert = true;
			setTimeout(() => (confirmRevert = false), 2500);
			return;
		}
		confirmRevert = false;
		dispatch("revert");
	}

	function handleKeyDown(e) {
		if (!e.ctrlKey) return;
		if (e.key === "s") {
			e.preventDefault();
			if (!disabled) dispatch("save");
		}
	}
</script>

<svelte:window on:keydown={handleKeyDown} />
<div class="my-2 flex gap-1">
	<div>
		<Button square size="small" tooltip="Save" {disabled} on:click={handleSave}>
			<Icon name="save" />
		</Button>
	</div>
	<div>
		<Button
			square
			size="small"
			className="error"
			tooltip={confirmRevert ? "Sure?" : "Revert"}
			{disabled}
			on:click={handleRevert}
		>
			{#if confirmRevert}
				<span class="text-xl">?</span>
			{:else}
				<Icon name="revert" />
			{/if}
		</Button>
	</div>
	<slot />
</div>

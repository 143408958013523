<script>
	import { setContext, tick } from "svelte";

	import draftsStore from "../../stores/data/draftsStore.js";
	import layoutStore from "../../stores/layoutStore.js";

	import BbCodeManager from "../../bbCode/BbCodeManager.js";

	import PostBar from "./postBar/PostBar.svelte";
	import PostInput from "./PostInput.svelte";

	let postInput;
	let showTextarea = true;
	let showPreview = true;
	let text = "";
	let draftId;
	let draft;

	$: drafts = $draftsStore;
	$: draft = drafts.find((d) => d.id === draftId) || { id: null, text: "" };
	$: textMatches = draft && text.length > 0 && text === draft.text;

	export async function insertBbCode(bbCode) {
		showTextarea = true;
		await tick();
		postInput.insertBbCode(bbCode);
	}

	export function draftSelected(id) {
		const selectedDraft = drafts.find((d) => d.id === id);
		if (!selectedDraft) return;
		draftId = id;
		text = selectedDraft.text;
		layoutStore.openBottomPanel();
	}

	export function draftDeleted(id) {
		if (draftId === id) clearDraft();
	}

	export function clearDraft() {
		draftId = null;
		text = "";
	}

	const bbCodeManager = new BbCodeManager(
		"b",
		"i",
		"u",
		"s",
		"datetime",
		"timediff",
		"quote",
		"link",
		"font",
		"hr",
		"pre",
		"code",
		"ul",
		"ol",
		"li"
	);
	setContext("bbCodeManager", bbCodeManager);

	const bottomPanelPositionStore = layoutStore.bottomPanelPositionStore;

	$: marginLeft = $layoutStore.sideWidth;
	$: marginRight = $layoutStore.sideWidth + $layoutStore.scrollbarWidth;
	$: margins = 2 * $layoutStore.sideWidth;
	$: height = $layoutStore.bottomBarHeight + $layoutStore.bottomPanelHeight;

	$: style = `margin-left: ${marginLeft}px; margin-right: ${marginRight}px; width: calc(100% - ${margins}px);`;

	$: divStyle = `${style} position: fixed; bottom: ${$bottomPanelPositionStore + $layoutStore.topAndBottomMargins}px; height: ${height}px;`;

	$: barStyle = `height: ${$layoutStore.bottomBarHeight}px;`;
	$: panelStyle = `height: ${$layoutStore.bottomPanelHeight}px;`;
</script>

<div style={divStyle}>
	<PostBar
		style={barStyle}
		{textMatches}
		postEdit={draft.post}
		bind:draftId
		bind:text
		bind:showTextarea
		bind:showPreview
		on:focusTextarea={() => postInput.focusTextarea()}
	/>
	<div style={panelStyle}>
		<div class="border-2 border-black h-full">
			<PostInput
				bind:this={postInput}
				bind:draft
				bind:draftId
				bind:text
				{drafts}
				{showTextarea}
				{showPreview}
			/>
		</div>
	</div>
</div>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";

class ChangePasswordForm extends Form {
	static title = "Change Password";
	static code = "change-password";
	static focusOnOpen = "current-password";
	static submitValue = "Save";

	static initialFields() {
		return [
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "Current Password",
				id: "current-password",
				placeholder: "Password",
				required: true,
			},
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "New Password",
				id: "new-password",
				placeholder: "Password",
				required: true,
			},
			{
				type: "input",
				inputType: "password",
				value: "",
				label: "New Password confirmation",
				id: "new-password-confirmation",
				placeholder: "Password confirmation",
				required: true,
			},
		];
	}

	static async submit({ fields }) {
		const [currentPassword, newPassword, newPasswordConfirmation] = fields;
		if (newPassword.value != newPasswordConfirmation.value) {
			return {
				errors: {
					[newPassword.id]: "",
					[newPasswordConfirmation.id]: "Passwords do not match.",
				},
			};
		}
		return Fetcher.changePassword({
			currentPassword: currentPassword.value,
			newPassword: newPassword.value,
		});
	}
}

export default ChangePasswordForm;

<script>
	import currentUserStore from "../../../stores/data/currentUserStore.js";
	import paramsStore from "../../../stores/paramsStore.js";
	import topicsStore from "../../../stores/data/topicsStore.js";
	import draftsStore from "../../../stores/data/draftsStore.js";
	import layoutStore from "../../../stores/layoutStore.js";

	import { redirectToTopic } from "../../../util/redirectTo.js";

	import Fetcher from "../../../api/Fetcher.js";

	import Button from "../../tailwind/Button.svelte";

	export let draftId;
	export let text;
	export let disabled;
	export let showTextarea;
	export let postEdit;

	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: pageSize = $currentUserStore.settings?.pageSize;

	async function setPostTag(json) {
		redirectToTopic({
			topicSlug,
			pageSize,
			postNumber: json.number,
			currentTopicSlug: topicSlug,
		});
	}

	function handleClick() {
		if (!topic || !draftId) return;
		if (postEdit) {
			Fetcher.editPost({ draftId }).then(handleCreatePostResponse);
		} else {
			Fetcher.createTopicIntent({ topic }).then(({ intentId }) => {
				if (!intentId) return;
				Fetcher.createPost({ topic, draftId, intentId }).then(
					handleCreatePostResponse
				);
			});
		}
	}

	function handleCreatePostResponse(json) {
		if (!json.id) return;
		topicsStore.addPost({ topicSlug, post: json });
		layoutStore.toggleBottomPanel();
		draftId = null;
		text = "";
		showTextarea = true;
		setPostTag(json);
		draftsStore.fetchDrafts();
	}
</script>

<Button
	className="success"
	extraClasses="btn-xs"
	{disabled}
	on:click={handleClick}
>
	{postEdit ? "Save" : "Post"}
</Button>

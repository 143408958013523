<script>
	import paramsStore from "../stores/paramsStore.js";
	import domainsStore from "../stores/data/domainsStore.js";
	import sectionsStore from "../stores/data/sectionsStore.js";
	import topicsStore from "../stores/data/topicsStore.js";

	import DefaultAvatar from "./shared/avatar/defaults/DefaultAvatar.svelte";

	let imgSource;
	let title;

	$: domainSlug = $paramsStore.domainSlug;
	$: domain = $domainsStore[domainSlug];
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];

	$: handleDomainChange(domain, section, topic);

	function handleDomainChange(domain, section, topic) {
		title = getTitle(domain, section, topic);
		imgSource = getImgSource(domain);
	}

	function getTitle(domain, section, topic) {
		const object = topic || section || domain;
		return object?.title || "Quende";
	}

	function getImgSource(domain) {
		if (!domain) return null;
		const icon = domain.icon;
		const content = document.createElement("div");
		new DefaultAvatar({
			target: content,
			props: { avatar: `default ${icon}` },
		});
		const svgString = new XMLSerializer().serializeToString(
			content.childNodes[0]
		);
		const decoded = decodeURI(encodeURI(svgString));
		const base64 = btoa(decoded);
		return `data:image/svg+xml;base64,${base64}`;
	}
</script>

<svelte:head>
	{#if imgSource}
		<link rel="shortcut icon" href={imgSource} type="image/png" />
	{:else}
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
	{/if}
	<title>{title}</title>
</svelte:head>

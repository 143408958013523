<script>
	import PermissionsRow from "./PermissionsRow.svelte";

	export let field;

	let minValue = -20;

	$: colsClass = ["grid-cols-2", "grid-cols-3", "grid-cols-4"][
		field.levels[0].columns.length - 1
	];

	const labels = {
		admin: "Admins",
		member: "Members",
		guest: "Guests",
		view: "View",
		post: "Post",
		createTopic: "Create Topic",
		viewMin: "View Min",
		viewDefault: "View Default",
		viewMax: "View Max",
		createTopicMin: "Create Topic Min",
		createTopicDefault: "Create Topic Default",
		createTopicMax: "Create Topic Max",
		postMin: "Post Min",
		postDefault: "Post Default",
		postMax: "Post Max",
	};

	$: handlePrimaryLevelChange(field.levels[0]);

	function handlePrimaryLevelChange(level) {
		const newMinValue = Math.min(
			...Object.values(level.value).filter((x) => typeof x === "number")
		);
		if (newMinValue === minValue) return;
		minValue = newMinValue;
		const newColumns = {
			20: [null, null, null],
			10: ["admin", null, null],
			0: ["admin", "member", null],
			[-20]: ["admin", "member", "guest"],
		}[minValue];
		field.levels.slice(1).forEach((_level) => {
			_level.columns = newColumns;
		});
	}

	function handleClick(e) {
		let { levelIndex, values } = e.detail;
		// if (value == undefined) return;
		// if (value === field.levels[levelIndex].value[keys[0]]) return;
		Object.entries(values).forEach(([key, value]) => {
			updateValue(levelIndex, key, value);
			updateRelatedKeys({
				levelIndex,
				key,
				value,
				code: "Min",
				otherCodes: ["Default", "Max"],
				compare: Math.max,
			});
			updateRelatedKeys({
				levelIndex,
				key,
				value,
				code: "Default",
				otherCodes: ["Min"],
				compare: Math.min,
			});
			updateRelatedKeys({
				levelIndex,
				key,
				value,
				code: "Default",
				otherCodes: ["Max"],
				compare: Math.max,
			});
			updateRelatedKeys({
				levelIndex,
				key,
				value,
				code: "Max",
				otherCodes: ["Min", "Default"],
				compare: Math.min,
			});
		});
	}

	function updateRelatedKeys({
		levelIndex,
		key,
		value,
		code,
		otherCodes,
		compare,
	}) {
		if (!key.includes(code)) return;
		for (const otherCode of otherCodes) {
			updateRelatedKey({ levelIndex, key, value, code, otherCode, compare });
		}
	}

	function updateRelatedKey({
		levelIndex,
		key,
		value,
		code,
		otherCode,
		compare,
	}) {
		const otherKey = key.replace(code, otherCode);
		const currentOtherValue = field.levels[levelIndex].value[otherKey];
		if (currentOtherValue == null) return;
		const newOtherValue = compare(currentOtherValue, value);
		if (newOtherValue === currentOtherValue) return;
		updateValue(levelIndex, otherKey, newOtherValue);
	}

	function updateValue(levelIndex, key, value) {
		if (key.includes("Add") && value < 0) {
			updateValue(levelIndex, key.replace("Add", "Unlock"), value);
			value = Math.max(0, value);
		}
		field.levels[levelIndex].value[key] = value;
		if (value >= 0 && key.includes("Unlock")) {
			addValueAtLeastZero(levelIndex, key);
		}
	}

	function addValueAtLeastZero(levelIndex, key) {
		const addKey = key.replace("Unlock", "Add");
		field.levels[levelIndex].value[addKey] = Math.max(
			0,
			field.levels[levelIndex].value[addKey]
		);
	}
</script>

{#each field.levels as level, levelIndex (level.heading)}
	<div class="w-full grid {colsClass} gap-2 justify-items-center">
		<div />
		{#each level.columns as column}
			<div class="select-none">
				{#if level.primary}{labels[column]}{/if}
			</div>
		{/each}
		{#if level.heading}
			<div class="select-none">{level.heading}</div>
			{#each level.columns as column}
				<div></div>
			{/each}
		{/if}
		{#each level.rows as row, rowIndex}
			<PermissionsRow
				{level}
				{levelIndex}
				{row}
				label={labels[row]}
				tooltip={level.tooltips && level.tooltips[rowIndex]}
				columns={level.columns}
				on:click={handleClick}
			/>
		{/each}
	</div>
{/each}

import { writable } from "svelte/store";
import { mergeObjects } from "./merge.js";
import API from "../../api/API.js";
import DateTime from "../../util/DateTime";
import Fetcher from "../../api/Fetcher.js";

const { subscribe, set, update } = writable({});

function reset() {
	set({});
	API.removePreviousRequests();
}

let forbiddenIds = [];

function fetchTopic({ domainSlug, sectionSlug, gameSlug, topicSlug }) {
	if (!domainSlug || !topicSlug || !(sectionSlug || gameSlug)) return;
	if (sectionSlug) {
		API.get(
			`forums/${domainSlug}/sections/${sectionSlug}/topics/${topicSlug}`
		).then((json) => {
			updateTopics([json]);
		});
	} else {
		API.get(`game_creator/games/${gameSlug}/topics/${topicSlug}`).then(
			(json) => {
				updateTopics([json]);
			}
		);
	}
}

function fetchPostsPage({
	domainSlug,
	sectionSlug,
	gameSlug,
	topicSlug,
	pageNumber,
}) {
	if (!domainSlug || !topicSlug || !(sectionSlug || gameSlug) || !pageNumber) {
		return;
	}
	const currentRequest = DateTime.now();
	if (sectionSlug) {
		const url = `forums/${domainSlug}/sections/${sectionSlug}/topics/${topicSlug}/pages/${pageNumber}`;
		API.get(url).then(({ offset = 0, posts } = {}) => {
			updateTopics([{ slug: topicSlug, posts: [...Array(offset), ...posts] }]);
		});
	} else {
		const url = `game_creator/games/${gameSlug}/topics/${topicSlug}/pages/${pageNumber}`;
		API.get(url).then(({ offset = 0, posts } = {}) => {
			updateTopics([
				{
					slug: topicSlug,
					gameSlug: gameSlug,
					posts: [...Array(offset), ...posts],
				},
			]);
		});
	}
}

function addPost({ topicSlug, post }) {
	update((topics) => {
		topics[topicSlug] ||= {};
		topics[topicSlug].posts ||= [];
		topics[topicSlug].posts[post.number - 1] = post;
		topics[topicSlug].postCount ||= 1;
		topics[topicSlug].postCount = Math.max(
			topics[topicSlug].postCount,
			post.number
		);
		return topics;
	});
}

function updateTopics(newTopics) {
	update((topics) => {
		for (const newTopic of newTopics) {
			const splitSlug = newTopic.slug.split("/");
			const gameSlug =
				(splitSlug.length > 1 && splitSlug[0]) || newTopic.gameSlug;
			newTopic.slug = splitSlug[splitSlug.length - 1];
			const slug = gameSlug ? `${gameSlug}/${newTopic.slug}` : newTopic.slug;
			topics[slug] = mergeInTopic(topics[slug], newTopic);
		}
		return topics;
	});
}

function updateTopic(newTopic) {
	const splitSlug = newTopic.slug.split("/");
	const gameSlug = (splitSlug.length > 1 && splitSlug[0]) || newTopic.gameSlug;
	newTopic.slug = splitSlug[splitSlug.length - 1];
	const slug = gameSlug ? `${gameSlug}/${newTopic.slug}` : newTopic.slug;
	if (!slug) return requestTopicCover(newTopic.id);
	update((topics) => {
		topics[slug] = mergeInTopic(topics[slug], newTopic);
		return topics;
	});
}

function setTopicValues(newTopic) {
	update((topics) => {
		topics[newTopic.slug] = {
			...topics[newTopic.slug],
			...newTopic,
		};
		return topics;
	});
}

function requestTopicCover(topicId) {
	if (!topicId) return;
	Fetcher.requestTopicCover({ topicId }).then(({ topic }) => {
		if (topic) updateTopic(topic);
	});
}

function mergeInTopic(topic = {}, newTopic = {}) {
	const keys = [
		"id",
		"slug",
		"sectionSlug",
		"forumSlug",
		"gameSlug",
		"title",
		"posts",
		"postCount",
		"startedBy",
		"userData",
		"userPermissions",
		"userSettings",
		"posts",
		"lastPost",
		"permissions",
		"viewers",
		"posters",
		"enablePostReacts",
		"userStyles",
		"actions",
	];
	const result = mergeObjects(topic, newTopic, keys);
	return result;
}

function updateActionVariableInput({
	gameSlug,
	topicSlug,
	actionVariableInput,
}) {
	update((topics) => {
		const topic = topics[`${gameSlug}/${topicSlug}`];
		const action = topic?.actions.find(
			(action) => action.id === actionVariableInput.actionId
		);
		if (!action) return topics;
		action.variableInputs[actionVariableInput.id] = actionVariableInput;
		return topics;
	});
}

function updateReceived(message) {
	if (message.topic) {
		updateTopic(message.topic);
	} else if (message.topicId) {
		if (forbiddenIds.includes(message.topicId)) return;
	}
}

function accessGranted(id) {
	forbiddenIds = forbiddenIds.filter((i) => i !== id);
}

export default {
	subscribe,
	reset,
	updateTopics,
	updateTopic,
	updateActionVariableInput,
	setTopicValues,
	fetchTopic,
	fetchPostsPage,
	addPost,
};

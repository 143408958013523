import channel from "./channel.js";
import { getJwt } from "../api/jwt.js";

import notificationsStore from "../stores/data/notificationsStore.js";
import draftsStore from "../stores/data/draftsStore.js";
import topicsStore from "../stores/data/topicsStore.js";

const messageFunctions = {
	notificationAdded,
	notificationDestroyed,
	refetchNotifications,
	draftAdded,
	draftDestroyed,
	topicUpdate,
};

function userChannel(slug) {
	return channel(
		{
			channel: "UserChannel",
			slug,
			jwt: getJwt(),
		},
		messageFunctions
	);
}

function notificationAdded({ notification }) {
	notificationsStore.addNotification(notification);
}

function notificationDestroyed({ id }) {
	notificationsStore.removeNotification(id);
}

function refetchNotifications() {
	notificationsStore.fetchNotifications();
}

function draftAdded({ draft }) {
	draftsStore.addDraft(draft);
}

function draftDestroyed({ id }) {
	draftsStore.removeDraft(id);
}

function topicUpdate({ topic }) {
	topicsStore.updateTopic(topic);
}

export default userChannel;

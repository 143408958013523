<script>
	import { tick } from "svelte";

	import DefaultAvatar from "./defaults/DefaultAvatar.svelte";
	import Image from "../Image.svelte";

	export let value = null;
	export let defaultAvatar = null;
	export let user = null;
	export let size = "large";
	export let round = false;
	export let avatar = null;

	let avatarType;

	$: avatarToShow =
		value || avatar || user?.profile?.avatarUrl || user?.profile?.avatarDefault;

	$: sizeClass = {
		large: "w-24 lg:w-28",
		medium: "w-16",
	}[size];

	$: roundedClass = round ? "rounded-full" : "rounded";

	$: handleAvatarToShowChange(avatarToShow);

	async function handleAvatarToShowChange(avatarToShow) {
		if (!avatarToShow) return;
		avatarType = "wait";
		await tick();
		avatarType = avatarToShow.startsWith("default") ? "default" : "image";
	}
</script>

<div class="avatar">
	<div class="{sizeClass} {roundedClass}">
		{#if avatarType === "image"}
			<Image src={avatarToShow} {defaultAvatar} />
		{:else if avatarType === "default"}
			<DefaultAvatar avatar={avatarToShow} />
		{/if}
	</div>
</div>

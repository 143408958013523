import { writable } from "svelte/store";

const { subscribe, update } = writable([]);

function addToast(json) {
	update((toasts) => [...toasts, json]);
	setTimeout(() => update((toasts) => toasts.slice(1)), 5000);
}

export default { subscribe, addToast };

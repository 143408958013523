<script>
	import paramsStore from "../../../../stores/paramsStore.js";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import NewSectionForm from "../../../../forms/NewSectionForm.js";

	export let sectionGroup;

	$: forumSlug = $paramsStore.domainSlug;

	$: args = forumSlug && sectionGroup && { forumSlug, sectionGroup };
</script>

{#if args}
	<FormModal
		form={NewSectionForm}
		{args}
		title="New {sectionGroup.title} Section"
		code="new-{sectionGroup.title}-section"
	/>
{/if}

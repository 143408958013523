<script>
	import { clone, equal } from "../../../../../util/objectOperations";

	import SaveAndRevertButtons from "../../../../tailwind/SaveAndRevertButtons.svelte";
	import Values from "./Values.svelte";
	import StandardSetupConstants from "./StandardSetupConstants.svelte";
	import Fetcher from "../../../../../api/gameCreator/Fetcher";

	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	export let activeTab;
	export let setup;
	export let unsaved;

	let values = {};
	let standardSetupConstants = {};
	let show = true;

	export function handleActiveFlavorChanged() {
		for (const scopeType of Object.keys(setup.values)) {
			for (let i = 0; i < setup.values[scopeType].length; i++) {
				if (setup.values[scopeType][i].isFlavorText) {
					values[scopeType][i].value = setup.values[scopeType][i].value;
				}
			}
		}
	}

	$: handleSetupValuesChange(setup.values);

	function handleSetupValuesChange(setupValues) {
		if (Object.keys(values).length === 0) {
			values = clone(setupValues);
			standardSetupConstants = {
				gameType: setup.gameType,
				playerCountMin: setup.playerCountMin,
				playerCountMax: setup.playerCountMax,
				defaultRole: setup.defaultRole,
				gameStartEffect: setup.gameStartEffect,
				gameEndCondition: setup.gameEndCondition,
				gameEndEffect: setup.gameEndEffect,
			};
		}
	}

	$: unsaved =
		!equal(values, setup.values) ||
		!equal(standardSetupConstants, {
			gameType: setup.gameType,
			playerCountMin: setup.playerCountMin,
			playerCountMax: setup.playerCountMax,
			defaultRole: setup.defaultRole,
			gameStartEffect: setup.gameStartEffect,
			gameEndCondition: setup.gameEndCondition,
			gameEndEffect: setup.gameEndEffect,
		});

	function handleSave() {
		if (activeTab !== "Values") return;
		Fetcher.updateSetupValues({
			setupSlug: setup.slug,
			values,
			standardSetupConstants,
		}).then(handleSuccessfulSave);
	}

	function handleSuccessfulSave(savedSetup) {
		setupsStore.updateSetup(savedSetup);
		values = clone(savedSetup.values);
	}

	function handleRevert() {
		values = clone(setup.values);
		show = false;
		setTimeout(() => (show = true), 1);
	}
</script>

<SaveAndRevertButtons
	disabled={!unsaved}
	on:save={handleSave}
	on:revert={handleRevert}
/>

{#if show}
	<div class="flex flex-col gap-2 mt-2">
		<Values bind:values={values.Setup} title="Setup Values" scopeType="Setup">
			<StandardSetupConstants bind:standardSetupConstants />
		</Values>
		<Values bind:values={values.Game} title="Game Values" scopeType="Game" />
		<Values bind:values={values.Team} title="Team Values" scopeType="Team" />
		<Values bind:values={values.Role} title="Role Values" scopeType="Role" />
		<Values
			bind:values={values.Player}
			title="Player Values"
			scopeType="Player"
		/>
		<Values
			bind:values={values.Action}
			title="Action Values"
			scopeType="Action"
		/>
		<Values bind:values={values.Chat} title="Chat Values" scopeType="Chat" />
	</div>
{/if}

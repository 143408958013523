<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "../effects/EffectSelect.svelte";
	import Field from "../../../../forms/Field.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let item;

	const dispatch = createEventDispatcher();

	$: labelField = getLabelField(item);

	function getLabelField(item) {
		return {
			type: "input",
			inputType: "text",
			value: item.label,
			maxLength: 1,
			placeholder: "Label",
		};
	}

	function handleNameChange({ detail }) {
		dispatch("event", { value: { ...item, name: detail.value } });
	}

	function handleLabelChange({ detail }) {
		dispatch("event", { value: { ...item, label: detail.value } });
	}

	function handleChancesChange({ detail }) {
		dispatch("event", { value: { ...item, chances: detail.value } });
	}

	function handleEffectChange({ detail }) {
		dispatch("event", { value: { ...item, effect: detail.value } });
	}

	function deleteItem() {
		dispatch("destroy");
	}
</script>

<SetupValueSelect
	dataType="Text"
	scopeType="Game"
	allowRaw
	value={item.name}
	placeholder="Name"
	on:event={handleNameChange}
/>
{#if labelField}
	<Field field={labelField} on:event={handleLabelChange} />
{/if}
<SetupValueSelect
	dataType="Number"
	scopeType="Game"
	allowRaw
	value={item.chances}
	placeholder="Chances"
	min={0}
	on:event={handleChancesChange}
/>
<EffectSelect
	scopeType="Game"
	effect={item.effect}
	on:event={handleEffectChange}
/>
{#if item.name || item.label}
	<div class="mt-2">
		<Button
			square
			size="small"
			className="error"
			tooltip="Delete Item"
			on:click={deleteItem}
		>
			<Icon name="bin" />
		</Button>
	</div>
{/if}

<script>
	import CalculationTerm from "./CalculationTerm.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let calculation;
	export let scopeType;
	export let valueId;

	$: calculationTerms = getCalculationTerms(calculation);

	function getCalculationTerms(calculation) {
		if (calculation.find((term) => [null, 0].includes(term[0][0]))) {
			return calculation;
		}
		return [...calculation, [[0], []]];
	}

	function handleCalculationTermChange(i, value) {
		if (value[0][0] === null) {
			calculation = [...calculation.slice(0, i), ...calculation.slice(i + 1)];
		} else {
			calculation[i] = value;
		}
	}
</script>

{#each calculationTerms as _, i}
	<CalculationTerm
		calculationTerm={calculationTerms[i]}
		{scopeType}
		{valueId}
		on:event={({ detail }) => handleCalculationTermChange(i, detail.value)}
	/>
	{#if i < calculationTerms.length - 1}
		<Align both="center"><Icon name="plus" /></Align>
		<div></div>
		<div></div>
		<div></div>
	{/if}
{/each}

<script>
	import { createEventDispatcher } from "svelte";

	import Input from "../Input.svelte";
	import Button from "../../../tailwind/Button.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let field;
	export let error;
	error;

	const dispatch = createEventDispatcher();

	$: inputField = getInputField(field);

	$: buttonIcon = field.mode === "select" && "click";
	$: buttonText =
		field.mode === "text" ? "Aa" : field.mode === "number" && "01";

	function getInputField({ mode, items, value, label, placeholder }) {
		if (mode === "select") {
			return {
				type: "input",
				inputType: "select",
				items,
				value,
				label,
				placeholder,
				padding: true,
			};
		}
		if (mode === "text") {
			return {
				type: "input",
				inputType: "text",
				value,
				label,
				placeholder,
			};
		}
		if (mode === "number") {
			return {
				type: "input",
				inputType: "number",
				value,
				label,
				placeholder,
				min: field.min,
				max: field.max,
			};
		}
	}

	function handleEvent({ detail }) {
		field.value = detail.value;
		dispatch("event", detail);
	}

	function handleClick() {
		if (field.mode === "select") {
			field.mode = field.dataType.toLowerCase();
		} else {
			field.mode = "select";
		}
		if (field.value !== null) {
			field.value = null;
			dispatch("event", { value: field.value });
		}
	}
</script>

<div>
	<div>
		<Input field={inputField} on:event={handleEvent} />
	</div>
	<div class="pt-1 w-full">
		<Button square full size="small" on:click={handleClick}>
			{#if buttonIcon}
				<Icon name={buttonIcon} />
			{:else if buttonText}
				{buttonText}
			{/if}
		</Button>
	</div>
</div>

<script>
	import { onMount } from "svelte";

	import formModalStore from "../../../stores/formModalStore";
	import currentUserStore from "../../../stores/data/currentUserStore";

	import Button from "../../tailwind/Button.svelte";
	import Icon from "../../icons/large/Icon.svelte";

	export let form = {};
	export let args = {};
	export let title = form.title || "Form";
	export let iconName = null;
	export let indicator = null;
	export let code = form.code || "form-modal";
	export let focusOnOpen = form.focusOnOpen || `${code}-title`;
	export let submitValue = form.submitValue || "Create";
	export let initialPages = form.initialPages || (() => {});
	export let initialFields = form.initialFields || (() => {});
	export let handleValueChange = form.handleValueChange || (() => {});
	export let submit = form.submit || (() => {});
	export let handleSuccess = form.handleSuccess || (() => {});
	export let buttonClass = "btn-primary w-full";
	export let disabled = false;
	export let hideButton = false;
	export let openOnMount = false;

	onMount(handleMount);

	function handleMount() {
		if (openOnMount) {
			open();
		}
	}

	export async function open() {
		if (disabled && !hideButton) return;
		if (form.open) {
			form.open({ ...args, currentUser: $currentUserStore });
		} else {
			formModalStore.open({
				open: true,
				title,
				code,
				focusOnOpen,
				submitValue,
				pages: initialPages(),
				fields: initialFields(),
				errors: {},
				handleValueChange,
				submit,
				handleSuccess,
			});
		}
	}

	export function close() {
		formModalStore.close();
	}
</script>

{#if title && !hideButton}
	<div>
		<Button
			extraClasses={buttonClass}
			{indicator}
			{disabled}
			id="{code}-modal"
			on:click={open}
		>
			{#if iconName}
				<Icon name={iconName} />
			{:else}
				{title}
			{/if}
		</Button>
	</div>
{/if}

<script>
	import pageNameStore from "../../../stores/pageNameStore.js";

	import {
		getIndexItems,
		getForumItems,
		getSectionItems,
		getTopicItems,
	} from "./getItems.js";

	import SideMenu from "../shared/SideMenu.svelte";

	let getItems;

	$: getItems = getGetItems($pageNameStore);

	function getGetItems(pageName) {
		if (!pageName) return null;
		if (pageName === "index") return getIndexItems;
		if (pageName === "forum") return getForumItems;
		if (pageName === "forumSection") return getSectionItems;
		if (pageName === "forumTopic") return getTopicItems;
		return null;
	}
</script>

<SideMenu side="right" {getItems} />

<script>
	import Transition from "../../shared/Transition.svelte";

	import UserBadge from "../../../shared/UserBadge.svelte";

	export let user;
</script>

<Transition>
	<div class="flex gap-1">
		<div class="flex-grow"></div>
		<UserBadge {user} size="lg" />
		<div class="flex-grow"></div>
	</div>
</Transition>

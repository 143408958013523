<script>
	import { createEventDispatcher } from "svelte";

	import Button from "../../tailwind/Button.svelte";

	export let draftId;
	export let text;
	export let disabled;

	const dispatch = createEventDispatcher();

	function handleClick() {
		draftId = null;
		text = "";
		dispatch("focusTextarea");
	}
</script>

<Button
	className="warning"
	extraClasses="btn-xs"
	{disabled}
	on:click={handleClick}
>
	Clear
</Button>

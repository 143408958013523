<script>
	import paramsStore from "../../stores/paramsStore.js";
	import topicsStore from "../../stores/data/topicsStore.js";

	import redirect from "../../util/redirect.js";
	import { redirectToTopic, redirectTo } from "../../util/redirectTo.js";

	import currentUserStore from "../../stores/data/currentUserStore.js";

	export let post = null;
	export let forum = null;
	export let section = null;
	export let topic = null;
	export let url = null;

	$: currentUser = $currentUserStore;
	$: pageSize = currentUser?.settings?.pageSize || 50;
	$: currentTopicSlug = $paramsStore.topicSlug;
	$: currentTopic = $topicsStore[currentTopicSlug];

	function handleClick() {
		if (currentTopic?.slug === topic) {
			if (topic && !(forum && section)) {
				forum = currentTopic.forumSlug;
				section = currentTopic.sectionSlug;
			}
			if (!forum && !section && !topic) return redirect("");
		}
		if (topic) {
			return redirectToTopic({
				forumSlug: forum,
				sectionSlug: section,
				topicSlug: topic,
				postNumber: post,
				pageSize,
				currentTopicSlug,
			});
		}
		if (forum || section) {
			return redirectTo({
				forumSlug: forum,
				sectionSlug: section,
			});
		}
		redirect("");
	}
</script>

{#if url}
	<a class="link" href={url}>
		<slot />
	</a>
{:else}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<span class="link" on:click={handleClick}>
		<slot />
	</span>
{/if}

<style>
	.link {
		text-decoration-line: underline;
	}
</style>

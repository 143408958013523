<script>
	import { createEventDispatcher } from "svelte";
	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let concatenationPiece;
	export let scopeType;
	export let rank;
	export let valueId;

	const dispatch = createEventDispatcher();

	$: handleConcatenationPieceChange(concatenationPiece);

	function handleConcatenationPieceChange(concatenationPiece) {
		dispatch("change", { value: concatenationPiece, rank });
	}
</script>

<SetupValueSelect
	bind:value={concatenationPiece}
	{scopeType}
	dataType="Text"
	dataTypes={["Text", "Number"]}
	allowRaw
	excluded={valueId}
/>

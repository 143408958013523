<script>
	import { onMount } from "svelte";

	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import sectionsStore from "../../../../stores/data/sectionsStore.js";
	import topicsStore from "../../../../stores/data/topicsStore.js";

	import FormModal from "../../../shared/modal/FormModal.svelte";
	import MoveTopicForm from "../../../../forms/MoveTopicForm.js";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];

	$: args = forum && section && topic && { forum, section, topic };

	onMount(handleMount);

	function handleMount() {
		if ((forum?.sectionGroupIds || []).length === 0) {
			forumsStore.fetchSectionGroups(forumSlug);
		}
	}
</script>

{#if args}
	<FormModal form={MoveTopicForm} disabled={!forum} {args} />
{/if}

<script>
	export let columns = 1;

	const cols = Math.min(Math.max(columns, 0), 12);

	const gridColsClass = [
		"",
		"grid-cols-1",
		"grid-cols-2",
		"grid-cols-3",
		"grid-cols-4",
		"grid-cols-5",
		"grid-cols-6",
		"grid-cols-7",
		"grid-cols-8",
		"grid-cols-9",
		"grid-cols-10",
		"grid-cols-11",
		"grid-cols-12",
	][cols];
</script>

<div class="grid {gridColsClass} gap-1 sm:gap-2 w-fit"><slot /></div>

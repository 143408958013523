import bookmarksStore from "./bookmarksStore";
import domainsStore from "./domainsStore";
import draftsStore from "./draftsStore";
import forumsStore from "./forumsStore";
import notificationsStore from "./notificationsStore";
import sectionGroupsStore from "./sectionGroupsStore";
import sectionsStore from "./sectionsStore";
import topicsStore from "./topicsStore";
import usersDataStore from "./usersDataStore";

const stores = [
	bookmarksStore,
	domainsStore,
	draftsStore,
	forumsStore,
	notificationsStore,
	sectionGroupsStore,
	sectionsStore,
	topicsStore,
	usersDataStore,
];

function resetDataStores() {
	stores.forEach((store) => store.reset());
}

export default resetDataStores;

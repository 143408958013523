<script>
	import SetupValueSelect from "./SetupValueSelect.svelte";
	import Field from "../../../../forms/Field.svelte";

	export let filterList;
	export let scopeType;
	export let dataType;

	$: listItemDataType = dataType.split(" ")[2].slice(0, -1);
	$: sortTypeField = getSortTypeField(filterList);

	function getSortTypeField(filterList) {
		return {
			type: "input",
			inputType: "select",
			items: ["Ascending", "Descending"],
			value: filterList.sortType,
		};
	}

	function handleFilterByChange({ detail }) {
		filterList.filterBy = detail.value;
	}

	function handleSortByChange({ detail }) {
		filterList.sortBy = detail.value;
	}

	function handleSortTypeChange({ detail }) {
		filterList.sortType = detail.value;
	}
</script>

<SetupValueSelect
	dataType="True or False"
	scopeTypes={[listItemDataType]}
	placeholder="Filter by"
	value={filterList.filterBy}
	on:event={handleFilterByChange}
/>
<SetupValueSelect
	dataType="Number"
	scopeTypes={[listItemDataType]}
	{scopeType}
	placeholder="Sort by"
	allowNull
	value={filterList.sortBy}
	on:event={handleSortByChange}
/>
{#if sortTypeField}
	<Field field={sortTypeField} on:event={handleSortTypeChange} />
{/if}

import getAllowedScopeTypes from "../getAllowedScopeTypes.js";

export default function getSetupValueItems({
	setup,
	dataType,
	dataTypes,
	baseValue,
	allowRaw,
	allowNull,
	scopeType,
	scopeTypes,
	excludedScopeTypes,
	valueTypes,
	excludedValueTypes,
	excluded,
}) {
	let allowedScopeTypes = getAllowedScopeTypes(scopeType, scopeTypes, true);
	if (excludedScopeTypes) {
		allowedScopeTypes = allowedScopeTypes.filter(
			(st) => !excludedScopeTypes.includes(st)
		);
	}
	let result = allowNull ? [{ value: null, label: " " }] : [];
	if (allowRaw) {
		result = [...result, ...rawItems({ dataType, setup })];
	}
	if (baseValue?.allowedValues?.length > 0) {
		result = [...result, ...allowedValuesItems({ baseValue })];
	}
	result = [...result, ...standardItems(scopeType, dataType)];
	for (const allowedScopeType of allowedScopeTypes || []) {
		result = [
			...result,
			...getItemsOfScopeType({
				allowedScopeType,
				setup,
				dataType,
				dataTypes,
				valueTypes,
				excludedValueTypes,
				excluded,
				baseValue,
			}),
		];
	}
	return result;
}

function getItemsOfScopeType({
	allowedScopeType,
	setup,
	dataType,
	dataTypes,
	valueTypes,
	excludedValueTypes,
	excluded,
	baseValue,
}) {
	if (!setup.values[allowedScopeType]) return [];
	let result = [];
	if (dataType === "Text") {
		if (allowedScopeType === "Role") {
			result = [
				...result,
				{ value: "___Role Full___", label: "Role Full" },
				{ value: "___Role Private___", label: "Role Private" },
				{ value: "___Role Public___", label: "Role Public" },
			];
		} else {
			result = [
				...result,
				{ value: `___${allowedScopeType}___`, label: allowedScopeType },
			];
			if (allowedScopeType == "Game") {
				result = [
					...result,
					{
						value: "___Game Deadline___",
						label: "Game: Deadline",
					},
				];
			}
		}
	}
	const setupValues = setup.values[allowedScopeType].filter(
		(v) =>
			((!dataType && !dataTypes) ||
				dataTypes?.includes(v.dataType) ||
				v.dataType === dataType ||
				dataType === "Text") &&
			(!valueTypes || valueTypes.includes(v.valueType)) &&
			(!excludedValueTypes || !excludedValueTypes.includes(v.valueType)) &&
			v.id !== excluded &&
			v.id !== baseValue?.id
	);
	for (const setupValue of setupValues) {
		result = [
			...result,
			{
				value: setupValue.id,
				label: `${allowedScopeType}: ${setupValue.name}`,
				dataType: setupValue.dataType,
			},
		];
		if (["Team", "Role", "Player", "Action"].includes(setupValue.dataType)) {
			result = nestedItems({
				result,
				setupValue,
				setup,
				dataType,
			});
		}
	}
	return result;
}

function standardItems(scopeType, dataType) {
	if (scopeType === "Player" && dataType === "Team") {
		return [
			{
				value: "___Team___",
				label: "Player: Team",
			},
		];
	}
	return [];
}

function rawItems({ dataType, setup }) {
	{
		if (dataType === "True or False") {
			return [
				{
					value: "True",
					label: "True",
					dataType: "True or False",
					raw: true,
				},
				{
					value: "False",
					label: "False",
					dataType: "True or False",
					raw: true,
				},
			];
		}
		if (dataType === "Team") {
			return setup.teams.map((team) => ({
				value: team.id,
				label: team.teamFlavor.name,
				dataType: "Team",
				raw: true,
			}));
		}
		if (dataType === "Role") {
			return setup.roles.map((role) => ({
				value: role.id,
				label: role.roleFlavor.fullName,
				dataType: "Role",
				raw: true,
			}));
		}
		return [];
	}
}

function allowedValuesItems({ baseValue }) {
	return baseValue.allowedValues.map((value) => ({
		value: value,
		label: value,
		dataType: value.dataType,
		raw: true,
	}));
}

function nestedItems({ result, setupValue, setup, dataType }) {
	const setupValueAllowedScopeTypes = getAllowedScopeTypes(setupValue.dataType);
	for (const setupValueAllowedScopeType of setupValueAllowedScopeTypes) {
		if (!["Setup", "Game"].includes(setupValueAllowedScopeType)) {
			for (const objectSetupValue of setup.values[setupValueAllowedScopeType]) {
				if (dataType === "Text" || objectSetupValue.dataType === dataType) {
					result = [
						...result,
						{
							value: `${setupValue.id}: ${objectSetupValue.id}`,
							label: `${setupValue.name}: ${objectSetupValue.name}`,
							dataType: setupValue.dataType,
						},
					];
				}
			}
		}
	}
	return result;
}

<script>
	import Ban from "./Ban.svelte";

	export let card;
</script>

{#if card.bans}
	<div class="flex flex-wrap gap-2 mt-1">
		<div class="font-bold pt-1">Banned:</div>
		{#each card.bans as ban}
			<Ban {ban} />
		{/each}
	</div>
{/if}

<script>
	import Chat from "./Chat.svelte";

	export let chats;
	export let chatConstants;
	export let chatVariables;

	$: chatsToShow = getChatsToShow(chats);

	function getChatsToShow(chats) {
		if (chats.find((chat) => [null, ""].includes(chat.name))) {
			return [...chats];
		}
		return [
			...chats,
			{
				name: "",
				chatType: "Default",
				title: null,
				randomUrl: "False",
				revealRoles: "False",
				revealAllRoles: "False",
				permissions: {
					playerView: "True",
					playerChat: "True",
					spectatorView: "False",
					spectatorChat: "False",
					spectatorViewUrl: "False",
					spectatorChatPassword: "False",
					spectatorViewAdd: "False",
					spectatorChatAdd: "False",
				},
				constants: {},
				variables: {},
			},
		];
	}

	function handleChatChange(i, detail) {
		chats[i] = detail.value;
	}
</script>

<div class="flex flex-col gap-2">
	{#each chatsToShow as chat, i}
		<Chat
			{chat}
			{chatConstants}
			{chatVariables}
			on:event={({ detail }) => handleChatChange(i, detail)}
		/>
	{/each}
</div>

import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import currentUserStore from "../stores/data/currentUserStore.js";

class EmailForm extends Form {
	static title = "Email Address";
	static code = "email-address";

	static initialFields({ currentUser, emailAddress, emailConfirmed }) {
		emailAddress ||= currentUser?.account?.email?.address || "";
		if (emailConfirmed == null) {
			emailConfirmed = currentUser?.account?.email?.confirmed;
		}
		return [
			{
				type: "input",
				inputType: "email",
				initialValue: emailAddress,
				value: emailAddress,
				label: "Email address",
				id: "email-address-email-address",
			},
			{
				type: "emailConfirmation",
				value: "",
				confirmed: emailConfirmed,
				label: "Confirmation code",
				id: "email-address-confirmation-code",
			},
		];
	}

	static async submit({ fields }) {
		const initialEmailAddress = fields[0].initialValue;
		const emailAddress = fields[0].value;
		const confirmationCode = fields[1].value;
		if (initialEmailAddress === emailAddress && confirmationCode.length !== 6) {
			return {
				errors: {
					"email-address-confirmation-code":
						"Please enter the code that was sent to your email address.",
				},
			};
		}
		const args = {};
		if (emailAddress !== initialEmailAddress) args.emailAddress = emailAddress;
		if (confirmationCode?.length === 6)
			args.confirmationCode = confirmationCode;
		return Fetcher.updateEmail(args);
	}

	static handleSuccess = (json) => {
		currentUserStore.updateCurrentUser(json);
		if (json.account?.email?.confirmed === false) {
			this.open({
				emailAddress: json.account.email.address,
				emailConfirmed: false,
			});
		}
	};
}

export default EmailForm;

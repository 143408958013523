<script>
	import Fetcher from "../../../../../api/gameCreator/Fetcher";

	import paramsStore from "../../../../../stores/paramsStore";
	import gamesStore from "../../../../../stores/data/gameCreator/gamesStore";
	import topicsStore from "../../../../../stores/data/topicsStore";

	import Button from "../../../../tailwind/Button.svelte";
	import Player from "../../../../../bbCode/components/Player.svelte";
	import Align from "../../../../tailwind/align/Align.svelte";
	import Badge from "../../../../tailwind/Badge.svelte";
	import VotePlaceholder from "./VotePlaceholder.svelte";
	import Votes from "./Votes.svelte";
	import VoteCounts from "./VoteCounts.svelte";

	import currentUserStore from "../../../../../stores/data/currentUserStore";

	export let actionVariableInput;

	let gridWidth;

	$: gameSlug = $paramsStore.gameSlug;
	$: game = $gamesStore[gameSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[`${gameSlug}/${topicSlug}`];

	$: currentPlayer = getCurrentPlayer(game, $currentUserStore);
	$: currentVote = actionVariableInput.votes.find(
		(vote) => vote.player == currentPlayer?.user.username
	);

	$: notVoting = actionVariableInput.votes.filter((vote) => vote.value == null);

	$: majority = Math.ceil((actionVariableInput.votes.length + 0.5) / 2);

	$: votesPerRow = gridWidth && Math.floor((gridWidth + 8) / 109);
	$: rows = Math.ceil(majority / votesPerRow);
	$: rowHeight = rows * 40 - 8;
	$: notVotingRows = Math.ceil(notVoting.length / votesPerRow);
	$: notVotingHeight = Math.max(notVotingRows * 40 - 8, 32);
	$: voteCounts = getVoteCounts(actionVariableInput);
	$: playersToChooseFrom = getPlayersToChooseFrom(
		actionVariableInput,
		voteCounts
	);

	$: votes = actionVariableInput.votes.sort((a, b) => a.id.localeCompare(b.id));

	$: console.log("votes", votes);

	$: console.log("voteCounts", voteCounts);

	function getCurrentPlayer(game, currentUser) {
		let result;
		if (
			game.testPerspective &&
			!["Moderator", "Spectator"].includes(game.testPerspective)
		) {
			result = game.players.find((player) => player.id == game.testPerspective);
			if (result) return result;
		}
		return game.players.find((player) => player.user.id == currentUser.id);
	}

	function getVoteCounts(actionVariableInput) {
		let result = {};
		for (const player of actionVariableInput.playersToChooseFrom) {
			result[player.id] = 0;
		}
		for (const vote of actionVariableInput.votes) {
			result[vote.value] ||= 0;
			result[vote.value] += 1;
		}
		return result;
	}

	function getPlayersToChooseFrom(actionVariableInput, voteCounts) {
		return actionVariableInput.playersToChooseFrom.sort(
			(a, b) => voteCounts[b.id] - voteCounts[a.id]
		);
	}

	function handleVote(player) {
		Fetcher.changeActionVariableInputVote({
			id: actionVariableInput.id,
			value: player?.id,
		}).then((json) => {
			console.log("jcsn", json);
			if (!json.actionVariableInput) return;
			topicsStore.updateActionVariableInput({
				gameSlug,
				topicSlug,
				actionVariableInput: json.actionVariableInput,
			});
		});
	}
</script>

{#if actionVariableInput.setupValue?.dataType === "Player"}
	<div class="flex gap-2">
		<div class="flex flex-col gap-2 relative">
			{#each playersToChooseFrom as player}
				{#if currentVote?.value === player.id}
					<div style="height: {rowHeight}px;">
						<Align vertical="center">
							<Button className="info" size="small" full on:click={handleVote}>
								Unvote
							</Button>
						</Align>
					</div>
				{:else if currentVote}
					<div style="height: {rowHeight}px;">
						<Align vertical="center">
							<Button
								className="warning"
								size="small"
								full
								on:click={() => handleVote(player)}
							>
								Vote
							</Button>
						</Align>
					</div>
				{:else}
					<div class="invisible" style="height: {rowHeight}px;"></div>
				{/if}
			{/each}
			<div class="invisible">
				<div style="height: {notVotingHeight}px;"></div>
			</div>
		</div>
		<div class="flex flex-col gap-2">
			{#each playersToChooseFrom as player}
				<div style="height: {rowHeight}px;">
					<Align vertical="center">
						<div style="width: 101px;">
							<Player full>{player.user.username}</Player>
						</div>
					</Align>
				</div>
			{/each}
			<div style="height: 32px;">
				<Align vertical="center">
					<Badge colorName="info" full>Not Voting</Badge>
				</Align>
			</div>
		</div>
		<VoteCounts {playersToChooseFrom} {rowHeight} {votes} {notVoting} />
		<div
			bind:clientWidth={gridWidth}
			class="flex flex-col gap-2 w-full h-full relative"
		>
			{#if !gridWidth}
				<div class="invisible">0</div>
			{:else}
				{#each playersToChooseFrom as player}
					<VotePlaceholder {majority} {votesPerRow} {rowHeight} />
				{/each}
			{/if}

			<Votes
				votes={actionVariableInput.votes}
				options={actionVariableInput.playersToChooseFrom}
				{votesPerRow}
				{rows}
				{rowHeight}
				{currentVote}
			/>
		</div>
	</div>
{/if}

<script>
	import DateTime from "../../../../util/DateTime.js";
	import SvelteSelect from "./SvelteSelect.svelte";

	export let field;
	export let error = null;

	const items = DateTime.timeZones();
</script>

<SvelteSelect bind:value={field.value} {items} {error} on:change on:event />

<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import ChangeVariable from "./ChangeVariable.svelte";
	import If from "./If.svelte";
	import SetDeadline from "./SetDeadline.svelte";
	import ObjectEffect from "./ObjectEffect.svelte";
	import CreateChat from "./CreateChat.svelte";
	import PostInChat from "./PostInChat.svelte";
	import AddToChat from "./AddToChat.svelte";

	export let effectLine;
	export let scopeType;
	export let effectId;

	const dispatch = createEventDispatcher();
	const objectEffects = [
		"Game Effect",
		"Team Effect",
		"Player Effect",
		"Action Effect",
		"Chat Effect",
	];

	$: effectLineTypeField = getEffectLineTypeField(effectLine);

	function getEffectLineTypeField(effectLine) {
		if (!effectLine) return;
		return {
			type: "input",
			inputType: "select",
			items: [
				"Change Variable",
				"If",
				"Set Deadline",
				...objectEffects,
				"Create Chat",
				"Post in Chat",
				...scopedEffectLineTypes(scopeType),
			],
			value: effectLine.effectLineType,
			placeholder: "Effect Line Type",
			padding: true,
		};
	}

	function scopedEffectLineTypes(scopeType) {
		if (scopeType === "Game") {
			return ["Create Actions", "Resolve Actions", "Check Game End Conditions"];
		}
		if (scopeType === "Player") {
			return ["Add as Chat Viewer", "Add as Chat Poster"];
		}
		return [];
	}

	function handleEffectLineTypeChange({ detail }) {
		effectLine.effectLineType = null;
		setTimeout(() => {
			const newEffectLineType = detail.value;
			if (newEffectLineType === effectLine.effectLineType) return;
			effectLine = {
				effectLineType: newEffectLineType,
			};
			dispatch("event", { value: effectLine });
		}, 1);
	}
</script>

{#if effectLineTypeField}
	<Field field={effectLineTypeField} on:event={handleEffectLineTypeChange} />
{/if}
{#if effectLine.effectLineType}
	{#if effectLine.effectLineType === "Change Variable"}
		<ChangeVariable {effectLine} {scopeType} on:event />
	{:else if effectLine.effectLineType === "If"}
		<If {effectLine} {scopeType} {effectId} on:event />
	{:else if effectLine.effectLineType === "Set Deadline"}
		<SetDeadline {effectLine} {scopeType} on:event />
	{:else if objectEffects.includes(effectLine.effectLineType)}
		<ObjectEffect {effectLine} {scopeType} {effectId} on:event />
	{:else if effectLine.effectLineType === "Create Chat"}
		<CreateChat {effectLine} {scopeType} on:event />
	{:else if effectLine.effectLineType === "Post in Chat"}
		<PostInChat {effectLine} {scopeType} on:event />
	{:else if effectLine.effectLineType === "Add as Chat Viewer"}
		<AddToChat {effectLine} {scopeType} on:event />
	{:else if effectLine.effectLineType === "Add as Chat Poster"}
		<AddToChat {effectLine} {scopeType} on:event />
	{:else}
		<div></div>
		<div></div>
		<div></div>
	{/if}
{/if}

<script>
	import DeleteButton from "./DeleteButton.svelte";
	import ClearButton from "./ClearButton.svelte";
	import PostButton from "./PostButton.svelte";
	import TopicLocked from "./TopicLocked.svelte";
	import PostUnlocker from "./PostUnlocker.svelte";

	export let topic;
	export let draftId;
	export let text;
	export let textMatches;
	export let showTextarea;
	export let postEdit;

	$: showButtons = text?.length > 0;
	$: canPost = topic?.userPermissions?.post;
	$: canUnlock = topic?.userPermissions?.postUnlock;
</script>

{#if showButtons}
	<div>
		<DeleteButton
			bind:draftId
			bind:text
			disabled={!textMatches}
			on:focusTextarea
		/>
	</div>
	<div>
		<ClearButton
			bind:draftId
			bind:text
			disabled={!textMatches}
			on:focusTextarea
		/>
	</div>
	{#if !topic || canPost}
		<div>
			<PostButton
				bind:draftId
				bind:text
				bind:showTextarea
				disabled={!textMatches || !canPost}
				{postEdit}
			/>
		</div>
	{/if}
{/if}

{#if topic && !canPost}
	{#if canUnlock}
		<PostUnlocker {topic} />
	{:else}
		<TopicLocked />
	{/if}
{/if}

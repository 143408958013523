export function getColumns(permissions) {
	const minViewPermission = Math.min(
		permissions.view,
		permissions.viewUnlock,
		permissions.viewAdd
	);
	if (minViewPermission <= -20) return ["admin", "member", "guest"];
	if (minViewPermission <= 0) return ["admin", "member"];
	if (minViewPermission <= 10) return ["admin"];
	return [];
}

export function values(args) {
	return {
		value: value(args),
		valueMin: valueMin(args),
		valueMax: valueMax(args),
	};
}

function value({ codes, existing, parent, meta = false }) {
	let result = {};
	const func = meta ? superValue : actualValue;
	for (const code of codes) {
		result = {
			...result,
			...func({ code, existing, parent }),
		};
	}
	if (!meta) {
		if (codes.includes("createTopic")) {
			result["createTopicPassword"] = parent.createTopicPassword || "";
		}
		if (codes.includes("post")) {
			result["postPassword"] = parent.postPassword || "";
		}
	}
	return result;
}

function superValue({ code, existing, parent }) {
	if (existing) return existing;
	if (parent) return parent;
	if (code === "view") {
		return {
			viewMin: -20,
			viewMinUnlock: -20,
			viewMinAdd: 0,
			viewDefault: -20,
			viewDefaultUnlock: -20,
			viewDefaultAdd: 0,
			viewMax: 20,
			viewMaxUnlock: 20,
			viewMaxAdd: 20,
		};
	}
	return {
		[`${code}Min`]: -20,
		[`${code}MinUnlock`]: -20,
		[`${code}MinAdd`]: 0,
		[`${code}Default`]: 0,
		[`${code}DefaultUnlock`]: 0,
		[`${code}DefaultAdd`]: 0,
		[`${code}Max`]: 20,
		[`${code}MaxUnlock`]: 20,
		[`${code}MaxAdd`]: 20,
	};
}

function actualValue({ code, existing, parent }) {
	const unlockCode = `${code}Unlock`;
	const addCode = `${code}Add`;
	const defaultCode = `${code}Default`;
	const defaultUnlockCode = `${defaultCode}Unlock`;
	const defaultAddCode = `${defaultCode}Add`;
	if (existing) {
		return {
			[code]: existing[code],
			[unlockCode]: existing[unlockCode],
			[addCode]: existing[addCode],
		};
	}
	if (parent) {
		return {
			[code]: parent[defaultCode],
			[unlockCode]: parent[defaultUnlockCode],
			[addCode]: parent[defaultAddCode],
		};
	}
	return {
		[code]: -20,
		[unlockCode]: -20,
		[addCode]: 0,
	};
}

function valueMin({ codes, parent, meta = false }) {
	let result = {};
	const func = meta ? superValueMin : actualValueMin;
	for (const code of codes) {
		result = {
			...result,
			...func({ code, parent }),
		};
	}
	return result;
}

function superValueMin({ code, parent }) {
	if (parent) return minmaxFromParent({ code, parent, minmax: "Min" });
	return {
		[`${code}Min`]: -20,
		[`${code}MinUnlock`]: -20,
		[`${code}MinAdd`]: 0,
		[`${code}Default`]: -20,
		[`${code}DefaultUnlock`]: -20,
		[`${code}DefaultAdd`]: 0,
		[`${code}Max`]: -20,
		[`${code}MaxUnlock`]: -20,
		[`${code}MaxAdd`]: 0,
	};
}

function actualValueMin({ code, parent }) {
	if (parent && parent[`${code}Min`]) {
		return {
			[code]: parent[`${code}Min`],
			[`${code}Unlock`]: parent[`${code}MinUnlock`],
			[`${code}Add`]: parent[`${code}MinAdd`],
		};
	}
	return {
		[code]: -20,
		[`${code}Unlock`]: -20,
		[`${code}Add`]: 0,
	};
}

function valueMax({ codes, parent, meta = false }) {
	let result = {};
	const func = meta ? superValueMax : actualValueMax;
	for (const code of codes) {
		result = {
			...result,
			...func({ code, parent }),
		};
	}
	return result;
}

function superValueMax({ code, parent }) {
	if (parent) return minmaxFromParent({ code, parent, minmax: "Max" });
	return {
		[`${code}Min`]: 20,
		[`${code}MinUnlock`]: 20,
		[`${code}MinAdd`]: 20,
		[`${code}Default`]: 20,
		[`${code}DefaultUnlock`]: 20,
		[`${code}DefaultAdd`]: 20,
		[`${code}Max`]: 20,
		[`${code}MaxUnlock`]: 20,
		[`${code}MaxAdd`]: 20,
	};
}

function actualValueMax({ code, parent, existing }) {
	if (existing && code === "view") {
		return {
			[code]: existing[code],
			[`${code}Unlock`]: existing[`${code}Unlock`],
			[`${code}Add`]: existing[`${code}Add`],
		};
	}
	if (parent && parent[`${code}Max`]) {
		return {
			[code]: parent[`${code}Max`],
			[`${code}Unlock`]: parent[`${code}MaxUnlock`],
			[`${code}Add`]: parent[`${code}MaxAdd`],
		};
	}
	return {
		[code]: 20,
		[`${code}Unlock`]: 20,
		[`${code}Add`]: 20,
	};
}

function minmaxFromParent({ code, parent, minmax }) {
	return {
		[`${code}Min`]: parent[`${code}${minmax}`],
		[`${code}MinUnlock`]: parent[`${code}${minmax}Unlock`],
		[`${code}MinAdd`]: parent[`${code}${minmax}Add`],
		[`${code}Default`]: parent[`${code}${minmax}`],
		[`${code}DefaultUnlock`]: parent[`${code}${minmax}Unlock`],
		[`${code}DefaultAdd`]: parent[`${code}${minmax}Add`],
		[`${code}Max`]: parent[`${code}${minmax}`],
		[`${code}MaxUnlock`]: parent[`${code}${minmax}Unlock`],
		[`${code}MaxAdd`]: parent[`${code}${minmax}Add`],
	};
}

<script>
	import { createEventDispatcher } from "svelte";

	import Button from "../tailwind/Button.svelte";
	import Icon from "../icons/large/Icon.svelte";

	export let code;
	export let button;

	const dispatch = createEventDispatcher();

	function handleClick() {
		dispatch("insert", { code });
	}
</script>

<div class:tooltip={button.tooltip} data-tip={button.tooltip}>
	<Button
		className="default"
		extraClasses="btn-xs sm:btn-sm btn-outline"
		outline={true}
		square={true}
		on:click={handleClick}
	>
		{#if button.icon}
			<Icon name={button.icon} />
		{:else}
			<span class={button.textClass || ""}>{button.text}</span>
		{/if}
	</Button>
</div>

<script>
	import { tweened } from "svelte/motion";
	import { cubicInOut } from "svelte/easing";

	import Player from "../../../../../bbCode/components/Player.svelte";

	export let vote;

	let top, left;

	let pos;
	$: handleVoteChange(vote);

	function handleVoteChange(vote) {
		if (pos) {
			pos.set({ top: vote.top, left: vote.left });
		} else {
			pos = tweened(
				{ top: vote.top, left: vote.left },
				{ duration: 1500, easing: cubicInOut }
			);
		}
		// if (top && left) {
		// 	top.set(vote.top);
		// 	left.set(vote.left);
		// } else {
		// 	top = tweened(vote.top, {
		// 		duration: 1000,
		// 		easing: cubicInOut,
		// 	});
		// 	left = tweened(vote.left, {
		// 		duration: 1000,
		// 		easing: cubicInOut,
		// 	});
	}
</script>

<div
	class="absolute opacity-100 z-50"
	style="width: 101px; top: {$pos.top}px; left: {$pos.left}px;"
>
	<Player full>{vote.player}</Player>
</div>

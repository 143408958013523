<script>
	import Icon from "../icons/large/Icon.svelte";
	import Align from "../tailwind/align/Align.svelte";

	export let value = true;
	export let height;
	export let neutralState = false;

	$: color = getColor(value, neutralState);
	$: tickOrX = value ? "tick" : "x";

	let hovered = false;

	function getColor(value, neutralState) {
		if (neutralState) return "#2a0a8a";
		return value ? "green" : "red";
	}

	function handleClick() {
		value = !value;
	}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="rounded-lg p-4 flex cursor-pointer text-lg sm:text-xl"
	class:bg-base-200={value || neutralState}
	class:bg-base-300={!value && !neutralState}
	style="border: {hovered ? 3 : 2}px solid {color}; height: {height}px;"
	on:click={handleClick}
	on:click
	on:mouseenter={() => (hovered = true)}
	on:mouseleave={() => (hovered = false)}
>
	<Align vertical="center">
		<slot />
	</Align>
	<div class="flex-grow"></div>
	{#if !neutralState}
		<div style="color: {color};">
			<Align vertical="center">
				<Icon name={tickOrX} />
			</Align>
		</div>
	{/if}
</div>

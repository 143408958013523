import { writable } from "svelte/store";
import topicsStore from "./topicsStore";
import pageStore from "../pageStore.js";
import API from "../../api/API";
import DateTime from "../../util/DateTime";
import { mergeObjects } from "./merge.js";

const { subscribe, set, update } = writable({});

let previousRequests = {
	page: {},
};

function reset() {
	set({});
	previousRequests = {
		page: {},
	};
}

function fetchSection({ forumSlug, sectionSlug }) {
	API.get(`forums/${forumSlug}/sections/${sectionSlug}`).then(updateSection);
}

function fetchPage(forumSlug, sectionSlug, pageNumber) {
	const currentRequest = DateTime.now();
	previousRequests.page[forumSlug] ||= {};
	previousRequests.page[forumSlug][sectionSlug] ||= {};
	const previousRequest =
		previousRequests.page[forumSlug][sectionSlug][pageNumber];
	API.get(`forums/${forumSlug}/sections/${sectionSlug}/pages/${pageNumber}`, {
		previousRequest,
	}).then((json) => {
		updateSection(
			{ slug: sectionSlug, topics: json.topics, topicCount: json.itemCount },
			pageNumber
		);
		pageStore.set({ pageNumber: json.pageNumber, pageCount: json.pageCount });
		previousRequests.page[forumSlug][sectionSlug][pageNumber] = currentRequest;
	});
}

function updateSections(newSections) {
	update((sections) => {
		const mergeFunc = Array.isArray(newSections)
			? mergeInSectionsArray
			: mergeInSectionsObject;
		return mergeFunc(sections, newSections);
	});
}

function mergeInSectionsArray(sections, newSections, topicPageNumber) {
	for (const newSection of newSections) {
		const section = sections[newSection.slug];
		sections[newSection.slug] = mergeInSection(
			section,
			newSection,
			topicPageNumber
		);
	}
	return sections;
}

function mergeInSectionsObject(sections, newSections, topicPageNumber) {
	for (const [sectionSlug, newSection] of Object.entries(newSections)) {
		const section = sections[sectionSlug];
		sections[sectionSlug] = mergeInSection(
			section,
			newSection,
			topicPageNumber
		);
	}
	return sections;
}

function mergeInSection(section = {}, newSection, topicPageNumber = 1) {
	let replace = [];
	let topicSlugs = section.topicSlugs || [];
	if (newSection.topics) {
		const newTopics = newSection.topics;
		const newTopicSlugs = newTopics.map((t) => t.slug);
		const offset = (topicPageNumber - 1) * 50;
		for (const newTopicSlug of newTopicSlugs) {
			if (newTopicSlug) {
				for (let i = 0; i < newTopicSlugs.length; i++) {
					if (newTopicSlugs.includes(newTopicSlug)) {
						topicSlugs[offset + i] = null;
					}
				}
			}
		}
		for (let i = 0; i < newTopicSlugs.length; i++) {
			const newTopicSlug = newTopicSlugs[i];
			if (newTopicSlug) {
				topicSlugs[offset + i] = newTopicSlugs[i];
			}
		}
		topicsStore.updateTopics(newTopics);
	}
	if (newSection.lastTopic) {
		topicSlugs = [
			newSection.lastTopic.slug,
			...topicSlugs.filter((slug) => slug !== newSection.lastTopic.slug),
		];
		replace = ["topicSlugs"];
	}
	const keys = [
		"id",
		"title",
		"description",
		"forumSlug",
		"lastTopic",
		"postCount",
		"rank",
		"sectionGroupId",
		"slug",
		"title",
		"topicCount",
		"topicSlugs",
		"permissions",
		"userAuthority",
		"userPermissions",
		"permissions",
	];
	const result = mergeObjects(
		section,
		{ ...newSection, topicSlugs },
		keys,
		replace
	);
	return result;
}

function updateSection(newSection, topicPageNumber) {
	update((sections) =>
		mergeInSectionsArray(sections, [newSection], topicPageNumber)
	);
}

export default {
	subscribe,
	reset,
	updateSections,
	updateSection,
	fetchSection,
	fetchPage,
};

<script>
	import { createEventDispatcher } from "svelte";

	import FlavorCard from "./FlavorCard.svelte";
	import Field from "../../../../forms/Field.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let flavor;

	const dispatch = createEventDispatcher();

	$: nameField = getNameField(flavor);
	$: primaryColorField = getPrimaryColorField(flavor);
	$: secondaryColorField = getSecondaryColorField(flavor);

	function getNameField(flavor) {
		return {
			type: "input",
			inputType: "text",
			value: flavor.name,
			maxLength: 32,
			label: "Name",
		};
	}

	function getPrimaryColorField(flavor) {
		return {
			type: "input",
			inputType: "color",
			value: flavor.primaryColor,
			label: "Primary Color",
		};
	}

	function getSecondaryColorField(flavor) {
		return {
			type: "input",
			inputType: "color",
			value: flavor.secondaryColor,
			label: "Secondary Color",
		};
	}

	function handleNameFieldChange({ detail }) {
		if (detail.type === "input") {
			dispatch("event", { value: { ...flavor, name: detail.value } });
		}
	}

	function handlePrimaryColorChange({ detail }) {
		dispatch("event", { value: { ...flavor, primaryColor: detail.value } });
	}

	function handleSecondaryColorChange({ detail }) {
		dispatch("event", {
			value: { ...flavor, secondaryColor: detail.value },
		});
	}

	function duplicateFlavor() {
		dispatch("duplicateFlavor");
	}
</script>

<FlavorCard {flavor} title={flavor.name}>
	<div slot="rightOfTitle">
		<Button
			square
			size="small"
			tooltip="Duplicate Flavor"
			on:click={duplicateFlavor}
		>
			<Icon name="copy" />
		</Button>
	</div>
	<div class="mt-2">
		{#if nameField}
			<Field field={nameField} on:event={handleNameFieldChange} />
		{/if}
		<div class="grid grid-cols-2">
			{#if primaryColorField}
				<Field field={primaryColorField} on:event={handlePrimaryColorChange} />
			{/if}
			{#if secondaryColorField}
				<Field
					field={secondaryColorField}
					on:event={handleSecondaryColorChange}
				/>
			{/if}
		</div>
	</div>
</FlavorCard>

<script>
	import FormModal from "../../../shared/modal/FormModal.svelte";
	import CreateTopicForm from "../../../../forms/CreateTopicForm.js";

	export let section;
</script>

{#if section}
	<FormModal form={CreateTopicForm} args={{ section }} />
{/if}

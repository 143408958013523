export default function getAllowedScopeTypes(
	scopeType,
	scopeTypes,
	involveSetup = true
) {
	if (scopeTypes) return scopeTypes;
	if (!scopeType)
		return involveSetup
			? ["Setup", "Game", "Team", "Role", "Player", "Action", "Chat"]
			: ["Game", "Team", "Player", "Action", "Chat"];
	if (scopeType === "Setup") return involveSetup ? ["Setup"] : [];
	if (scopeType === "Game") return involveSetup ? ["Setup", "Game"] : ["Game"];
	if (scopeType === "Team")
		return involveSetup ? ["Setup", "Game", "Team"] : ["Game", "Team"];
	if (scopeType === "Role") {
		return involveSetup ? ["Setup", "Game", "Team", "Role"] : ["Game", "Team"];
	}
	if (scopeType === "Player")
		return involveSetup
			? ["Setup", "Game", "Team", "Role", "Player"]
			: ["Game", "Team", "Player"];
	if (scopeType === "Action")
		return involveSetup
			? ["Setup", "Game", "Team", "Role", "Player", "Action"]
			: ["Game", "Team", "Player", "Action"];
	if (scopeType === "Chat")
		return involveSetup ? ["Setup", "Game", "Chat"] : ["Game", "Chat"];
}

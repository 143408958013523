<script>
	import Card from "../../../../tailwind/card/Card.svelte";
	import CardBody from "../../../../tailwind/card/CardBody.svelte";
	import CardTitle from "../../../../tailwind/card/CardTitle.svelte";
	import Effect from "./Effect.svelte";

	export let effects = [];
	export let title;
	export let scopeType;

	$: effectsToShow = getEffectsToShow(effects);

	function getEffectsToShow(effects) {
		if (effects.find((effect) => [null, ""].includes(effect.name))) {
			return [...effects];
		}
		return [
			...effects,
			{
				effectType: "Normal",
				name: "",
				effectLines: [],
			},
		];
	}

	function handleEffectChange(i, detail) {
		const newEffect = detail.value;
		if (newEffect.effectType === "Normal") {
			newEffect.effectLines ||= [
				{
					effectLineType: "Change Variable",
					variable: null,
					changeType: "Set To",
					setTo: null,
				},
			];
		} else {
			delete newEffect.effectLines;
		}
		if (newEffect.effectType === "Switch") {
			newEffect.switch ||= {
				comparisonDataType: "True or False",
				comparisonValue: "True",
				operator: "equals",
				switchLines: [],
				otherwise: null,
			};
		} else {
			delete newEffect.switch;
		}
		effects[i] = newEffect;
	}
</script>

<Card>
	<CardBody>
		<CardTitle>{title}</CardTitle>
		{#each effectsToShow as effect, i}
			<Effect
				{effect}
				{scopeType}
				on:event={({ detail }) => handleEffectChange(i, detail)}
			/>
		{/each}
	</CardBody>
</Card>

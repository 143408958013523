<script>
	import { createEventDispatcher } from "svelte";

	import TextInput from "./inputs/TextInput.svelte";
	import NumberInput from "./inputs/NumberInput.svelte";
	import PasswordInput from "./inputs/PasswordInput.svelte";
	import EmailInput from "./inputs/EmailInput.svelte";
	import TimeZoneInput from "./inputs/TimeZoneInput.svelte";
	import FileInput from "./inputs/FileInput.svelte";
	import AvatarInput from "./inputs/AvatarInput.svelte";
	import UserInput from "./inputs/UserInput.svelte";
	import ThemeInput from "./inputs/ThemeInput.svelte";
	import SectionsInput from "./inputs/SectionsInput.svelte";
	import SelectInput from "./inputs/SelectInput.svelte";
	import UrlTypeInput from "./inputs/UrlTypeInput.svelte";
	import IconInput from "./inputs/IconInput.svelte";
	import ColorInput from "./inputs/ColorInput.svelte";

	export let field;
	export let error = null;

	let input;

	$: inputComponent = {
		text: TextInput,
		number: NumberInput,
		password: PasswordInput,
		email: EmailInput,
		timeZone: TimeZoneInput,
		file: FileInput,
		avatar: AvatarInput,
		user: UserInput,
		theme: ThemeInput,
		sections: SectionsInput,
		select: SelectInput,
		urlType: UrlTypeInput,
		icon: IconInput,
		color: ColorInput,
	}[field.inputType];

	export function focus() {
		if (input?.focus) input.focus();
	}
</script>

<div class="form-control">
	{#if field.label}
		<label for={field.id} class="label">
			<span class="label-text">{field.label}</span>
		</label>
	{/if}
	<svelte:component
		this={inputComponent}
		bind:this={input}
		bind:field
		bind:error
		on:event
	/>
	{#if error && error.length > 0}
		<label for={field.id} class="label">
			<div class="badge badge-error">{error}</div>
		</label>
	{/if}
</div>

<script>
	import paramsStore from "../../../../stores/paramsStore.js";
	import domainsStore from "../../../../stores/data/domainsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import editSectionsStore from "../../../../stores/editSectionsStore.js";

	import Reorder from "../../../shared/reorder/Reorder.svelte";
	import TextInput from "../../../forms/fields/inputs/TextInput.svelte";
	import EditSectionGroup from "../EditSectionGroup.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: domain = $domainsStore[forumSlug];

	$: editSections = domain && $editSectionsStore[forumSlug];

	function handleSort(e) {
		editSectionsStore.change(forumSlug, e.detail);
	}

	function handleTextEvent(e, index) {
		if (e.detail.type === "keyup") {
			editSectionsStore.renameSectionGroup(forumSlug, index, e.detail.value);
		}
	}

	function itemToField(item) {
		return {
			id: item.id,
			value: item.title,
			placeholder: item.placeholder,
			required: !item.new,
		};
	}
</script>

{#if editSections}
	<Reorder items={editSections} let:item let:index on:sort={handleSort}>
		<div class="w-full flex gap-2">
			<div class="grow">
				<TextInput
					field={itemToField(item)}
					on:event={(e) => handleTextEvent(e, index)}
				/>
			</div>
			<div class:invisible={item.new}>
				<EditSectionGroup sectionGroupId={item.id} />
			</div>
		</div>
	</Reorder>
{/if}

<script>
	import { flip } from "svelte/animate";

	import bookmarksStore from "../../../../stores/data/bookmarksStore";

	import Transition from "../../shared/Transition.svelte";
	import Display from "../../shared/Display.svelte";
	import Bookmark from "./Bookmark.svelte";

	$: bookmarks = $bookmarksStore;
</script>

<Display title="Bookmarks">
	{#each bookmarks as bookmark (bookmark.id)}
		<div animate:flip={{ duration: 200 }}>
			<Transition>
				<Bookmark {bookmark} />
			</Transition>
		</div>
	{/each}
</Display>

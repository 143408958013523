<script>
	import currentUserStore from "../../../stores/data/currentUserStore";
	import formModalStore from "../../../stores/formModalStore";

	import Input from "./Input.svelte";
	import Badge from "../../tailwind/Badge.svelte";

	export let field;
	export let error = null;

	$: currentEmailAddress = $currentUserStore?.account?.email?.address;
	$: emailAddressFieldValue = $formModalStore?.fields[0]?.value;

	$: emailChanged =
		currentEmailAddress &&
		emailAddressFieldValue &&
		currentEmailAddress !== emailAddressFieldValue;

	let textField = {
		type: "input",
		inputType: "text",
		value: "",
		label: "Confirmation code",
		id: "confirmation-code",
		maxLength: 6,
	};

	$: field.value = textField?.value;

	let badgeText;
	let badgeColorName;

	$: handleConfirmedChange(field.confirmed);

	function handleConfirmedChange(confirmed) {
		if (confirmed) {
			badgeText = "Email address confirmed";
			badgeColorName = "success";
		} else {
			badgeText = "Confirmation email sent. Please check your spam folder.";
			badgeColorName = "secondary";
		}
	}
</script>

{#if !emailChanged}
	{#if field.confirmed === false}
		<Input bind:field={textField} bind:error on:event={() => (error = null)} />
	{/if}
	{#if badgeText != null}
		<div class="my-2">
			<Badge colorName={badgeColorName}>{badgeText}</Badge>
		</div>
	{/if}
{/if}

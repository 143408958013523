<script>
	import Team from "./Team.svelte";

	export let teams;
	export let teamConstants;
	export let teamVariables;
	export let roleConstants;
	export let playerVariables;

	function handleTeamChange(i, detail) {
		teams[i] = detail.value;
	}
</script>

{#each teams as team, i}
	<Team
		{team}
		{teamConstants}
		{teamVariables}
		{roleConstants}
		{playerVariables}
		on:event={({ detail }) => handleTeamChange(i, detail)}
	/>
{/each}

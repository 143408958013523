<script>
	import { createEventDispatcher } from "svelte";

	import Field from "../../../../forms/Field.svelte";
	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import Button from "../../../../tailwind/Button.svelte";
	import Icon from "../../../../icons/large/Icon.svelte";

	export let role;
	export let items;

	const dispatch = createEventDispatcher();

	$: itemField = getItemField(role, items);

	function getItemField(role, items) {
		return {
			type: "input",
			inputType: "select",
			items: items
				.filter((item) => item.id)
				.map((item) => ({ value: item.id, label: item.name })),
			value: role.item,
			placeholder: "Item",
			padding: true,
		};
	}

	function handleRoleChange({ detail }) {
		dispatch("event", { value: { ...role, role: detail.value } });
	}

	function handleItemChange({ detail }) {
		dispatch("event", { value: { ...role, item: detail.value } });
	}

	function handleMinimumChange({ detail }) {
		dispatch("event", { value: { ...role, minimum: detail.value } });
	}

	function handleMaximumChange({ detail }) {
		dispatch("event", { value: { ...role, maximum: detail.value } });
	}

	function deleteRole() {
		dispatch("destroy");
	}
</script>

<SetupValueSelect
	dataType="Role"
	scopeType="Game"
	value={role.role}
	placeholder="Role"
	allowRaw
	on:event={handleRoleChange}
/>
{#if itemField}
	<Field field={itemField} on:event={handleItemChange} />
{/if}
<SetupValueSelect
	dataType="Number"
	scopeType="Game"
	value={role.minimum}
	placeholder="Minimum"
	allowRaw
	on:event={handleMinimumChange}
/>
<SetupValueSelect
	dataType="Number"
	scopeType="Game"
	value={role.maximum}
	placeholder="Maximum"
	allowRaw
	on:event={handleMaximumChange}
/>

{#if role.role}
	<div class="mt-2">
		<Button
			square
			size="small"
			className="error"
			tooltip="Delete Item"
			on:click={deleteRole}
		>
			<Icon name="bin" />
		</Button>
	</div>
{/if}

import NewForum from "./displays/NewForum.svelte";

export function getIndexItems({ currentUser }) {
	if (!currentUser || currentUser.authority < 0) return [];
	return [["plus", NewForum, null, "newForum"]];
}

import NewSectionGroupOrSection from "./displays/NewSectionGroupOrSection.svelte";
import ForumSettings from "./displays/ForumSettings.svelte";
import Admins from "./displays/Admins.svelte";

export function getForumItems({ forum }) {
	const result = [];
	if (forum?.userPermissions?.createSectionGroup) {
		result.push([
			"plus",
			NewSectionGroupOrSection,
			null,
			"newSectionGroupOrSection",
		]);
	}
	if (forum?.userPermissions?.editForum) {
		result.push(["cog", ForumSettings, null, "forumSettings"]);
	}
	result.push(["userCircle", Admins, null, "admins"]);
	return result;
}

import NewTopic from "./displays/NewTopic.svelte";
import SectionSettings from "./displays/SectionSettings.svelte";

export function getSectionItems({ section }) {
	const items = [];
	if (
		section?.userPermissions?.createTopic ||
		section?.userPermissions?.createTopicUnlock
	) {
		items.push(["plus", NewTopic, null, "newTopic"]);
	}
	if (section?.userPermissions?.editSection) {
		items.push(["cog", SectionSettings, null, "sectionSetting"]);
	}
	return items;
}

import TopicSettings from "./displays/TopicSettings.svelte";
import TopicUsers from "./displays/TopicUsers.svelte";

export function getTopicItems({ currentUser, topic }) {
	if (currentUser.authority < 0) return [];
	const items = [["cog", TopicSettings, null, "topicSettings"]];
	const userPermissions = topic?.userPermissions;
	if (userPermissions?.addViewers || userPermissions?.addPosters) {
		items.push(["userCircle", TopicUsers, null, "topicUsers"]);
	}
	return items;
}

<script>
	import paramsStore from "../../../../../stores/paramsStore";
	import setupsStore from "../../../../../stores/data/gameCreator/setupsStore";

	import SetupValueSelect from "./SetupValueSelect.svelte";

	export let listConcatenation;
	export let scopeType;

	$: setupSlug = $paramsStore.setupSlug;
	$: setup = $setupsStore[setupSlug];

	$: listValue = findListValue(listConcatenation.list);
	$: itemDataType = listValue?.dataType.split(" ")[2].slice(0, -1);

	function findListValue(id) {
		if (!id) return;
		for (const scopeType of Object.keys(setup.values)) {
			for (const v of setup.values[scopeType]) {
				if (v.id === id) {
					return v;
				}
			}
		}
	}
</script>

<SetupValueSelect
	bind:value={listConcatenation.list}
	{scopeType}
	dataTypes={[
		"List of Teams",
		"List of Roles",
		"List of Players",
		"List of Actions",
		"List of Chats",
	]}
	label="List"
	placeholder="List"
/>
{#if itemDataType}
	<SetupValueSelect
		bind:value={listConcatenation.itemText}
		scopeType={itemDataType}
		excludedScopeTypes={["Setup", "Game"]}
		dataType="Text"
		label="Item Text"
		placeholder="Item Text"
	/>
	<SetupValueSelect
		bind:value={listConcatenation.separator}
		scopeType="Game"
		dataType="Text"
		label="Separator"
		placeholder="Separator"
		allowRaw
	/>
	<SetupValueSelect
		bind:value={listConcatenation.lastSeparator}
		scopeType="Game"
		dataType="Text"
		label="Last Separator"
		placeholder="Last Separator"
		allowRaw
	/>
{/if}

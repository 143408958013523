import { writable } from "svelte/store";
import API from "../../api/API";

const { subscribe, set, update } = writable([]);

function reset() {
	set([]);
}

function fetchNotifications() {
	API.get("notifications").then(updateNotifications);
}

function updateNotifications(newNotifications) {
	update(() => newNotifications);
}

function addNotification(newNotification) {
	update((notifications) => [
		newNotification,
		...notifications.filter(
			(notification) => notification.id !== newNotification.id
		),
	]);
}

function removeNotification(id) {
	update((notifications) =>
		notifications.filter((notification) => notification.id !== id)
	);
}

export default {
	subscribe,
	reset,
	fetchNotifications,
	addNotification,
	removeNotification,
};

<script>
	import Member from "./Member.svelte";

	$: users = [];
</script>

<div class="flex flex-wrap gap-2">
	{#each users as member (member.slug)}
		<Member {member} />
	{/each}
</div>

<script>
	import { flip } from "svelte/animate";

	import notificationsStore from "../../../../stores/data/notificationsStore";

	import Display from "../../shared/Display.svelte";
	import Transition from "../../shared/Transition.svelte";
	import Notification from "./Notification.svelte";

	$: notifications = $notificationsStore;
</script>

<Display title="Notifications">
	{#each notifications as notification (notification.id)}
		<div animate:flip={{ duration: 200 }}>
			<Transition>
				<Notification {notification} />
			</Transition>
		</div>
	{/each}
</Display>

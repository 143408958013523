import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import redirect from "../util/redirect.js";

class MoveTopicForm extends Form {
	static title = "Move Topic";
	static code = "move-topic";
	static submitValue = "Move";

	static initialFields({ forum, section, topic }) {
		return [
			{
				type: "input",
				inputType: "sections",
				value: section.id,
				label: "Section",
				id: "move-topic-section",
				placeholder: "Section",
				required: true,
			},
			{
				forumSlug: forum.slug,
				sectionSlug: section.slug,
				topicSlug: topic.slug,
			},
		];
	}

	static async submit({ fields }) {
		const newSectionId = fields[0].value;
		const { forumSlug, sectionSlug, topicSlug } = fields[1];
		redirect(`${forumSlug}/forum`);
		return Fetcher.moveTopic({
			forumSlug,
			sectionSlug,
			topicSlug,
			newSectionId,
		});
	}

	static handleSuccess({ forumSlug, sectionSlug, topicSlug }) {
		const url = `${forumSlug}/forum/${sectionSlug}/${topicSlug}/p/1`;
		setTimeout(() => redirect(url), 250);
	}
}

export default MoveTopicForm;

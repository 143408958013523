<script>
	import { onDestroy } from "svelte";

	import currentUserStore from "../../../stores/data/currentUserStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import documentVisibleStore from "../../../stores/documentVisibleStore.js";

	import Domain from "./Domain.svelte";

	import domainsStore from "../../../stores/data/domainsStore.js";
	import domainsChannel from "../../../channels/domainsChannel";

	$: forums = $forumsStore || {};
	$: currentUser = $currentUserStore;

	let channel;

	onDestroy(handleDestroy);

	function handleDestroy() {
		channel?.unsubscribe();
	}

	$: fetchData(currentUser, $documentVisibleStore);

	function fetchData(currentUser, documentVisible) {
		if (!currentUser || !documentVisible) return;
		domainsStore.fetchDomains();
		forumsStore.fetchForumsData();
		channel?.unsubscribe();
		channel = domainsChannel(currentUser);
	}

	$: domains = Object.values($domainsStore);
</script>

<div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
	{#each domains as domain (domain.slug)}
		<Domain {domain} forumData={forums[domain.slug]} />
	{/each}
</div>

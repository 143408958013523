<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";
	import EffectSelect from "./EffectSelect.svelte";

	export let effectLine;
	export let scopeType;
	export let effectId;

	$: dataType = effectLine.effectLineType.split(" ")[0];
	$: objectKey = dataType.toLowerCase();
	$: dataTypes = [dataType, `List of ${[dataType]}s`];

	const dispatch = createEventDispatcher();

	function handleObjectChange({ detail }) {
		effectLine[objectKey] = detail.value;
		dispatch("event", { value: effectLine });
	}

	function handleEffectChange({ detail }) {
		effectLine.effect = detail.value;
		dispatch("event", { value: effectLine });
	}
</script>

{#if dataType !== "Game"}
	<SetupValueSelect
		value={effectLine[objectKey]}
		excluded={effectId}
		{dataType}
		{dataTypes}
		{scopeType}
		allowRaw
		on:event={handleObjectChange}
	/>
{/if}
<EffectSelect
	effect={effectLine.effect}
	excluded={effectId}
	scopeTypes={[dataType]}
	on:event={handleEffectChange}
/>
{#if dataType === "Game"}
	<div></div>
{/if}
<div></div>

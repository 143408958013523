<script>
	import { onMount } from "svelte";

	import FormModal from "../../../../shared/modal/FormModal.svelte";

	import ForgotPasswordForm from "../../../../../forms/ForgotPasswordForm.js";

	export let formModal;

	onMount(handleMount);

	function handleMount() {}
</script>

<FormModal bind:this={formModal} form={ForgotPasswordForm} />

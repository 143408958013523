<script>
	import ReorderButton from "./ReorderButton.svelte";

	export let sizeStyle;
	export let width;
	export let height;

	$: sizeStyle = getSizeStyle(width, height);

	function getSizeStyle(width, height) {
		if (!width || !height) return "";
		return ` width: ${width}px; height: ${height}px;`;
	}
</script>

<div
	class="flex w-full invisible"
	bind:clientWidth={width}
	bind:clientHeight={height}
>
	<div class="flex-grow">
		<slot />
	</div>
	<div>
		<ReorderButton disabled />
	</div>
</div>

<script>
	import { createEventDispatcher } from "svelte";

	import SetupValueSelect from "../values/SetupValueSelect.svelte";

	export let standardAssignmentItem;

	const dispatch = createEventDispatcher();

	function handleRoleChange({ detail }) {
		if (detail.label === " ") {
			dispatch("remove");
			return;
		}
		dispatch("event", {
			value: { ...standardAssignmentItem, role: detail.value },
		});
	}

	function handlePlayerCountMinimumChange({ detail }) {
		dispatch("event", {
			value: { ...standardAssignmentItem, playerCountMinimum: detail.value },
		});
	}

	function handlePlayerCountMaximumChange({ detail }) {
		dispatch("event", {
			value: { ...standardAssignmentItem, playerCountMaximum: detail.value },
		});
	}

	function handleConditionChange({ detail }) {
		dispatch("event", {
			value: { ...standardAssignmentItem, condition: detail.value },
		});
	}
</script>

<SetupValueSelect
	value={standardAssignmentItem.role}
	dataType="Role"
	scopeType="Game"
	allowRaw
	allowNull={!!standardAssignmentItem.role}
	on:event={handleRoleChange}
/>
{#if standardAssignmentItem.role}
	<SetupValueSelect
		value={standardAssignmentItem.playerCountMinimum}
		dataType="Number"
		scopeType="Game"
		allowRaw
		placeholder="Player Count Minimum"
		on:event={handlePlayerCountMinimumChange}
	/>
	<SetupValueSelect
		value={standardAssignmentItem.playerCountMaximum}
		dataType="Number"
		scopeType="Game"
		allowRaw
		placeholder="Player Count Maximum"
		on:event={handlePlayerCountMaximumChange}
	/>
	<SetupValueSelect
		value={standardAssignmentItem.condition}
		dataType="True or False"
		scopeType="Game"
		allowRaw
		placeholder="Condition"
		on:event={handleConditionChange}
	/>
{:else}
	<div></div>
	<div></div>
	<div></div>
{/if}

import API from "./API";
import { getJwt } from "./jwt";
import fetchCurrentUser from "./fetchCurrentUser.js";

class Fetcher {
	// POST START

	static register({ username, password, emailAddress, transferGuestData }) {
		const url = "users";
		const body = {
			user: {
				username,
				password,
				email_address: emailAddress,
			},
			transfer_guest_data: transferGuestData,
		};
		return API.post(url, body);
	}

	static login({ username, password }) {
		const url = "login";
		const body = {
			user: {
				username,
				password,
			},
		};
		return API.post(url, body);
	}

	static createDomain({
		title,
		description,
		urlType,
		domainPermissions,
		forumPermissions,
	}) {
		const body = {
			url_type: urlType,
			domain: {
				title,
				description,
				permissions: domainPermissions,
				forum_attributes: {
					permissions: forumPermissions,
				},
			},
		};
		return API.post("domains", body);
	}

	static createTopicIntent({ topic }) {
		const url = `forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/topic_intents`;
		return API.post(url);
	}

	static createPost({ topic, draftId, intentId }) {
		const url = `forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/posts`;
		const body = {
			draft_id: draftId,
			intent_id: intentId,
		};
		return API.post(url, body);
	}

	static createSection({
		forumSlug,
		sectionGroup,
		title,
		description,
		urlType,
		permissions,
	}) {
		const url = `forums/${forumSlug}/section_groups/${sectionGroup.id}/sections`;
		const body = {
			url_type: urlType,
			section: {
				title,
				description,
				permissions,
			},
		};
		return API.post(url, body);
	}

	static createSectionGroup({ forumSlug, title, permissions }) {
		const url = `forums/${forumSlug}/section_groups`;
		const body = {
			section_group: {
				title,
				permissions,
			},
		};
		return API.post(url, body);
	}

	static createTopic({ section, title, urlType, permissions }) {
		const url = `forums/${section.forumSlug}/sections/${section.slug}/topics`;
		const body = {
			url_type: urlType,
			topic: {
				title,
				permissions,
			},
		};
		return API.post(url, body);
	}

	static bookmark({ post }) {
		return API.post(`posts/${post.id}/bookmark`);
	}

	static createDraft({ text }) {
		const url = `drafts`;
		const body = {
			draft: { text },
		};
		return API.post(url, body);
	}

	static forgotPassword({ input }) {
		const url = "forgot_password";
		const body = { input };
		return API.post(url, body);
	}

	static resetPassword({ username, code, password }) {
		const url = "reset_password";
		const body = {
			username,
			code,
			password,
		};
		return API.post(url, body);
	}

	static createPostDraft({ post }) {
		const url = `posts/${post.id}/create_draft`;
		return API.post(url);
	}

	// POST END

	// GET START

	static findTopic({ topicSlug }) {
		return API.get(`find_topic/${topicSlug}`);
	}

	static findSection({ sectionSlug }) {
		return API.get(`find_section/${sectionSlug}`);
	}

	static requestTopicCover({ topicId }) {
		return API.get(`request_topic_cover/${topicId}`);
	}

	static requestTopicSectionData({ topicId, topicSlug }) {
		if (topicId) {
			return API.get(`request_topic_section_data_by_id/${topicId}`);
		}
		if (topicSlug) {
			return API.get(`request_topic_section_data_by_slug/${topicSlug}`);
		}
	}

	static sectionAndTopicPermissions({ forumSlug, sectionSlug, topicSlug }) {
		const url = `section_and_topic_permissions/${forumSlug}/${sectionSlug}/${topicSlug}`;
		return API.get(url);
	}

	static sectionGroupAndSectionPermissions({ forumSlug, sectionSlug }) {
		const url = `section_group_and_section_permissions/${forumSlug}/${sectionSlug}`;
		return API.get(url);
	}

	static forumAndSectionGroupPermissions({ forumSlug, sectionId }) {
		const url = `forum_and_section_group_permissions/${forumSlug}/${sectionSlug}`;
		return API.get(url);
	}

	static domainAndForumPermissions({ forumSlug }) {
		const url = `domain_and_forum_permissions/${forumSlug}`;
		return API.get(url);
	}

	static allPermissions({ forumSlug }) {
		const url = `all_permissions/${forumSlug}`;
		return API.get(url);
	}

	// GET END

	// PUT START

	static updateDraft({ draft, text }) {
		const url = `drafts/${draft.id}`;
		const body = {
			draft: { text },
		};
		return API.put(url, body);
	}

	static updateProfile({ avatarDefault, avatarUrl, timeZone, showTimeZone }) {
		const body = {
			profile: {
				avatar_default: avatarDefault,
				avatar_url: avatarUrl,
				time_zone: timeZone,
				show_time_zone: showTimeZone,
			},
		};
		return API.put("update_profile", body);
	}

	static updateSettings({ pageSize, theme, quoteDepth }) {
		const body = {
			settings: {
				page_size: pageSize,
				theme,
				quote_depth: quoteDepth,
			},
		};
		return API.put("update_settings", body);
	}

	static markPostAsSpam({ post }) {
		return API.put(`posts/${post.id}/mark_as_spam`);
	}

	static unmarkPostAsSpam({ post }) {
		return API.put(`posts/${post.id}/unmark_as_spam`);
	}

	static makeForumAdmin({ forumSlug, user }) {
		const body = {
			user: {
				slug: user.slug,
			},
		};
		return API.put(`forums/${forumSlug}/make_admin`, body);
	}

	static removeForumAdmin({ forumSlug, user }) {
		const body = {
			user: {
				slug: user.slug,
			},
		};
		return API.put(`forums/${forumSlug}/remove_admin`, body);
	}

	static makeTopicViewer({ forumSlug, sectionSlug, topicSlug, user }) {
		const url = `forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}/make_viewer`;
		const body = {
			user: {
				slug: user.slug,
			},
		};
		return API.put(url, body);
	}

	static makeTopicPoster({ forumSlug, sectionSlug, topicSlug, user }) {
		const url = `forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}/make_poster`;
		const body = {
			user: {
				slug: user.slug,
			},
		};
		return API.put(url, body);
	}

	static removeTopicPoster({ forumSlug, sectionSlug, topicSlug, user }) {
		const url = `forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}/remove_poster`;
		const body = {
			user: {
				slug: user.slug,
			},
		};
		return API.put(url, body);
	}

	static updateForum({
		forumSlug,
		domainPermissions,
		description,
		forumPermissions,
		urlType,
		sectionGroups,
	}) {
		const body = {
			...(urlType ? { url_type: urlType } : {}),
			...(domainPermissions || description
				? {
						domain: {
							...(domainPermissions ? { permissions: domainPermissions } : {}),
							...(description ? { description } : {}),
						},
				  }
				: {}),
			...(forumPermissions ? { forum: { permissions: forumPermissions } } : {}),
			...(sectionGroups ? { section_groups: sectionGroups } : {}),
		};
		return API.put(`forums/${forumSlug}`, body);
	}

	static saveEditedSections({ forumSlug, editSections, urlType }) {
		return Fetcher.updateForum({
			forumSlug,
			urlType,
			sectionGroups: editSections.map((sectionGroup) => ({
				id: sectionGroup.id,
				title: sectionGroup.title,
				new: sectionGroup.new,
				sections:
					sectionGroup.sections ||
					sectionGroup.sectionSlugs
						.map((sectionSlug) => $sectionsStore[sectionSlug])
						.map((section) => ({
							id: section.id,
							title: section.title,
							new: section.new,
						})),
			})),
		});
	}

	static subscribe({ topic }) {
		return API.put(
			`forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/subscribe`
		);
	}

	static unsubscribe({ topic }) {
		return API.put(
			`forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/unsubscribe`
		);
	}

	static moveTopic({ forumSlug, sectionSlug, topicSlug, newSectionId }) {
		const url = `forums/${forumSlug}/sections/${sectionSlug}/topics/${topicSlug}/move_to`;
		const body = {
			section: {
				id: newSectionId,
			},
		};
		return API.put(url, body);
	}

	static updateUserTopic({ topic, lastViewedPostNumber }) {
		const url = `forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/user_topic`;
		const lvpn = lastViewedPostNumber
			? { last_viewed_post_number: lastViewedPostNumber }
			: {};
		const body = {
			user_topic: {
				...lvpn,
			},
		};
		return API.put(url, body);
	}

	static updateSectionGroup({ forum, sectionGroup, permissions }) {
		const url = `forums/${forum.slug}/section_groups/${sectionGroup.id}`;
		const body = {
			section_group: {
				permissions,
			},
		};
		return API.put(url, body);
	}

	static updateSection({ forumSlug, sectionSlug, permissions }) {
		const url = `forums/${forumSlug}/sections/${sectionSlug}`;
		const body = {
			section: {
				permissions,
			},
		};
		return API.put(url, body);
	}

	static updateTopic({ topic, permissions }) {
		const url = `forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}`;
		const body = {
			topic: {
				permissions,
			},
		};
		return API.put(url, body);
	}

	static createTopicUnlock({ section, password }) {
		const url = `forums/${section.forumSlug}/sections/${section.slug}/create_topic_unlock`;
		const body = {
			password,
		};
		return API.put(url, body);
	}

	static postUnlock({ topic, password }) {
		const url = `forums/${topic.forumSlug}/sections/${topic.sectionSlug}/topics/${topic.slug}/post_unlock`;
		const body = {
			password,
		};
		return API.put(url, body);
	}

	static changePassword({ currentPassword, newPassword }) {
		const url = "change_password";
		const body = {
			current_password: currentPassword,
			new_password: newPassword,
		};
		return API.put(url, body);
	}

	static updateEmail({ emailAddress, confirmationCode }) {
		const url = "update_email";
		const body = {};
		if (emailAddress) body.email_address = emailAddress;
		if (confirmationCode) body.confirmation_code = confirmationCode;
		return API.put(url, body);
	}

	static editPost({ draftId }) {
		const url = "edit_post";
		const body = { draft_id: draftId };
		return API.put(url, body);
	}

	// PUT END

	// DELETE START

	static deleteDraft({ draftId }) {
		const url = `drafts/${draftId}`;
		return API.delete(url);
	}

	static deleteNotification({ notification }) {
		const url = `notifications/${notification.id}`;
		return API.delete(url);
	}

	static deleteSpam({ post }) {
		if (!post.spam) return;
		const url = `posts/${post.id}/destroy_spam`;
		return API.delete(url);
	}

	static unbookmark({ post, bookmark }) {
		if (post) return API.delete(`posts/${post.id}/unbookmark`);
		return API.delete(`bookmarks/${bookmark.id}`);
	}

	static deleteGuest() {
		const url = `destroy_guest`;
		return API.delete(url);
	}

	// DELETE END

	// POST FORM START

	static uploadAvatar(avatar, depth = 1) {
		if (getJwt() !== "guest") {
			const url = "upload_avatar";
			const body = { avatar };
			return API.postForm(url, body).then(() => {
				fetchCurrentUser();
				setTimeout(fetchCurrentUser, 2500);
			});
		}
		setTimeout(() => this.uploadAvatar(avatar, depth + 1), 500 * depth);
	}

	// POST FORM END
}

export default Fetcher;

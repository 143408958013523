<script>
	import Fetcher from "../../../../api/Fetcher";

	import paramsStore from "../../../../stores/paramsStore.js";
	import forumsStore from "../../../../stores/data/forumsStore.js";
	import editSectionsStore from "../../../../stores/editSectionsStore.js";

	import Button from "../../../tailwind/Button.svelte";

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: editSections = $editSectionsStore[forumSlug];
	$: urlType = $editSectionsStore.urlType;

	function handleSave() {
		Fetcher.saveEditedSections({ forumSlug, editSections, urlType }).then(
			(json) => {
				if (json.slug) handleCancel();
			}
		);
	}

	function handleCancel() {
		editSectionsStore.remove(forumSlug);
	}
</script>

<div class="flex gap-2">
	<div>
		<Button on:click={handleSave}>Save</Button>
	</div>
	<div>
		<Button className="default" on:click={handleCancel}>Cancel</Button>
	</div>
</div>

<script>
	import Fetcher from "../../../../api/Fetcher";

	import paramsStore from "../../../../stores/paramsStore";
	import topicsStore from "../../../../stores/data/topicsStore";

	import Transition from "../../shared/Transition.svelte";
	import UserBadge from "../../../shared/UserBadge.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let user;

	$: forumSlug = $paramsStore.forumSlug;
	$: sectionSlug = $paramsStore.sectionSlug;
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];

	function handleRemoveClick() {
		Fetcher.removeTopicPoster({ forumSlug, sectionSlug, topicSlug, user });
	}
</script>

<Transition>
	<div class="flex gap-1">
		<div class="flex-grow"></div>
		<UserBadge {user} size="lg" />
		{#if topic?.userPermissions.addPosters && !user.super}
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<div
				class="text-error cursor-pointer tooltip z-50"
				data-tip="Remove"
				on:click={handleRemoveClick}
			>
				<Icon name="x" />
			</div>
		{/if}
		<div class="flex-grow"></div>
	</div>
</Transition>

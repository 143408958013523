import redirect from "./redirect";
import Fetcher from "../api/Fetcher";
import scrollStore from "../stores/scrollStore";

export function redirectToPost({
	topic,
	forumSlug,
	sectionSlug,
	gameSlug,
	topicSlug,
	currentTopicSlug,
	postNumber,
}) {
	if (topic) {
		forumSlug ||= topic.forumSlug;
		sectionSlug ||= topic.sectionSlug;
		topicSlug ||= topic.slug;
		gameSlug ||= topic.gameSlug;
	}
	if (postOnPage(topicSlug, currentTopicSlug, postNumber)) return;
	const url =
		forumSlug && sectionSlug
			? `${forumSlug}/forum/${sectionSlug}/${topicSlug}/post/${postNumber}`
			: `post-redirect/${topicSlug}/post/${postNumber}`;
	redirect(url);
}

function postOnPage(topicSlug, currentTopicSlug, postNumber) {
	if (
		topicSlug &&
		topicSlug === currentTopicSlug &&
		postNumber != null &&
		document.getElementById(postNumber)
	) {
		scrollStore.setId(postNumber);
		return true;
	}
	// if (!forumSlug && !sectionSlug && !topicSlug) return;
	// redirectTo({
	// 	domainSlug,
	// 	forumSlug,
	// 	sectionSlug,
	// 	gameSlug,
	// 	topicSlug,
	// 	pageNumber,
	// 	tag,
	// });
}

export function redirectTo({
	topic,
	domainSlug,
	forumSlug,
	sectionSlug,
	gameSlug,
	topicSlug,
	pageNumber = 1,
	tag = "",
}) {
	if (topic) {
		forumSlug ||= topic.forumSlug;
		sectionSlug ||= topic.sectionSlug;
		topicSlug ||= topic.slug;
	}
	if (topicSlug && !(forumSlug && sectionSlug) && !gameSlug) {
		return findAndRedirectToTopic({ topicSlug, pageNumber, tag });
	}
	if (sectionSlug && !forumSlug) {
		return findAndRedirectToSection({ sectionSlug });
	}
	if (topicSlug && gameSlug && domainSlug) {
		return redirect(
			`${domainSlug}/game/${gameSlug}/${topicSlug}/p/${pageNumber}`
		);
	}
	if (!sectionSlug) return redirect(`${forumSlug}/forum`);
	if (!topicSlug) return redirect(`${forumSlug}/forum/${sectionSlug}/p/1`);
	if (forumSlug && sectionSlug && topicSlug) {
		const url = `${forumSlug}/forum/${sectionSlug}/${topicSlug}/p/${pageNumber}${tag}`;
		redirect(url);
	}
}

function findAndRedirectToTopic({ topicSlug, pageNumber, tag }) {
	Fetcher.findTopic({ topicSlug }).then((json) => {
		const { forumSlug, sectionSlug } = json;
		if (forumSlug && sectionSlug)
			redirectTo({ forumSlug, sectionSlug, topicSlug, pageNumber, tag });
	});
}

function findAndRedirectToSection({ sectionSlug }) {
	Fetcher.findSection({ sectionSlug }).then((json) => {
		const { forumSlug } = json;
		if (forumSlug) {
			redirectTo({ forumSlug, sectionSlug });
		}
	});
}

<script>
	import { getContext } from "svelte";

	import Fetcher from "../../../../../api/Fetcher.js";
	import fetchCurrentUser from "../../../../../api/fetchCurrentUser.js";
	import { clearJwt } from "../../../../../api/jwt.js";
	import redirect from "../../../../../util/redirect.js";

	import Button from "../../../../tailwind/Button.svelte";

	const clearDraft = getContext("clearDraft");

	function handleClick() {
		Fetcher.deleteGuest().then(() => {
			redirect("");
			clearJwt();
			setTimeout(() => {
				fetchCurrentUser();
				clearDraft();
			}, 200);
		});
	}
</script>

<Button
	id="delete-guest-button"
	className="neutral"
	extraClasses="w-full"
	on:click={handleClick}
>
	Clear Guest Data
</Button>

<script>
	import Card from "../tailwind/card/Card.svelte";
	import CardBody from "../tailwind/card/CardBody.svelte";
	import CardTitle from "../tailwind/card/CardTitle.svelte";
	import CardIcon from "./CardIcon.svelte";
	import Bans from "./Bans.svelte";

	export let card;

	let show = false;

	export function hide() {
		show = false;
	}

	export function unhide() {
		show = true;
	}

	function handleClick() {
		show = !show;
	}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="cursor-pointer" on:click={handleClick}>
	<Card>
		<CardBody>
			<CardTitle>
				<div class="flex gap-2 w-full">
					<div>
						{card.name}
					</div>
					<div class="grow"></div>
					<div>
						<CardIcon icon={card.icon} />
					</div>
				</div>
			</CardTitle>
			{#if show}
				<div class="italic">{card.description}</div>
				<div class="bg-base-300 py-2 px-3 rounded-xl">
					{#each card.effects as effect}
						{#each effect.text as text, i}
							<div>
								{#if i === 0}<span class="font-bold">{effect.title}</span>{/if}
								{text}
							</div>
						{/each}
					{/each}
				</div>
				<Bans {card} />
				<div class="italic">{card.set}</div>
			{/if}
		</CardBody>
	</Card>
</div>

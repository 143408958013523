<script>
	import { onMount, setContext } from "svelte";
	import { writable } from "svelte/store";

	export let tab = null;
	export let activeTab = null;
	export let minHeight = 0;
	export let clientHeight = null;

	let mounted = false;

	const activeTabStore = writable(false);

	setContext("activeTabStore", activeTabStore);

	$: handleActiveTabChange(activeTab);

	onMount(async () => {
		mounted = true;
	});

	function handleActiveTabChange(activeTab) {
		activeTabStore.set(tab === activeTab);
	}
</script>

{#if tab}
	<div
		class:hidden={mounted && tab !== activeTab}
		style="min-height: {minHeight}px"
		bind:clientHeight
	>
		<slot />
	</div>
{:else}
	<slot />
{/if}

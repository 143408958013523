<script>
	import { createEventDispatcher } from "svelte";
	import LotteryItem from "./LotteryItem.svelte";

	export let items;

	const dispatch = createEventDispatcher();

	$: itemsToShow = getItemsToShow(items);

	function getItemsToShow(items) {
		if (items.find((item) => !item.name || !item.label)) {
			return [...items];
		}
		return [
			...items,
			{
				name: "",
				label: "",
				chances: null,
				effect: null,
			},
		];
	}

	function handleItemChange(i, detail) {
		items[i] = detail.value;
		dispatch("event", { value: items });
	}

	function destroyItem(i) {
		dispatch("event", { value: [...items.slice(0, i), ...items.slice(i + 1)] });
	}
</script>

<div
	class="grid gap-2"
	style="grid-template-columns: repeat(4, minmax(0, 1fr)) 32px;"
>
	{#each itemsToShow as item, i}
		<LotteryItem
			{item}
			on:event={({ detail }) => handleItemChange(i, detail)}
			on:destroy={() => destroyItem(i)}
		/>
	{/each}
</div>
